import { notification, Spin } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { usePrevious, useUnmount } from 'react-use';
import MapWithPads from '../../components/mapWithPads';
import {
  cleanUsersListMap,
  getUsersListMap,
} from '../../store/actions/userListMapAction';
import axiosInstance from '../../utils/apiConfig';
import formattingFilters from '../../utils/formattingFilters';
import getApiErrorMessages from '../../utils/getApiErrorMessages';

const parseMapUsers = (data) => {
  if (data) {
    return data.map((user) => ({
      ...user,
      category_id: user?.deleted_at || user.is_deleted_account ? 9 : 8,
      latitude: Number(user.latitude),
      longitude: Number(user.longitude),
    }));
  }

  return [];
};

const UserListMap = (props) => {
  const { filters, keyword, cleanOnUnmount, onDisableAction } = props;

  const [loading, setLoading] = React.useState(false);
  const [map, setMap] = React.useState();

  const previousKeyword = usePrevious(keyword);
  const previousFilters = usePrevious(filters);

  const cancelTokenSource = React.useRef();
  const mapContainer = React.useRef();

  const { userListMap } = useSelector((store) => ({
    userListMap: store.userListMap.payload,
  }));

  const dispatch = useDispatch();

  const getUsersForMap = React.useCallback(
    async (params) => {
      setLoading(true);

      cancelTokenSource.current = axiosInstance.CancelToken.source();

      try {
        await getUsersListMap({
          params,
          cancelToken: cancelTokenSource.current.token,
        })(dispatch);
        setLoading(false);
      } catch (error) {
        if (!axios.isCancel(error)) {
          notification.error({
            message: 'Error',
            description: getApiErrorMessages(error),
          });
          onDisableAction();
        }
      }
    },
    [onDisableAction, dispatch],
  );

  React.useEffect(() => {
    const checks = [
      JSON.stringify(previousFilters) !== JSON.stringify(filters),
      previousKeyword !== keyword,
    ];

    if (checks.some(Boolean)) {
      const advancedFilters = {};

      filters.forEach((filter) => {
        const temp_filter = formattingFilters(filter, filter.keyword, 'STRING');
        Object.keys(temp_filter).forEach((key) => {
          advancedFilters[`filters[${key}]`] = temp_filter[key];
        });
      });

      advancedFilters.keyword = keyword;

      // Cancel previous request
      if (loading) cancelTokenSource.current.cancel();

      getUsersForMap(advancedFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, keyword, getUsersForMap, loading]);

  useUnmount(() => {
    if (cleanOnUnmount) {
      cleanUsersListMap()(dispatch);

      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel();
      }
    }
  });

  return (
    <div className="map-container" ref={mapContainer}>
      <Spin spinning={loading}>
        <MapWithPads
          height={400}
          zoom={5}
          onReady={(_, _map) => setMap(_map)}
          map={map}
          readOnly
          pads={parseMapUsers(userListMap.data)}
          containerClassName="mb-2_5"
          displayCategories={false}
          showLocationPin={false}
          urlTarget="/dashboard/user-view"
          clustererConfig={{
            gridSize: 45,
          }}
        />
      </Spin>
    </div>
  );
};

UserListMap.propTypes = {
  filters: PropTypes.array,
  onDisableAction: PropTypes.func,
  cleanOnUnmount: PropTypes.bool,
  keyword: PropTypes.string,
};

export default UserListMap;
