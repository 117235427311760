import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

export function SurveyLoading() {
  return (
    <div className="survey-layout pt-5 pb-5 mt-5 mb-5 d-flex align-items-center justify-content-center">
      <div className="survey-loading">
        <div className="survey-loading__spin d-flex align-items-center flex-column">
          <Spin
            spinning
            indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />}
          />
          <span className="d-block survey-loading__text mt-3">
            Loading survey data...
          </span>
        </div>
      </div>
    </div>
  );
}
