import { useState, useCallback, useEffect, useContext } from 'react';
import { useUnmount } from 'react-use';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  notification,
  Row,
  Col,
  Card,
  Typography,
  Table,
  Pagination,
  Popconfirm,
  Popover,
  Button,
  Spin,
  Timeline,
  Drawer,
} from 'antd';
import dayjs from 'dayjs';
import {
  dateFormats,
  moderationTypes,
  userRolesStr,
} from '../../../../utils/constants';
import api from '../../../../utils/appApi';
import NotesList from './parts/notesList';
import InternalNotesDrawerFooter from './parts/notesDrawerFooter';
import AddOwner from './parts/addOwner';
import buildTableColumns from '../../../../utils/buildTableColumns';
import { setCleanOnUnmountFalse } from '../../../../store/actions/padListAction';
import FullsizeReport from './parts/fullsizeReport';
import getApiErrorMessages from '../../../../utils/getApiErrorMessages';

import styles from './adminTab.module.sass';
import PadViewContext from '../../PadView.context';
import { OwnersList } from './parts/OwnersList';
import CardinalDirectionIcon from '../../../../components/CardinalDirectionIcon';

const ignoreRevisionType = {
  'Reported upload': {
    getId: (revision) => revision?.upload_report?.id,
    action: api.upload.ignoreRevision,
  },
  'Reported review': {
    getId: (revision) => revision?.review_report?.id,
    action: api.reviews.ignoreRevision,
  },
  'Reported pad': {
    getId: (revision) => revision?.id,
    action: api.pad.cancelRevision,
  },
};

const acceptRevisionType = {
  'Reported upload': {
    getId: (revision) => revision?.upload_report?.id,
    action: api.upload.acceptRevision,
  },
  'Reported review': {
    getId: (revision) => revision?.review_report?.id,
    action: api.reviews.acceptRevision,
  },
  'Reported pad': {
    getId: (revision) => revision?.id,
    action: api.pad.acceptRevision,
  },
};

const AdminTab = (props) => {
  const { ...rest } = props;
  const { pad, updatePad, padViewState, updatePadViewState } = useContext(
    PadViewContext,
  );
  const [logs, setLogs] = useState();
  const [reports, setReports] = useState();
  const [owners, setOwners] = useState();
  const [logsPage, setLogsPage] = useState(1);
  const [reportsPage, setReportsPage] = useState(1);
  const [reportActionLoading, setReportActionLoading] = useState(false);
  const [fullsizeReportStatus, setFullsizeReportStatus] = useState({
    data: {},
    status: false,
  });
  const [internalNotesDrawerVisible, setInternalNotesDrawerVisible] = useState(
    false,
  );
  const history = useHistory();

  // Checking for pad actions
  useEffect(() => {
    if (reports?.data && owners) {
      const ownersForClaim = owners.filter((owner) => !owner.is_claimed);

      if (!ownersForClaim.length && !reports?.data.length) {
        updatePad({ resolvable_actions: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports, owners]);

  const shortNotesPreview =
    pad?.pad_notes?.slice(
      pad?.pad_notes?.length - (pad?.pad_notes?.length > 1 ? 2 : 1),
    ) || [];

  const openInternalNotesDrawer = useCallback(
    () => setInternalNotesDrawerVisible(true),
    [],
  );

  const closeInternalNotesDrawer = useCallback(
    () => setInternalNotesDrawerVisible(false),
    [],
  );

  useEffect(() => {
    if (pad?.id)
      api.pad
        .getLogs(pad?.id, { params: { page: logsPage } })
        .then((res) => setLogs(res.data));
  }, [pad?.id, logsPage]);

  const paginationOnChangeHandle = useCallback((page) => {
    setLogsPage(page);
  }, []);

  const paginationReportsHandle = useCallback((page) => {
    setReportsPage(page);
  }, []);

  const getOwnerList = useCallback(() => {
    if (pad?.id) {
      api.pad.getOwners(pad?.id).then((res) => {
        setOwners(res.data);
      });
    }
  }, [pad?.id, setOwners]);

  const addOwnerHandle = useCallback(
    (user) => {
      if (pad?.id)
        api.pad.addOwner(pad?.id, user.id).then((res) => {
          getOwnerList();
          updatePad(res.data.data);
        });
    },
    [pad?.id, getOwnerList, updatePad],
  );

  const removeNoteHandle = useCallback(
    (noteId) =>
      api.pad
        .deleteNote(noteId)
        .then((res) => {
          updatePad(res.data.data);
          return Promise.resolve();
        })
        .catch(() => Promise.reject()),
    [updatePad],
  );

  const editNoteHandle = useCallback(
    (noteId, value) =>
      noteId && value
        ? api.pad.updateNote(noteId, { note: value }).then((res) => {
            updatePad(res.data.data);
          })
        : null,
    [updatePad],
  );
  const dispatch = useDispatch();

  useEffect(() => {
    api.pad.getReports(pad?.id).then((res) => {
      setReports({
        ...res.data,
        data: res.data.data.map((el) => ({
          ...el,
          type: moderationTypes[el.type],
        })),
      });
    });
  }, [pad?.id]);

  const cancelFullsizeReportHandler = useCallback(
    () =>
      setFullsizeReportStatus((prevState) => ({ ...prevState, status: false })),
    [],
  );

  const acceptBtnHandle = useCallback(
    (revision, type, clbk = null) => () => {
      setReportActionLoading(true);
      const revisionId = acceptRevisionType[type].getId(revision);

      acceptRevisionType[type]
        .action(revisionId)
        .then(() => {
          setReports((prevState) => ({
            ...prevState,
            data: prevState.data.filter((itm) => itm.id !== revision?.id),
          }));
          setReportActionLoading(false);
          if (clbk) cancelFullsizeReportHandler();
          history.push({
            pathname: history.location.pathname,
            search: '?tab=contact&edit_mode=true',
          });
        })
        .catch((err) => {
          notification.error({
            message: 'Error',
            description: getApiErrorMessages(err),
          });
          if (clbk) cancelFullsizeReportHandler();
          setReportActionLoading(false);
        });
    },
    [cancelFullsizeReportHandler, history],
  );

  const ignoreBtnHandle = useCallback(
    (revision, type, clbk) => () => {
      setReportActionLoading(true);
      const revisionId = ignoreRevisionType[type].getId(revision);

      ignoreRevisionType[type]
        .action(revisionId)
        .then(() => {
          setReports((prevState) => ({
            ...prevState,
            data: prevState.data.filter((itm) => itm.id !== revision?.id),
          }));
          setReportActionLoading(false);
          if (clbk) cancelFullsizeReportHandler();
        })
        .catch((err) => {
          notification.error({
            message: 'Error',
            description: getApiErrorMessages(err),
          });
          if (clbk) cancelFullsizeReportHandler();
          setReportActionLoading(false);
        });
    },
    [cancelFullsizeReportHandler],
  );

  useEffect(() => {
    if (reports && reports.data.length) {
      const reportedIdEl = reports?.data.find(
        (report) => report.id === parseInt(padViewState?.reported_id, 10),
      );

      if (reportedIdEl) {
        setFullsizeReportStatus((prevState) => ({
          ...prevState,
          data: reportedIdEl,
          status: true,
        }));
      }
    }
  }, [padViewState?.reported_id, reports]);

  useUnmount(() => {
    if (padViewState?.reported_id) updatePadViewState({ reported_id: null });
  });

  const setFullsizeReportReview = (revision) =>
    setFullsizeReportStatus((prevState) => ({
      ...prevState,
      data: revision,
      status: true,
    }));

  const reportPopoverContent = (rowData) => {
    if (rowData.type === 'Reported upload') {
      return (
        <div className={styles.reportedImage}>
          <div
            className={`${styles.reportedImage__image} direction-relative-top-right`}
          >
            <div className="image">
              <img
                src={rowData?.upload_report?.upload.file_url}
                alt="Reported"
              />
              {true && (
                <CardinalDirectionIcon
                  direction={rowData?.upload_report?.upload?.direction}
                  width={30}
                />
              )}
            </div>
          </div>
        </div>
      );
    }

    return '';
  };

  const updatePadContacts = useCallback(
    (contact) => {
      const newPad = { ...pad };
      newPad.contacts.push(contact);

      updatePad(newPad);
      setOwners([...owners, contact]);
    },
    [owners, pad, updatePad],
  );

  return (
    <div {...rest}>
      <Row gutter={[16, 16]}>
        {!!reports?.data.length && (
          <Col span={24}>
            <div className="mb-2" style={{ minHeight: 32 }}>
              <Typography.Title level={4} className="mb-0">
                Reports section
              </Typography.Title>
            </div>
            <Card
              className={`${styles.timelineContainer} ${styles.timelineContainer__custom}`}
              actions={[
                <Pagination
                  size="small"
                  showSizeChanger={false}
                  showQuickJumper
                  onChange={paginationReportsHandle}
                  pageSize={reports?.meta.per_page || 25}
                  total={reports?.meta.total}
                  current={reportsPage}
                />,
              ]}
            >
              <Table
                columns={[
                  ...buildTableColumns(reports?.columns, {
                    promisedClbk: () => setCleanOnUnmountFalse()(dispatch),
                    type: {
                      render: (data, rowData) => {
                        if (
                          data === 'Reported review' ||
                          data === 'Reported pad'
                        ) {
                          return (
                            <Button
                              onClick={() => setFullsizeReportReview(rowData)}
                            >
                              {rowData.type}
                            </Button>
                          );
                        }
                        return (
                          <Popover
                            content={reportPopoverContent(rowData)}
                            title={
                              rowData.type === 'Reported upload'
                                ? 'Picture preview'
                                : 'Comment'
                            }
                            trigger="click"
                          >
                            <Button>{rowData.type}</Button>
                          </Popover>
                        );
                      },
                    },
                  }),
                  {
                    title: 'Action',
                    key: 'action',
                    render: (rowData) => (
                      <div className="d-flex">
                        <Popconfirm
                          title="Are you sure to remove this revision?"
                          onConfirm={acceptBtnHandle(rowData, rowData.type)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="primary" className="mr-2">
                            Remove
                          </Button>
                        </Popconfirm>

                        <Popconfirm
                          title="Are you sure to ignore this revision?"
                          onConfirm={ignoreBtnHandle(rowData, rowData.type)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button>Ignore</Button>
                        </Popconfirm>
                      </div>
                    ),
                  },
                ]}
                rowKey={(e) => e.id}
                dataSource={reports?.data}
                loading={!reports?.data || reportActionLoading}
                pagination={false}
              />
            </Card>
            <FullsizeReport
              visible={fullsizeReportStatus.status}
              onRevisionIgnore={ignoreBtnHandle}
              onRevisionAccept={acceptBtnHandle}
              onCancel={cancelFullsizeReportHandler}
              revision={fullsizeReportStatus.data}
            />
          </Col>
        )}
        <Col span={12}>
          <div style={{ marginBottom: 16 }}>
            <div
              className="d-flex justify-content-between mb-2"
              style={{ minHeight: 32 }}
            >
              <Typography.Title level={4} className="mb-0">
                Owners
              </Typography.Title>
              <AddOwner
                onAddOwner={addOwnerHandle}
                updatePadContacts={updatePadContacts}
                isArchived={!!pad?.deleted_at}
              />
            </div>
            <OwnersList
              padId={pad?.id}
              updatePad={updatePad}
              owners={owners}
              getOwnerList={getOwnerList}
            />
          </div>
          <div>
            <div className="d-flex justify-content-between mb-2">
              <Typography.Title level={4} className="mb-0">
                Internal notes
              </Typography.Title>
              <Button htmlType="button" onClick={openInternalNotesDrawer}>
                Add note
              </Button>
            </div>
            <NotesList
              loading={{
                spinning: !pad?.pad_notes,
                wrapperClassName: styles.internalNotes,
              }}
              dataSource={
                pad?.pad_notes?.length > 2
                  ? [{}, ...shortNotesPreview]
                  : shortNotesPreview
              }
              showMore={openInternalNotesDrawer}
              truncate
              bordered
            />
          </div>
        </Col>
        <Col span={12}>
          <div className="mb-2" style={{ minHeight: 32 }}>
            <Typography.Title level={4} className="mb-0">
              Change logs
            </Typography.Title>
          </div>
          <Spin spinning={!logs}>
            <Card
              className={styles.timelineContainer}
              actions={[
                <Pagination
                  size="small"
                  showSizeChanger={false}
                  showQuickJumper
                  onChange={paginationOnChangeHandle}
                  pageSize={logs?.meta.per_page || 25}
                  total={logs?.meta.total}
                  current={logsPage}
                />,
              ]}
            >
              <Timeline className={styles.timeline}>
                {logs?.data?.map((itm) => (
                  <Timeline.Item key={`pad-log-${itm.id}`}>
                    {dayjs(itm.created_at).format(dateFormats[0])} -{' '}
                    {itm.action_type}
                    {' -'}
                    <Link
                      to={`/dashboard/user-view/${itm.user.id}`}
                      target="_blank"
                    >
                      {' '}
                      {`${itm.user.name} (${userRolesStr[itm.user.role]})`}
                    </Link>{' '}
                    {!!itm.additional && itm.additional}
                    {itm.device_info && ` (${itm.device_info})`}
                  </Timeline.Item>
                ))}
              </Timeline>
            </Card>
          </Spin>
        </Col>
      </Row>
      <Drawer
        destroyOnClose
        style={{ zIndex: 1001 }}
        width={520}
        title="Internal notes"
        placement="right"
        onClose={closeInternalNotesDrawer}
        visible={internalNotesDrawerVisible}
        footer={
          <InternalNotesDrawerFooter padId={pad?.id} onNoteAdd={updatePad} />
        }
      >
        <NotesList
          enableEdit
          onEdit={editNoteHandle}
          enableRemove
          onRemove={removeNoteHandle}
          dataSource={pad?.pad_notes}
        />
      </Drawer>
    </div>
  );
};

export { AdminTab };
