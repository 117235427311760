import { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useDebounce } from 'react-use';
import AutoComplete from '../../uiKitComponents/autoComplete';
import api from '../../utils/appApi';

const SearchOwner = (props) => {
  const {
    placeholder,
    onSelect,
    name,
    value,
    onAfterSearch,
    disabled,
    ...rest
  } = props;
  const [query, setQuery] = useState();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const valueToRender = query === undefined ? value.label || '' : query;

  const searchOwners = (input) => {
    if (!input || (input && input.length < 3)) return;

    setLoading(true);

    api.pad
      .searchOwners({ params: { email: input } })
      .then((res) => {
        setLoading(false);
        setOptions(res.data);
        onAfterSearch(input, res.data);
      })
      .catch(() => {
        setLoading(false);
        setOptions([]);
      });
  };

  useDebounce(() => searchOwners(query), 300, [query]);

  const handleOnSearch = useCallback(
    (input) => {
      setQuery(input);
      if (input.length > 0 && input.length < 4) setOptions([]);

      if (!input.length)
        onSelect({
          target: {
            name,
            value: {},
          },
        });
    },
    [onSelect, name],
  );

  const handleOnBlur = useCallback(() => setQuery(undefined), []);

  const handleOnSelect = useCallback(
    (inputValue, option) => {
      const newValue = options.find(
        (itm) => itm.id.toString() === option.value,
      );

      newValue.label = option.label;

      onSelect({
        target: {
          name,
          value: newValue,
        },
      });
      setQuery(undefined);
    },
    [onSelect, name, options],
  );

  return (
    <AutoComplete
      {...rest}
      title={valueToRender}
      value={valueToRender}
      onSearch={handleOnSearch}
      onSelect={handleOnSelect}
      onBlur={handleOnBlur}
      options={options.map((itm) => ({
        value: itm.id.toString(),
        label: `${itm.email} - #${itm.id} - (${itm.name})`,
      }))}
      placeholder={placeholder}
      loading={loading}
      autoComplete="off"
      disabled={disabled}
      notFoundContent="No email coincidence"
      allowClear
    />
  );
};

SearchOwner.defaultProps = {
  placeholder: 'Search owner',
  onSelect: () => null,
  name: '',
  value: {},
  onAfterSearch: () => null,
  disabled: false,
};

SearchOwner.propTypes = {
  placeholder: PropTypes.string,
  onSelect: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  onAfterSearch: PropTypes.func,
  disabled: PropTypes.bool,
};

export default memo(SearchOwner);
