import { useEffect, useContext, useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useDebounce } from 'react-use';
import AutoComplete from '../../uiKitComponents/autoComplete';
import GoogleMapsContext from '../../context/googleMapsContext';
import getAddressComponentByName from '../../utils/getAddressComponentByName';

let autocompleteService;
let geocoderService;

const SearchLocationRoot = memo((props) => {
  const { placeholder, onSelect, name, value, ...rest } = props;
  const [query, setQuery] = useState();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const valueToRender = query === undefined ? value.address || '' : query;

  useEffect(() => {
    // https://developers.google.com/maps/documentation/javascript/places-autocomplete#place_autocomplete_service
    autocompleteService = new window.google.maps.places.AutocompleteService();
    // https://developers.google.com/maps/documentation/javascript/geocoding#place-id
    geocoderService = new window.google.maps.Geocoder();
  }, []);

  const searchLocations = (input) => {
    if (!input || (input && input.length < 3)) return;

    setLoading(true);
    new Promise((resolve, reject) =>
      // Getting predictions
      autocompleteService.getPlacePredictions(
        { input },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK)
            return resolve(predictions);
          return reject();
        },
      ),
    )
      .then((predictions) => {
        setLoading(false);
        setOptions(
          predictions.map((itm) => ({
            value: itm.place_id,
            label: itm.description,
          })),
        );
      })
      .catch(() => setLoading(false));
  };

  useDebounce(() => searchLocations(query), 300, [query]);

  const handleOnSearch = useCallback(
    (input) => {
      setQuery(input);
      if (!input.length)
        onSelect({
          target: {
            name,
            value: {},
          },
        });
    },
    [name, onSelect],
  );

  const handleOnBlur = useCallback(() => setQuery(undefined), []);

  const handleOnSelect = useCallback(
    (placeId) => {
      new Promise((resolve, reject) =>
        geocoderService.geocode({ placeId }, (results, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK)
            return resolve(results);
          return reject();
        }),
      ).then((results) => {
        setQuery(undefined);
        onSelect({
          target: {
            name,
            value: {
              latitude: results[0].geometry.location.lat(),
              longitude: results[0].geometry.location.lng(),
              address: results[0].formatted_address,
              country: getAddressComponentByName(
                results[0].address_components,
                'country',
              ).long_name,
              country_short: getAddressComponentByName(
                results[0].address_components,
                'country',
              ).short_name,
              county: getAddressComponentByName(
                results[0].address_components,
                'administrative_area_level_2',
              ).long_name,
            },
          },
        });
      });
    },
    [name, onSelect],
  );

  return (
    <AutoComplete
      autoComplete="off"
      {...rest}
      title={valueToRender}
      value={valueToRender}
      onSearch={handleOnSearch}
      onSelect={handleOnSelect}
      onBlur={handleOnBlur}
      options={options}
      placeholder={placeholder}
      loading={loading}
    />
  );
});

SearchLocationRoot.defaultProps = {
  placeholder: 'Search location',
  onSelect: () => null,
  name: '',
  value: {},
};

SearchLocationRoot.propTypes = {
  placeholder: PropTypes.string,
  onSelect: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.shape({
    latitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    longitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    address: PropTypes.string,
  }),
};

const SearchLocation = (props) => {
  const { googleMapsIsLoaded, googleMapsError } = useContext(GoogleMapsContext);

  if (googleMapsIsLoaded) return <SearchLocationRoot {...props} />;

  return googleMapsError || null;
};

export default SearchLocation;
