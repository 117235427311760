import { useState, useEffect, useCallback, useMemo } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import debounce from 'lodash.debounce';
import { ReactComponent as LayerPlus } from '../../assets/icons/layer-plus.svg';
import Button from '../../uiKitComponents/button';
import Tooltip from '../../uiKitComponents/tooltip';
import AnnouncementItem from './announcementItem';
import { CustomDragLayer } from './announcementDragLayer';
import Upload from '../../uiKitComponents/upload';
import Switch from '../../uiKitComponents/switch';
import notification from '../../uiKitComponents/notification';
import getApiErrorMessages from '../../utils/getApiErrorMessages';
import SliderModal from '../../components/sliderModal/sliderModal';
import api from '../../utils/appApi';

export default function TabContent(props) {
  const {
    announcement,
    updateList,
    deviceType,
    announcementId,
    orderLoading,
    isArchived,
  } = props;
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [toggleStatusLoading, setToggleStatusLoading] = useState(false);
  const [showUploadBtn, setUploadBtn] = useState(false);
  const [modalCarouselProps, setModalCarouselProps] = useState({
    visible: false,
    activeIndex: 0,
  });

  useEffect(() => {
    if (announcement && Object.keys(announcement).length) {
      const { is_enabled, uploads_count } = announcement;

      if (uploads_count === 0) return setUploadBtn(true);
      if (uploads_count < 5 && !is_enabled) return setUploadBtn(true);
    }

    return setUploadBtn(false);
  }, [announcement]);

  useEffect(() => {
    if (announcement.uploads) {
      const new_uploads_count = announcement.uploads.length;
      updateList('uploads_count', new_uploads_count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announcement.uploads]);

  const updateListHanlder = async ({ fileList: files }) => {
    if (files.length) {
      const updatedFiles = files
        .filter((file) => !!file.response)
        .map((file) => file.response.data);

      updateList('uploads_add', [...announcement.uploads, ...updatedFiles]);
    }
  };

  const addLayerHandler = useCallback(
    async (uploadConfig) => {
      const { file, filename, onSuccess } = uploadConfig;

      if (filename) {
        const formData = new FormData();

        formData.append('upload', file);
        formData.append('device_type', deviceType);

        try {
          setLoading(true);
          const response = await api.upload.store(
            'announcement',
            announcementId,
            formData,
            {
              onUploadProgress: ({ total, loaded }) => {
                setUploadProgress(
                  Math.round((loaded / total) * 100).toFixed(2),
                );
              },
            },
          );
          onSuccess(response);
        } catch (error) {
          notification.error({
            message: 'Error',
            description: getApiErrorMessages(error),
          });
        } finally {
          setLoading(false);
          setTimeout(() => setUploadProgress(0), 100);
        }
      }
    },
    [announcementId, deviceType],
  );

  const switchStatusHander = async (status) => {
    try {
      setToggleStatusLoading(true);
      await api.announcements.toggle(announcementId, deviceType);
      updateList('is_enabled', status);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: getApiErrorMessages(error),
      });
    } finally {
      setToggleStatusLoading(false);
    }
  };

  const removeLayerHandler = (id) => () => {
    updateList(
      'uploads',
      announcement.uploads.filter((l) => l.id !== id),
    );
    updateList('uploads_count', announcement.uploads.length - 1);
  };

  const toggleModalCarousel = (status) => {
    if (status)
      return (index) =>
        setModalCarouselProps({
          visible: true,
          activeIndex: index,
        });

    return () => {
      setModalCarouselProps((prevState) => ({ ...prevState, visible: false }));
    };
  };

  const updateAnnouncementOrder = useCallback(
    async (orderedList = null) => {
      try {
        orderLoading(true);
        await api.upload.updateOrder({
          upload: orderedList.map((u, i) => ({
            id: u.id,
            order: i + 1,
          })),
        });
      } catch (error) {
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(error),
        });
      } finally {
        orderLoading(false);
      }
    },
    [orderLoading],
  );

  const debouncedUpdateAnnouncementOrder = useMemo(
    () => debounce(updateAnnouncementOrder, 1000),
    [updateAnnouncementOrder],
  );

  const moveLayer = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = announcement.uploads[dragIndex];

      const updatedListOrder = update(announcement.uploads, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      });

      updateList('uploads', updatedListOrder);
      debouncedUpdateAnnouncementOrder(updatedListOrder);
    },
    [announcement.uploads, debouncedUpdateAnnouncementOrder, updateList],
  );

  if (!announcement || !Object.keys(announcement).length) {
    return (
      <div
        className={`announcements-content_items ${
          !announcement.is_enabled || !isArchived ? '' : 'disabled'
        }`}
        style={{
          '--i': 0,
        }}
      >
        <div className="announcements-item">
          <div className="image skeleton" />
        </div>
        <div
          className="announcements-item"
          style={{
            '--i': 1,
          }}
        >
          <div className="image skeleton" />
        </div>
      </div>
    );
  }

  return (
    <div className="announcements-content">
      <SliderModal
        isModalVisible={modalCarouselProps.visible}
        activeIndex={modalCarouselProps.activeIndex}
        onCancelHandler={toggleModalCarousel(false)}
        wrapClassName="announcements-content_carousel"
        slides={announcement.uploads}
      />
      <DndProvider backend={HTML5Backend}>
        <CustomDragLayer />
        <div
          className={`announcements-content_items ${
            announcement.is_enabled || isArchived ? 'disabled' : ''
          }`}
        >
          <div
            className={`announcements-content_toggle d-flex align-items-center ${
              !announcement.uploads.length && 'font-weight-bold'
            }`}
          >
            <span className="mr-2">
              {announcement.uploads.length
                ? 'Toggle Publish Status'
                : '*Cannot publish without uploads'}
            </span>
            <Switch
              onChange={switchStatusHander}
              checked={announcement.is_enabled}
              disabled={loading || !announcement.uploads.length}
              loading={toggleStatusLoading}
            />
          </div>
          {announcement.uploads.map((itm, idx) => (
            <AnnouncementItem
              key={itm.id}
              onRemove={removeLayerHandler(itm.id)}
              layer={itm}
              moveLayer={moveLayer}
              index={idx}
              listStatus={!announcement.is_enabled}
              disabled={loading}
              onClickAction={() => toggleModalCarousel(true)(idx)}
              style={{
                '--i': idx,
              }}
            />
          ))}
          {showUploadBtn && (
            <div className="announcements-item">
              <Upload.Dragger
                name="layer"
                showUploadList={false}
                accept=".png,.jpg,.jpeg,.gif"
                onChange={updateListHanlder}
                customRequest={addLayerHandler}
                disabled={loading}
                multiple
                maxCount={5 - announcement.uploads_count}
              >
                <div className="icon">
                  <Tooltip title="Add Layer">
                    <Button
                      loading={loading}
                      className="add-layer-btn d-flex align-items-center justify-content-center"
                      shape="circle"
                      size="large"
                      icon={<LayerPlus />}
                    />
                  </Tooltip>
                </div>
                <p className="description">
                  Click or drag file to this area to upload
                </p>
                <div className="upload-bar" data-loading={loading} />
                <div
                  className="upload-bar_progress"
                  data-loading={loading}
                  style={{
                    width: `${uploadProgress}%`,
                  }}
                />
              </Upload.Dragger>
            </div>
          )}
        </div>
      </DndProvider>
    </div>
  );
}

TabContent.propTypes = {
  announcement: PropTypes.object,
  updateList: PropTypes.func,
  resetList: PropTypes.func,
  deviceType: PropTypes.string,
  announcementId: PropTypes.string,
  orderLoading: PropTypes.func,
  isArchived: PropTypes.bool,
};

TabContent.defaultProps = {
  announcement: {},
  updateList: () => null,
  resetList: () => null,
  deviceType: '',
  announcementId: '',
  orderLoading: () => null,
  isArchived: false,
};
