import {
  USERS_LIST_GET,
  USERS_LIST_CLEAN,
  CLEAN_ON_UNMOUNT_FALSE,
  CLEAN_ON_UNMOUNT_TRUE,
} from '../actionTypes';
import api from '../../utils/appApi';

export const getUsersList = (config) => (dispatch) =>
  api.users
    .getList(config)
    .then((res) => {
      dispatch({ type: USERS_LIST_GET, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const cleanUsersList = () => (dispatch) =>
  dispatch({
    type: USERS_LIST_CLEAN,
  });

export const setCleanOnUnmountTrue = () => (dispatch) =>
  new Promise((resolve, reject) => {
    try {
      dispatch({
        type: CLEAN_ON_UNMOUNT_TRUE,
      });
      return resolve();
    } catch (e) {
      return reject(Error(e));
    }
  });

export const setCleanOnUnmountFalse = () => (dispatch) =>
  new Promise((resolve, reject) => {
    try {
      dispatch({
        type: CLEAN_ON_UNMOUNT_FALSE,
      });
      return resolve();
    } catch (e) {
      return reject(Error(e));
    }
  });
