import PropTypes from 'prop-types';
import { memo, useContext } from 'react';
import condStrings from '../../utils/condStrings';
import TableBodyContext from './TableBodyContext';
import TableRow from './TableRow';

function TableBody(props) {
  const { rows } = props;
  const { rowsRenderKeys, rowKey, rowClassName } = useContext(TableBodyContext);
  return (
    <tbody className="ant-table-tbody">
      {rows.map((row, idx) => (
        <TableRow
          key={row[rowKey]}
          className={condStrings('ant-table-row', rowClassName(row))}
          index={idx}
          id={row.id}
        >
          {rowsRenderKeys.map(({ propery, render }) => (
            <td key={`${row[rowKey]}-${propery}`} className="ant-table-cell">
              {render(row[propery], row)}
            </td>
          ))}
        </TableRow>
      ))}
    </tbody>
  );
}

TableBody.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default memo(TableBody);
