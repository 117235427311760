import PropTypes from 'prop-types';
import React from 'react';
import {
  DownOutlined,
  FolderOpenOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { Button, Card, List } from 'antd';
import { Comment } from './Comment';

import styles from '../ReviewTab.module.sass';

const ASC = 'asc';
const DESC = 'desc';

export function CommentsSection({ reviews, toggleReviewMode }) {
  const [direction, setDirection] = React.useState(DESC);

  const EmptyComments = (
    <div>
      <FolderOpenOutlined style={{ fontSize: 36 }} />
      <p className="ant-empty-description">No comments</p>
    </div>
  );

  return (
    <>
      <div className="mb-2">
        <Button
          htmlType="button"
          type="text"
          icon={direction === ASC ? <UpOutlined /> : <DownOutlined />}
          onClick={() =>
            setDirection((prevState) => (prevState === ASC ? DESC : ASC))
          }
        >
          {direction === DESC ? 'Date Descending' : 'Date Ascending'}
        </Button>
      </div>
      <Card className={styles.commentsCard}>
        <List
          itemLayout="vertical"
          loading={{
            spinning: !reviews?.reviews,
            wrapperClassName: styles.listSpinner,
          }}
          locale={{ emptyText: EmptyComments }}
          dataSource={
            direction === ASC
              ? [...reviews?.reviews].reverse()
              : reviews?.reviews
          }
          renderItem={(comment) =>
            (!!comment.rating || !!comment?.comment) && (
              <Comment toggleReviewMode={toggleReviewMode} comment={comment} />
            )
          }
        />
      </Card>
    </>
  );
}

CommentsSection.propTypes = {
  reviews: PropTypes.object,
  toggleReviewMode: PropTypes.func,
};
