import { useState, useCallback } from 'react';
import { useMount } from 'react-use';
import PropTypes from 'prop-types';
import {
  EditOutlined,
  CloseCircleOutlined,
  SaveOutlined,
  DeleteOutlined,
  EllipsisOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import List from '../../../../../uiKitComponents/list';
import Tooltip from '../../../../../uiKitComponents/tooltip';
import Button from '../../../../../uiKitComponents/button';
import AsyncPopconfirm from '../../../../../components/asyncPopconfirm';
import { formatTextToJsx } from '../../../../../utils/formatText';
import FormattedText from '../../../../../components/formattedText';
import TruncatedText from '../../../../../components/truncateText';
import { dateFormats } from '../../../../../utils/constants';
import EditNote from './editNote';

const NotesList = (props) => {
  const {
    showMore,
    truncate,
    onRemove,
    onEdit,
    enableEdit,
    enableRemove,
    dataSource,
    ...rest
  } = props;
  const [itemOnEditing, setItemOnEditing] = useState();
  const [temp, setTemp] = useState();

  useMount(() => {
    const drawerBody = document.getElementsByClassName('ant-drawer-body')[0];
    if (drawerBody) drawerBody.scrollTo(0, drawerBody.scrollHeight);
  });

  const onEditHandle = useCallback((noteId) => () => setItemOnEditing(noteId), [
    setItemOnEditing,
  ]);

  const onSaveEditHandle = useCallback(
    (noteId) => () => {
      if (temp !== dataSource.find((itm) => itm.id === noteId)?.body)
        onEdit(noteId, temp);
      setItemOnEditing(undefined);
    },
    [onEdit, dataSource, temp],
  );

  const onCancelEditHandle = useCallback(() => {
    setItemOnEditing(undefined);
  }, []);

  const onRemoveHandle = useCallback((noteId) => () => onRemove(noteId), [
    onRemove,
  ]);

  const getNoteMeta = (user, created_at) => (
    <div>
      <Link to={`/dashboard/user-view/${user.id}`}>{user.name}</Link>
      <span className="mr-1 ml-1">-</span>
      <span className="mr-1 ml-1">
        {dayjs(created_at).format(dateFormats[0])}
      </span>
    </div>
  );

  const sanitizeText = (text) => {
    const sanitizedText = text.replace(/<[^>]*>?/gm, '');

    // Replace special characters with HTML entities
    return sanitizedText
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
  };

  return (
    <List
      {...rest}
      dataSource={dataSource}
      itemLayout="vertical"
      renderItem={(itm) => (
        <List.Item
          extra={
            !!Object.keys(itm).length && [
              enableEdit &&
                (itemOnEditing === itm.id ? (
                  <Tooltip key="edit-note-btn" title="Save changes">
                    <Button
                      htmlType="button"
                      type="link"
                      icon={<SaveOutlined />}
                      onClick={onSaveEditHandle(itm.id)}
                    />
                    <Button
                      htmlType="button"
                      type="link"
                      icon={<CloseCircleOutlined />}
                      onClick={onCancelEditHandle}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip key="edit-note-btn" title="Edit">
                    <Button
                      htmlType="button"
                      type="link"
                      icon={<EditOutlined />}
                      onClick={onEditHandle(itm.id)}
                    />
                  </Tooltip>
                )),
              enableRemove && (
                <AsyncPopconfirm
                  key="delete-note-btn"
                  placement="topRight"
                  title="Are you sure to delete this note?"
                  onConfirmPromise={onRemoveHandle(itm.id)}
                  component={(wrapperProps) => (
                    <Button
                      {...wrapperProps}
                      htmlType="button"
                      type="link"
                      danger
                      icon={<DeleteOutlined />}
                    />
                  )}
                />
              ),
            ]
          }
        >
          {Object.keys(itm).length ? (
            <>
              <List.Item.Meta
                description={getNoteMeta(itm.user, itm.created_at)}
              />
              {(truncate && !enableEdit && (
                <TruncatedText
                  text={formatTextToJsx(sanitizeText(itm.body), {
                    replaceBreakLines: false,
                  })}
                />
              )) ||
                (itemOnEditing === itm.id && (
                  <EditNote value={itm.body} setTempValue={setTemp} />
                )) || <FormattedText text={itm.body} />}
            </>
          ) : (
            <Button
              className="px-0"
              type="link"
              icon={<EllipsisOutlined />}
              onClick={showMore}
            >
              Show more
            </Button>
          )}
        </List.Item>
      )}
    />
  );
};

NotesList.defaultProps = {
  showMore: () => null,
  truncate: false,
  onRemove: () => null,
  onEdit: () => null,
  enableEdit: false,
  enableRemove: false,
  dataSource: [],
};

NotesList.propTypes = {
  showMore: PropTypes.func,
  truncate: PropTypes.bool,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  enableEdit: PropTypes.bool,
  enableRemove: PropTypes.bool,
  dataSource: PropTypes.array,
};

export default NotesList;
