import { useState, useMemo, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useUpdateEffect } from 'react-use';
import Lightbox from 'react-image-lightbox';
import { PlusOutlined } from '@ant-design/icons';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { Checkbox } from 'antd';
import dayjs from 'dayjs';
import Upload from '../../uiKitComponents/upload';
import { cardinalDirections, imageFormats } from '../../utils/constants';
import getFileFormat from '../../utils/getFileFormat';
import PhotoUploadItem from './components/item';

import styles from './picturewall.module.sass';
import { useSelector } from 'react-redux';

const lightboxModalStyle = {
  overlay: {
    zIndex: 1001,
  },
};

const initialLightbox = {
  mainSrc: null,
  nextSrc: null,
  prevSrc: null,
};

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const PicturesWall = (props) => {
  const {
    fileList,
    setFileList,
    showUploadBtn,
    showRemoveIcon,
    selectPictures,
    selectedPictures,
    hasCheckbox,
    onRemove,
    limit,
    maxCount,
    editing,
    ...rest
  } = props;

  const { uploads } = useSelector((state) => ({
    uploads: state.padList.upload,
  }));

  const [preview, setPreview] = useState({
    uid: null,
    visible: false,
  });
  const [lightbox, setLightbox] = useState(initialLightbox);

  const photoIndex = useMemo(
    () => fileList.findIndex((itm) => itm.uid === preview.uid),
    [fileList, preview.uid],
  );

  const photoTitle = useMemo(() => {
    const file = fileList[photoIndex];

    if (file) {
      return (
        <div className={styles.photoTitle}>
          <span>{file.name || file.title}</span>
          {file?.user?.id && (
            <span>
              <a href={`/dashboard/user-view/${file.user.id}`}>
                by {file.user.first_name}
              </a>
            </span>
          )}
          {file?.created_at && (
            <span>
              on {dayjs(file.created_at).format('DD MMMM, YYYY [at] HH:mm')}
            </span>
          )}
        </div>
      );
    }

    return file
      ? `${file.name}, by ${file?.user?.first_name}` ||
          file.url.substring(file.url.lastIndexOf('/') + 1)
      : null;
  }, [fileList, photoIndex]);

  useUpdateEffect(() => {
    if (photoIndex !== -1) {
      const file = fileList[photoIndex];
      const nextFile = fileList[(photoIndex + 1) % fileList.length];
      const prevFile =
        fileList[(photoIndex + fileList.length - 1) % fileList.length];

      Promise.all([
        file.url ? Promise.resolve(file.url) : getBase64(file.originFileObj),
        nextFile.url
          ? Promise.resolve(nextFile.url)
          : getBase64(nextFile.originFileObj),
        prevFile.url
          ? Promise.resolve(prevFile.url)
          : getBase64(prevFile.originFileObj),
      ]).then((res) => {
        setLightbox((prevState) => ({
          ...prevState,
          mainSrc: res[0],
          nextSrc: res[1],
          prevSrc: res[2],
        }));
      });
    }
  }, [photoIndex, fileList]);

  useUpdateEffect(() => {
    if (!preview.visible) {
      setLightbox(initialLightbox);
    }
  }, [preview.visible]);

  const handleCancel = useCallback(() => {
    setPreview((prevState) => ({
      ...prevState,
      visible: false,
      uid: undefined,
    }));
  }, []);

  const handlePreview = useCallback(async (file) => {
    if (!imageFormats.includes(getFileFormat(file.name))) return;

    setPreview((prevState) => ({
      ...prevState,
      visible: true,
      uid: file.uid,
    }));
  }, []);

  const onMovePrevRequest = useCallback(() => {
    setPreview((prevState) => ({
      ...prevState,
      uid: fileList[(photoIndex + fileList.length - 1) % fileList.length].uid,
    }));
  }, [fileList, photoIndex]);

  const onMoveNextRequest = useCallback(() => {
    setPreview((prevState) => ({
      ...prevState,
      uid: fileList[(photoIndex + 1) % fileList.length].uid,
    }));
  }, [fileList, photoIndex]);

  const moveFileHandler = useCallback(
    (dragIdx, hoverIdx) => {
      const dragFile = fileList[dragIdx];
      setFileList(
        update(fileList, {
          $splice: [
            [dragIdx, 1],
            [hoverIdx, 0, dragFile],
          ],
        }),
      );
    },
    [fileList, setFileList],
  );

  const handleFileRender = useCallback(
    (_, file) => (
      <span style={{ position: 'relative' }}>
        {hasCheckbox && (
          <Checkbox
            style={{ position: 'absolute', top: 0, left: 0, zIndex: 100 }}
            checked={selectedPictures.includes(file.uid.replace('-', ''))}
            value={file.uid.replace('-', '')}
            onChange={selectPictures}
          />
        )}

        <PhotoUploadItem
          file={file}
          previewFile={handlePreview}
          moveFile={moveFileHandler}
          fileList={fileList}
          removeFile={onRemove}
          showRemoveIcon={showRemoveIcon}
          uploadsList={uploads}
        />
      </span>
    ),
    [
      hasCheckbox,
      selectedPictures,
      selectPictures,
      handlePreview,
      moveFileHandler,
      fileList,
      onRemove,
      showRemoveIcon,
      uploads,
    ],
  );

  const maxUploadCount = useMemo(() => {
    if (uploads) {
      const adminUploads = uploads.filter((u) => u.user.role === 1 && !cardinalDirections.includes(u.direction));
      return 4 - adminUploads.length + uploads.length;
    }
    return 4;
  }, [uploads]);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Upload
          accept="image/png, image/jpeg"
          fileList={fileList}
          listType="picture-card"
          onPreview={handlePreview}
          itemRender={handleFileRender}
          maxCount={maxCount}
          {...rest}
        >
          {((limit > fileList.length && showUploadBtn) || (editing && maxUploadCount > fileList.length && showUploadBtn)) && (
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          )}
        </Upload>
      </DndProvider>
      {preview.visible &&
        photoTitle &&
        lightbox.mainSrc &&
        lightbox.nextSrc &&
        lightbox.prevSrc && (
          <Lightbox
            mainSrc={lightbox.mainSrc}
            nextSrc={lightbox.nextSrc}
            prevSrc={lightbox.prevSrc}
            reactModalStyle={lightboxModalStyle}
            onCloseRequest={handleCancel}
            imageTitle={photoTitle}
            onMovePrevRequest={onMovePrevRequest}
            onMoveNextRequest={onMoveNextRequest}
          />
        )}
    </>
  );
};

PicturesWall.defaultProps = {
  fileList: [],
  setFileList: () => null,
  selectPictures: () => null,
  selectedPictures: [],
  showUploadBtn: true,
  showRemoveIcon: true,
  hasCheckbox: false,
  onRemove: () => null,
  limit: Infinity,
  maxCount: 10,
};

PicturesWall.propTypes = {
  fileList: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string, // Must be negative, ex: '-1'
      name: PropTypes.string,
      url: PropTypes.string,
      status: PropTypes.string,
      originFileObj: PropTypes.object,
      created_at: PropTypes.string,
      user: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        first_name: PropTypes.string,
      }),
      title: PropTypes.string,
    }),
  ),
  setFileList: PropTypes.func,
  selectPictures: PropTypes.func,
  selectedPictures: PropTypes.arrayOf(PropTypes.string),
  showUploadBtn: PropTypes.bool,
  showRemoveIcon: PropTypes.bool,
  hasCheckbox: PropTypes.bool,
  onRemove: PropTypes.func,
  limit: PropTypes.number,
  maxCount: PropTypes.number,
  editing: PropTypes.bool,
};

export default memo(PicturesWall);
