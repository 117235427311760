/**
 * Clear object from empty values (recursively)
 * Source: https://stackoverflow.com/a/38340374
 *
 * @param {object} obj
 * @returns {object}
 */
const objectRemoveEmpty = (obj) => {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === Object(obj[key]))
      newObj[key] = objectRemoveEmpty(obj[key]);
    else if (!!obj[key] || obj[key] === 0 || obj[key] === false)
      newObj[key] = obj[key];
  });
  return newObj;
};

export default objectRemoveEmpty;
