import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import Card from '../../../../uiKitComponents/card';
import Timeline from '../../../../uiKitComponents/timeline';
import Pagination from '../../../../uiKitComponents/pagination';
import Spin from '../../../../uiKitComponents/spin';
import { dateFormats } from '../../../../utils/constants';
import api from '../../../../utils/appApi';

import styles from '../../../PadViewPage/tabs/adminTab/adminTab.module.sass';

const LogsTab = (props) => {
  const { data } = props;
  const [logsPage, setLogsPage] = useState(1);
  const [userLogs, setUserLogs] = useState(undefined);

  useEffect(() => {
    api.users
      .getLogs(data, { params: { page: logsPage } })
      .then((res) => setUserLogs(res.data));
  }, [data, logsPage]);

  const paginationOnChangeHandle = useCallback((page) => {
    setLogsPage(page);
  }, []);

  const getEntityObjectLink = ({ id, name }, type) => {
    if (type === 'App\\Models\\User') {
      return (
        <Link to={`/dashboard/user-view/${id}`}> - #{`${id} ${name}`}</Link>
      );
    }

    return <Link to={`/dashboard/pad-view/${id}`}> - #{`${id} ${name}`}</Link>;
  };

  const getEntityAction = (entity) => {
    const action = [entity.action_type];
    if (entity?.additional) action.push(`(${entity.additional})`);

    return action.join(' ');
  };

  return (
    <>
      <Spin spinning={!userLogs}>
        <Card
          className={styles.timelineContainer}
          actions={[
            <Pagination
              size="small"
              showSizeChanger={false}
              showQuickJumper
              onChange={paginationOnChangeHandle}
              pageSize={userLogs?.meta?.per_page || 25}
              total={userLogs?.meta?.total}
              current={logsPage}
            />,
          ]}
        >
          <Timeline className={styles.timeline}>
            {userLogs?.data?.map((itm) => (
              <Timeline.Item key={`pad-log-${itm.id}`}>
                {dayjs(itm.created_at).format(dateFormats[0])} -{' '}
                {getEntityAction(itm)}
                {getEntityObjectLink(itm.entity_object, itm.entity_type)}
              </Timeline.Item>
            ))}
          </Timeline>
        </Card>
      </Spin>
    </>
  );
};

LogsTab.defaultProps = {
  data: undefined,
};

LogsTab.propTypes = {
  data: PropTypes.string,
};

export default LogsTab;
