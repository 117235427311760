import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import Form from '../../uiKitComponents/form';
import Input from '../../uiKitComponents/input';
import Button from '../../uiKitComponents/button';
import { login } from '../../store/actions/userAction';
import Alert from '../../uiKitComponents/alert';
import useApiErrorsWithAntd from '../../hooks/useApiErrorsWithAntd';
import styles from './loginPage.module.sass';
import helipaddyLogo from '../../assets/svg/basic-company-logo_black.svg';

const LoginPage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { setApiErrorsToAntdForm } = useApiErrorsWithAntd(form);
  const globalErrors = form.getFieldError('global');

  const onSubmit = useCallback(
    (values) => {
      setLoading(true);
      login(values)(dispatch).catch((err) => {
        setLoading(false);
        setApiErrorsToAntdForm(err);
      });
    },
    [dispatch, setApiErrorsToAntdForm],
  );

  return (
    <div className={`${styles.root} login d-flex align-items-center px-3`}>
      <Form
        form={form}
        onFinish={onSubmit}
        className={`${styles.form} form mx-auto`}
      >
        <div className="text-center">
          <img src={helipaddyLogo} alt="Helipady logo" />
          <h2>
            <b>Admin panel</b>
          </h2>
        </div>
        <Form.Item name="email" rules={[{ required: true }, { type: 'email' }]}>
          <Input size="large" prefix={<UserOutlined />} placeholder="Email" />
        </Form.Item>

        <Form.Item name="password" rules={[{ required: true }]}>
          <Input.Password
            size="large"
            prefix={<LockOutlined />}
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item hidden name="global">
          <Input />
        </Form.Item>

        <Form.Item>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            className="w-100"
            loading={loading}
          >
            Log in
          </Button>
        </Form.Item>
        {!!globalErrors.length && (
          <Alert
            message="Error"
            description={globalErrors}
            type="error"
            showIcon
          />
        )}
      </Form>
    </div>
  );
};

export default LoginPage;
