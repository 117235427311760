import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef } from 'react';
import Modal from '../../uiKitComponents/modal';
import Carousel from '../../uiKitComponents/carousel';

const SliderModal = (props) => {
  const {
    isModalVisible,
    slides,
    onCancelHandler,
    activeIndex,
    wrapClassName,
  } = props;
  const slider = useRef(null);

  useEffect(() => {
    if (isModalVisible && slider.current) {
      slider.current.goTo(activeIndex);
    }
  }, [isModalVisible, activeIndex]);

  const transformSlidesToNodes = useCallback(() => {
    if (slides.length) {
      return slides.map((img) => (
        <div className="slider-slide" key={img.id}>
          <div className="image">
            <img src={img.file_url} alt={img.title} />
          </div>
        </div>
      ));
    }

    return null;
  }, [slides]);

  return (
    <Modal
      className="image-slider_modal"
      wrapClassName={wrapClassName}
      closeIcon={null}
      visible={isModalVisible}
      onCancel={onCancelHandler}
      footer={false}
    >
      <Carousel arrows="true" accessibility focusOnSelect ref={slider}>
        {transformSlidesToNodes()}
      </Carousel>
    </Modal>
  );
};

SliderModal.defaultProps = {
  isModalVisible: false,
  slides: [],
  updatePadUploads: () => null,
  onCancelHandler: () => null,
  activeIndex: 0,
  wrapClassName: '',
};

SliderModal.propTypes = {
  isModalVisible: PropTypes.bool,
  wrapClassName: PropTypes.string,
  slides: PropTypes.array,
  updatePadUploads: PropTypes.func,
  onCancelHandler: PropTypes.func,
  activeIndex: PropTypes.number,
};

export default SliderModal;
