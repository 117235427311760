import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Tag } from 'antd';
import condStrings from '../../../utils/condStrings';
import DataRowContext from '../DataRowContext';
import CheckableTagList from '../../checkableTagList';
import isValidHttpUrl from '../../../utils/isValidHttpUrl';
import StaticDataPhotos from './StaticDataPhotos';

export default function StaticData(props) {
  const { side, content, extra, noValues, additionalChecks } = props;
  const {
    isConflict,
    type,
    options,
    checkedSide,
    onUploadSelect,
    selectedUploads,
    setCheckedSide,
  } = useContext(DataRowContext);

  const renderContacts = () =>
    content.map((contact, idx) => (
      <div className="row-contacts-item" key={`${contact?.id}${idx}`}>
        <li>
          <span className="label">Name:</span>
          <span className="value">{contact?.name}</span>
        </li>
        <li>
          <span className="label">Email:</span>
          <span className="value">{contact?.email}</span>
        </li>
        <li>
          <span className="label">Phone:</span>
          <span className="value">{contact?.phone}</span>
        </li>
        {contact?.is_primary && (
          <li>
            <span className="label">
              <b>Primary Contact</b>
            </span>
          </li>
        )}
      </div>
    ));

  const generateContent = () => {
    if (type === 'contacts') {
      return renderContacts();
    }

    if (type === 'tags') {
      return <CheckableTagList options={options} value={content} readOnly />;
    }

    if (type === 'tags_input') {
      return content.map((tag, idx) => (
        <Tag key={`${side}-${tag.id || idx}`} className="mb-1">
          {tag.name}
        </Tag>
      ));
    }

    if (type === 'url') {
      if (isValidHttpUrl(content)) {
        return (
          <a
            href={content}
            target="_blank"
            rel="noopener noreferrer"
            style={{ wordBreak: 'break-word' }}
          >
            {content}
          </a>
        );
      }
    }

    if (type === 'select') {
      return content?.label;
    }

    if (type === 'photos') {
      return (
        <StaticDataPhotos
          side={side}
          setCheckedSide={setCheckedSide}
          checkedSide={checkedSide}
          content={content}
          selected={selectedUploads}
          onSelect={onUploadSelect}
        />
      );
    }

    if (type === 'bool') {
      // bool content was changed false to true and true to false
      // because: Landing Possible --> Landing NOT Possible
      return content ? 'False' : 'True';
    }

    if (type === 'survey') {
      if (side === "prev") {
        if (additionalChecks?.surveyProgressPrev) {
          return `Survey progress: ${additionalChecks.surveyProgressPrev}%`
        }
        return null;
      }

      if (side === "next") {
        if (additionalChecks?.surveyProgressNext) {
          return `Survey progress: ${additionalChecks.surveyProgressNext}%`
        }
        return null;
      }
    };

    return content;
  };

  return (
    <div
      className={condStrings(
        'data-row-info',
        side,
        isConflict && 'is-conflict',
        noValues && 'invisible',
      )}
    >
      <div className="data-row-content">
        {generateContent()}
        <p className="mb-0">{extra}</p>
      </div>
    </div>
  );
}

StaticData.propTypes = {
  side: PropTypes.oneOf(['next', 'prev']),
  content: PropTypes.any,
  extra: PropTypes.any,
  noValues: PropTypes.bool,
  additionalChecks: PropTypes.object,
};

StaticData.defaultProps = {
  side: 'prev',
  content: '',
  extra: null,
  noValues: false,
};
