import PropTypes from 'prop-types';
import React from 'react';
import { Col, Descriptions, Form, Input, Row, Select } from 'antd';

export const SurveyGeneralStep = React.memo(({ stepValues }) => (
  <Descriptions
    labelStyle={{ width: 150 }}
    size="small"
    bordered
    column={1}
    layout="vertical"
    className="mb-3"
  >
    <Descriptions.Item label={`General: ${stepValues.is_completed ? 'Completed' : 'Incomplete'}`}>
      <Row gutter={20}>
        <Col span={8}>
          <Form.Item
            label="PPR"
            name={['general', 'general_notice']}
            className="m-0"
            required
          >
            <Select
              placeholder="Please select"
              options={[
                { value: 1, label: 'Short Notice' },
                { value: 2, label: '24 Hours' },
                { value: 3, label: '48 Hours' },
                { value: 99, label: 'Other' },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item
            label="PPR Details"
            name={['general', 'general_notice_comment']}
            className="m-0"
            required={stepValues.general_notice === 99}
            rules={[
              {
                min: 3,
                max: 200,
                message: 'Must be between 3 and 200 characters',
              },
            ]}
          >
            <Input.TextArea autoSize placeholder="Please give details" />
          </Form.Item>
        </Col>
      </Row>
    </Descriptions.Item>
  </Descriptions>
));

SurveyGeneralStep.propTypes = {
  stepValues: PropTypes.object,
};
