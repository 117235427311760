import PropTypes from 'prop-types';
import { memo } from 'react';

function TableHead(props) {
  const { columns } = props;

  return (
    <thead className="ant-table-thead">
      <tr>
        <th className="ant-table-cell drag-head drag-cell">Drag</th>
        {columns.map((column) => (
          <th key={column.dataIndex} className="ant-table-cell">
            {column.title}
          </th>
        ))}
      </tr>
    </thead>
  );
}

TableHead.propTypes = {
  columns: PropTypes.array.isRequired,
};

export default memo(TableHead);
