import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import Modal from '../../../../../uiKitComponents/modal';
import Rate from '../../../../../uiKitComponents/rate';
import Popconfirm from '../../../../../uiKitComponents/popconfirm';
import Button from '../../../../../uiKitComponents/button';
import Spin from '../../../../../uiKitComponents/spin';
import notification from '../../../../../uiKitComponents/notification';
import Checkbox from '../../../../../uiKitComponents/checkbox';
import api from '../../../../../utils/appApi';

import ExLink from '../../../../../assets/icons/ex-link.svg';
import getApiErrorMessages from '../../../../../utils/getApiErrorMessages';
import CardinalDirectionIcon from '../../../../../components/CardinalDirectionIcon';

const FullsizeReport = (props) => {
  const {
    visible,
    onCancel,
    onRevisionAccept,
    onRevisionIgnore,
    revision,
  } = props;
  const [contentLoading, setContentLoading] = useState(false);
  const [content, setContent] = useState(null);

  const fullsizeContentReview = useCallback(
    (reportedReview) => (
      <div className="review">
        <div className="review-inner">
          <div className="review-inner_content">
            <div className="line-to" />
            <div className="user-meta">
              <Link
                className="name"
                target="_blank"
                to={`/dashboard/user-view/${reportedReview.review?.user?.id}`}
              >
                <span className="icon">
                  <img src={ExLink} alt="Extra" />
                </span>
                <span className="text">
                  {`${reportedReview.review?.user?.first_name} ${reportedReview.review?.user?.last_name}`}
                </span>
                <span className="role">Review</span>
              </Link>
              <div className="created">
                {dayjs(reportedReview.review.created_at).format(
                  'DD MMM YYYY HH:mm',
                )}
              </div>
            </div>
            <div className="content-comment">
              <p>{reportedReview.review?.comment}</p>
            </div>
            <div className="comment-stars">
              <div className="rate-inline-container d-flex">
                {!!reportedReview.review.landing && (
                  <div className="rate-and-title-element">
                    <div className="rate-label">Ease of Landings</div>
                    <div className="d-flex align-items-center">
                      <Rate
                        value={reportedReview.review.landing}
                        disabled
                        allowHalf
                      />
                    </div>
                  </div>
                )}
                {!!reportedReview.review.helifriendliness && (
                  <div className="rate-and-title-element">
                    <div className="rate-label">Helifriendliness</div>
                    <div className="d-flex align-items-center">
                      <Rate
                        value={reportedReview.review.helifriendliness}
                        disabled
                        allowHalf
                      />
                    </div>
                  </div>
                )}
                {!!reportedReview.review.food && (
                  <div className="rate-and-title-element">
                    <div className="rate-label">Food</div>
                    <div className="d-flex align-items-center">
                      <Rate
                        value={reportedReview.review.food}
                        disabled
                        allowHalf
                      />
                    </div>
                  </div>
                )}
                {!!reportedReview.review.service && (
                  <div className="rate-and-title-element">
                    <div className="rate-label">Service</div>
                    <div className="d-flex align-items-center">
                      <Rate
                        value={reportedReview.review.service}
                        disabled
                        allowHalf
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="review-inner_content report">
            <div className="line-from" />
            <div className="user-meta">
              <Link
                className="name"
                target="_blank"
                to={`/dashboard/user-view/${revision.user.id}`}
              >
                <span className="icon">
                  <img src={ExLink} alt="Extra" />
                </span>
                <span className="text">
                  {`${revision.user.first_name} ${revision.user.last_name}`}
                </span>
                <span className="role">Report</span>
              </Link>
              <div className="created">
                {dayjs(revision?.created_at).format('DD MMM YYYY HH:mm')}
              </div>
            </div>
            <div className="content-comment">
              <p>{reportedReview?.comment}</p>
            </div>
          </div>
        </div>
      </div>
    ),
    [revision],
  );

  const fullsizeContentUpload = (reportedUpload, direction) => (
    <div className="fillsize-content_image direction-relative-top-right">
      <div className="image">
        <img src={reportedUpload?.upload.file_url} alt="Reported" />
        {direction && (
          <CardinalDirectionIcon
            direction={direction}
            width={30}
          />
        )}
      </div>
    </div>
  );

  const fullSizeContentPadReasons = (data) => {
    const className = ['review-reason'];

    const generateReasons = (reason_id = '') => {
      if (reason_id.toString() === '0') return "You can't land here";
      if (reason_id.toString() === '1') return 'Wrong contact details';
      if (reason_id.toString() === '2') return 'Wrong landing location';

      return null;
    };

    return (
      <div className={className.join(' ')}>
        {data.map((itm, idx) => (
          <div className="checkbox" key={idx}>
            <Checkbox checked>{generateReasons(itm)}</Checkbox>
          </div>
        ))}
      </div>
    );
  };

  const fullsizeContentPad = useCallback(
    (reportedPad, reasons = null) => (
      <div className="review">
        <div className="review-inner_content">
          <div className="user-meta">
            <Link
              className="name"
              target="_blank"
              to={`/dashboard/user-view/${reportedPad?.user?.id}`}
            >
              <span className="icon">
                <img src={ExLink} alt="Extra" />
              </span>
              <span className="text">
                {`${reportedPad?.user?.first_name} ${reportedPad?.user?.last_name}`}
              </span>
            </Link>
            <div className="created">
              {dayjs(reportedPad?.created_at).format('DD MMM YYYY HH:mm')}
            </div>
          </div>
          <div className="content-comment">
            <p>{reportedPad?.comment}</p>
          </div>
        </div>
        {reasons.length > 0 && fullSizeContentPadReasons(reasons)}
      </div>
    ),
    [],
  );

  useEffect(() => {
    if (revision) {
      if (revision.type === 'Reported review') {
        setContentLoading(true);
        api.reviews
          .getSingleReview(revision?.review_report?.id)
          .then((res) => {
            setContent(fullsizeContentReview(res.data));
            setContentLoading(false);
          })
          .catch((err) => {
            setContentLoading(false);
            onCancel();
            notification.error({
              message: 'Error',
              description:
                getApiErrorMessages(err) || 'Cannot access to this review!',
            });
          });
      } else if (revision.type === 'Reported upload') {
        setContent(fullsizeContentUpload(revision.upload_report, revision.upload_report.upload?.direction));
      } else if (revision.type === 'Reported pad') {
        setContent(
          fullsizeContentPad(revision, revision.pad_report_type || []),
        );
      }
    }
  }, [fullsizeContentPad, fullsizeContentReview, onCancel, revision]);

  return (
    <Modal
      title={revision?.prev?.name}
      visible={visible}
      footer={[
        <div className="fillsize-modal-meta" key="slider_modal_name">
          <span className="user-name">
            reported by, {revision?.user?.first_name}{' '}
          </span>
          <span className="user-date">
            {dayjs(revision?.created_at).format('DD MMMM, YYYY')}
          </span>
        </div>,
        <div className="fullsize-modal-buttons" key="fullsize-buttons">
          <Button
            type="primary"
            className="mr-2"
            onClick={onRevisionAccept(revision, revision?.type, true)}
          >
            Accept & Edit
          </Button>

          <Popconfirm
            title="Are you sure to ignore this revision?"
            onConfirm={onRevisionIgnore(revision, revision?.type, true)}
            okText="Yes"
            cancelText="No"
          >
            <Button>Ignore</Button>
          </Popconfirm>
        </div>,
      ]}
      onCancel={onCancel}
      wrapClassName={
        revision?.type === 'Reported review'
          ? 'fullsizeReport review'
          : 'fullsizeReport'
      }
    >
      {contentLoading ? (
        <div className="fillsize-content_image">
          <Spin style={{ paddingTop: 100, paddingBottom: 100 }} />
        </div>
      ) : (
        content
      )}
    </Modal>
  );
};

FullsizeReport.defaultProps = {
  visible: false,
  onCancel: () => null,
  onRevisionAccept: () => null,
  onRevisionIgnore: () => null,
  revision: {},
};

FullsizeReport.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onRevisionAccept: PropTypes.func,
  onRevisionIgnore: PropTypes.func,
  revision: PropTypes.object,
};

export default FullsizeReport;
