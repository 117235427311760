/**
 * Checking equality between two arrays ignoring order.
 *
 * Source: https://www.30secondsofcode.org/blog/s/javascript-array-comparison
 *
 * Limitations:
 * - primitive values only
 * - first level only
 * - can't compare objects
 *
 * @param {Array} a
 * @param {Array} b
 * @returns {Boolean}
 */
const arrIsEqual = (a, b) =>
  a.length !== b.length
    ? false
    : [...new Set([...a, ...b])].every(
        (itm) =>
          a.filter((e) => e === itm).length ===
          b.filter((e) => e === itm).length,
      );

export default arrIsEqual;
