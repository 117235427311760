export function errorParser(error) {
  if (error?.response?.data) {
    const { errors, data } = error.response.data;

    return (
      <div className="import-pad-errors">
        {Object.keys(errors).map((row) =>
          Object.keys(errors[row]).map((field) => {
            const fieldParts = field.split('.');
            const invalidValue = fieldParts.reduce((o, i) => o[i], data[row]);

            return (
              <div key={`${row}-${field}`} className="import-pad-error">
                <span>
                  Row number: <b>{+row + 1}</b>
                </span>
                <span>
                  Invalid field: <b>{fieldParts.at(-1)}</b>
                </span>
                <span>
                  Invalid value: <b>{invalidValue || '(empty)'}</b>
                </span>
                <span>
                  Orginal message: <i>{errors[row][field]}</i>
                </span>
              </div>
            );
          }),
        )}
      </div>
    );
  }

  return error.toString();
}
