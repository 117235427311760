import {
  REVIEWS_LIST_GET,
  REVIEWS_LIST_CLEAN,
  REVIEWS_TOGGLE_REVIEW_STATUS,
  CLEAN_ON_UNMOUNT_FALSE,
  CLEAN_ON_UNMOUNT_TRUE,
  REVIEWS_REMOVE_REVIEW_REPORTED,
} from '../actionTypes';
import api from '../../utils/appApi';

export const getReviewsList = (config) => (dispatch) =>
  api.reviews
    .getList(config)
    .then((res) => {
      dispatch({ type: REVIEWS_LIST_GET, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const cleanReviewsList = () => (dispatch) =>
  dispatch({
    type: REVIEWS_LIST_CLEAN,
  });

export const toggleSingleReviewStatus = (reviewId) => (dispatch) =>
  dispatch({
    type: REVIEWS_TOGGLE_REVIEW_STATUS,
    payload: reviewId,
  });

export const removeSingleReviewReport = (reviewId) => (dispatch) =>
  dispatch({
    type: REVIEWS_REMOVE_REVIEW_REPORTED,
    payload: reviewId,
  });

export const setCleanOnUnmountTrue = () => (dispatch) =>
  new Promise((resolve, reject) => {
    try {
      dispatch({
        type: CLEAN_ON_UNMOUNT_TRUE,
      });
      return resolve();
    } catch (e) {
      return reject(Error(e));
    }
  });

export const setCleanOnUnmountFalse = () => (dispatch) =>
  new Promise((resolve, reject) => {
    try {
      dispatch({
        type: CLEAN_ON_UNMOUNT_FALSE,
      });
      return resolve();
    } catch (e) {
      return reject(Error(e));
    }
  });
