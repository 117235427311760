import { useState, useCallback, useMemo, useEffect, useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  useDebounce,
  useMount,
  useSessionStorage,
  useUnmount,
  useUpdateEffect,
} from 'react-use';
import { StarFilled } from '@ant-design/icons';
import PageHeader from '../../uiKitComponents/pageHeader';
import Tabs from '../../uiKitComponents/tabs';
import Tooltip from '../../uiKitComponents/tooltip';
import Typography from '../../uiKitComponents/typography';
import history from '../../utils/history';
import ContactTab from './tabs/contactTab';
import ReviewsTab from './tabs/reviewsTab';
import api from '../../utils/appApi';
import LandingTab from './tabs/landingTab';
import AdminTab from './tabs/adminTab';
import MapWithPads from '../../components/mapWithPads';
import GoogleMapsContext from '../../context/googleMapsContext';
import getAddressComponentByName from '../../utils/getAddressComponentByName';
import {
  selectRegionOptions,
  selectTagOptions,
  selectLandingFeeOptions,
  selectCategoryOptions,
  selectStatusesForDisplay,
  selectWarningOptions,
  selectFacilityOptions,
} from '../../store/selectors/bootstrapSelector';

import styles from './padViewPage.module.sass';

let geocoderService;

const getGeoCodeByLatLng = (lat, lng) =>
  new Promise((resolve, reject) =>
    geocoderService.geocode(
      {
        location: { lat: parseFloat(lat), lng: parseFloat(lng) },
      },
      (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK)
          return resolve(results);
        return reject();
      },
    ),
  );

const initialActivaTabState = {
  params: {
    tab: 'contact',
  },
};

const PadViewPage = () => {
  const {
    categories,
    regions,
    tags,
    statuses,
    landingFees,
    warnings,
    facilities,
  } = useSelector((store) => ({
    categories: selectCategoryOptions(store),
    regions: selectRegionOptions(store),
    tags: selectTagOptions(store),
    statuses: selectStatusesForDisplay(store),
    landingFees: selectLandingFeeOptions(store),
    warnings: selectWarningOptions(store),
    facilities: selectFacilityOptions(store),
  }));
  const [reviewsData, setReviewsData] = useState({});
  const [pageState, setPageState] = useSessionStorage(
    'pad-view-active-tab',
    initialActivaTabState,
  );
  const [pad, setPad] = useState();
  const { googleMapsIsLoaded } = useContext(GoogleMapsContext);
  const [map, setMap] = useState();
  const [mapPlaces, setMapPlaces] = useState([]);
  const [location, setLocation] = useState({});
  const [contactEditMode, setContactEditMode] = useState(false);
  const [isPadRecommended, setIsPadRecommended] = useState(false);
  const { padId } = useParams();
  const useQuery = useLocation();

  const updatePageStateHandler = useCallback(
    (type, value = {}) => {
      setPageState((prevState) => ({
        ...prevState,
        [type]: { ...prevState[type], ...value },
      }));
    },
    [setPageState],
  );

  useEffect(() => {
    const search = new URLSearchParams(useQuery.search);

    if (search.has('tab'))
      updatePageStateHandler('params', { tab: search.get('tab') });

    if (search.has('reported_id')) {
      updatePageStateHandler('params', {
        reported_id: search.get('reported_id'),
      });
    }

    if (search.has('edit_mode') && search.get('tab') === 'contact') {
      setContactEditMode(true);
    }
  }, [updatePageStateHandler, useQuery]);

  useEffect(() => {
    if (pad?.data) {
      if (
        pad.data.contacts.find((c) => c.is_premium && c.contact_type_id === 4)
      ) {
        setIsPadRecommended(true);
      } else setIsPadRecommended(false);
    }
  }, [pad]);

  useMount(() => {
    api.pad.get(padId).then((res) => {
      setPad(res.data);
      setLocation({
        ...res.data.data.location,
        address: res.data.data.location?.name,
      });
    });
    api.pad.getMapPlaces().then((res) => setMapPlaces(res.data.data));
    api.review.getFullListByPad(padId).then((res) => setReviewsData(res.data));
  });

  useEffect(() => {
    if (googleMapsIsLoaded) {
      // https://developers.google.com/maps/documentation/javascript/geocoding#place-id
      geocoderService = new window.google.maps.Geocoder();
    }
  }, [googleMapsIsLoaded]);

  useUpdateEffect(() => {
    if (pageState?.params?.tab !== 'contact' && contactEditMode) {
      setContactEditMode(false);
    }
  }, [pageState, contactEditMode]);

  useDebounce(
    () => {
      if (
        location.getGeoCode &&
        !isNaN(location.latitude) &&
        location.latitude > -91 &&
        location.latitude < 91 &&
        !isNaN(location.longitude) &&
        location.longitude > -181 &&
        location.longitude < 181
      )
        getGeoCodeByLatLng(location.latitude, location.longitude).then(
          (results) => {
            if (results.length) {
              setLocation({
                address: results[0].formatted_address,
                latitude: location.latitude,
                longitude: location.longitude,
                country: getAddressComponentByName(
                  results[0].address_components,
                  'country',
                ).long_name,
                country_short: getAddressComponentByName(
                  results[0].address_components,
                  'country',
                ).short_name,
                county: getAddressComponentByName(
                  results[0].address_components,
                  'administrative_area_level_2',
                ).long_name,
              });
            }
          },
        );
    },
    300,
    [location.latitude, location.longitude, location.getGeoCode],
  );

  const tabOnChange = useCallback(
    (key) => {
      updatePageStateHandler('params', { tab: key });
    },
    [updatePageStateHandler],
  );

  useUnmount(() => {
    sessionStorage.removeItem('pad-view-active-tab');
  });

  const padOnChangeHandle = useCallback((newPad) => {
    setPad(newPad);
  }, []);

  const onBackHandle = useCallback(() => {
    history.goBack();
  }, []);

  const setPadNoAction = useCallback(() => {
    setPad((prevState) => ({
      ...prevState,
      data: { ...prevState.data, resolvable_actions: false },
    }));
  }, []);

  const tabs = useMemo(
    () => [
      {
        tab: 'Contact',
        key: 'contact',
        content: (
          <ContactTab
            pad={pad?.data}
            regions={regions}
            categories={categories}
            statuses={statuses}
            padOnChange={padOnChangeHandle}
            location={location}
            setLocation={setLocation}
            editMode={contactEditMode}
            setEditMode={setContactEditMode}
            isPadRecommended={isPadRecommended}
          />
        ),
      },
      {
        tab: 'Landing',
        key: 'landing',
        content: (
          <LandingTab
            pad={pad?.data}
            warnings={warnings}
            facilities={facilities}
            tags={tags}
            landingFees={landingFees}
            padOnChange={padOnChangeHandle}
          />
        ),
      },
      {
        tab: `Reviews${
          reviewsData?.reviews ? ` (${reviewsData?.reviews?.length})` : ''
        }`,
        key: 'reviews',
        content: (
          <ReviewsTab
            reviewsData={reviewsData}
            setReviewsData={setReviewsData}
          />
        ),
      },
      {
        tab: (
          <span className="d-flex align-items-center">
            Admin
            {!!pad?.data?.pad_notes.length && (
              <span className="ml-1">({pad?.data?.pad_notes.length})</span>
            )}
            {pad?.data?.resolvable_actions && (
              <div className="bullet-point ml-2" />
            )}
          </span>
        ),
        key: 'admin',
        content: (
          <AdminTab
            pad={pad?.data}
            padOnChange={padOnChangeHandle}
            pageParams={pageState}
            setPageParams={updatePageStateHandler}
            padNoActionHandler={setPadNoAction}
          />
        ),
      },
    ],
    [
      pad?.data,
      regions,
      categories,
      statuses,
      padOnChangeHandle,
      location,
      contactEditMode,
      isPadRecommended,
      warnings,
      facilities,
      tags,
      landingFees,
      reviewsData,
      pageState,
      updatePageStateHandler,
      setPadNoAction,
    ],
  );

  const mapOnReadyHandle = useCallback((_, _map) => setMap(_map), []);

  const locationOnDragendHandle = useCallback((_, marker) => {
    const latitude = marker.position.lat();
    const longitude = marker.position.lng();
    setLocation((prevState) => ({
      ...prevState,
      latitude,
      longitude,
      getGeoCode: true,
      withoutZoom: true,
    }));
  }, []);

  const mapOnClickHandle = useCallback((_, _map, e) => {
    const latitude = e.latLng.lat();
    const longitude = e.latLng.lng();
    setLocation((prevState) => ({
      ...prevState,
      latitude,
      longitude,
      getGeoCode: true,
      withoutZoom: true,
    }));
  }, []);

  const newPadLocation = useMemo(
    () =>
      !isNaN(location.latitude) && !isNaN(location.longitude)
        ? {
            lat: parseFloat(location.latitude),
            lng: parseFloat(location.longitude),
            withoutZoom: !!location.withoutZoom,
          }
        : null,
    [location.latitude, location.longitude, location.withoutZoom],
  );

  const PadHeader = useMemo(() => {
    const title = [<span key="title_name">{pad?.data?.name}</span>];

    if (isPadRecommended) {
      title.unshift(
        <Tooltip title="Helipaddy Recommended" key="title_star">
          <StarFilled
            color="#88173f"
            className="cursor-pointer accent-icon mr-1"
          />
        </Tooltip>,
      );
    }
    if (pad?.data?.is_private) {
      title.push(<b key="title_private">(Private)</b>);
    }

    return title.map((t) => t);
  }, [isPadRecommended, pad?.data?.is_private, pad?.data?.name]);

  return (
    <div>
      <PageHeader
        className="page-main-header no-children"
        onBack={onBackHandle}
        title={PadHeader}
        subTitle={
          <>
            #
            <Typography.Paragraph
              style={{ color: 'inherit' }}
              className="mb-0 d-inline"
              copyable
            >
              {pad?.data.id}
            </Typography.Paragraph>
          </>
        }
      />
      <div className="page-main-content">
        <MapWithPads
          height={250}
          clustererConfig={{
            gridSize: 45,
          }}
          onReady={mapOnReadyHandle}
          map={map}
          pads={mapPlaces}
          categories={categories}
          locationOnDragend={locationOnDragendHandle}
          location={newPadLocation}
          onClick={mapOnClickHandle}
          readOnly={!contactEditMode}
        />
        <Tabs
          activeKey={pageState?.params?.tab}
          onChange={tabOnChange}
          className={styles.tabs}
          size="large"
        >
          {tabs.map((itm) => (
            <Tabs.TabPane tab={itm.tab} key={itm.key} className="flex-grow-1">
              {pageState?.params?.tab === itm.key && itm.content}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default PadViewPage;
