import PropTypes from 'prop-types';
import React from 'react';
import { Descriptions, notification } from 'antd';
import UploadCard from './UploadCard';
import { removeHandler, uploadHandler } from './SurveyViewStep.utils';
import validateFile from '../../../../utils/validateFile';

import './SurveyViewStep.styles.scss';

const acceptedFiles = '.jpg,.png,.jpeg';

export const SurveyViewStep = React.memo(
  ({ stepValues, surveyId, updateFormFields }) => {
    const [isUploading, setIsUplaoding] = React.useState(false);
    const [uploadingSideKey, setUploadingSideKey] = React.useState('');
    const [deletingSideKey, setDeletingSideKey] = React.useState('');

    const inputFileRef = React.useRef();

    const openSelectFilesDialog = (sideKey) => () => {
      setUploadingSideKey(sideKey);
      inputFileRef.current.click();
    };

    const onFileInputChange = React.useCallback(
      async (event) => {
        const file = event.target.files[0];

        if (file && surveyId) {
          const isFileInvalid = validateFile(file, {
            maxSize: null,
            accept: acceptedFiles,
          });

          if (!isFileInvalid) {
            setIsUplaoding(true);
            try {
              const upload = await uploadHandler(
                surveyId,
                file,
                uploadingSideKey.replace('view_', ''),
              );

              updateFormFields([
                { name: ['view', uploadingSideKey, 'upload'], value: upload },
              ]);
            } catch (error) {
              //
            } finally {
              setIsUplaoding(false);
            }
          } else if (isFileInvalid.error_code.ext_not_allowed) {
            notification.error({
              message: 'Error',
              description: 'This extension is not supported',
            });
          }
        }

        inputFileRef.current.value = '';
      },
      [surveyId, updateFormFields, uploadingSideKey],
    );

    const removeFileHandler = React.useCallback(
      (sideKey) => async () => {
        setDeletingSideKey(sideKey);
        try {
          await removeHandler(stepValues[sideKey]?.upload?.id);
          updateFormFields([
            { name: ['view', sideKey, 'upload'], value: null },
          ]);
        } catch (error) {
          //
        }
        setDeletingSideKey(null);
      },
      [stepValues, updateFormFields],
    );

    const updateSideComment = React.useCallback(
      (sideKey) => (value) => {
        updateFormFields([{ name: ['view', sideKey, 'comment'], value }]);
      },
      [updateFormFields],
    );

    return (
      <Descriptions
        labelStyle={{ width: 150 }}
        size="small"
        bordered
        column={1}
        layout="vertical"
        className="mb-3"
      >
        <Descriptions.Item label={`View: ${stepValues.is_completed ? 'Completed' : 'Incomplete'}`}>
          <div className="survey-pad-view__uploads">
            <UploadCard
              sideKey="view_north"
              title="North"
              comment={stepValues.view_north.comment}
              updateComment={updateSideComment('view_north')}
              upload={stepValues.view_north.upload}
              uploadFile={openSelectFilesDialog('view_north')}
              removeFile={removeFileHandler('view_north')}
              isUploading={isUploading && uploadingSideKey === 'view_north'}
              isRemoving={deletingSideKey === 'view_north'}
            />
            <UploadCard
              sideKey="view_east"
              title="East"
              comment={stepValues.view_east.comment}
              updateComment={updateSideComment('view_east')}
              upload={stepValues.view_east.upload}
              uploadFile={openSelectFilesDialog('view_east')}
              removeFile={removeFileHandler('view_east')}
              isUploading={isUploading && uploadingSideKey === 'view_east'}
              isRemoving={deletingSideKey === 'view_east'}
            />

            <UploadCard
              sideKey="view_west"
              title="West"
              comment={stepValues.view_west.comment}
              updateComment={updateSideComment('view_west')}
              upload={stepValues.view_west.upload}
              uploadFile={openSelectFilesDialog('view_west')}
              removeFile={removeFileHandler('view_west')}
              isUploading={isUploading && uploadingSideKey === 'view_west'}
              isRemoving={deletingSideKey === 'view_west'}
            />

            <UploadCard
              sideKey="view_south"
              title="South"
              comment={stepValues.view_south.comment}
              updateComment={updateSideComment('view_south')}
              upload={stepValues.view_south.upload}
              uploadFile={openSelectFilesDialog('view_south')}
              removeFile={removeFileHandler('view_south')}
              isUploading={isUploading && uploadingSideKey === 'view_south'}
              isRemoving={deletingSideKey === 'view_south'}
            />
          </div>
          <input
            ref={inputFileRef}
            type="file"
            accept={acceptedFiles}
            className="d-none"
            onChange={onFileInputChange}
          />
        </Descriptions.Item>
      </Descriptions>
    );
  },
);

SurveyViewStep.propTypes = {
  stepValues: PropTypes.object,
  surveyId: PropTypes.number,
  updateFormFields: PropTypes.func,
};
