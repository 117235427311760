import { Button, Popover, Form, Input, Popconfirm, notification } from 'antd';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import api from '../../../../../utils/appApi';
import styles from '../contactTab.module.sass';

export default function ArchiveAction(props) {
  const { padId, isArchived, onActionComplete } = props;
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (padId) setDisabled(false);
  }, [padId]);

  const handleArchivePad = (data = { note: '' }) => {
    setLoading(true);

    api.pad
      .archive(padId, data)
      .then((res) => {
        onActionComplete(res.data);
        notification.success({
          message: 'Success',
          description: 'Done!',
        });
        setLoading(false);
        setVisible(false);
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: err?.response?.data?.message,
        });
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    handleArchivePad(values);
  };

  const cancelArchivePad = () => {
    setVisible(false);
    form.resetFields();
  };

  const ArchiveBtnContent = (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Form.Item
        name="note"
        label="Reason"
        className="mb-2"
        rules={[
          {
            required: true,
            message: 'Please provide reason',
          },
          {
            min: 3,
            message: 'At least 3 characters',
          },
        ]}
      >
        <Input.TextArea autoSize style={{ minHeight: 50 }} />
      </Form.Item>
      <Button.Group size="small" className="d-flex justify-content-end">
        <Button htmlType="button" onClick={cancelArchivePad}>
          Cancel
        </Button>
        <Button type="primary" onClick={() => form.submit()} loading={loading}>
          Submit
        </Button>
      </Button.Group>
    </Form>
  );

  return (
    <>
      {!isArchived ? (
        <Popover
          key="archive-pad"
          content={ArchiveBtnContent}
          visible={visible}
          trigger="click"
          placement="topRight"
          overlayClassName={styles.padArchiveContnet}
        >
          <Button
            htmlType="button"
            disabled={disabled}
            onClick={() => setVisible(true)}
          >
            Archive
          </Button>
        </Popover>
      ) : (
        <Popconfirm
          key="unarchive-pad"
          title="Are you sure you want to unarchive this pad?"
          placement="topRight"
          trigger="click"
          onConfirm={() => handleArchivePad({ note: '' })}
        >
          <Button htmlType="button" disabled={disabled} loading={loading}>
            Unarchive
          </Button>
        </Popconfirm>
      )}
    </>
  );
}

ArchiveAction.propTypes = {
  padId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isArchived: PropTypes.string,
  onActionComplete: PropTypes.func,
};

ArchiveAction.defaultProps = {
  padId: null,
  isArchived: '',
  onActionComplete: () => null,
};
