export default function objectsAreSame(x, y) {
  let areSame = true;

  // eslint-disable-next-line no-restricted-syntax
  for (const propertyName in x) {
    if (x[propertyName] !== y[propertyName]) {
      areSame = false;
      break;
    }
  }

  return areSame;
}
