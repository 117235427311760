import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  EditOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import Form from '../../uiKitComponents/form';
import Input from '../../uiKitComponents/input';
import Tooltip from '../../uiKitComponents/tooltip';

import './inlineEditInput.scss';

export default function InlineEditInput(props) {
  const { value, name, onFinish } = props;
  const [form] = Form.useForm();
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const onKeyDown = ({ key }) => {
    if (key === 'Enter') {
      form.submit();
    }
  };

  const onSubmitHandler = async (values) => {
    setLoading(true);

    await onFinish(values);

    setLoading(false);
    setStatus(false);
  };

  return (
    <div className="inline-edit-wrapper">
      {status ? (
        <div>
          <Form
            onFinish={onSubmitHandler}
            form={form}
            initialValues={{
              name: value,
            }}
          >
            <Form.Item name={name} className="mb-0">
              <Input
                size="large"
                autoFocus
                onBlur={() => setStatus(false)}
                onKeyDown={onKeyDown}
                suffix={
                  <Tooltip title="Press Enter to save">
                    {loading ? (
                      <LoadingOutlined spin />
                    ) : (
                      <InfoCircleOutlined />
                    )}
                  </Tooltip>
                }
              />
            </Form.Item>
          </Form>
        </div>
      ) : (
        <div
          className="inline-edit-static"
          aria-hidden="true"
          onClick={() => setStatus(true)}
        >
          <h3>{value}</h3>
          <EditOutlined className="ml-2" />
        </div>
      )}
    </div>
  );
}

InlineEditInput.propTypes = {
  value: PropTypes.string,
  onFinish: PropTypes.func,
  staticContent: PropTypes.element,
  name: PropTypes.string,
};

InlineEditInput.defaultProps = {
  staticContent: null,
  name: '',
  value: '',
  onFinish: () => null,
};
