import PropTypes from 'prop-types';
import { useCallback } from 'react';
import PicturesWall from '../../picturesWall';
import { parseUploadsToPicturesWall } from '../dataRowUtils';

export default function StaticDataPhotos(props) {
  const {
    onSelect,
    side,
    content,
    selected,
    checkedSide,
    setCheckedSide,
  } = props;

  const selectPicturesHandler = useCallback(
    (event) => {
      if (side !== checkedSide) {
        setCheckedSide(side);
      } else onSelect(side)(event);
    },
    [checkedSide, onSelect, setCheckedSide, side],
  );

  return (
    <div className="data-row-static-photos">
      <PicturesWall
        selectedPictures={selected.map((u) => String(u.id))}
        selectPictures={selectPicturesHandler}
        fileList={parseUploadsToPicturesWall(content)}
        showUploadBtn={false}
        showRemoveIcon={false}
        hasCheckbox
      />
    </div>
  );
}

StaticDataPhotos.propTypes = {
  onSelect: PropTypes.func.isRequired,
  side: PropTypes.string.isRequired,
  content: PropTypes.array,
  selected: PropTypes.array,
  checkedSide: PropTypes.string,
  setCheckedSide: PropTypes.func,
};

StaticDataPhotos.defaultProps = {
  selected: [],
  content: [],
  checkedSide: '',
  setCheckedSide: () => null,
};
