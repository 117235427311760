import PropTypes from 'prop-types';
import moment from 'moment';
import {
  MinusCircleOutlined,
  PlusOutlined,
  SaveOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSessionStorage } from 'react-use';
import Form from '../../uiKitComponents/form';
import Input from '../../uiKitComponents/input';
import Button from '../../uiKitComponents/button';
import Select from '../../uiKitComponents/select';
import Space from '../../uiKitComponents/space';
import DatePicker from '../../uiKitComponents/datePicker';
import Spin from '../../uiKitComponents/spin';
import Checkbox from '../../uiKitComponents/checkbox';
import Popconfirm from '../../uiKitComponents/popconfirm';
import Collapse from '../../uiKitComponents/collapse';
import {
  updateUserFilter,
  removeUserFilter,
  storeNewFilter,
} from '../../store/actions/userFiltersAction';
import formattingFilters from '../../utils/formattingFilters';
import ExportFiltersBtn from './ExportFiltersBtn';

import './filterSection.styles.scss';

const { Panel } = Collapse;

const rangeConfig = {
  rules: [{ required: true, message: 'Please select time!' }],
};

const dateFormat = 'YYYY-MM-DD';

const getRangePickerDates = (values) => {
  if (values) {
    if (typeof values === 'string') {
      const dates = values.split(',');

      if (dates && Array.isArray(dates)) {
        return [moment(dates[0], dateFormat), moment(dates[1], dateFormat)];
      }
    }

    if (Array.isArray(values)) {
      return [moment(values[0], dateFormat), moment(values[1], dateFormat)];
    }
  }

  return [null, null];
};

const toSelectOptions = (obj) =>
  Object.entries(obj).map(([value, label]) => ({
    value,
    label,
  }));

const toColumnSelectOptions = (obj) =>
  Object.entries(obj).map(([value, data]) => ({
    value,
    label: data.display_name,
  }));

const toOperatorSelectOptions = (columnOptions, selectedColumn) =>
  selectedColumn
    ? columnOptions[selectedColumn].operators.map((itm) => ({
      value: itm,
      label: itm,
    }))
    : [];

const FiltersSection = (props) => {
  const {
    onSubmit,
    clearFilters,
    filters,
    keyword,
    filter_info,
    columnOptions,
    loading,
    filter_columns,
    entity,
    onUpdateFilter,
    defaultCollapseStatus,
    exportOptions,
    hiddenExportForUpload,
  } = props;
  const [form] = Form.useForm();
  const [update_filters] = Form.useForm();
  const [showSaveForm, setStatusForSaveForm] = useState(false);
  const [isSavedFilter, setIsSavedFilter] = useState(false);
  const [isFiltersUploading, setIsFiltersUploading] = useState(false);
  const [isFiltersDeleting, setIsFiltersDeleting] = useState(false);
  const dispatch = useDispatch();
  const [
    collapseStatus,
    setCollapseStatus,
  ] = useSessionStorage(defaultCollapseStatus.name, [
    defaultCollapseStatus.status,
  ]);

  useEffect(() => {
    form.resetFields();
    update_filters.resetFields();
    setIsSavedFilter(false);

    // Checking if filters was set from DB
    if (Object.keys(filter_info).length) {
      setIsSavedFilter(true);
      update_filters.resetFields();
    }
    if (Object.keys(filters[0]).length) setStatusForSaveForm(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const onFinish = (values) => {
    const _filters = values.filters
      .map((filter) => {
        if (filter.operator === 'between') {
          return {
            ...filter,
            keyword: Array.isArray(filter.keyword)
              ? filter.keyword.map((itm) => moment(itm).format()).join(',')
              : filter.keyword,
          };
        }

        if (
          filter.operator === 'is blank' ||
          filter.operator === "isn't blank"
        ) {
          return { ...filter, keyword: 'true' };
        }

        return filter;
      })
      .filter(
        (filter) =>
          Object.keys(filter).length === 3 &&
          Object.values(filter).every(Boolean),
      );

    if (!_filters.length) _filters.push({});

    const data = {
      ...values,
      filters: _filters,
    };

    onSubmit(data);
  };

  const collapseCallback = (key) => {
    setCollapseStatus(key);
  };

  const handleClearFilters = () => {
    clearFilters();
    form.setFields([
      { name: 'filters', value: [{}] },
      { name: 'keyword', value: undefined },
    ]);
    update_filters.setFields([
      { name: 'name', value: '' },
      { name: 'is_public', value: false },
    ]);
    setStatusForSaveForm(false);
    onUpdateFilter('initial');
  };

  const updateFilter = (filter_id, values, filter_string) => {
    updateUserFilter(filter_id, { ...values, filters: filter_string })(dispatch)
      .then(() => {
        setIsFiltersUploading(false);
        form.submit();
        onUpdateFilter(filter_id);
      })
      .catch(() => {
        setIsFiltersUploading(false);
      });
  };

  const saveFilter = (values, filter_string, columns, entity_name) => {
    storeNewFilter({
      ...values,
      filters: filter_string,
      columns,
      entity: entity_name,
    })(dispatch)
      .then((res) => {
        setIsFiltersUploading(false);
        form.submit();
        onUpdateFilter(res?.data?.id);
      })
      .catch(() => {
        setIsFiltersUploading(false);
      });
  };

  const handleSavingForm = useCallback(
    (values) => {
      setIsFiltersUploading(true);

      const formatedFilters = form
        .getFieldValue('filters')
        .map((filter) => formattingFilters(filter, filter.keyword, 'STRING'));

      const stringFilters = {};

      formatedFilters.forEach((itm) => {
        Object.keys(itm).forEach((key) => {
          stringFilters[key] = itm[key];
        });
      });

      if (isSavedFilter) {
        updateFilter(filter_info?.id, values, stringFilters);
      } else {
        saveFilter(values, stringFilters, filter_columns, entity);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      filter_info,
      form,
      update_filters,
      isSavedFilter,
      filter_columns,
      entity,
    ],
  );

  const handleRemoveUserFilter = () => {
    setIsFiltersDeleting(true);
    removeUserFilter(filter_info?.id)(dispatch)
      .then(() => {
        handleClearFilters();
        setIsFiltersDeleting(false);
      })
      .catch(() => {
        setIsFiltersDeleting(false);
      });
  };

  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: 90 }}
      >
        <Spin />
      </div>
    );

  const getSelectDropdownClassName = (data) => {
    if (data && typeof data === 'object') {
      if (Object.keys(data).length > 8) return 'scroll-persist';
    }

    return '';
  };

  return (
    <div>
      <Collapse defaultActiveKey={collapseStatus} onChange={collapseCallback}>
        <Panel header="Filter list">
          <Form
            form={form}
            initialValues={{ filters, keyword }}
            onFinish={onFinish}
          >
            <div className="d-flex flex-wrap justify-content-between align-items-start">
              <div style={{ maxWidth: 761 }}>
                <h3>Advanced search</h3>
                <Form.List name="filters">
                  {(fields, { add, remove }) => (
                    <Space align="start">
                      <div>
                        {fields.map((field, i, arr) => (
                          <Space key={field.key} align="baseline">
                            <Form.Item
                              {...field}
                              name={[field.name, 'column']}
                              fieldKey={[field.fieldKey, 'column']}
                            >
                              <Select
                                placeholder="Column"
                                style={{ width: 200 }}
                                options={toColumnSelectOptions(columnOptions)}
                                allowClear
                                showSearch
                                dropdownClassName={getSelectDropdownClassName(
                                  columnOptions,
                                )}
                                filterOption={(input, option) =>
                                  option.label
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              noStyle
                              shouldUpdate={(prevValues, curValues) =>
                                prevValues.filters[i] &&
                                curValues.filters[i] &&
                                prevValues.filters[i].column !==
                                curValues.filters[i].column
                              }
                            >
                              {({ getFieldValue }) => (
                                <Form.Item
                                  {...field}
                                  name={[field.name, 'operator']}
                                  fieldKey={[field.fieldKey, 'operator']}
                                >
                                  <Select
                                    placeholder="Operator"
                                    style={{ width: 200 }}
                                    options={toOperatorSelectOptions(
                                      columnOptions,
                                      getFieldValue('filters')[i].column,
                                    )}
                                  />
                                </Form.Item>
                              )}
                            </Form.Item>
                            <Form.Item
                              noStyle
                              shouldUpdate={(prevValues, curValues) => {
                                const shouldUpdate =
                                  prevValues.filters[i] &&
                                  curValues.filters[i] &&
                                  prevValues.filters[i].column !==
                                  curValues.filters[i].column &&
                                  prevValues.filters.length ===
                                  curValues.filters.length;
                                if (shouldUpdate) {
                                  const filtersCopy = [
                                    ...form.getFieldValue('filters'),
                                  ];
                                  filtersCopy[i] = {
                                    ...filtersCopy[i],
                                    operator: curValues.filters[i].column
                                      ? columnOptions[
                                        curValues.filters[i].column
                                      ].operators[0]
                                      : undefined,
                                    keyword: undefined,
                                  };

                                  form.setFieldsValue({
                                    filters: filtersCopy,
                                  });
                                }
                                return shouldUpdate;
                              }}
                            >
                              {({ getFieldValue }) => {
                                const { column } = getFieldValue('filters')[i];

                                switch (
                                column &&
                                columnOptions[column].value_type
                                ) {
                                  case 'select':
                                    return (
                                      <Form.Item
                                        {...field}
                                        name={[field.name, 'keyword']}
                                        fieldKey={[field.fieldKey, 'keyword']}
                                      >
                                        <Select
                                          placeholder="Select"
                                          style={{ width: 200 }}
                                          options={toSelectOptions(
                                            columnOptions[column].options,
                                          )}
                                          dropdownClassName={getSelectDropdownClassName(
                                            columnOptions[column].options,
                                          )}
                                          allowClear
                                        />
                                      </Form.Item>
                                    );
                                  case 'multiple':
                                    return (
                                      <Form.Item
                                        {...field}
                                        name={[field.name, 'keyword']}
                                        fieldKey={[field.fieldKey, 'keyword']}
                                      >
                                        <Select
                                          placeholder="Select"
                                          mode="multiple"
                                          style={{ width: 200 }}
                                          options={toSelectOptions(
                                            columnOptions[column].options,
                                          )}
                                          dropdownClassName={getSelectDropdownClassName(
                                            columnOptions[column].options,
                                          )}
                                          allowClear
                                          filterOption={(input, option) =>
                                            option.label
                                              .toLowerCase()
                                              .indexOf(input.toLowerCase()) >= 0
                                          }
                                        />
                                      </Form.Item>
                                    );
                                  case 'date':
                                    /**
                                     * * Warning:
                                     * * Datepicker receive value from form controller but no show it.
                                     * * And because of that we have additional Form.Item wrapper to get
                                     * * date and pass to DatePicker "value" prop manually.
                                     */
                                    return (
                                      <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, curValues) =>
                                          prevValues.filters[i]?.keyword !==
                                          curValues.filters[i]?.keyword
                                        }
                                      >
                                        {() => {
                                          const {
                                            keyword: date,
                                          } = getFieldValue('filters')[i];
                                          return (
                                            <Form.Item
                                              {...field}
                                              {...rangeConfig}
                                              name={[field.name, 'keyword']}
                                              fieldKey={[
                                                field.fieldKey,
                                                'keyword',
                                              ]}
                                              getValueProps={(value) =>
                                                getRangePickerDates(value)
                                              }
                                            //* Pass value to DatePicker but not show it
                                            >
                                              <DatePicker.RangePicker
                                                style={{ width: 240 }}
                                                format="DD/MM/YYYY"
                                                placeholder={['Start', 'End']}
                                                value={getRangePickerDates(
                                                  date,
                                                )}
                                              />
                                            </Form.Item>
                                          );
                                        }}
                                      </Form.Item>
                                    );
                                  default:
                                    return (
                                      <Form.Item
                                        {...field}
                                        name={[field.name, 'keyword']}
                                        fieldKey={[field.fieldKey, 'keyword']}
                                        autoComplete="keyword"
                                      >
                                        <Input
                                          style={{ width: 200 }}
                                          placeholder="Keyword"
                                        />
                                      </Form.Item>
                                    );
                                }
                              }}
                            </Form.Item>
                            {arr.length > 1 && (
                              <MinusCircleOutlined
                                onClick={() => remove(field.name)}
                              />
                            )}
                          </Space>
                        ))}
                      </div>

                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add({})}
                          icon={<PlusOutlined />}
                        >
                          Add filter
                        </Button>
                      </Form.Item>
                    </Space>
                  )}
                </Form.List>
              </div>
              <div>
                <h3>Search</h3>
                <Form.Item
                  name="keyword"
                  autoComplete="keyword"
                  rules={[{ min: 3, message: 'At least 3 characters' }]}
                >
                  <Input placeholder="Keyword" />
                </Form.Item>
              </div>
              <Space style={{ marginTop: 'calc(23px + 0.5em)' }}>
                <Form.Item>
                  <Button
                    type="dashed"
                    htmlType="button"
                    onClick={handleClearFilters}
                  >
                    Clear
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Space>
            </div>
          </Form>

          {exportOptions && (
            <ExportFiltersBtn
              activeFilters={filters}
              activeKeyword={keyword}
              config={exportOptions}
              hiddenExportForUpload={hiddenExportForUpload}
            />
          )}

          {showSaveForm && (
            <Form
              className="mt-3"
              onFinish={handleSavingForm}
              form={update_filters}
              name="saveFilterForm"
            >
              <Space align="end">
                <div>
                  <Space className="mb-2">
                    <h3 className="mb-0">Save filter</h3>
                    <Form.Item
                      className="mb-0"
                      name="is_public"
                      valuePropName="checked"
                      initialValue={filter_info.is_public}
                    >
                      <Checkbox className="ml-2">Public</Checkbox>
                    </Form.Item>
                  </Space>
                  <Form.Item
                    className="mb-0"
                    name="name"
                    required
                    initialValue={filter_info?.name}
                  >
                    <Input
                      style={{ width: 200 }}
                      placeholder="Filter name"
                      required
                    />
                  </Form.Item>
                </div>
                <Form.Item className="mb-0">
                  <Button
                    htmlType="submit"
                    icon={<SaveOutlined />}
                    loading={isFiltersUploading}
                  >
                    {isSavedFilter ? 'Update' : 'Save'}
                  </Button>
                </Form.Item>
                {isSavedFilter && (
                  <Form.Item className="mb-0">
                    <Popconfirm
                      title="Are you sure you want to delete this filter?"
                      onConfirm={handleRemoveUserFilter}
                    >
                      <Button
                        htmlType="button"
                        loading={isFiltersDeleting}
                        icon={<DeleteOutlined />}
                      />
                    </Popconfirm>
                  </Form.Item>
                )}
              </Space>
            </Form>
          )}
        </Panel>
      </Collapse>
    </div>
  );
};

FiltersSection.propTypes = {
  onSubmit: PropTypes.func,
  clearFilters: PropTypes.func,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      column: PropTypes.string,
      operator: PropTypes.string,
      keyword: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    }),
  ),
  keyword: PropTypes.string,
  filter_info: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    is_public: PropTypes.bool,
  }),
  columnOptions: PropTypes.object,
  loading: PropTypes.bool,
  filter_columns: PropTypes.array,
  entity: PropTypes.string,
  onUpdateFilter: PropTypes.func,
  defaultCollapseStatus: PropTypes.object,
  exportOptions: PropTypes.shape({
    action: PropTypes.func,
    text: PropTypes.string,
    filename: PropTypes.string,
  }),
  hiddenExportForUpload: PropTypes.bool,
};

FiltersSection.defaultProps = {
  onSubmit: () => null,
  clearFilters: () => null,
  filters: [{}],
  keyword: undefined,
  filter_info: {},
  columnOptions: {},
  loading: false,
  filter_columns: [],
  entity: undefined,
  onUpdateFilter: () => null,
  exportOptions: null,
  defaultCollapseStatus: {
    status: '0',
    name: 'collapseStatus',
  },
  hiddenExportForUpload: false,
};

export default FiltersSection;
