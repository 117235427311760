import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Checkbox,
  Descriptions,
  Form,
  Input,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import { twitterUsernameHandle } from '../../../../components/dupeResolver/dataRowUtils';
import {
  selectFacilityOptions,
  selectTagOptions,
  selectWarningOptions,
} from '../../../../store/selectors/bootstrapSelector';
import PadViewContext from '../../PadView.context';
import { generateWaringsOrFacilities } from './LandingTab.utils';
import CheckableTagList from '../../../../components/checkableTagList';
import IcaoInput from '../../../../components/icaoInput';
import { LandingTabEditLandingFees } from './parts';
import { ReactComponent as InfoCircleInfo } from '../../../../assets/icons/info-circle.svg';
import PadHiscInput from '../ContactTab/parts/PadHiscInput';

export const formItemVerticalLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export default function LandingTabEdit({ SON, form, isHiscErr, setIsHiscErr }) {
  const { pad } = React.useContext(PadViewContext);
  const { tags, warnings, facilities } = useSelector((store) => ({
    tags: selectTagOptions(store),
    warnings: selectWarningOptions(store),
    facilities: selectFacilityOptions(store),
  }));

  const warningsAndFacilities = React.useMemo(
    () => generateWaringsOrFacilities(pad),
    [pad],
  );

  const convertFirstSixLettersToUpperCase = (value) => {

    if (value?.length > 6) {
      return;
    };

    const newValue = value?.toUpperCase() ?? '';
    form.setFieldsValue({ hisc: newValue });
  };

  return (
    <Descriptions labelStyle={{ width: 160 }} size="small" bordered column={1}>
      <Descriptions.Item label="Site Description">
        <Form.Item name="site_information" className="mb-0">
          <Input.TextArea
            placeholder="Do NOT include technical landing information here. This is only for interesting or offering information."
            autoSize={{ minRows: 4 }}
          />
        </Form.Item>
      </Descriptions.Item>
      <Descriptions.Item label={SON}>
        <Form.Item name="landing_advice" className="mb-0">
          <Input.TextArea
            placeholder="ONLY technical landing information here."
            autoSize={{ minRows: 4 }}
          />
        </Form.Item>
      </Descriptions.Item>
      {warningsAndFacilities && (
        <Descriptions.Item label={warningsAndFacilities.label}>
          {warningsAndFacilities.content_type === 'facilities' ? (
            <Form.Item
              {...formItemVerticalLayout}
              label="Click on list item to select"
              name="facilities"
              style={{ maxWidth: 400 }}
            >
              <CheckableTagList
                options={facilities}
                style={{ margin: '0 -7px', display: 'block' }}
              />
            </Form.Item>
          ) : (
            <Form.Item
              {...formItemVerticalLayout}
              label="Click on list item to select"
              name="warnings"
              style={{ maxWidth: 400 }}
            >
              <CheckableTagList
                options={warnings}
                style={{ margin: '0 -7px', display: 'block' }}
              />
            </Form.Item>
          )}
          <Space align="baseline">
            <LandingTabEditLandingFees />
          </Space>
        </Descriptions.Item>
      )}

      <Descriptions.Item label="ICAO Code">
        <Form.Item name="icao" initialValue={pad?.icao} className="mb-0">
          <IcaoInput style={{ width: 65 }} />
        </Form.Item>
      </Descriptions.Item>

      <Descriptions.Item
        label={
          <span className="d-flex align-items-center">
            <Typography className="mr-1">HISC</Typography>
            <Tooltip
              title="Helipaddy International Site Code"
              className="tooltip-responsive"
            >
              <InfoCircleInfo style={{ fontSize: '5px' }} />
            </Tooltip>
          </span>
        }
      >
        <Form.Item
          name="hisc"
          dependencies={['category_id']}
          rules={[
            ({ getFieldValue }) => ({
              required: pad.category_id !== 1 && pad.category_id !== 7 && pad?.is_valid,
              message: 'Please enter HISC',
            }),
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || /^[A-Z]{6}[0-9a-zA-Z]{2}$/.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('ex: NNNNnn'));
              },
            }),
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!isHiscErr) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(isHiscErr));
              },
            }),
          ]}
        >
          <PadHiscInput
            onChange={convertFirstSixLettersToUpperCase}
            setIsHiscErr={setIsHiscErr}
            defaultValue={pad?.hisc}
            form={form}
          />
        </Form.Item>
      </Descriptions.Item>
      <Descriptions.Item label="Social">
        <div style={{ maxWidth: 400 }}>
          <Form.Item
            name="pad_social#website"
            label="Website"
            className="mb-0"
            initialValue={pad?.pad_social?.website}
          >
            <Input size="small" placeholder="https://website.com" />
          </Form.Item>
          <Form.Item
            name="pad_social#twitter_handle"
            label="Twitter handle"
            rules={twitterUsernameHandle}
            className="mb-0"
            initialValue={pad?.pad_social?.twitter_handle?.replace('@', '')}
          >
            <Input size="small" prefix="@" placeholder="username" />
          </Form.Item>
          <Form.Item
            label="Tags"
            name="tags"
            help={'To create new one just type and press "Enter"'}
            className="mb-0"
          >
            <Select
              size="small"
              mode="tags"
              placeholder="Select existing or create"
              options={tags}
            />
          </Form.Item>
        </div>
      </Descriptions.Item>
      <Descriptions.Item label="Access">
        <table style={{ maxWidth: 320 }}>
          <tbody>
            <tr>
              <td>API Access:</td>
              <td>
                <Form.Item
                  name="is_api_accessible"
                  className="mb-0"
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
              </td>
            </tr>
          </tbody>
        </table>
      </Descriptions.Item>
    </Descriptions>
  );
}

LandingTabEdit.propTypes = {
  SON: PropTypes.string,
  form: PropTypes.object,
  isHiscErr: PropTypes.string,
  setIsHiscErr: PropTypes.func,
};
