import { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import { useUpdateEffect } from 'react-use';
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';
import {
  LinkOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Descriptions from '../../../../uiKitComponents/descriptions';
import Popconfirm from '../../../../uiKitComponents/popconfirm';
import Spin from '../../../../uiKitComponents/spin';
import Space from '../../../../uiKitComponents/space';
import Button from '../../../../uiKitComponents/button';
import convertDMS from '../../../../utils/convertDMS';
import { dateFormats } from '../../../../utils/constants';
import NumericInput from '../../../../components/numericInput';
import Form from '../../../../uiKitComponents/form';
import Input from '../../../../uiKitComponents/input';
import condStrings from '../../../../utils/condStrings';
import styles from '../../../PadCreatePage/padCreatePage.module.sass';
import fieldsErrors from '../../../../utils/fieldsErrors';
import SearchLocation from '../../../../components/searchLocation';
import Select from '../../../../uiKitComponents/select';
import Typography from '../../../../uiKitComponents/typography';
import PicturesWall from '../../../../components/picturesWall';
import api from '../../../../utils/appApi';
import Tooltip from '../../../../uiKitComponents/tooltip';
import Alert from '../../../../uiKitComponents/alert';
import getApiErrorMessages from '../../../../utils/getApiErrorMessages';
import useApiErrorsWithAntd from '../../../../hooks/useApiErrorsWithAntd';
import CheckboxInverted from '../../../../components/checkboxInverted';
import {
  loadingBarDisable,
  loadingBarEnable,
} from '../../../../store/actions/loadingBarAction';
import ContactsList from './parts/ContactsList';
import ArchiveAction from './parts/ArchiveAction';
import history from '../../../../utils/history';
import { ReactComponent as ArchivedIcon } from '../../../../assets/svg/archived.svg';
import contactStyles from './contactTab.module.sass';
import landingStyles from '../landingTab/landingTab.module.sass';
import PrimaryContact from './parts/PrimaryContact';
import SurveyUploads from './parts/SurveyUploads';

const latValidationRules = [
  {
    validator: (itm, value) =>
      (!isNaN(value) && parseFloat(value) < 91) || isNaN(value) || !value
        ? Promise.resolve()
        : Promise.reject(new Error(fieldsErrors.max(90))),
  },
  {
    validator: (itm, value) =>
      (!isNaN(value) && parseFloat(value) > -91) || isNaN(value) || !value
        ? Promise.resolve()
        : Promise.reject(new Error(fieldsErrors.min(-90))),
  },
];

const lngValidationRules = [
  {
    validator: (itm, value) =>
      (!isNaN(value) && parseFloat(value) < 181) || isNaN(value) || !value
        ? Promise.resolve()
        : Promise.reject(new Error(fieldsErrors.max(180))),
  },
  {
    validator: (itm, value) =>
      (!isNaN(value) && parseFloat(value) > -181) || isNaN(value) || !value
        ? Promise.resolve()
        : Promise.reject(new Error(fieldsErrors.min(-180))),
  },
];

export const visualizeBool = (value) =>
  value !== null &&
  value !== undefined &&
  (value ? (
    <CheckCircleOutlined className={landingStyles.checkCircleOutlined} />
  ) : (
    <CloseCircleOutlined className={landingStyles.closeCircleOutlined} />
  ));

const ContactTab = (props) => {
  const {
    pad,
    regions,
    categories,
    statuses,
    padOnChange,
    location,
    setLocation,
    editMode,
    setEditMode,
    isPadRecommended,
    ...rest
  } = props;
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const { setApiErrorsToAntdForm } = useApiErrorsWithAntd(form);
  const globalErrors = form.getFieldError('global');
  const dispatch = useDispatch();
  const { loadingBarEnabled } = useSelector((state) => ({
    loadingBarEnabled: state.loadingBar.enabled,
  }));
  const [generateLinkProps, setGenerateLinkProps] = useState({
    status: false,
    loading: false,
    button_content: 'Generate Link',
  });

  useEffect(() => {
    if (pad) {
      if (pad?.status_id === 2 || (pad?.status_id === 3 && !pad?.deleted_at)) {
        setGenerateLinkProps((prevState) => ({ ...prevState, status: true }));
      }
    }
  }, [pad]);

  const getPadSubmitter = useCallback(() => {
    const generateLink = ({ id, user_id, email }) =>
      user_id ? (
        <Link
          key={id}
          to={`/dashboard/user-view/${user_id}`}
          target="_blank"
          className="d-flex align-items-center ml-1"
        >
          <span className="text">{`${email}`}</span>
        </Link>
      ) : (
        <span key={id} className="text">{`${email}`}</span>
      );

    if (pad?.submitters) {
      return (
        <div className={contactStyles.submittersList}>
          {pad.submitters.map(generateLink)}
        </div>
      );
    }
    return undefined;
  }, [pad]);

  const PadSubmitter = useMemo(() => getPadSubmitter(), [getPadSubmitter]);

  useEffect(() => {
    if (pad?.uploads?.length) {
      setFileList(
        pad?.uploads.map((itm) => ({
          uid: `-${itm.id}`,
          name: itm.title,
          url: itm.file_url,
          status: 'done',
          user: itm?.user,
        })),
      );
    }
  }, [pad?.uploads?.length, pad?.uploads]);

  useEffect(() => {
    form.setFields([
      { name: 'location#latitude', value: location?.latitude },
      { name: 'location#longitude', value: location?.longitude },
    ]);
  }, [location, form]);

  useEffect(() => {
    form.setFields([
      {
        name: 'location#name',
        value: location,
      },
    ]);
    // eslint-disable-next-line
  }, [location?.address, form]);

  useUpdateEffect(() => {
    if (location.country_short)
      api.region
        .searchRegionByCountry({
          params: { country_code: location.country_short },
        })
        .then((res) =>
          form.setFields([
            {
              name: 'location#region',
              value: regions.find((itm) => itm.label === res.data.region)
                ?.value,
            },
          ]),
        );
  }, [location.country_short]);

  const enableEditableMode = useCallback(() => {
    form.resetFields();
    setEditMode(true);
  }, [form, setEditMode]);

  const disableEditableMode = useCallback(() => {
    form.resetFields();
    setEditMode(false);
    setLocation({
      latitude: pad?.location?.latitude,
      longitude: pad?.location?.longitude,
      address: pad?.location?.name,
    });
  }, [form, setEditMode, pad?.location, setLocation]);

  const debouncedLocationInput = debounce((key, value, limit) => {
    if (value >= -limit && value <= limit) {
      setLocation((prevState) => ({
        ...prevState,
        [key]: value,
        getGeoCode: true,
      }));
    }
  }, 300);

  const onFormChange = useCallback(
    (changedValue) => {
      const {
        'location#name': locationName,
        'location#latitude': latitude,
        'location#longitude': longitude,
      } = changedValue;
      if (locationName) {
        setLocation(locationName);
        form.setFields([
          { name: 'location#latitude', value: locationName.latitude },
          { name: 'location#longitude', value: locationName.longitude },
        ]);
      }
      if (latitude) debouncedLocationInput('latitude', latitude, 90);
      if (longitude) debouncedLocationInput('longitude', longitude, 180);
    },
    [form, setLocation, debouncedLocationInput],
  );

  const uploadFileHandle = useCallback(
    (uploadConfig) => {
      if (pad?.id) {
        const { file, onError, onProgress, onSuccess } = uploadConfig;
        const formData = new FormData();
        formData.append('upload', file);

        api.upload
          .store('pad', pad.id, formData, {
            onUploadProgress: ({ total, loaded }) => {
              onProgress(
                { percent: Math.round((loaded / total) * 100).toFixed(2) },
                file,
              );
            },
          })
          .then((res) => {
            onSuccess('Success', { ...file, id: res.data.id });
          })
          .catch((err) => {
            onError();

            setFileList((prevFileList) =>
              prevFileList.filter((itm) => itm.uid !== file.uid),
            );

            notification.error({
              message: 'Error',
              description: getApiErrorMessages(err),
            });
          });
      }
    },
    [pad?.id],
  );

  const picturesWallChangeHandle = useCallback((e) => {
    setFileList(e.fileList);
  }, []);

  const picturesWallRemoveHandle = useCallback((file) => {
    api.upload
      .delete(file.uid.substring(1))
      .then(() =>
        setFileList((prev) => prev.filter((itm) => itm.uid !== file.uid)),
      );
  }, []);

  const moderateHandle = useCallback(() => {
    if (pad?.id)
      api.pad
        .toggleModeration(pad?.id)
        .then((res) => {
          padOnChange(res.data);
          notification.success({
            message: 'Success',
            description: 'Done!',
          });
        })
        .catch((err) =>
          notification.error({
            message: 'Error',
            description: err?.response?.data?.message,
          }),
        );
  }, [pad?.id, padOnChange]);

  const requestModerationHandle = useCallback(() => {
    if (pad?.id)
      api.revision
        .requestModeration(pad?.id)
        .then((res) => {
          padOnChange(res.data);
          notification.success({
            message: 'Success',
            description: 'Request moderation link is send',
          });
        })
        .catch((err) =>
          notification.error({
            message: 'Error',
            description: err?.response?.data?.message,
          }),
        );
  }, [pad?.id, padOnChange]);

  const toggleRecommendedRequest = useCallback(
    (data) => () => {
      api.pad
        .updateRecommended(data?.id)
        .then((res) => {
          padOnChange(res.data);
        })
        .catch((err) =>
          notification.error({
            message: 'Error',
            description: err?.response?.data?.message,
          }),
        );
    },
    [padOnChange],
  );

  const cancelRevisionHandle = useCallback(() => {
    if (pad?.id)
      api.revision
        .cancelRevision(pad?.id)
        .then(() => {
          notification.success({
            message: 'Success',
            description: 'Request moderation link is canceled',
          });
        })
        .catch((err) =>
          notification.error({
            message: 'Error',
            description: getApiErrorMessages(err),
          }),
        );
  }, [pad?.id]);

  const alertOnSave = (updatedPad = null) => {
    let description = 'Update Saved!';
    let type = 'success';

    if (updatedPad?.data) {
      const { has_uncontactable_revision, is_contactable } = updatedPad.data;

      if (has_uncontactable_revision && is_contactable) {
        description = 'Update saved - pad now visible to Pilots!';
      }

      if (has_uncontactable_revision && !is_contactable) {
        type = 'warning';
        description =
          "Update saved - pad is missing contact data so will not be shown to Pilots! Update it from 'Uncontactable'";
      }
    }

    notification[type]({
      message: 'Success',
      description,
    });
  };

  const goToDuplicateResolver = useCallback(
    () =>
      history.push(
        `/dashboard/reports/duplicate-report/${pad?.id}/${pad?.duplicated_pad_id}`,
      ),
    [pad?.id, pad?.duplicated_pad_id],
  );

  // PAD UPLOADS ORDER
  const setOrderedUploads = useCallback(() => {
    if (pad?.id) {
      const orderedUploads = fileList.map((file, index) => ({
        id: file?.xhr?.id || file.uid.replace('-', ''),
        order: index + 1,
      }));

      api.pad.uploadsOrder({ upload: orderedUploads }).catch((err) =>
        notification.error({
          message: 'Uploads Order Error!',
          description: getApiErrorMessages(err),
        }),
      );
    }
  }, [fileList, pad]);

  const handleAddToPurgatory = useCallback(
    (padId) => () =>
      api.pad
        .addToPurgatory(padId)
        .then(() => {
          api.pad.get(padId).then((res) => {
            padOnChange(res.data);
          });
        })
        .catch(() => Promise.reject()),
    [padOnChange],
  );

  const onSubmit = useCallback(
    (values) => {
      const data = {
        ...values,
        tab: 'contact',
        'location#name': values['location#name'].address,
        'location#country': values['location#name'].country,
        'location#county': values['location#name'].county,
        'location#region':
          regions.find((itm) => itm.value === values['location#region'])
            ?.label || values['location#region'],
        contacts: values.contacts[0]
          ? values.contacts.map((itm) => ({
              ...itm,
              phone: itm.phone ? itm.phone : null,
            }))
          : [],
      };

      data.contacts = data.contacts.map((c) => ({
        name: null,
        phone: null,
        email: null,
        ...c,
      }));

      if (fileList?.length) {
        data.upload = fileList.map((file, index) => ({
          id: parseInt(file.uid.replace('-', ''), 10),
          order: index,
        }));
      }

      setOrderedUploads();

      loadingBarEnable()(dispatch);

      if (pad?.id) {
        api.pad
          .update(pad?.id, data)
          .then((res) => {
            loadingBarDisable()(dispatch);
            setEditMode(false);
            padOnChange(res.data);
            alertOnSave(res.data);
          })
          .catch((err) => {
            loadingBarDisable()(dispatch);
            setApiErrorsToAntdForm(err);
          });
      }
    },
    [
      regions,
      fileList,
      setOrderedUploads,
      dispatch,
      pad?.id,
      setEditMode,
      padOnChange,
      setApiErrorsToAntdForm,
    ],
  );

  const generatedLinkContent = (url) => (
    <>
      <Typography.Paragraph
        ellipsis={{ rows: 1, expandable: false }}
        className={contactStyles.typography}
      >
        <a href={url} target="_blank" rel="noreferrer">
          {url}
        </a>
      </Typography.Paragraph>
    </>
  );

  const generateLinkHandler = async () => {
    setGenerateLinkProps((prevState) => ({ ...prevState, loading: true }));

    try {
      const response = await api.pad.generateLink(pad?.id);

      if (response && response.data) {
        // Set generated link to clipboard
        navigator.clipboard.writeText(response.data);

        notification.success({
          message: 'Link copied!',
          description: generatedLinkContent(response.data),
        });
      }
    } catch (err) {
      getApiErrorMessages(err);
    } finally {
      setGenerateLinkProps((prevState) => ({
        ...prevState,
        loading: false,
        button_content: 'Generated',
      }));

      setTimeout(() => {
        setGenerateLinkProps((prevState) => ({
          ...prevState,
          button_content: 'Generate Link',
        }));
      }, 1000);
    }
  };

  const saveAsPrivateHanlder = useCallback(() => {
    api.pad
      .saveAsPrivate(pad?.id)
      .then((res) => {
        padOnChange(res.data);
        notification.success({
          message: 'Success',
          description: 'Done!',
        });
      })
      .catch((err) =>
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(err),
        }),
      );
  }, [pad?.id, padOnChange]);

  return (
    <div {...rest}>
      <Spin spinning={!pad}>
        <Form
          form={form}
          onValuesChange={onFormChange}
          onFinish={onSubmit}
          autoComplete="off"
        >
          <Form.Item noStyle shouldUpdate>
            {() => {
              const errors = form.getFieldError('global');

              return (
                !!errors.length && (
                  <Alert
                    message="Error"
                    description={globalErrors}
                    type="error"
                    showIcon
                    className="mb-3"
                  />
                )
              );
            }}
          </Form.Item>
          <div className="text-right mb-2">
            <Form.Item hidden name="global">
              <Input />
            </Form.Item>
            <Space className="d-flex justify-content-between">
              {!pad?.is_private && (
                <div>
                  <Button
                    htmlType="button"
                    className="mr-2"
                    disabled={!!pad?.deleted_at}
                    onClick={moderateHandle}
                  >
                    {pad?.status_id === 1 ? 'Moderate' : 'Unmoderate'}
                  </Button>
                  {pad?.duplicated_pad_id > 0 && (
                    <Button
                      type="primary"
                      htmlType="button"
                      className=""
                      onClick={goToDuplicateResolver}
                    >
                      Resolve duplicate
                    </Button>
                  )}
                </div>
              )}
              {!pad?.is_private && (
                <>
                  {editMode ? (
                    <>
                      <span>Editing</span>
                      <Button.Group>
                        <Button htmlType="button" onClick={disableEditableMode}>
                          Cancel
                        </Button>
                        <Button
                          type="primary"
                          htmlType="submit"
                          disabled={loadingBarEnabled}
                        >
                          Save changes
                        </Button>
                      </Button.Group>
                    </>
                  ) : (
                    <Button
                      htmlType="button"
                      type="primary"
                      disabled={!!pad?.deleted_at}
                      onClick={enableEditableMode}
                    >
                      Edit pad
                    </Button>
                  )}
                </>
              )}
            </Space>
          </div>
          <Descriptions
            labelStyle={{ width: 160 }}
            size="small"
            bordered
            column={1}
          >
            <Descriptions.Item
              label="Name of pad"
              className="ant-descriptions-item-content_flex"
            >
              {editMode ? (
                <Form.Item
                  name="name"
                  initialValue={pad?.name}
                  className="mb-0"
                >
                  <Input
                    style={{ maxWidth: 200 }}
                    size="small"
                    placeholder="Pad name"
                  />
                </Form.Item>
              ) : (
                <>
                  {!!pad?.deleted_at && (
                    <Tooltip title="Pad is archived">
                      <span className="pad-name_prefix d-inline-flex align-items-center">
                        <ArchivedIcon width="15" height="15" />
                      </span>
                    </Tooltip>
                  )}
                  <span className="pad-name">{`#${pad?.id} ${pad?.name} `}</span>
                  {pad?.status_id === 1 && (
                    <span className="pad-name_suffix ml-3">
                      <Button
                        size="small"
                        disabled={pad?.is_private || !!pad?.deleted_at}
                        onClick={saveAsPrivateHanlder}
                      >
                        {pad?.is_private ? 'Private' : 'Save as Private'}
                      </Button>
                    </span>
                  )}
                </>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Category">
              {editMode ? (
                <Form.Item
                  name="category_id"
                  initialValue={pad?.category_id}
                  className="mb-0"
                >
                  <Select
                    size="small"
                    style={{ maxWidth: 200 }}
                    placeholder="Choose"
                    options={categories}
                  />
                </Form.Item>
              ) : (
                categories.find((itm) => itm.value === pad?.category_id)?.label
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              <span className="d-flex justify-content-between align-items-center">
                <span>{statuses?.[pad?.status_id]}</span>
                {pad?.status_id === 1 && !pad?.is_private && (
                  <Popconfirm
                    key="accept-owner-btn"
                    placement="topRight"
                    title="Are you sure you want to add this pad to purgatory?"
                    onConfirm={handleAddToPurgatory(pad?.id)}
                    okText="Add"
                    cancelText="Cancel"
                  >
                    <Button
                      htmlType="button"
                      type="secondary"
                      disabled={pad?.is_added_to_purgatory || !!pad?.deleted_at}
                    >
                      {pad?.is_added_to_purgatory
                        ? 'Pad added to purgatory'
                        : 'Add to purgatory'}
                    </Button>
                  </Popconfirm>
                )}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Landing not Possible">
              {editMode ? (
                <Form.Item
                  name="is_landing_permitted"
                  className="mb-0"
                  valuePropName="checked"
                  initialValue={pad?.is_landing_permitted}
                >
                  <CheckboxInverted />
                </Form.Item>
              ) : (
                visualizeBool(!pad?.is_landing_permitted)
              )}
            </Descriptions.Item>
            {pad?.has_uncontactable_revision && !pad?.is_contactable && (
              <Descriptions.Item label="Pad Uncontactable">
                {visualizeBool(
                  pad?.has_uncontactable_revision && !pad?.is_contactable,
                )}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Coordinates">
              {editMode ? (
                <div style={{ maxWidth: 400 }}>
                  <Form.Item label="Coordinates" className="mb-0">
                    <Space align="start">
                      <Form.Item
                        name="location#latitude"
                        initialValue={pad?.location?.latitude}
                        rules={latValidationRules}
                        className="mb-0"
                      >
                        <NumericInput size="small" placeholder="Latitude" />
                      </Form.Item>
                      <Form.Item
                        name="location#longitude"
                        initialValue={pad?.location?.longitude}
                        rules={lngValidationRules}
                        className="mb-0"
                      >
                        <NumericInput size="small" placeholder="Longitude" />
                      </Form.Item>
                    </Space>
                  </Form.Item>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues['location#latitude'] !==
                        curValues['location#latitude'] ||
                      prevValues['location#longitude'] !==
                        curValues['location#longitude']
                    }
                  >
                    {({ getFieldValue }) => (
                      <Form.Item label="DMS Format" className="mb-0">
                        <Input
                          readOnly
                          placeholder="Read only"
                          className={condStrings(
                            styles.dmsInput,
                            'font-weight-semi-bold',
                          )}
                          size="small"
                          value={convertDMS(
                            getFieldValue('location#latitude'),
                            getFieldValue('location#longitude'),
                          )}
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                  <Form.Item
                    name="location#name"
                    initialValue={{
                      latitude: pad?.location?.latitude,
                      longitude: pad?.location?.longitude,
                      address: pad?.location?.name,
                    }}
                    label="Address"
                    trigger="onSelect"
                    validateTrigger="onSelect"
                    className="mb-0"
                  >
                    <SearchLocation size="small" />
                  </Form.Item>
                  <Form.Item
                    name="location#region"
                    initialValue={pad?.location?.region}
                    label="Region"
                    className="mb-0"
                  >
                    <Select
                      size="small"
                      placeholder="Choose"
                      options={regions}
                    />
                  </Form.Item>
                </div>
              ) : (
                <>
                  Latitude: {pad?.location?.latitude} <br />
                  Longitude: {pad?.location?.longitude} <br />
                  DMS:{' '}
                  {pad?.location &&
                    convertDMS(pad.location?.latitude, pad.location?.longitude)}
                  <br />
                  Address: {pad?.location?.name} <br />
                  County: {pad?.location?.county}
                  {pad?.location?.county && ' (from Google Maps)'} <br />
                  Region: {pad?.location?.region} <br />
                </>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Photos">
              <PicturesWall
                fileList={fileList}
                setFileList={setFileList}
                onChange={picturesWallChangeHandle}
                onRemove={picturesWallRemoveHandle}
                customRequest={uploadFileHandle}
                multiple
                showUploadBtn={editMode}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Primary Contact">
              <PrimaryContact
                pad={pad}
                onChange={padOnChange}
                editing={editMode}
              />
            </Descriptions.Item>

            <Descriptions.Item label="Survey Uploads">
              <SurveyUploads padId={pad?.id} isArchived={!!pad?.deleted_at} />
            </Descriptions.Item>

            <Descriptions.Item label="Contacts">
              <ContactsList
                pad={pad}
                onChange={padOnChange}
                editing={editMode}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Created at">
              <div className="d-flex">
                <span className="text">
                  {dayjs(pad?.created_at).format(dateFormats[0])} by
                </span>
                {PadSubmitter}
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="Updated at">
              <span className="text">
                {dayjs(pad?.updated_at).format(dateFormats[0])} by
              </span>
              <Link
                className="ml-1"
                to={`/dashboard/user-view/${pad?.updated_by?.id}`}
              >
                {pad?.updated_by?.email}
              </Link>
            </Descriptions.Item>
            <Descriptions.Item
              label={
                pad?.is_helipaddy_recommended_admin
                  ? 'Is recommended'
                  : 'Not recommended'
              }
            >
              <Tooltip
                title={
                  (isPadRecommended && 'Has Premium Owner') ||
                  (pad?.is_private && 'Private Pad')
                }
              >
                <Button
                  className="mr-2"
                  htmlType="button"
                  disabled={
                    isPadRecommended || pad?.is_private || !!pad?.deleted_at
                  }
                  onClick={toggleRecommendedRequest(pad)}
                >
                  {pad?.is_helipaddy_recommended_admin
                    ? 'Remove from recommended'
                    : 'Add to recommended'}
                </Button>
              </Tooltip>
            </Descriptions.Item>
          </Descriptions>
          {!pad?.is_private && (
            <div className="mt-3 d-flex align-items-center justify-content-between">
              <Space align="center">
                <span>User moderation:</span>
                <Button.Group>
                  <Button
                    disabled={!!pad?.deleted_at}
                    htmlType="button"
                    onClick={requestModerationHandle}
                  >
                    Request moderation
                  </Button>
                  <Button
                    disabled={!!pad?.deleted_at}
                    htmlType="button"
                    onClick={cancelRevisionHandle}
                  >
                    Revert moderation
                  </Button>
                </Button.Group>
                {generateLinkProps.status && (
                  <Button
                    className={contactStyles.button}
                    icon={<LinkOutlined />}
                    onClick={generateLinkHandler}
                    loading={generateLinkProps.loading}
                  >
                    {generateLinkProps.button_content}
                  </Button>
                )}
              </Space>
              <Space align="center">
                <ArchiveAction
                  isArchived={pad?.deleted_at}
                  padId={pad?.id}
                  onActionComplete={padOnChange}
                />
              </Space>
            </div>
          )}
        </Form>
      </Spin>
    </div>
  );
};

ContactTab.defaultProps = {
  pad: undefined,
  regions: [],
  categories: [],
  padOnChange: () => null,
  statuses: {},
  location: {},
  setLocation: () => null,
  editMode: false,
  setEditMode: () => null,
  isPadRecommended: false,
};

ContactTab.propTypes = {
  pad: PropTypes.shape({
    id: PropTypes.number,
    duplicated_pad_id: PropTypes.number,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    is_helipaddy_recommended_admin: PropTypes.bool,
    is_added_to_purgatory: PropTypes.bool,
    deleted_at: PropTypes.string,
    name: PropTypes.string,
    category_id: PropTypes.number,
    is_private: PropTypes.bool,
    is_landing_permitted: PropTypes.bool,
    location: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      name: PropTypes.string,
      region: PropTypes.string,
      county: PropTypes.string,
    }),
    updated_by: PropTypes.object,
    contacts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        created_at: PropTypes.string,
        updated_at: PropTypes.string,
        name: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
        contact_type_id: PropTypes.number,
        is_primary: PropTypes.bool,
      }),
    ),
    contact: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      contact_type_id: PropTypes.number,
    }),
    uploads: PropTypes.array,
    status_id: PropTypes.number,
    submitter: PropTypes.object,
    submitters: PropTypes.array,
    has_uncontactable_revision: PropTypes.bool,
    is_contactable: PropTypes.bool,
  }),
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  padOnChange: PropTypes.func,
  statuses: PropTypes.object,
  location: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    address: PropTypes.string,
    country_short: PropTypes.string,
    county: PropTypes.string,
  }),
  setLocation: PropTypes.func,
  editMode: PropTypes.bool,
  setEditMode: PropTypes.func,
  isPadRecommended: PropTypes.bool,
};

export default ContactTab;
