const getFieldName = (name) => {
  if (name.includes('.')) {
    return name.split('.').map((i) => {
      if (i === '0') return 0;
      return +i || i;
    });
  }

  return name;
};

export default getFieldName;
