import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../uiKitComponents/checkbox';

const CheckboxInverted = (props) => {
  const { checked, onChange, ...rest } = props;

  const onChangeHandle = useCallback(
    (e) =>
      onChange({ ...e, target: { ...e.target, checked: !e.target.checked } }),
    [onChange],
  );

  return <Checkbox {...rest} checked={!checked} onChange={onChangeHandle} />;
};

CheckboxInverted.defaultProps = {
  checked: true,
  onChange: () => null,
};

CheckboxInverted.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CheckboxInverted;
