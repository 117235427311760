import PropTypes from 'prop-types';
import React from 'react';
import { Descriptions, notification, Form, Empty } from 'antd';
import {
  getPadSurvey,
  prepareSurveyBeforeUpdate,
  updatePadSurvey,
  updateSurveyStatus,
} from '../../services/api';
import PadSurveyLoading from './PadSurveyLoading';
import PadSurveyActions from './PadSurveyActions';
import {
  SurveyApproachStep,
  SurveyFacilitiesStep,
  SurveyGeneralStep,
  SurveyHazardsStep,
  SurveyLandingStep,
} from './steps';
import PadSurveyUploads from './PadSurveyUploads';
import { PadSurveyContext } from './PadSurveyContext';
import SurveyViewStep from './steps/SurveyViewStep';
import { calculateSurveyStatus } from './utils';
import condStrings from '../../utils/condStrings';
import getApiErrorMessages from '../../utils/getApiErrorMessages';

const parseSurveyData = (surveyData) => ({
  id: surveyData.id,
  general: surveyData.general?.values,
  landing: surveyData.landing?.values,
  hazards: surveyData.hazards?.values,
  approach: surveyData.approach?.values,
  facilities: surveyData.facilities?.values,
  view: surveyData.view?.values,
  static: {
    map: surveyData.map,
  },
});

export function PadSurveyDetails(props) {
  const { padId } = props;
  const [surveyData, setSurveyData] = React.useState({});
  const [surveyLoading, setSurveyLoading] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [stepsVisibility, setStepsVisibility] = React.useState({
    general: false,
    landing: false,
    hazards: false,
    facilities: false,
    approach: false,
  });
  const [form] = Form.useForm();

  const toggleStepVisibility = React.useCallback((stepName, status) => {
    setStepsVisibility((prev) => ({ ...prev, [stepName]: status }));
  }, []);

  const toggleEditStatus = React.useCallback(
    (state) => () => setIsEditing(state),
    [],
  );

  React.useEffect(() => {
    const fetchSurvey = async () => {
      try {
        setSurveyLoading(true);

        const survey = await getPadSurvey(padId);
        setSurveyData(parseSurveyData(survey));
      } catch (error) {
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(error),
        });
      } finally {
        setSurveyLoading(false);
      }
    };

    fetchSurvey();
  }, [padId]);

  const submitChangesHandler = React.useCallback(
    async (values) => {
      const updatedSurvey = prepareSurveyBeforeUpdate(values);
      updatedSurvey.map = surveyData.static.map;

      try {
        const response = await updatePadSurvey(padId, updatedSurvey);
        const currentSurveyStatus = calculateSurveyStatus(updatedSurvey);

        if (
          currentSurveyStatus.survey_status === 3 ||
          currentSurveyStatus.survey_completed_steps_count === 7
        ) {
          updateSurveyStatus(padId, currentSurveyStatus.survey_status);
        }

        setSurveyData(parseSurveyData(response));
        setIsEditing(false);
      } catch (error) {
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(error),
        });
      }

      return Promise.resolve();
    },
    [padId, surveyData.static],
  );

  const nodData = React.useMemo(
    () => Object.values(stepsVisibility).every((v) => !v),
    [stepsVisibility],
  );

  if (surveyLoading) {
    return <PadSurveyLoading />;
  }

  if (nodData && !isEditing) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <PadSurveyContext.Provider value={{ isEditing, toggleStepVisibility }}>
      <Form
        form={form}
        onFinish={submitChangesHandler}
        layout="vertical"
        initialValues={{ ...surveyData }}
      >
        <PadSurveyActions submit={form.submit} toggleEdit={toggleEditStatus} />
        <Descriptions
          labelStyle={{ width: 120 }}
          size="small"
          bordered
          column={1}
        >
          <Descriptions.Item
            className={condStrings(
              !stepsVisibility.general && !isEditing && 'd-none',
            )}
            contentStyle={{ padding: '0.25rem' }}
            labelStyle={{ padding: '10px', fontWeight: 500 }}
            label="General"
          >
            <SurveyGeneralStep step={surveyData.general} />
          </Descriptions.Item>
          <Descriptions.Item
            className={condStrings(
              !stepsVisibility.landing && !isEditing && 'd-none',
            )}
            contentStyle={{ padding: '0.25rem' }}
            labelStyle={{ padding: '10px', fontWeight: 500 }}
            label="Landing"
          >
            <SurveyLandingStep step={surveyData.landing} />
          </Descriptions.Item>
          <Descriptions.Item
            className={condStrings(
              !stepsVisibility.general && !isEditing && 'd-none',
            )}
            contentStyle={{ padding: '0' }}
            labelStyle={{ padding: '10px', fontWeight: 500 }}
            label="360º View"
          >
            <SurveyViewStep
              step={surveyData.view}
              padId={padId}
              surveyId={surveyData.id}
              updateFormValue={form.setFields}
            />
          </Descriptions.Item>
          <Descriptions.Item
            className={condStrings(
              !stepsVisibility.hazards && !isEditing && 'd-none',
            )}
            contentStyle={{ padding: '0.25rem' }}
            labelStyle={{ padding: '10px', fontWeight: 500 }}
            label="Hazards"
          >
            <SurveyHazardsStep step={surveyData.hazards} />
          </Descriptions.Item>
          <Descriptions.Item
            className={condStrings(
              !stepsVisibility.approach && !isEditing && 'd-none',
            )}
            contentStyle={{ padding: '0.25rem' }}
            labelStyle={{ padding: '10px', fontWeight: 500 }}
            label="Approach"
          >
            <SurveyApproachStep step={surveyData.approach} />
          </Descriptions.Item>
          <Descriptions.Item
            className={condStrings(
              !stepsVisibility.facilities && !isEditing && 'd-none',
            )}
            contentStyle={{ padding: '0.25rem' }}
            labelStyle={{ padding: '10px', fontWeight: 500 }}
            label="Facilities"
          >
            <SurveyFacilitiesStep step={surveyData.facilities} />
          </Descriptions.Item>
        </Descriptions>
      </Form>
    </PadSurveyContext.Provider>
  );
}

PadSurveyDetails.propTypes = {
  padId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

PadSurveyDetails.defaultProps = {};

export { PadSurveyUploads };
