import {
  ANNOUNCEMENTS_LIST_GET,
  ANNOUNCEMENTS_LIST_CLEAN,
  CLEAN_ON_UNMOUNT_TRUE,
  CLEAN_ON_UNMOUNT_FALSE,
  UPDATE_LOCAL_ANNOUNCEMENT_IN_LIST,
} from '../actionTypes';

const initialState = {
  cleanOnUnmount: true,
  payload: {},
};

const announcementsList = (state = initialState, action = {}) => {
  switch (action.type) {
    case ANNOUNCEMENTS_LIST_GET:
      return {
        ...state,
        payload: action.payload,
      };
    case UPDATE_LOCAL_ANNOUNCEMENT_IN_LIST: {
      if (Object.keys(state.payload).length) {
        return {
          ...state,
          payload: {
            ...state.payload,
            data: state.payload.data.map((itm) =>
              itm.id === action.payload.id
                ? { ...itm, ...action.payload.data }
                : itm,
            ),
          },
        };
      }
      return state;
    }
    case ANNOUNCEMENTS_LIST_CLEAN:
      return initialState;
    case CLEAN_ON_UNMOUNT_TRUE:
      return {
        ...state,
        cleanOnUnmount: true,
      };
    case CLEAN_ON_UNMOUNT_FALSE:
      return {
        ...state,
        cleanOnUnmount: false,
      };
    default:
      return state;
  }
};

export default announcementsList;
