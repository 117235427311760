import { useState, useCallback, useRef } from 'react';
import { useMount } from 'react-use';
import PropTypes from 'prop-types';
import Form from '../../../../../uiKitComponents/form';
import Input from '../../../../../uiKitComponents/input';
import Button from '../../../../../uiKitComponents/button';
import Alert from '../../../../../uiKitComponents/alert';
import useApiErrorsWithAntd from '../../../../../hooks/useApiErrorsWithAntd';
import api from '../../../../../utils/appApi';

const InternalNotesDrawerFooter = (props) => {
  const { padId, onNoteAdd, ...rest } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { setApiErrorsToAntdForm } = useApiErrorsWithAntd(form);
  const globalErrors = form.getFieldError('global');
  const textareaRef = useRef(null);

  useMount(() => {
    textareaRef?.current.focus();
  });

  const onSubmit = useCallback(
    (values) => {
      if (padId) {
        setLoading(true);
        api.pad
          .addNote(padId, values)
          .then((res) => {
            setLoading(false);
            form.resetFields();
            onNoteAdd(res.data);
          })
          .catch((err) => {
            setApiErrorsToAntdForm(err);
            setLoading(false);
          });
      }
    },
    [padId, setApiErrorsToAntdForm, onNoteAdd, form],
  );

  return (
    <Form {...rest} form={form} onFinish={onSubmit}>
      {!!globalErrors.length && (
        <Alert
          message="Error"
          description={globalErrors}
          type="error"
          showIcon
        />
      )}
      <Form.Item name="note">
        <Input.TextArea
          placeholder="Write note..."
          style={{ minHeight: 100, maxHeight: 100 }}
          ref={textareaRef}
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={loading}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

InternalNotesDrawerFooter.defaultProps = {
  padId: null,
  onNoteAdd: () => null,
};

InternalNotesDrawerFooter.propTypes = {
  padId: PropTypes.number,
  onNoteAdd: PropTypes.func,
};

export default InternalNotesDrawerFooter;
