import { notification } from 'antd';

import api from '../../../../utils/appApi';
import getApiErrorMessages from '../../../../utils/getApiErrorMessages';

export const uploadHandler = async (surveyId, file, sideKey) => {
  const formData = new FormData();

  formData.append('upload', file);
  formData.append('direction', sideKey);

  try {
    const response = await api.upload.store('padSurvey', surveyId, formData);
    return Promise.resolve(response.data);
  } catch (error) {
    notification.error({
      message: 'Error',
      description: getApiErrorMessages(error),
    });

    return Promise.reject(error);
  }
};

export const removeHandler = async (fileId) => {
  try {
    await api.upload.delete(fileId);
    return Promise.resolve();
  } catch (error) {
    notification.error({
      message: 'Error',
      description: getApiErrorMessages(error),
    });
    return Promise.reject(error);
  }
};
