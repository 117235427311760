import { Button, notification } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../../../../utils/appApi';
import getApiErrorMessages from '../../../../../utils/getApiErrorMessages';
import PadViewContext from '../../../PadView.context';

export function PadModerateActions() {
  const { pad, updatePad } = React.useContext(PadViewContext);
  const history = useHistory();

  const actionDisabled = React.useMemo(
    () => [pad?.is_private, pad?.deleted_at].some(Boolean),
    [pad],
  );

  const moderateHandler = React.useCallback(() => {
    if (pad?.id)
      api.pad
        .toggleModeration(pad?.id)
        .then((res) => {
          updatePad(res.data.data);
          notification.success({
            message: 'Success',
            description: 'Done!',
          });
        })
        .catch((err) =>
          notification.error({
            message: 'Error',
            description: getApiErrorMessages(err),
          }),
        );
  }, [pad?.id, updatePad]);

  return (
    <div>
      <Button
        htmlType="button"
        className="mr-2"
        disabled={actionDisabled}
        onClick={moderateHandler}
      >
        {pad?.status_id === 1 ? 'Moderate' : 'Unmoderate'}
      </Button>
      {!!pad?.duplicated_pad_id && (
        <Button
          type="primary"
          htmlType="button"
          onClick={() =>
            history.push(
              `/dashboard/reports/duplicate-report/${pad?.id}/${pad?.duplicated_pad_id}`,
            )
          }
        >
          Resolve duplicate
        </Button>
      )}
    </div>
  );
}
