import { useState, useCallback, useEffect } from 'react';
import { useMount, useSessionStorage, useUnmount } from 'react-use';
import { ToolOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Slider } from 'antd';
import PageHeader from '../../uiKitComponents/pageHeader';
import Table from '../../uiKitComponents/table';
import buildTableColumns from '../../utils/buildTableColumns';
import history from '../../utils/history';
import Button from '../../uiKitComponents/button';
import Alert from '../../uiKitComponents/alert';
import Spin from '../../uiKitComponents/spin';
import Select from '../../uiKitComponents/select';
import Switch from '../../uiKitComponents/switch';
import simulateNativeLink from '../../utils/simulateNativeLink';
import {
  loadingBarDisable,
  loadingBarEnable,
} from '../../store/actions/loadingBarAction';
import {
  getDupeList,
  cleanDupeList,
  setCleanOnUnmountFalse,
  setCleanOnUnmountTrue,
} from '../../store/actions/dupeListAction';
import axiosInstance from '../../utils/apiConfig';
import getApiErrorMessages from '../../utils/getApiErrorMessages';
import { selectCountriesOptions } from '../../store/selectors/bootstrapSelector';
import { ReactComponent as BanIcon } from '../../assets/icons/ban.svg';
import condStrings from '../../utils/condStrings';

const initialState = {
  page: 1,
  sort_column: 'id',
  sort_direction: 'descend',
  filters: {
    country_code: null,
    is_lnp_pads_included: 0,
    is_archived_pads_included: 0,
    is_unmoderated_pads_included: 0,
    distance: 1,
  },
};

const DuplicateReportList = () => {
  const [error, setError] = useState();
  const [state, setState] = useSessionStorage('dupe-list-state', initialState);
  const { page, sort_direction, sort_column, filters } = state;
  const [tableLoading, setTableLoading] = useState(true);
  const { cleanOnUnmount, dupeList, countries } = useSelector((store) => ({
    cleanOnUnmount: store.dupeList.cleanOnUnmount,
    dupeList: store.dupeList.payload,
    countries: selectCountriesOptions(store),
  }));
  const dispatch = useDispatch();

  useMount(() => {
    setCleanOnUnmountTrue()(dispatch);
  });

  useEffect(() => {
    const axiosInstanceSource = axiosInstance.CancelToken.source();

    loadingBarEnable()(dispatch);
    setTableLoading(true);

    getDupeList({
      cancelToken: axiosInstanceSource.token,
      params: {
        page,
        sort_column,
        sort_direction: sort_direction || 'ascend',
        ...filters,
      },
    })(dispatch)
      .catch((err) => {
        if (err?.response?.status === 504)
          setError({
            status: err.response.status,
            message: (
              <>
                Gateway Timeout Error <br /> Reload the page or use cached data
                if exist.
              </>
            ),
          });
        else if (err?.response?.data?.message)
          setError({
            status: err?.response?.status,
            message: getApiErrorMessages(err),
          });
      })
      .finally(() => {
        setTableLoading(false);
        loadingBarDisable()(dispatch);
      });

    // UnMount
    return () => {
      axiosInstanceSource.cancel();
      loadingBarDisable()(dispatch);
    };
  }, [cleanOnUnmount, dispatch, filters, page, sort_column, sort_direction]);

  const onBackHandle = useCallback(() => {
    history.goBack();
  }, []);

  const goToDuplicate = useCallback(
    ({ original_id, dupe_id }) => {
      const path = `/dashboard/reports/duplicate-report/${original_id}/${dupe_id}`;

      return simulateNativeLink(path, () => {
        setCleanOnUnmountFalse()(dispatch).then(() => history.push(path));
      });
    },
    [dispatch],
  );

  const onTableChange = useCallback(
    (pagination, tableFilters, sorter, extra) => {
      const newState = {};
      if (extra.action === 'paginate') newState.page = pagination.current;
      else if (extra.action === 'sort') {
        newState.sort_column = sorter.field;
        newState.sort_direction = sorter.order;
      }
      setState((prevState) => ({ ...prevState, ...newState }));
    },
    [setState],
  );

  const onFiltersChange = (name) => (value) => {
    setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [name]: value,
      },
    }));
  };

  const goToPad = (path) => {
    setCleanOnUnmountFalse()(dispatch).then(() => history.push(path));
  };

  const getNameWithStatus = (name, lp, archived, unmoderated, id) => (
    <Button
      type="link"
      className={condStrings(
        'custom-btn custom-icon',
        archived || unmoderated ? 'dupe-gray' : '',
        archived && 'dupe-line-through',
      )}
      onClick={simulateNativeLink(`/dashboard/pad-view/${id}`, () =>
        goToPad(`/dashboard/pad-view/${id}`),
      )}
    >
      <span className="d-flex align-item-center">
        {name}
        {!lp && <BanIcon width="12" className="ml-1" />}
      </span>
    </Button>
  );

  useUnmount(() => {
    if (cleanOnUnmount) {
      sessionStorage.removeItem('dupe-list-state');
      cleanDupeList()(dispatch);
    }
  });

  return (
    <div>
      <PageHeader
        className="page-main-header"
        onBack={onBackHandle}
        title="Reports List Page"
        extra={
          <Spin
            key="loading"
            spinning={tableLoading}
            style={{ marginTop: 6 }}
          />
        }
      />
      {error && (
        <Alert
          showIcon
          type="error"
          message={`Error ${error.status}`}
          description={error.message}
          className="mb-4"
        />
      )}
      <div className="duplicate-list summary-container d-flex flex-wrap align-items-center mb-1">
        <div className="duplicate-list-filter">
          <span className="label">Country</span>
          <Select
            value={filters?.country_code}
            placeholder="Country"
            style={{ minWidth: 200 }}
            options={countries}
            optionFilterProp="label"
            onSelect={onFiltersChange('country_code')}
            showSearch
          />
        </div>
        <div className="duplicate-list-filter">
          <span className="label">Distance between pads</span>
          <Slider
            min={0}
            step={0.01}
            max={1.0}
            defaultValue={filters.distance}
            onAfterChange={onFiltersChange('distance')}
          />
        </div>
        <div className="duplicate-list-wrap">
          <div className="duplicate-list-swt mb-2">
            <Switch
              checked={filters.is_lnp_pads_included === 1}
              onChange={(status) =>
                onFiltersChange('is_lnp_pads_included')(status ? 1 : 0)
              }
            />
            <span className="label">Include LNP</span>
          </div>
          <div className="duplicate-list-swt mb-2">
            <Switch
              checked={filters.is_archived_pads_included === 1}
              onChange={(status) =>
                onFiltersChange('is_archived_pads_included')(status ? 1 : 0)
              }
            />
            <span className="label">Include archived</span>
          </div>
          <div className="duplicate-list-swt">
            <Switch
              checked={filters.is_unmoderated_pads_included === 1}
              onChange={(status) =>
                onFiltersChange('is_unmoderated_pads_included')(status ? 1 : 0)
              }
            />
            <span className="label">Include unmoderated</span>
          </div>
        </div>
      </div>
      <div className="page-main-content position-relative">
        {!!dupeList?.data?.length && (tableLoading || error) && (
          <h2>(Cached data)</h2>
        )}
        <Table
          columns={[
            ...buildTableColumns(dupeList?.columns, {
              promisedClbk: () => setCleanOnUnmountFalse()(dispatch),
              dupe_name: {
                render: (data, row) =>
                  getNameWithStatus(
                    data,
                    row.is_lp_dupe,
                    row.is_dupe_inactive,
                    row.is_dupe_unmoderated,
                    row.dupe_id,
                  ),
              },
              original_name: {
                render: (data, row) =>
                  getNameWithStatus(
                    data,
                    row.is_lp_original,
                    row.is_original_inactive,
                    row.is_original_unmoderated,
                    row.original_id,
                  ),
              },
              resolve: {
                render: (_, rowData) => (
                  <Button
                    type="primary"
                    icon={<ToolOutlined />}
                    onClick={goToDuplicate({
                      dupe_id: rowData.dupe_id,
                      original_id: rowData.original_id,
                    })}
                  >
                    Resolve
                  </Button>
                ),
              },
            }),
          ]}
          rowKey={(row) =>
            `${row.dupe_id}${row.original_id}${(Math.random() * 10).toPrecision(
              1,
            )}`
          }
          onChange={onTableChange}
          className="duplicate-table-with-summary"
          dataSource={dupeList?.data}
          style={{ paddingTop: 20, paddingBottom: 20 }}
          pagination={{
            position: ['topRight', 'bottomRight'],
            showSizeChanger: false,
            showQuickJumper: true,
            pageSize: dupeList?.meta?.per_page || 25,
            current: page,
            total: dupeList.meta?.total,
            showTotal: (total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total} listings`,
          }}
        />
      </div>
    </div>
  );
};

export default DuplicateReportList;
