import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useContext } from 'react';
import DataRowContext from '../DataRowContext';
import CheckableTagList from '../../checkableTagList';

export default function DataRowControllerTags(props) {
  const { name } = props;
  const { label, setCheckedSide, readOnly, options } = useContext(
    DataRowContext,
  );

  const onSelectChange = () => setCheckedSide(null);

  return (
    <Form.Item className="data-row-tags" label={label} name={name}>
      <CheckableTagList
        name={name}
        options={options}
        onSelectChange={onSelectChange}
        readOnly={readOnly}
      />
    </Form.Item>
  );
}

DataRowControllerTags.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
};

DataRowControllerTags.defaultProps = {
  placeholder: '',
  name: '',
  options: [],
};
