import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Image } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

function UploadCardBody(props) {
  const { upload, isLoading, onClick } = props;

  if (upload?.id) {
    return (
      <div className="upload-card__body" role="button" aria-hidden="true">
        <div className="upload-card__placeholder">
          <Image alt={upload.title} src={upload.file_url} />
        </div>
      </div>
    );
  }

  return (
    <div
      className="upload-card__body"
      role="button"
      aria-hidden="true"
      onClick={onClick}
    >
      <div className="upload-card__placeholder">
        <span className="upload-placeholder-icon">
          {isLoading ? <LoadingOutlined rotate={180} /> : <UploadOutlined />}
        </span>
        <span className="upload-placeholder-text">
          {isLoading ? 'Uploading...' : 'Click to upload image'}
        </span>
      </div>
    </div>
  );
}

UploadCardBody.propTypes = {
  upload: PropTypes.object,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
};

export default React.memo(UploadCardBody);
