import React from 'react';
import { Button, Tooltip, notification } from 'antd';
import PadViewContext from '../../../PadView.context';
import api from '../../../../../utils/appApi';

export function PadUserModeration() {
  const { pad, updatePad } = React.useContext(PadViewContext);
  const [loading, setLoading] = React.useState(false);

  const actionDisabled = React.useMemo(
    () => [pad?.is_private, pad?.deleted_at].some(Boolean),
    [pad],
  );

  const requestModerationHandler = React.useCallback(() => {
    setLoading(true);
    if (pad?.id)
      api.revision
        .requestModeration(pad?.id)
        .then((res) => {
          updatePad(res.data.data);
          notification.success({
            message: 'Success',
            description: 'Request moderation link is send',
          });
        })
        .catch((err) =>
          notification.error({
            message: 'Error',
            description: err?.response?.data?.message,
          }),
        )
        .finally(() => setLoading(false));
  }, [pad?.id, updatePad]);

  return (
    <>
      <Button.Group>
        <Tooltip
          title={!pad?.can_send_moderation ? 'You are not allowed to send moderation request' : ''}
        >
          <Button
            loading={loading}
            disabled={actionDisabled || !pad?.can_send_moderation}
            htmlType="button"
            onClick={requestModerationHandler}
          >
            Request Claim
          </Button>

        </Tooltip>
      </Button.Group>
    </>
  );
}
