import { combineReducers } from 'redux';
import user from './userReducer';
import loadingBar from './loadingBarReducer';
import padList from './padListReducer';
import padListMap from './padListMapReducer';
import usersList from './userListReducer';
import moderationList from './moderationListReducer';
import reviewsList from './reviewListReducer';
import userFilters from './userFiltersReducer';
import reportPages from './reportPagesReducer';
import bootstrap from './bootstrapReducer';
import dupeList from './dupeListReducer';
import announcementsList from './announcementsListReducer';
import campaigns from './campaignsReducer';
import userListMap from './userListMapReducer';

const rootReducer = combineReducers({
  bootstrap,
  user,
  loadingBar,
  padList,
  padListMap,
  usersList,
  moderationList,
  reviewsList,
  userFilters,
  reportPages,
  dupeList,
  announcementsList,
  campaigns,
  userListMap,
});

export default rootReducer;
