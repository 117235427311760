import React from 'react';
import { Form, Tabs, notification } from 'antd';
import { useDispatch } from 'react-redux';
import LandingTabRead from './LandingTabRead';
import LandingTabEdit from './LandingTabEdit';
import PadViewContext from '../../PadView.context';
import { EditActions } from '../EditActions';
import {
  loadingBarDisable,
  loadingBarEnable,
} from '../../../../store/actions/loadingBarAction';
import useApiErrorsWithAntd from '../../../../hooks/useApiErrorsWithAntd';
import api from '../../../../utils/appApi';
import { PadSurvey } from '../../../PadSurvey';
import { getApiError } from '../../../../utils/getApiErrorMessages';

export function LandingTab() {
  const [isEditingLandingTab, setIsEditingLandingTab] = React.useState(false);
  const [isHiscErr, setIsHiscErr] = React.useState(null);
  const { pad, updatePad } = React.useContext(PadViewContext);
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const { setApiErrorsToAntdForm } = useApiErrorsWithAntd(form);

  const SON = React.useMemo(() => {
    if (pad?.is_landing_advice_displayed) {
      return 'Landing Instructions';
    }

    return 'Landing Advice';
  }, [pad?.is_landing_advice_displayed]);

  const onLandingTabSubmit = React.useCallback(
    (values) => {
      const data = { ...values, tab: 'landing' };

      if (values['pad_social#twitter_handle'])
        data[
          'pad_social#twitter_handle'
        ] = `@${values['pad_social#twitter_handle']}`;

      dispatch(loadingBarEnable());

      api.pad
        .update(pad?.id, data)
        .then((res) => {
          dispatch(loadingBarDisable());
          setIsEditingLandingTab(false);
          updatePad(res.data.data);
        })
        .catch((err) => {
          dispatch(loadingBarDisable());

          notification.error({
            message: 'Error',
            description: getApiError(err),
          });

          // if exist hisc error set message
          if (err?.response?.data?.errors?.hisc) {
            setIsHiscErr(err?.response?.data?.errors?.hisc[0]);
          }
          setApiErrorsToAntdForm(err);
        });
    },
    [dispatch, pad?.id, updatePad, setApiErrorsToAntdForm],
  );

  return (
    <Tabs defaultActiveKey="1" size="small" tabPosition="left">
      <Tabs.TabPane tab="Landing Information" key="1">
        <Form
          onFinish={onLandingTabSubmit}
          form={form}
          initialValues={{
            ...pad,
            landing_fees: ['0', '1'].includes(pad?.landing_fees?.toString())
              ? null
              : pad?.landing_fees?.toString(),
            warnings: pad?.warnings?.map((itm) => itm.id),
            facilities: pad?.facilities?.map((itm) => itm.id),
            tags: pad?.tags?.map((itm) => itm.name),
          }}
        >
          <div className="d-flex align-items-center justify-content-end mb-2">
            <EditActions
              isEditing={isEditingLandingTab}
              setIsEditing={setIsEditingLandingTab}
            />
          </div>
          {isEditingLandingTab ? (
            <LandingTabEdit form={form} SON={SON} isHiscErr={isHiscErr} setIsHiscErr={setIsHiscErr} />
          ) : (
            <LandingTabRead SON={SON} />
          )}
        </Form>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Survey Information" key="2" disabled={!pad.survey_id}>
        <PadSurvey surveyId={pad.survey_id} />
      </Tabs.TabPane>
    </Tabs>
  );
}
