import {
  GET_CAMPAIGNS_LIST,
  REMOVE_CAMPAIGN,
  UPDATE_CAMPAIGN,
} from '../actionTypes';

const initialState = [];

const campaigns = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_CAMPAIGNS_LIST: {
      return action.payload;
    }
    case UPDATE_CAMPAIGN: {
      const newState = [...state];
      const updatedCampaign = action.payload;
      const campaignIndex = newState.findIndex(
        (cmpg) => cmpg.id === updatedCampaign.id,
      );

      if (campaignIndex !== -1) {
        newState[campaignIndex] = updatedCampaign;
      } else {
        newState.push(updatedCampaign);
      }

      return newState;
    }
    case REMOVE_CAMPAIGN: {
      const newState = [...state];
      const campaignId = action.payload;
      const campaignIndex = newState.findIndex(
        (cmpg) => +cmpg.id === +campaignId,
      );

      if (campaignIndex !== -1) {
        newState.splice(campaignIndex, 1);
      }

      return newState;
    }
    default:
      return state;
  }
};

export default campaigns;
