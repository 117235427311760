import axios from 'axios';

const getApiErrorMessages = (err) =>
  (err.response &&
    err.response.data &&
    ((err.response.data.errors && Object.entries(err.response.data.errors)) ||
      err.response.data.message)) ||
  err.message ||
  'Unknown error';

export const getApiError = (err) => {
  if (axios.isAxiosError(err)) {
    if (err.response.data?.message) return err.response.data.message;
    if (err.response.message) return err.response.message;
  }

  return err.message ?? 'Unknown error';
};

export default getApiErrorMessages;
