import { useEffect, useState, useCallback, useRef } from 'react';
import { useMount, useSessionStorage, useUnmount } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import moment from 'moment';
import { Col, Row, Space } from 'antd';
import PageHeader from '../../uiKitComponents/pageHeader';
import Table from '../../uiKitComponents/table';
import Form from '../../uiKitComponents/form';
import DatePicker from '../../uiKitComponents/datePicker';
import Select from '../../uiKitComponents/select';
import Alert from '../../uiKitComponents/alert';
import Button from '../../uiKitComponents/button';
import * as reports from '../../store/actions/reportPagesAction';
import {
  selectCountriesOptions,
  selectUSStatesOptions,
} from '../../store/selectors/bootstrapSelector';

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';

const initialState = {
  page: 1,
  sort_column: 'id',
  sort_direction: 'descend',
  dates: { from: { string: '', locale: '' }, to: { string: '', locale: '' } },
  country_codes: [],
  state_codes: [],
  filtersTrigger: false,
};

const PilotsCountryPage = () => {
  const [state, setState] = useSessionStorage('pilots-country', {
    ...initialState,
  });
  const { page, filtersTrigger, dates, country_codes, state_codes } = state;
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [isUSSelected, setIsUSSelected] = useState(false);
  const { cleanOnUnmount, pilotsCountry, countries, USStates } = useSelector(
    (store) => ({
      cleanOnUnmount: store.reportPages.cleanOnUnmount,
      pilotsCountry: store.reportPages.payload.pilotsCountry,
      countries: selectCountriesOptions(store),
      USStates: selectUSStatesOptions(store),
    }),
  );
  const paginationMeta = useRef({ range: [], total: 0 });

  const dispatch = useDispatch();

  useMount(() => {
    reports.setCleanOnUnmountTrue()(dispatch);

    if (country_codes.length === 1 && country_codes.includes('US')) {
      setIsUSSelected(true);
    }
  });

  const fetchPadStats = () => {
    setLoading(true);

    reports
      .getPilotsCountry({
        from: dates.from.string,
        to: dates.to.string,
        country_codes,
        state_codes,
      })(dispatch)
      .catch((err) => {
        if (err.response.status === 500) {
          setError({
            status: err.response.status,
            message: err.response.data.message,
          });
          sessionStorage.removeItem('pilots-country');
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchPadStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersTrigger, dispatch, dates]);

  useUnmount(() => {
    if (cleanOnUnmount) {
      sessionStorage.removeItem('pilots-country');
      reports.cleanReports()(dispatch);
    }
  });

  useEffect(() => {
    if (pilotsCountry) {
      setDataSource(pilotsCountry);
    }
  }, [pilotsCountry]);

  const onTableChange = useCallback(
    (pagination, tableFilters, sorter, extra) => {
      const newState = {};
      if (extra.action === 'paginate') newState.page = pagination.current;
      else if (extra.action === 'sort') {
        newState.sort_column = sorter.field;
        newState.sort_direction = sorter.order;
      }
      setState((prevState) => ({ ...prevState, ...newState }));
    },
    [setState],
  );

  const columns = [
    {
      dataIndex: 'country',
      title: 'Country',
      render: (data) => data,
    },
    {
      dataIndex: 'user_premium_count',
      title: 'Premium Users',
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.user_premium_count - b.user_premium_count,
      render: (data) => data,
    },
    {
      dataIndex: 'user_non_premium_count',
      title: 'Non Premium Users',
      sortDirections: ['descend', 'ascend', 'descend'],
      sorter: (a, b) => a.user_non_premium_count - b.user_non_premium_count,
      render: (data) => data,
    },
    {
      dataIndex: 'user_fremium_count',
      title: 'Freemium Users',
      sortDirections: ['descend', 'ascend', 'descend'],
      sorter: (a, b) => a.user_fremium_count - b.user_fremium_count,
      render: (data) => data,
    },
  ];

  const onSelectedDatesHandler = (values) => {
    const { range, country_codes: codes, state_codes: states } = values;

    if (range && range.length && range[0]) {
      const date_from = dayjs(range[0].$d).format('DD-MM-YYYY');
      const date_to = dayjs(range[1].$d).format('DD-MM-YYYY');

      setState((prevState) => ({
        ...prevState,
        dates: {
          from: { string: date_from, locale: range[0] },
          to: { string: date_to, locale: range[1] },
        },
        country_codes: codes,
        state_codes: states,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        dates: {
          from: { string: '', locale: '' },
          to: { string: '', locale: '' },
        },
        country_codes: codes,
        state_codes: states,
      }));
    }
  };

  const getMomentDate = (date) => {
    if (date) return moment(date, dateFormat);

    return null;
  };

  const onCountryChange = (_countries = []) => {
    if (_countries.length === 1 && _countries.includes('US'))
      setIsUSSelected(true);
    else setIsUSSelected(false);
  };

  if (error)
    return (
      <PageHeader className="page-main-header" title="Pilot Country">
        <Alert
          showIcon
          type="error"
          message={`Error ${error.status}`}
          description={error.message}
        />
      </PageHeader>
    );

  return (
    <div>
      <PageHeader className="page-main-header" title="Pilot Country" />
      <Form
        layout="vertical"
        className="mb-4"
        onFinish={onSelectedDatesHandler}
      >
        <Row justify="space-between">
          <Col className="d-flex flex-column justify-content-between">
            <Form.Item
              name="range"
              label="Select Dates"
              initialValue={[
                getMomentDate(dates.from?.locale),
                getMomentDate(dates.to?.locale),
              ]}
              style={{
                marginBottom: 18,
              }}
            >
              <RangePicker
                format="DD/MM/YYYY"
                disabledDate={(date) => date.isAfter(moment())}
              />
            </Form.Item>

            <Button type="primary" htmlType="submit">
              Select
            </Button>
          </Col>
          <Col>
            <Form.Item
              name="country_codes"
              label="Select Countries"
              initialValue={country_codes}
              style={{
                marginBottom: 18,
              }}
            >
              <Select
                mode="multiple"
                placeholder="Choose..."
                options={countries}
                optionFilterProp="label"
                onChange={onCountryChange}
                style={{
                  maxWidth: 294,
                  minWidth: 294,
                }}
              />
            </Form.Item>
            {isUSSelected && (
              <Form.Item
                name="state_codes"
                label="Select US States"
                initialValue={state_codes}
                style={{
                  marginBottom: 18,
                }}
              >
                <Select
                  mode="multiple"
                  placeholder="Choose..."
                  options={USStates}
                  optionFilterProp="label"
                  style={{
                    maxWidth: 294,
                    minWidth: 294,
                  }}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
      <div className="page-main-content">
        <Space
          className="d-flex justify-content-end"
          style={{ color: '#d9d9d9' }}
        >
          Showing {paginationMeta.current.range[0]}-
          {paginationMeta.current.range[1]} of{''}
          {paginationMeta.current.total} listings
        </Space>
        <Table
          columns={columns}
          rowKey="country"
          dataSource={dataSource}
          loading={loading}
          onChange={onTableChange}
          pagination={{
            position: ['topRight', 'bottomRight'],
            showSizeChanger: false,
            showQuickJumper: true,
            pageSize: 25,
            total: pilotsCountry?.meta?.total,
            current: page,
            showTotal: (total, range) => {
              paginationMeta.current = { range, total };
            },
          }}
        />
        <Space
          className="d-flex justify-content-end"
          style={{ color: '#d9d9d9' }}
        >
          Showing {paginationMeta.current.range[0]}-
          {paginationMeta.current.range[1]} of{''}
          {paginationMeta.current.total} listings
        </Space>
      </div>
    </div>
  );
};

PilotsCountryPage.defaultProps = {
  reportPageProps: {},
  row_action: [],
  row_inner_action: [],
};

PilotsCountryPage.propTypes = {
  reportPageProps: PropTypes.shape({
    sessionStorageName: PropTypes.string,
    pageTitle: PropTypes.string,
    stateName: PropTypes.string,
    actionName: PropTypes.string,
    default_sort_column: PropTypes.string,
  }),
  row_action: PropTypes.array,
  row_inner_action: PropTypes.array,
};

export default PilotsCountryPage;
