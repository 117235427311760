import PropTypes from 'prop-types';
import React from 'react';
import { Descriptions, Form, Input, Select } from 'antd';
import { surveyOptions } from '../utils';
import { usePadSurveyContext } from '../PadSurveyContext';

export default function SurveyGeneralStep(props) {
  const { isEditing, toggleStepVisibility } = usePadSurveyContext();
  const { step } = props;
  const [content, setContent] = React.useState([]);

  React.useEffect(() => {
    const values = [];

    if (step?.minimum_notice) {
      const label = surveyOptions[step.minimum_notice];
      const value = step?.minimum_notice_details || '---';
      values.push([label, value]);
    }
    if (step?.ppr) {
      const label = surveyOptions[step.ppr];
      const value = step?.ppr_details || '---';
      values.push([label, value]);
    }

    if (values.length) toggleStepVisibility('general', true);
    else toggleStepVisibility('general', false);

    setContent(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  if (isEditing) {
    return (
      <>
        <Descriptions
          size="small"
          bordered
          column={1}
          labelStyle={{ width: 280 }}
        >
          <Descriptions.Item
            className="py-2 px-2"
            label={
              <Form.Item
                label="Minimum Notice Required"
                name={['general', 'minimum_notice']}
                className="mb-0"
              >
                <Select
                  placeholder="Please select"
                  options={[
                    { value: 'short_notice', label: 'Short Notice' },
                    { value: '24', label: '24 Hours' },
                    { value: '48', label: '48 Hours' },
                    { value: 'other', label: 'Other' },
                  ]}
                />
              </Form.Item>
            }
          >
            <Form.Item
              name={['general', 'minimum_notice_details']}
              className="w-100 mb-0"
              style={{ marginTop: 27 }}
            >
              <Input placeholder="Please give details" />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item
            className="py-2 px-2"
            label={
              <Form.Item
                label="Contact via"
                name={['general', 'ppr']}
                className="mb-0"
              >
                <Select
                  placeholder="Please select"
                  options={[
                    { value: 'email', label: 'Email' },
                    { value: 'phone', label: 'Phone' },
                  ]}
                />
              </Form.Item>
            }
          >
            <Form.Item
              name={['general', 'ppr_details']}
              className="w-100 mb-0"
              style={{ marginTop: 27 }}
            >
              <Input placeholder="Please give details" />
            </Form.Item>
          </Descriptions.Item>
        </Descriptions>
        <Form.Item hidden noStyle name={['general', 'name']}>
          <Input />
        </Form.Item>
        <Form.Item hidden noStyle name={['general', 'category_id']}>
          <Input />
        </Form.Item>
        <Form.Item hidden noStyle name={['general', 'phone']}>
          <Input />
        </Form.Item>
        <Form.Item hidden noStyle name={['general', 'email']}>
          <Input />
        </Form.Item>
        <Form.Item hidden noStyle name={['general', 'address']}>
          <Input />
        </Form.Item>
      </>
    );
  }

  if (!content.length) return null;

  return (
    <Descriptions
      size="small"
      bordered
      column={1}
      labelStyle={{ width: '240px' }}
      contentStyle={{ whiteSpace: 'pre-line' }}
    >
      {content.map(([label, value]) => (
        <Descriptions.Item
          key={label.toLowerCase().replace(' ', '_')}
          className="py-2 px-2"
          label={label}
        >
          {value}
        </Descriptions.Item>
      ))}
    </Descriptions>
  );
}

SurveyGeneralStep.propTypes = {
  step: PropTypes.object,
};

SurveyGeneralStep.defaultProps = {
  step: {},
};
