import PropTypes from 'prop-types';
import Tag from '../../uiKitComponents/tag';
import condStrings from '../../utils/condStrings';

import styles from './checkableTagList.module.sass';

const CheckableTagList = (props) => {
  const {
    options,
    name,
    value,
    onChange,
    onSelectChange,
    checkableTagProps,
    readOnly,
    className,
    ...rest
  } = props;

  const handleChange = (optionalVal) => (checked) => {
    if (!readOnly) {
      onChange({
        target: {
          name,
          value: checked
            ? [...value, optionalVal]
            : value.filter((t) => t !== optionalVal),
        },
      });
      onSelectChange();
    }
  };

  return (
    <span
      {...rest}
      className={condStrings(className, styles.root, readOnly && 'readonly')}
    >
      {options.map((option) => (
        <Tag.CheckableTag
          key={option.value}
          checked={value.includes(option.value)}
          onChange={handleChange(option.value)}
        >
          {option.label}
        </Tag.CheckableTag>
      ))}
    </span>
  );
};

CheckableTagList.defaultProps = {
  options: [],
  name: '',
  value: [],
  onChange: () => null,
  checkableTagProps: {},
  readOnly: false,
  className: '',
  onSelectChange: () => null,
};

CheckableTagList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  name: PropTypes.string,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  onChange: PropTypes.func,
  checkableTagProps: PropTypes.object,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  onSelectChange: PropTypes.func,
};

export default CheckableTagList;
