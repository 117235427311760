import PropTypes from 'prop-types';
import { List } from 'antd';
import React from 'react';
import { useMount } from 'react-use';
import styles from '../../adminTab.module.sass';
import { OwnerItem } from './OwnerItem';

import './OwnersList.styles.scss';

export const OwnersListContext = React.createContext();

export const OwnersList = React.memo((props) => {
  const { padId, updatePad, owners, getOwnerList } = props;

  useMount(getOwnerList);

  return (
    <OwnersListContext.Provider value={{ padId, updatePad, getOwnerList }}>
      <List
        bordered
        loading={{
          spinning: !owners,
          wrapperClassName: styles.owners,
        }}
        dataSource={owners}
        renderItem={(item) => <OwnerItem owner={item} />}
      />
    </OwnersListContext.Provider>
  );
});

OwnersList.propTypes = {
  padId: PropTypes.number,
  updatePad: PropTypes.func,
  owners: PropTypes.array,
  getOwnerList: PropTypes.func,
};
