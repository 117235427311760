import PropTypes from 'prop-types';
import React from 'react';
import { Form, Input, notification } from 'antd';
import { getPadSurveyUploads } from '../../../services/api';
import PadSurveyUploads from '../PadSurveyUploads';
import { usePadSurveyContext } from '../PadSurveyContext';
import SurveyEditUploadSide from '../SurveyEditUploadSide';

export default function SurveyViewStep(props) {
  const { isEditing, toggleStepVisibility } = usePadSurveyContext();
  const { step, padId, surveyId, updateFormValue } = props;
  const [images, setImages] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const updateImagesList = React.useCallback(
    (action) => {
      if (action === 'remove') {
        return (id) => setImages(images.filter((img) => img.id !== id));
      }

      if (action === 'add') {
        return (file) => setImages([...images, file]);
      }

      return () => null;
    },
    [images],
  );

  React.useEffect(() => {
    updateFormValue([{ name: ['view', 'count'], value: images.length }]);
  }, [updateFormValue, images]);

  React.useEffect(() => {
    const fetchSurveyUploads = async () => {
      try {
        setLoading(true);

        const uploads = await getPadSurveyUploads(padId);

        if (uploads.length) toggleStepVisibility('general', true);
        else toggleStepVisibility('general', false);

        setImages(
          uploads.map((img) => ({
            id: img.id,
            url: img.file_url,
            dirLabel:
              img.direction.charAt(0).toUpperCase() + img.direction.slice(1),
            dir: img.direction,
            descrp: step?.[img.direction],
          })),
        );
      } catch (error) {
        notification.error({ message: 'Error', description: error });
      } finally {
        setLoading(false);
      }
    };

    if (padId) {
      fetchSurveyUploads();
    }
  }, [step, padId, toggleStepVisibility]);

  if (isEditing) {
    const imagesAsObject = {
      north: {
        dirLabel: 'North',
      },
      east: {
        dirLabel: 'East',
      },
      west: {
        dirLabel: 'West',
      },
      south: {
        dirLabel: 'South',
      },
    };

    images.forEach((image) => {
      imagesAsObject[image.dir] = image;
    });

    return (
      <div className="survey-uploads-grid editing">
        {Object.keys(imagesAsObject).map((key) => (
          <SurveyEditUploadSide
            key={key}
            sideData={imagesAsObject[key]}
            surveyId={surveyId}
            sideName={key}
            onRemoveSuccess={updateImagesList('remove')}
            onUploadSuccess={updateImagesList('add')}
          />
        ))}
        <Form.Item hidden noStyle name={['view', 'count']}>
          <Input />
        </Form.Item>
      </div>
    );
  }

  return <PadSurveyUploads images={images} loading={loading} />;
}

SurveyViewStep.propTypes = {
  step: PropTypes.object,
  padId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  surveyId: PropTypes.number,
  updateFormValue: PropTypes.func.isRequired,
};

SurveyViewStep.defaultProps = {
  step: {},
  padId: null,
  surveyId: null,
};
