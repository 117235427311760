import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Checkbox, notification } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import formattingFilters from '../../utils/formattingFilters';
import getApiErrorMessages from '../../utils/getApiErrorMessages';
import openCSV from '../../utils/openCSV';

export default function ExportFiltersBtn({
  activeFilters,
  activeKeyword,
  config,
  hiddenExportForUpload,
}) {
  const [loading, setLoading] = React.useState(false);
  const [isExportToUpdate, setIsExportToUpdate] = React.useState(false);

  const exportBtnVisible = React.useMemo(() => {
    if ((activeFilters.length && activeFilters[0]?.column) || activeKeyword) {
      return true;
    }

    return false;
  }, [activeFilters, activeKeyword]);

  const exportHandler = React.useCallback(async () => {
    const formattedFilters = {};

    activeFilters.forEach((filter) => {
      const temp_filter = formattingFilters(filter, filter.keyword, 'STRING');
      Object.keys(temp_filter).forEach((key) => {
        formattedFilters[`filters[${key}]`] = temp_filter[key];
      });
    });

    if (activeKeyword) {
      formattedFilters.keyword = activeKeyword;
    }

    formattedFilters.is_export_to_update = isExportToUpdate ? 1 : 0;

    setLoading(true);

    try {
      const response = await config.action(formattedFilters);
      openCSV(response.data, { filename: config.filename });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: getApiErrorMessages(error),
      });
    } finally {
      setLoading(false);
    }
  }, [activeFilters, config, activeKeyword, isExportToUpdate]);

  if (exportBtnVisible && config.action) {
    return (
      <>
        <div className="export-filters-btn-placeholder" />
        <div className="export-filters-btn">
          {!hiddenExportForUpload && <Checkbox
            checked={isExportToUpdate}
            onChange={(e) => setIsExportToUpdate(e.target.checked)}
          >
            Export for update
          </Checkbox>}
          <Button
            onClick={exportHandler}
            loading={loading}
            icon={<CloudDownloadOutlined />}
          >
            {config.text}
          </Button>
        </div>
      </>
    );
  }

  return null;
}

ExportFiltersBtn.propTypes = {
  activeFilters: PropTypes.array,
  activeKeyword: PropTypes.string,
  config: PropTypes.shape({
    action: PropTypes.func,
    text: PropTypes.string,
    filename: PropTypes.string,
  }),
  hiddenExportForUpload: PropTypes.bool,
};

ExportFiltersBtn.defaultProps = {
  activeFilters: [],
  config: {
    action: null,
    text: 'Export as CSV',
  },
  hiddenExportForUpload: false,
};
