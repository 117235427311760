import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { notification } from 'antd';
import debounce from 'lodash.debounce';
import { useUpdateEffect } from 'react-use';
import PicturesWall from '../../../../../components/picturesWall';
import api from '../../../../../utils/appApi';
import getApiErrorMessages from '../../../../../utils/getApiErrorMessages';
import PadViewContext from '../../../PadView.context';

export function PadPhotos({ editing }) {
  const [fileList, setFileList] = React.useState([]);
  const { pad, updatePad } = React.useContext(PadViewContext);

  // state for remove first request add uploading order
  const [uploading, setUploading] = useState(0);

  const uploadsLimitExceeded = React.useMemo(
    () =>
      fileList.filter((f) => f?.user?.role === 1 || !!f?.originFileObj)
        .length === 4,
    [fileList],
  );

  React.useEffect(() => {
    if (pad?.uploads?.length) {
      setFileList(
        pad?.uploads.map((itm) => ({
          uid: `-${itm.id}`,
          name: itm.title,
          url: itm.file_url,
          status: 'done',
          user: itm?.user,
          created_at: itm.created_at,
          id: itm.id,
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUploadsOrder = (padId, files = []) => {
    if (padId) {
      const areUploadsValid = fileList.every((file) =>
        Boolean(file?.xhr?.id || file.uid.replace('-', '')),
      );

      if (areUploadsValid) {
        const orderedUploads = files.map((file, index) => ({
          id: file?.xhr?.id || file.uid.replace('-', ''),
          order: index + 1,
        }));

        api.pad.uploadsOrder({ upload: orderedUploads }).catch((err) =>
          notification.error({
            message: 'Uploads Order Error!',
            description: getApiErrorMessages(err),
          }),
        );
      }
    }
  };

  const debouncedUpdateUploadsOrder = React.useRef(
    debounce(updateUploadsOrder, 800),
  );

  useUpdateEffect(
    () => {
      setUploading(1);
      if (uploading) debouncedUpdateUploadsOrder.current(pad?.id, fileList)
    },
    [fileList, debouncedUpdateUploadsOrder],
  );

  const picturesWallChangeHandle = React.useCallback(() => {
    // setFileList(e.fileList);
  }, []);

  const picturesWallRemoveHandle = React.useCallback(
    (file) => {
      const updatedFileList = fileList.filter((itm) => itm.uid !== file.uid);

      let fileId = null;

      if (file?.xhr?.id) fileId = file.xhr.id;
      else fileId = file.uid.substring(1);

      api.upload.delete(fileId).then(() => {
        updatePad({
          uploads: pad?.uploads.filter((u) => u.id !== Number(fileId)),
        });
      });

      setFileList(updatedFileList);
    },
    [fileList, updatePad, pad?.uploads],
  );

  const uploadFileHandle = React.useCallback(
    (uploadConfig) => {
      if (pad?.id) {
        const { file, onError, onProgress, onSuccess } = uploadConfig;
        const formData = new FormData();

        formData.append('upload', file);

        api.upload
          .store('pad', pad.id, formData, {
            onUploadProgress: ({ total, loaded }) => {
              onProgress(
                { percent: Math.round((loaded / total) * 100).toFixed(2) },
                file,
              );
            },
          })
          .then((res) => {
            onSuccess('Success', { ...file, id: res.data.id });
            updatePad({ uploads: [...pad.uploads, res.data] });
            setFileList(
              [...pad.uploads, res.data].map((itm) => ({
                uid: `-${itm.id}`,
                name: itm.title,
                url: itm.file_url,
                status: 'done',
                user: itm?.user,
                created_at: itm.created_at,
              })),
            );
          })
          .catch((err) => {
            onError();

            setFileList((prevFileList) =>
              prevFileList.filter((itm) => itm.uid !== file.uid),
            );

            notification.error({
              message: 'Error',
              description: getApiErrorMessages(err),
            });
          });
      }
    },
    [pad?.id, pad?.uploads, updatePad],
  );

  return (
    <PicturesWall
      fileList={fileList}
      setFileList={setFileList}
      onChange={picturesWallChangeHandle}
      onRemove={picturesWallRemoveHandle}
      customRequest={uploadFileHandle}
      showUploadBtn={editing && !uploadsLimitExceeded}
      maxCount={1}
      editing={editing}
    />
  );
}

PadPhotos.propTypes = {
  editing: PropTypes.bool,
};
