import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox, Descriptions, Form, Input } from 'antd';
import { hazards } from '../constants';
import { separatedArray } from '../utils';

export const SurveyHazardsStep = React.memo(({ categoryId, stepValues }) => (
  <Descriptions
    labelStyle={{ width: 150 }}
    size="small"
    bordered
    column={1}
    layout="vertical"
    className="mb-3"
  >
    <Descriptions.Item label={`Hazards: ${stepValues.is_completed ? 'Completed' : 'Incomplete'}`}>
      <Form.Item name={['hazards', 'hazards_relevant_categories']}>
        <Checkbox.Group className="survey-checkbox-list w-100">
          <div className="d-flex justify-content-between">
            {/* <div className="d-flex flex-column"> */}
            {separatedArray(
              hazards.filter((h) => h.categories.includes(categoryId)),
            ).map((side) => (
              <div key={side.name} className="d-flex flex-column">
                {side.values.map((v) => (
                  <Checkbox value={v.id} className="mb-2">
                    {v.name}
                  </Checkbox>
                ))}
              </div>
            ))}
            {/* <Checkbox value={1} className="mb-2">
                Animals
              </Checkbox>
              <Checkbox value={2} className="mb-2">
                Power lines
              </Checkbox>
              <Checkbox value={3} className="mb-2">
                High trees
              </Checkbox>
              <Checkbox value={4} className="mb-2">
                Loose debris
              </Checkbox>
              <Checkbox value={5}>Tall building</Checkbox>
            </div>
            <div className="d-flex flex-column">
              <Checkbox value={6} className="mb-2">
                Public right of way across landing area
              </Checkbox>
              <Checkbox value={7} className="mb-2">
                Unsecured items (downwash issue)
              </Checkbox>
              <Checkbox value={8} className="mb-2">
                Changeable surface (after heavy rain/snow)
              </Checkbox>
              <Checkbox value={99}>Other</Checkbox>
            </div> */}
          </div>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item
        name={['hazards', 'hazards_comment']}
        label="Hazard details"
        className="mt-4 mb-0"
        required={(stepValues.hazards_relevant_categories || []).includes(99)}
        rules={[
          {
            min: 3,
            max: 500,
            message: 'Must be between 3 and 500 characters',
          },
        ]}
      >
        <Input.TextArea
          autoSize
          placeholder="Please elaborate on each hazard, giving as specific detail as possible including bearing and distance from Landing Area"
        />
      </Form.Item>
    </Descriptions.Item>
  </Descriptions>
));

SurveyHazardsStep.propTypes = {
  categoryId: PropTypes.number,
  stepValues: PropTypes.object,
};
