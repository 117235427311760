import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import Table from '../../../../uiKitComponents/table';
import Tag from '../../../../uiKitComponents/tag';
import api from '../../../../utils/appApi';

const { Option } = Select;

const PadsTab = (props) => {
  const { data } = props;
  const [ownedPads, setOwnedPads] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const { statuses } = useSelector((state) => ({
    statuses: state.bootstrap.payload.statuses,
  }));

  const renderPadStatus = (pad) => {
    if (pad.status_id === 1 && pad.is_private) {
      return '-';
    }

    return (
      statuses.find(({ id }) => id === pad.status_id).display_name ||
      pad.status_id
    );
  };

  const columns = [
    {
      dataIndex: 'name',
      title: 'Name',
      render: (_, { id, name }) => (
        <Link to={`/dashboard/pad-view/${id}`}>{name}</Link>
      ),
    },
    {
      dataIndex: 'status_id',
      title: 'Status',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => a.status_id - b.status_id,
      render: (_, pad) => renderPadStatus(pad),
    },
    {
      dataIndex: 'owners',
      title: 'Owners',
      render: (_, rowData) =>
        rowData.owners.map(({ user_id, name }) => (
          <Tag key={user_id}>
            <Link to={`/dashboard/user-view/${user_id}`}>
              {name || 'Undefined'}
            </Link>
          </Tag>
        )),
    },
  ];

  const getUserPads = (config) => {
    setIsLoad(true);
    api.users
      .getPads(data.id, config || '')
      .then((res) => {
        setOwnedPads(res.data.data);
        setIsLoad(false);
      })
      .catch((err) => {
        setIsLoad(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (data?.id) getUserPads(data.filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleFilterUserPads = (value) => {
    switch (value) {
      case '0':
        getUserPads();
        break;
      case '1':
        getUserPads(1);
        break;
      case '2':
        getUserPads(2);
        break;
      case '3':
        getUserPads(3);
        break;
      case '5':
        getUserPads(5);
        break;
      default:
        break;
    }
  };

  if (!data)
    return (
      <div className="text-center mt-3">
        <h3>This user doesn&apos;t have owned pads</h3>
      </div>
    );

  return (
    <>
      <div className="filters-container user-pads-filters">
        <h3>Filter user pads</h3>
        <Select
          defaultValue={data.filter || '0'}
          onChange={handleFilterUserPads}
          style={{ width: '180px' }}
        >
          <Option value="0">All</Option>
          <Option value="1">Private</Option>
          <Option value="2">Submitted</Option>
          <Option value="3">Owned</Option>
          <Option value="5">Favourite</Option>
        </Select>
      </div>
      <Table
        dataSource={ownedPads}
        columns={columns}
        pagination={{ pageSize: '20' }}
        loading={isLoad}
        rowKey={'id'}
      />
    </>
  );
};

PadsTab.defaultProps = {
  data: undefined,
};

PadsTab.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    filter: PropTypes.string,
  }),
};

export default PadsTab;
