import {
  Button,
  Form,
  Input,
  notification,
  PageHeader,
  Popconfirm,
  Popover,
  Table,
} from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMount, useSessionStorage } from 'react-use';
import {
  getCampaings,
  removeCampaign,
  updateCampaign,
} from '../../../store/actions/campaignsAction';
import api from '../../../utils/appApi';
import getApiErrorMessages from '../../../utils/getApiErrorMessages';
import CampaignName from './campaignName';

const initialState = {
  page: 1,
  sort_column: 'count',
  sort_direction: 'descend',
  filtersTrigger: false,
  keyword: '',
  country: '',
  us_state: '',
};

export default function CampaignsPage() {
  const [state, setState] = useSessionStorage('campaigns-page', {
    ...initialState,
  });
  const [createLoading, setCreateLoading] = useState(false);
  const { campaigns } = useSelector((store) => ({
    campaigns: store.campaigns,
  }));
  const [loading, setLoading] = useState(true);
  const [createVisible, setCreateVisible] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const fetcher = useCallback(async () => {
    setLoading(true);
    await dispatch(getCampaings());
    setLoading(false);
  }, [dispatch]);

  useMount(fetcher);

  const onCreateSuccess = useCallback(
    (campaign) => {
      dispatch(updateCampaign(campaign));
      setCreateVisible(false);
    },
    [dispatch],
  );

  const onCreateHandler = useCallback(
    async (values) => {
      setCreateLoading(true);
      try {
        const res = await api.campaign.create(values);
        onCreateSuccess(res.data);
      } catch (error) {
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(error),
        });
      } finally {
        setCreateLoading(false);
      }
    },
    [onCreateSuccess],
  );

  const onTableChange = useCallback(
    (pagination, tableFilters, sorter, extra) => {
      const newState = {};
      if (extra.action === 'paginate') newState.page = pagination.current;
      else if (extra.action === 'sort') {
        newState.sort_column = sorter.field;
        newState.sort_direction = sorter.order;
      }
      setState((prevState) => ({ ...prevState, ...newState }));
    },
    [setState],
  );

  const CreateCampaignsForm = useMemo(
    () => (
      <Form layout="inline" onFinish={onCreateHandler}>
        <Form.Item
          name="name"
          className="mb-0"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Input placeholder="Name of the campaign" autoComplete="off" />
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={createLoading}>
          Create
        </Button>
      </Form>
    ),
    [createLoading, onCreateHandler],
  );

  const onRemoveSuccess = useCallback(
    (campaignId) => {
      dispatch(removeCampaign(campaignId));
    },
    [dispatch],
  );

  const onRemoveHandler = useCallback(
    (id) => async () => {
      try {
        await api.campaign.remove(id);
        onRemoveSuccess(id);

        return Promise.resolve();
      } catch (error) {
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(error),
        });

        return Promise.reject();
      }
    },
    [onRemoveSuccess],
  );

  const goToCampaignUsers = useCallback(
    (id) => () =>
      history.push(
        `/dashboard/user-list?filters=true&column=userCampaign.campaign_id&operator=where in&keyword=[${id}]`,
      ),
    [history],
  );

  const columns = [
    {
      dataIndex: 'name',
      title: 'Name',
      render: (_, row) => <CampaignName campaign={row} />,
    },
    {
      dataIndex: 'count',
      title: 'Current',
      sortDirections: ['descend', 'ascend', 'descend'],
      sortOrder: state.sort_column === 'count' && state.sort_direction,
      sorter: (a, b) => a.count - b.count,
    },
    {
      dataIndex: 'converted',
      title: 'Converted',
      sortDirections: ['descend', 'ascend', 'descend'],
      sortOrder: state.sort_column === 'converted' && state.sort_direction,
      sorter: (a, b) => a.converted - b.converted,
    },
    {
      dataIndex: 'reverted',
      title: 'Reverted',
      sortDirections: ['descend', 'ascend', 'descend'],
      sortOrder: state.sort_column === 'reverted' && state.sort_direction,
      sorter: (a, b) => a.reverted - b.reverted,
    },
    {
      title: 'Actions',
      render: (_, row) => (
        <Button.Group size="small">
          <Button onClick={goToCampaignUsers(row.id)}>View</Button>
          <Popconfirm
            placement="bottomLeft"
            title={`${row.count} User(-s) are assigned to this campaign - are you sure you want to delete it`}
            onConfirm={onRemoveHandler(row.id)}
          >
            <Button danger>Delete</Button>
          </Popconfirm>
        </Button.Group>
      ),
    },
  ];

  return (
    <>
      <PageHeader
        className="page-main-header"
        title="Campaigns"
        extra={[
          <Popover
            trigger="click"
            key="create-campaign"
            placement="bottomLeft"
            title={<h3 className="mb-0">Create a new campaign</h3>}
            content={CreateCampaignsForm}
            visible={createVisible}
            onVisibleChange={setCreateVisible}
          >
            <Button type="primary">Add New</Button>
          </Popover>,
        ]}
      />
      <div className="page-main-content">
        <Table
          columns={columns}
          rowKey="id"
          dataSource={campaigns}
          loading={loading}
          onChange={onTableChange}
          pagination={{
            position: ['topRight', 'bottomRight'],
            showSizeChanger: false,
            showQuickJumper: true,
            pageSize: 25,
            total: campaigns.length,
            current: 1,
            hideOnSinglePage: true,
            showTotal: (total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total} listings`,
          }}
        />
      </div>
    </>
  );
}
