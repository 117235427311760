import PropTypes from 'prop-types';
import React from 'react';
import { Button, Space } from 'antd';
import { usePadSurveyContext } from './PadSurveyContext';

export default function PadSurveyActions(props) {
  const { isEditing } = usePadSurveyContext();
  const { toggleEdit, submit } = props;
  const [isSaving, setIsSaving] = React.useState(false);

  const submitHandler = React.useCallback(async () => {
    setIsSaving(true);
    await submit();
    setIsSaving(false);
  }, [submit]);

  if (isEditing) {
    return (
      <div className="text-right mb-2">
        <Button.Group>
          <Button
            htmlType="button"
            onClick={toggleEdit(false)}
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button type="primary" onClick={submitHandler} loading={isSaving}>
            Save changes
          </Button>
        </Button.Group>
      </div>
    );
  }

  return (
    <div className="text-right mb-2">
      <Space>
        <Button htmlType="button" onClick={toggleEdit(true)}>
          Edit survey
        </Button>
      </Space>
    </div>
  );
}

PadSurveyActions.propTypes = {
  toggleEdit: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};
