import PropTypes from 'prop-types';
import React from 'react';
import { Descriptions, Space, Tag, Tooltip, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { selectLandingFeeOptions } from '../../../../store/selectors/bootstrapSelector';
import PadViewContext from '../../PadView.context';
import { generateWaringsOrFacilities } from './LandingTab.utils';
import generateExternalLink from '../../../../utils/generateExternalLink';
import { visualizeBool } from '../../PadView.utils';
import FormattedText from '../../../../components/formattedText';
import { ReactComponent as InfoCircleInfo } from '../../../../assets/icons/info-circle.svg';

export default function LandingTabRead({ SON }) {
  const { pad } = React.useContext(PadViewContext);
  const { landingFees } = useSelector((store) => ({
    landingFees: selectLandingFeeOptions(store),
  }));

  const warningsAndFacilities = React.useMemo(
    () => generateWaringsOrFacilities(pad),
    [pad],
  );

  return (
    <Descriptions labelStyle={{ width: 150 }} size="small" bordered column={1}>
      <Descriptions.Item label="Site Description">
        {pad?.site_information}
      </Descriptions.Item>
      <Descriptions.Item label={SON}>
        <FormattedText text={pad?.landing_advice ? pad?.landing_advice : ''} />
      </Descriptions.Item>
      {warningsAndFacilities && (
        <Descriptions.Item label={warningsAndFacilities.label}>
          {warningsAndFacilities.content}
        </Descriptions.Item>
      )}
      <Descriptions.Item label="Landing fees">
        {landingFees.find((fee) => +fee.value === pad.landing_fees)?.label}
      </Descriptions.Item>
      <Descriptions.Item label="ICAO Code">
        <FormattedText text={pad?.icao ? pad?.icao : ''} />
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <span className="d-flex align-items-center">
            <Typography className="mr-1">HISC</Typography>
            <Tooltip
              title="Helipaddy International Site Code"
              className="tooltip-responsive"
            >
              <InfoCircleInfo style={{ fontSize: '5px' }} />
            </Tooltip>
          </span>
        }
      >
        {pad?.hisc ? pad?.hisc : ''}
      </Descriptions.Item>
      <Descriptions.Item label="Social">
        <>
          Website:{' '}
          <a
            href={generateExternalLink(pad?.pad_social?.website)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {pad?.pad_social?.website}
          </a>
          <br />
          Twitter handle: {pad?.pad_social?.twitter_handle} <br />
          <br />
          <Space align="start">
            Tags:
            <div style={{ maxWidth: 300 }}>
              {pad?.tags?.map((itm) => (
                <Tag key={`tag-${itm.id}`}>{itm.name}</Tag>
              ))}
            </div>
          </Space>
        </>
      </Descriptions.Item>
      <Descriptions.Item label="Access">
        <table style={{ maxWidth: 320 }}>
          <tbody>
            <tr>
              <td>API Access:</td>
              <td>{visualizeBool(pad?.is_api_accessible)}</td>
            </tr>
          </tbody>
        </table>
      </Descriptions.Item>
    </Descriptions>
  );
}

LandingTabRead.propTypes = {
  SON: PropTypes.string,
};
