import { LinkOutlined } from '@ant-design/icons';
import { Button, notification, Popover, Typography } from 'antd';
import React from 'react';
import api from '../../../../../utils/appApi';
import getApiErrorMessages from '../../../../../utils/getApiErrorMessages';
import PadViewContext from '../../../PadView.context';

export function PadExternalLink() {
  const { pad } = React.useContext(PadViewContext);
  const [loading, setLoading] = React.useState(false);
  const [contentVisible, setContentVisible] = React.useState(false);
  const [btnContent, setBtnContent] = React.useState('Generate Pad Link');

  const actionDisabled = React.useMemo(
    () => [pad?.is_private, pad?.deleted_at].some(Boolean),
    [pad],
  );

  const generateLinkHandler = (type) => async () => {
    setLoading(true);
    setContentVisible(false);

    try {
      const response = await api.pad.generateLink(pad?.id, type);

      if (response && response.data) {
        navigator.clipboard.writeText(response.data);

        notification.success({
          message: 'Link copied!',
          description: (
            <Typography.Paragraph
              className="mb-0"
              ellipsis={{ rows: 1, expandable: false }}
            >
              <a href={response.data} target="_blank" rel="noreferrer">
                {response.data}
              </a>
            </Typography.Paragraph>
          ),
        });
      }

      setBtnContent('Generated!');
    } catch (err) {
      notification.error({
        message: 'Error',
        description: getApiErrorMessages(err),
      });
      setBtnContent('Something went wrong');
    } finally {
      setLoading(false);
      setTimeout(() => {
        setBtnContent('Generate Pad Link');
      }, 1000);
    }
  };

  return (
    <Popover
      visible={contentVisible}
      onVisibleChange={setContentVisible}
      content={
        <Button.Group>
          <Button onClick={generateLinkHandler('mobile')}>Mobile App</Button>
          <Button onClick={generateLinkHandler('web')}>Ownerdash</Button>
        </Button.Group>
      }
    >
      <Button
        icon={<LinkOutlined />}
        loading={loading}
        disabled={actionDisabled}
      >
        {btnContent}
      </Button>
    </Popover>
  );
}
