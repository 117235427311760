import {
  GET_PAD_SUBMISSIONS,
  GET_LOG_REPORTS,
  GET_DUPLICATE_REPORT,
  GET_PAD_STATS_FAV,
  CLEAN_REPORTS,
  CLEAN_ON_UNMOUNT_TRUE,
  CLEAN_ON_UNMOUNT_FALSE,
  GET_PAD_STATS_VIEWS,
  GET_UNDEFINED_USER,
  GET_PILOTS_COUNTRY,
  GET_PADS_COUNTRY,
  GET_MODERATION_ENGAGEMENT,
  GET_SUBS_REPORT,
  GET_CONCURRENT_LOGINS_REPORT,
  GET_SURVEYS_REPORT,
  GET_PILOT_AIRCRAFTS_REPORT,
} from '../actionTypes';

const initialState = {
  cleanOnUnmount: true,
  payload: {
    padSubmissions: undefined,
    logReports: undefined,
  },
};

const reportPages = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_PAD_SUBMISSIONS:
      return {
        ...state,
        payload: {
          padSubmissions: action.payload,
        },
      };
    case GET_LOG_REPORTS:
      return {
        ...state,
        payload: {
          logReports: action.payload,
        },
      };
    case GET_DUPLICATE_REPORT:
      return {
        ...state,
        payload: {
          duplicateReport: action.payload,
        },
      };
    case GET_PAD_STATS_FAV:
      return {
        ...state,
        payload: {
          padStatsFav: action.payload,
        },
      };
    case GET_PAD_STATS_VIEWS:
      return {
        ...state,
        payload: {
          padStatsViews: action.payload,
        },
      };
    case GET_UNDEFINED_USER:
      return {
        ...state,
        payload: {
          undefinedUsers: action.payload,
        },
      };
    case GET_PILOTS_COUNTRY:
      return {
        ...state,
        payload: {
          pilotsCountry: action.payload,
        },
      };
      case GET_PADS_COUNTRY:
        return {
          ...state,
          payload: {
            padsCountry: action.payload,
          },
        };
    case GET_MODERATION_ENGAGEMENT:
      return {
        ...state,
        payload: {
          moderationENgagement: action.payload,
        },
      };
    case GET_SUBS_REPORT:
      return {
        ...state,
        payload: {
          subsReport: action.payload,
        },
      };
    case GET_CONCURRENT_LOGINS_REPORT:
      return {
        ...state,
        payload: {
          concurrentLogins: action.payload,
        },
      };
    case CLEAN_REPORTS:
      return initialState;
    case CLEAN_ON_UNMOUNT_TRUE:
      return {
        ...state,
        cleanOnUnmount: true,
      };
    case CLEAN_ON_UNMOUNT_FALSE:
      return {
        ...state,
        cleanOnUnmount: false,
      };
    case GET_SURVEYS_REPORT:
      return {
        ...state,
        payload: {
          surveysReport: action.payload,
        },
      };
    case GET_PILOT_AIRCRAFTS_REPORT:
      return {
        ...state,
        payload: {
          pilotAircraftsReport: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reportPages;
