import api from '../../utils/appApi';
import {
  GET_CAMPAIGNS_LIST,
  REMOVE_CAMPAIGN,
  UPDATE_CAMPAIGN,
} from '../actionTypes';

export const getCampaings = () => async (dispatch) => {
  try {
    const res = await api.campaign.list();

    dispatch({ type: GET_CAMPAIGNS_LIST, payload: res.data });

    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateCampaign = (campaign) => (dispatch) => {
  dispatch({ type: UPDATE_CAMPAIGN, payload: campaign });
};

export const removeCampaign = (id) => (dispatch) => {
  dispatch({ type: REMOVE_CAMPAIGN, payload: id });
};
