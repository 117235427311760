import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Descriptions, Typography } from 'antd';
import dayjs from 'dayjs';
import PadViewContext from '../../PadView.context';
import { generatePadSubmitters } from './ContactTab.utils';
import { selectCategoryOptions } from '../../../../store/selectors/bootstrapSelector';
import { visualizeBool } from '../../PadView.utils';
import convertDMS from '../../../../utils/convertDMS';
import { dateFormats } from '../../../../utils/constants';
import { ReactComponent as ExclamationIcon } from '../../../../assets/icons/exclamation.svg';

import {
  ContactsList,
  PadPhotos,
  PadPrimaryContact,
  PadRecommended,
  PadStatus,
  PadTitle,
} from './parts';

export default function ContactTabRead({ updateFormValues }) {
  const { categories } = useSelector((store) => ({
    categories: selectCategoryOptions(store),
  }));
  const { pad } = React.useContext(PadViewContext);

  const Submitters = React.useMemo(() => generatePadSubmitters(pad), [pad]);

  return (
    <>
      <Descriptions
        labelStyle={{ width: 160 }}
        size="small"
        bordered
        column={1}
      >
        <Descriptions.Item label="Name of pad">
          <PadTitle />
        </Descriptions.Item>
        <Descriptions.Item label="Category">
          {categories?.find((itm) => itm.value === pad?.category_id)?.label}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          <PadStatus />
        </Descriptions.Item>
        {pad?.category_id !== 1 && (
          <Descriptions.Item label="Landing not Possible">
            {visualizeBool(!pad?.is_landing_permitted)}
          </Descriptions.Item>
        )}
        {pad?.has_uncontactable_revision && !pad?.is_contactable && (
          <Descriptions.Item label="Pad Uncontactable">
            {visualizeBool(
              pad?.has_uncontactable_revision && !pad?.is_contactable,
            )}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Coordinates">
          <div>Latitude: {pad?.location?.latitude}</div>
          <div>Longitude: {pad?.location?.longitude}</div>
          {pad?.location && (
            <div>
              DMS: {convertDMS(pad.location?.latitude, pad.location?.longitude)}
            </div>
          )}
          <div>Address: {pad?.location?.name}</div>
          {pad?.location?.county && (
            <div>County: {pad?.location?.county} (from Google Maps)</div>
          )}
          <div>Region: {pad?.location?.region}</div>
        </Descriptions.Item>
        <Descriptions.Item label="Photos">
          <PadPhotos editing={false} />
        </Descriptions.Item>
        <Descriptions.Item label="Primary Contact">
          <PadPrimaryContact
            onPrimaryChanged={(contacts) => {
              updateFormValues([
                { name: 'editable_contacts', value: contacts },
              ]);
            }}
            editing={false}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Contacts">
          <ContactsList updateFormValues={updateFormValues} />
        </Descriptions.Item>
        <Descriptions.Item label="Created at">
          <div className="d-flex pad-submitters-list">
            <span className="text">
              {dayjs(pad?.created_at).format(dateFormats[0])}
            </span>
            {Submitters}
          </div>
        </Descriptions.Item>
        <Descriptions.Item label="Updated at">
          <span className="text">
            {dayjs(pad?.updated_at).format(dateFormats[0])}
          </span>
          {pad?.updated_by?.id && (
            <>
              &nbsp;by&nbsp;
              <Link to={`/dashboard/user-view/${pad?.updated_by?.id}`}>
                {pad?.updated_by?.email}
              </Link>
            </>
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            pad?.is_helipaddy_recommended_admin
              ? 'Is recommended'
              : 'Not recommended'
          }
        >
          <PadRecommended />
        </Descriptions.Item>
      </Descriptions>
    </>
  );
}

ContactTabRead.propTypes = {
  updateFormValues: PropTypes.func,
};
