import { Button, Radio, Table, Tag, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectCategoryOptions,
  selectContactTypeOptions,
  selectFacilityOptions,
} from '../../store/selectors/bootstrapSelector';

export default function PadImportTable(props) {
  const {
    pads,
    fileName,
    setPadList,
    setFileList,
    handleImport,
    importLoading,
    selected,
    setSelected,
  } = props;

  const { categories, contactTypes, facilities } = useSelector((store) => ({
    categories: selectCategoryOptions(store),
    contactTypes: selectContactTypeOptions(store),
    facilities: selectFacilityOptions(store),
  }));

  const radioChangeHandle = React.useCallback(
    (index) => (e) => {
      setSelected((prevState) => ({
        ...prevState,
        [index]:
          (e.target.value === '0' && selected[index] === false) ||
          (e.target.value === '1' && selected[index] === true)
            ? null
            : !!+e.target.value,
      }));
    },
    [selected, setSelected],
  );

  const rowSelectionRenderCell = React.useCallback(
    (_, record, index) => {
      if (record.is_duplicated) {
        return (
          <Radio
            value={0}
            checked={!selected[index] && selected[index] !== undefined}
            onClick={radioChangeHandle(index)}
          />
        );
      }

      if (record.is_auto_updated) {
        return (
          <Radio
            value={1}
            checked
            disabled
            onClick={radioChangeHandle(record.parentIndex)}
          />
        );
      }

      if (record.id) {
        return (
          <Radio
            value={1}
            checked={selected[record.parentIndex]}
            onClick={radioChangeHandle(record.parentIndex)}
          />
        );
      }

      return null;
    },
    [selected, radioChangeHandle],
  );

  const onDiscard = () => {
    setPadList(undefined);
    setSelected({});
    setFileList([]);
  };

  const columns = React.useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        render: (dataIndex, row) =>
          dataIndex ? (
            <a
              href={`/dashboard/pad-view/${dataIndex}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {row.created_at ? `#${dataIndex}` : 'Updated'}
            </a>
          ) : (
            'Imported'
          ),
      },
      { title: 'Name', dataIndex: 'name', width: 150 },
      {
        title: 'Category',
        dataIndex: 'category_id',
        width: 150,
        render: (dataIndex) =>
          categories.find((itm) => itm.value === dataIndex)?.label,
      },

      { title: 'ICAO', dataIndex: 'icao', width: 150 },
      {
        title: 'Website',
        dataIndex: 'pad_social',
        width: 200,
        ellipsis: true,
        render: (dataIndex) => (
          <a href={dataIndex?.website}>{dataIndex?.website}</a>
        ),
      },
      {
        title: 'Geolocation',
        children: [
          {
            title: 'Country',
            dataIndex: 'location',
            width: 80,
            render: (dataIndex) => dataIndex.country,
          },
          {
            title: 'Latitude',
            dataIndex: 'location',
            width: 120,
            render: (dataIndex) => dataIndex.latitude,
          },
          {
            title: 'Longitude',
            dataIndex: 'location',
            width: 120,
            render: (dataIndex) => dataIndex.longitude,
          },
        ],
      },
      {
        title: 'Contacts',
        children: [
          {
            title: 'Contact type',
            dataIndex: 'contacts',
            width: 150,
            render: (dataIndex = []) =>
              dataIndex.map((itm, idx) =>
                itm?.contact_type_id ? (
                  <div key={`contact-${itm.contact_type_id || idx}`}>
                    {
                      contactTypes.find(
                        (itm2) => itm2.value === itm.contact_type_id,
                      )?.label
                    }
                  </div>
                ) : (
                  <br key={`contact-${itm.contact_type_id || idx}`} />
                ),
              ),
          },
          {
            title: 'Email',
            dataIndex: 'contacts',
            width: 200,
            ellipsis: true,
            render: (dataIndex = []) =>
              dataIndex.map((itm, idx) =>
                itm.email ? (
                  <div key={`contact-${itm?.email || idx}`}>
                    <a href={`mailto:${itm?.email}`}>{itm?.email}</a>
                  </div>
                ) : (
                  <br key={`contact-${itm?.email || idx}`} />
                ),
              ),
          },
          {
            title: 'Phone',
            dataIndex: 'contacts',
            width: 160,
            render: (dataIndex = []) =>
              dataIndex.map((itm, idx) =>
                itm.phone ? (
                  <div key={`contact-${itm?.phone || idx}`}>
                    <a href={`tel:${itm?.phone}`}>{itm?.phone}</a>
                  </div>
                ) : (
                  <br key={`contact-${itm?.phone || idx}`} />
                ),
              ),
          },
        ],
      },
      {
        title: 'Tags',
        dataIndex: 'tags',
        width: 200,
        render: (dataIndex = []) =>
          dataIndex.map((itm) => <Tag key={`tag-${itm}`}>{itm}</Tag>),
      },
      {
        title: 'Facilities',
        dataIndex: 'facilities',
        width: 200,
        render: (dataIndex = []) =>
          dataIndex.map((itm) => (
            <Tag key={`facility-${itm}`}>
              {typeof itm === 'string'
                ? itm
                : facilities.find((itm2) => itm2.value === itm)?.label}
            </Tag>
          )),
      },
      {
        title: 'Landing advice',
        dataIndex: 'landing_advice',
        width: 200,
        ellipsis: true,
      },
      {
        title: 'Special instructions',
        dataIndex: 'site_information',
        width: 200,
        ellipsis: true,
      },
    ],
    [categories, contactTypes, facilities],
  );

  if (!pads.length) return null;

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <Typography.Title className="mb-0 mr-2" level={4}>
          Resolve conflicts
        </Typography.Title>
        <Tag>
          <Typography.Paragraph
            ellipsis={{
              rows: 1,
            }}
            className="mb-0"
            style={{ maxWidth: 180 }}
            title={fileName}
          >
            {fileName}
          </Typography.Paragraph>
        </Tag>
      </div>
      <Table
        className="mb-4"
        bordered
        size="small"
        scroll={{ x: 'max-content' }}
        pagination={false}
        columns={columns}
        dataSource={pads?.filter((pad) => !pad?.is_auto_updated)}
        rowKey={(record) =>
          `${record.name}-${record.category_id}-${record?.id}`
        }
        expandable={{
          defaultExpandAllRows: true,
        }}
        rowSelection={{
          checkStrictly: true,
          hideSelectAll: true,
          fixed: true,
          renderCell: rowSelectionRenderCell,
        }}
      />
      <div className="d-flex justify-content-between">
        <Button htmlType="button" onClick={onDiscard}>
          Discard
        </Button>
        <Button
          htmlType="button"
          type="primary"
          onClick={handleImport}
          disabled={importLoading}
        >
          Import CSV
        </Button>
      </div>
    </div>
  );
}

PadImportTable.propTypes = {
  pads: PropTypes.array,
  fileName: PropTypes.string,
  setPadList: PropTypes.func,
  setFileList: PropTypes.func,
  handleImport: PropTypes.func,
  importLoading: PropTypes.bool,
  selected: PropTypes.object,
  setSelected: PropTypes.func,
};

PadImportTable.defaultProps = {
  pads: [],
};
