import dayjs from 'dayjs';

export const UKLocation = { lat: 54.4747043, lng: -4.9561606 };

/**
 * User roles
 */
export const userRoles = {
  ROLE_ADMIN: 1,
};

export const dateFormats = ['DD MMM YYYY HH:mm', 'DD MMM YYYY'];

export const imageFormats = ['jpg', 'jpeg', 'png'];

export const priceLevelOptions = [
  {
    value: '$',
    labelSuffix: '(usually $10 and under)',
  },
  {
    value: '$$',
    labelSuffix: '(usually between $10-$25)',
  },
  {
    value: '$$$',
    labelSuffix: '(usually between $25-$45)',
  },
  {
    value: '$$$$',
    labelSuffix: '(usually $50 and up)',
  },
];

export const userType = [
  {
    value: 1,
    label: 'Admin',
  },
  {
    value: 2,
    label: 'Pilot',
  },
  {
    value: 3,
    label: 'Owner',
  },
];

export const userRolesStr = {
  1: 'Admin',
  2: 'Pilot',
  3: 'Owner',
};

export const regex = {
  simplePhone: /[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*/,
  breakLine: /\r\n|\r|\n/g,
};

export const moderationTypes = {
  1: 'Addition',
  2: 'Claim',
  3: 'Update',
  4: 'Reported pad',
  5: 'Moderation requested',
  6: 'Reported review',
  7: 'Reported upload',
  8: 'Converted',
};

export const containerSizes = ['sm', 'lg'];

const utcDateTime = () =>
  dayjs().subtract(dayjs().utcOffset(), 'minute').format('YYYY-MM-DD HH');

const last30days = () =>
  `${dayjs().subtract(1, 'month').toISOString()},${dayjs().toISOString()}`;

const premiumStatusFilters = {
  premium: `renewal_date >= '${utcDateTime()}' AND free_renewal_date IS NULL`,
  free: `((renewal_date IS NULL AND free_renewal_date IS NULL) OR (renewal_date < '${utcDateTime()}'))`,
  fremium: `free_renewal_date = renewal_date AND free_renewal_date >='${utcDateTime()}'`,
};

export const topBarStatsFilters = {
  // Pads Moderated
  'pads.moderated':
    '/dashboard/pad-list?filters=true&column=status.identifier;is_landing_permitted;category.identifier&operator=equals;equals;doesn%27t+equal&keyword=3;1;1',
  // Pads Unmoderated
  'pads.unmoderated':
    '/dashboard/pad-list?filters=true&column=status.identifier&operator=equals&keyword=1',
  // Pads Claimed
  'pads.claimed':
    '/dashboard/pad-list?filters=true&column=owners&operator=has&keyword=1',
  // Free Owners
  'owners.free': `/dashboard/user-list?filters=true&column=role;is_premium&operator=equals;is&keyword=3;${premiumStatusFilters.free}`,
  // Premium Owners
  'owners.premium': `/dashboard/user-list?filters=true&column=role;is_premium&operator=equals;is&keyword=3;${premiumStatusFilters.premium}`,
  // Free Pilots
  'pilots.free': `/dashboard/user-list?filters=true&column=role;is_premium&operator=equals;is&keyword=2;${premiumStatusFilters.free}`,
  // Premium Pilots
  'pilots.premium': `/dashboard/user-list?filters=true&column=role;is_premium&operator=equals;is&keyword=2;${premiumStatusFilters.premium}`,
  // Freemium Pilots
  'pilots.freemium': `/dashboard/user-list?filters=true&column=is_premium;role&operator=is;equals&keyword=${premiumStatusFilters.fremium};2`,
  // Free pilots active 30 days
  'pilots.free.30': `/dashboard/user-list?filters=true&column=role;is_premium;last_action_at&operator=equals;is;between&keyword=2;${
    premiumStatusFilters.free
  };${last30days()}`,
  'pilots.premium.30': `/dashboard/user-list?filters=true&column=role;is_premium;last_action_at&operator=equals;is;between&keyword=2;${
    premiumStatusFilters.premium
  };${last30days()}`,
  'pilots.freemium.30': `/dashboard/user-list?filters=true&column=role;is_premium;last_action_at&operator=equals;is;between&keyword=2;${
    premiumStatusFilters.fremium
  };${last30days()}`,
  deleted_user_accounts:
    '/dashboard/user-list?filters=true&column=is_deleted_account&operator=is&keyword=is_deleted_account IS TRUE',
};


export const cardinalDirections = ['north', 'south', 'east', 'west'];