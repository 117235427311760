import { useToggle } from 'react-use';
import PropTypes from 'prop-types';
import Typography from '../../uiKitComponents/typography';
import condStrings from '../../utils/condStrings';
import Button from '../../uiKitComponents/button';

const TruncatedText = (props) => {
  const [expandable, expandableToggle] = useToggle(true);
  const { className, text, rows, style, ...rest } = props;

  if (!expandable)
    return (
      <>
        <div
          {...rest}
          style={{ whiteSpace: 'pre-wrap', ...style }}
          className={className}
        >
          {text}
        </div>
        <div>
          <Button className="px-0" type="link" onClick={expandableToggle}>
            Show less
          </Button>
        </div>
      </>
    );

  return (
    <Typography.Paragraph
      {...rest}
      style={{ whiteSpace: 'pre-wrap', ...style }}
      className={condStrings('mb-0', className)}
      ellipsis={{ rows, expandable, onExpand: expandableToggle }}
    >
      {text}
    </Typography.Paragraph>
  );
};

TruncatedText.defaultProps = {
  className: '',
  text: '',
  rows: 2,
  style: {},
};

TruncatedText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rows: PropTypes.number,
  style: PropTypes.object,
};

export default TruncatedText;
