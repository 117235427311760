import PropTypes from 'prop-types';
import { Select, Form } from 'antd';
import { useContext } from 'react';
import DataRowContext from '../DataRowContext';

export default function RowControllerSelect(props) {
  const { placeholder, name, options, mode } = props;
  const { label, setCheckedSide, readOnly } = useContext(DataRowContext);

  const onSelectChange = () => {
    setCheckedSide(null);
  };

  return (
    <Form.Item className="data-row-select" label={label} name={name}>
      <Select
        mode={mode}
        disabled={readOnly}
        options={options}
        placeholder={placeholder}
        value={['label']}
        onChange={onSelectChange}
      />
    </Form.Item>
  );
}

RowControllerSelect.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      label: PropTypes.string,
    }),
  ),
  mode: PropTypes.string,
};

RowControllerSelect.defaultProps = {
  placeholder: '',
  name: '',
  options: [],
  mode: '',
};
