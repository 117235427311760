import {
  MinusCircleOutlined,
  PlusOutlined,
  StarFilled,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Popconfirm,
  Row,
  Select,
  Space,
  Tooltip,
} from 'antd';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PhoneInput from '../../../../../components/phoneInput';
import { selectContactTypeOptions } from '../../../../../store/selectors/bootstrapSelector';
import api from '../../../../../utils/appApi';
import getApiErrorMessages from '../../../../../utils/getApiErrorMessages';

import contactStyles from '../contactTab.module.sass';

export default function ContactsList(props) {
  const { pad, editing, onChange } = props;
  const { contactTypes } = useSelector((store) => ({
    contactTypes: selectContactTypeOptions(store, (c) => c.identifier !== 2),
  }));

  const [formattedContacts, setFormattedContacts] = useState({
    submitter: {},
    list: [],
  });

  useEffect(() => {
    if (pad) {
      if (pad.contacts) {
        setFormattedContacts((prevState) => ({
          ...prevState,
          submitter: pad.contacts.filter((c) => c.contact_type_id === 3)?.[0],
          list: pad.contacts.filter((c) => c.contact_type_id !== 3),
        }));
      }
    }
  }, [pad]);

  const removeOwnerHandle = useCallback(
    (userID, typeID, remove = null) => async () => {
      if (pad?.id) {
        if (userID) {
          try {
            const res = await api.pad.deleteOwner(pad?.id, userID, typeID);

            onChange(res.data);

            if (remove) remove();

            return Promise.resolve();
          } catch (error) {
            notification.error({
              message: 'Error',
              description: getApiErrorMessages(error),
            });

            return Promise.reject();
          }
        } else {
          remove();

          return Promise.resolve();
        }
      }

      return Promise.reject();
    },
    [onChange, pad?.id],
  );

  const acceptOwnerHandle = useCallback(
    (userId, id) => () => {
      if (pad?.id)
        api.pad
          .acceptOwner({ pad_id: pad?.id, user_id: userId, contact_id: id })
          .then((res) => onChange(res.data));
    },
    [onChange, pad?.id],
  );

  const getContactTypeById = (contact_type_id) => {
    if (contactTypes && contactTypes.length) {
      return contactTypes.find((itm) => itm.value === contact_type_id);
    }

    return null;
  };

  const inPendingBtn = (itm) => (
    <Popconfirm
      key="accept-owner-btn"
      placement="topRight"
      title="Are you sure you want to confirm this owner?"
      onConfirm={acceptOwnerHandle(itm.user_id, itm.id)}
      onCancel={removeOwnerHandle(itm.id, itm.contact_type_id)}
      okText="Confirm"
      cancelText="Reject"
    >
      <Button
        htmlType="button"
        type="secondary"
        className="pt-0 pb-0 ml-2"
        style={{ height: 20 }}
      >
        In pending
      </Button>
    </Popconfirm>
  );

  if (editing) {
    return (
      <Form.List name="contacts" initialValue={formattedContacts.list}>
        {(fields, { add, remove }) => (
          <Row gutter={[0, 24]}>
            {fields.map((field, i, arr) => (
              <Col span={8} key={field.key}>
                <Space align="baseline" className="position-relative">
                  <Space direction="vertical" size={0}>
                    <Form.Item
                      {...field}
                      label={
                        <>
                          Type
                          {formattedContacts.list[field.name]?.is_premium && (
                            <div className={contactStyles.premiumUserEdit}>
                              <Tooltip title="Premium User" key="title_star">
                                <StarFilled
                                  color="#88173f"
                                  className="ml-1 cursor-pointer accent-icon"
                                />
                              </Tooltip>
                            </div>
                          )}
                        </>
                      }
                      name={[field.name, 'contact_type_id']}
                      fieldKey={[field.fieldKey, 'contact_type_id']}
                      wrapperCol={{ span: 24 }}
                      className="mb-0"
                      key={`${field.key}${i}`}
                    >
                      <Select
                        disabled={formattedContacts.list[field.name]}
                        size="small"
                        placeholder="Type"
                        options={contactTypes}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label="Name"
                      name={[field.name, 'name']}
                      fieldKey={[field.fieldKey, 'name']}
                      wrapperCol={{ span: 24 }}
                      className="mb-0"
                    >
                      <Input size="small" placeholder="Name" />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label="Phone"
                      name={[field.name, 'phone']}
                      fieldKey={[field.fieldKey, 'phone']}
                      wrapperCol={{ span: 24 }}
                      className="mb-0"
                    >
                      <PhoneInput size="small" placeholder="Phone number" />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label="Email"
                      name={[field.name, 'email']}
                      fieldKey={[field.fieldKey, 'email']}
                      wrapperCol={{ span: 24 }}
                      className="mb-0"
                    >
                      <Input size="small" type="email" placeholder="Email" />
                    </Form.Item>
                  </Space>
                  <Tooltip
                    title={
                      arr.length === 1
                        ? 'The pad must have at least one contact'
                        : ''
                    }
                  >
                    <MinusCircleOutlined
                      disabled={arr.length === 1}
                      onClick={
                        arr.length === 1
                          ? () => null
                          : removeOwnerHandle(
                              pad?.contacts[field.name]?.id,
                              pad?.contacts[field.name]?.contact_type_id,
                              () => remove(field.name),
                            )
                      }
                    />
                  </Tooltip>
                </Space>
              </Col>
            ))}
            {pad?.category_id !== 1 && (
              <Col span={8}>
                <Form.Item>
                  <Button
                    size="small"
                    type="dashed"
                    onClick={() => add({})}
                    icon={<PlusOutlined />}
                  >
                    Add contact
                  </Button>
                </Form.Item>
              </Col>
            )}
          </Row>
        )}
      </Form.List>
    );
  }

  return (
    <Space size="large" wrap>
      {formattedContacts.list.map((itm, i) => (
        <div
          key={`contact-${i}`}
          className={`mb-2 ${
            i === 0 && contactStyles.recentContact
          } position-relative`}
        >
          Name: {itm.name} <br />
          Email: <a href={`mailto:${itm.email}`}>{itm.email}</a>
          <br />
          Phone: <a href={`tel:${itm.phone}`}>{itm.phone}</a>
          <br />
          Contact type: {getContactTypeById(itm.contact_type_id)?.label}
          <br />
          {itm.contact_type_id === 4 && !itm?.is_claimed && (
            <>
              Claim:
              {inPendingBtn(itm)}
              <br />
            </>
          )}
          {itm?.is_primary && (
            <span className="font-weight-bold">Primary Contact</span>
          )}
          {itm.is_premium && (
            <div className={contactStyles.premiumUser}>
              <Tooltip title="Premium User" key="title_star">
                <StarFilled
                  color="#88173f"
                  className="cursor-pointer accent-icon"
                />
              </Tooltip>
            </div>
          )}
        </div>
      ))}
    </Space>
  );
}

ContactsList.propTypes = {
  pad: PropTypes.object,
  editing: PropTypes.bool,
  onChange: PropTypes.func,
};

ContactsList.defaultProps = {
  editing: false,
  pad: {},
  onChange: () => null,
};
