export const selectCampaignsOptions = (store) => {
  const campaigns = store.campaigns || [];

  return campaigns.map((itm) => ({
    value: itm.id,
    label: itm.name,
  }));
};

export const getCampaignNameById = (
  id,
  campaigns,
  valueKey = 'id',
  nameKey = 'name',
) => {
  const campaign = campaigns.find((cmpg) => cmpg[valueKey] === id);

  if (campaign) {
    return campaign[nameKey];
  }

  return '';
};
