/**
 * Inserting '//' for url if it not contains http or https
 *
 * @param {string} href
 * @returns {Formatted URL}
 */
const generateExternalLink = (href) => {
  if (typeof href === 'string') {
    if (!href.includes('http')) return `//${href}`;
  }

  return href;
};

export default generateExternalLink;
