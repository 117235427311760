import {
  GET_PAD_SUBMISSIONS,
  GET_LOG_REPORTS,
  GET_DUPLICATE_REPORT,
  CLEAN_REPORTS,
  CLEAN_ON_UNMOUNT_FALSE,
  CLEAN_ON_UNMOUNT_TRUE,
  GET_PAD_STATS_FAV,
  GET_PAD_STATS_VIEWS,
  GET_UNDEFINED_USER,
  GET_PILOTS_COUNTRY,
  GET_PADS_COUNTRY,
  GET_MODERATION_ENGAGEMENT,
  GET_SUBS_REPORT,
  GET_CONCURRENT_LOGINS_REPORT,
  GET_SURVEYS_REPORT,
  GET_PILOT_AIRCRAFTS_REPORT,
} from '../actionTypes';
import api from '../../utils/appApi';

export const getPadSubmissions = (config) => (dispatch) =>
  api.reportsPages
    .getPadSubmissions(config)
    .then((res) => {
      dispatch({ type: GET_PAD_SUBMISSIONS, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const getLogReports = (config) => (dispatch) =>
  api.reportsPages
    .getLogReports(config)
    .then((res) => {
      dispatch({ type: GET_LOG_REPORTS, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const getDuplicateReport = (config) => (dispatch) =>
  api.reportsPages
    .getDuplicateReport(config)
    .then((res) => {
      dispatch({ type: GET_DUPLICATE_REPORT, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const getPadStatsFav = (config) => (dispatch) =>
  api.reportsPages
    .getPadStatsFav(config)
    .then((res) => {
      dispatch({ type: GET_PAD_STATS_FAV, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const getPadStatsViews = (config) => (dispatch) =>
  api.reportsPages
    .getPadStatsViews(config)
    .then((res) => {
      dispatch({ type: GET_PAD_STATS_VIEWS, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const getUserDropOff = (config) => (dispatch) =>
  api.reportsPages
    .getUndefinedUsers(config)
    .then((res) => {
      dispatch({ type: GET_UNDEFINED_USER, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const getPilotsCountry = (config) => (dispatch) => {
  if (!config.to || !config.from) {
    delete config.to;
    delete config.from;
  }

  return api.reportsPages
    .getPilotsCountry(config)
    .then((res) => {
      dispatch({ type: GET_PILOTS_COUNTRY, payload: res.data });
      return Promise.resolve(res.data);
    })
    .catch((err) => Promise.reject(err));
};

export const getPadsCountry = (config) => (dispatch) =>
  api.reportsPages
    .getPadsCountry(config)
    .then((res) => {
      dispatch({ type: GET_PADS_COUNTRY, payload: res.data });
      return Promise.resolve(res.data);
    })
    .catch((err) => Promise.reject(err));

export const getModerationEngagement = (config) => (dispatch) => {
  if (!config.to || !config.from) {
    delete config.to;
    delete config.from;
  }

  return api.reportsPages
    .getModerationEngagement(config)
    .then((res) => {
      dispatch({ type: GET_MODERATION_ENGAGEMENT, payload: res.data });
      return Promise.resolve(res.data);
    })
    .catch((err) => Promise.reject(err));
};

export const getSubsReportList = (config) => (dispatch) => {
  if (!config.from || !config.to) {
    delete config.from;
    delete config.to;
  }

  return api.reportsPages
    .getSubsReport(config)
    .then((res) => {
      dispatch({ type: GET_SUBS_REPORT, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));
};

export const getConcurrentLoginsList = (config) => (dispatch) => {
  if (!config.params.from || !config.params.to) {
    delete config.params.from;
    delete config.params.to;
  }

  return api.reportsPages
    .getConcurrentLogins(config)
    .then((res) => {
      dispatch({ type: GET_CONCURRENT_LOGINS_REPORT, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));
};

export const cleanReports = () => (dispatch) =>
  dispatch({
    type: CLEAN_REPORTS,
  });

export const setCleanOnUnmountTrue = () => (dispatch) =>
  new Promise((resolve, reject) => {
    try {
      dispatch({
        type: CLEAN_ON_UNMOUNT_TRUE,
      });
      return resolve();
    } catch (e) {
      return reject(Error(e));
    }
  });

export const setCleanOnUnmountFalse = () => (dispatch) =>
  new Promise((resolve, reject) => {
    try {
      dispatch({
        type: CLEAN_ON_UNMOUNT_FALSE,
      });
      return resolve();
    } catch (e) {
      return reject(Error(e));
    }
  });

export const getSurveysReports = (config) => (dispatch) =>
  api.reportsPages
    .getSurveysReports(config)
    .then((res) => {
      dispatch({ type: GET_SURVEYS_REPORT, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const getPilotAircraftsReports = (config) => (dispatch) =>
  api.reportsPages
    .getPilotAircraftsReports(config)
    .then((res) => {
      dispatch({ type: GET_PILOT_AIRCRAFTS_REPORT, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));
