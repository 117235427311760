import PropTypes from 'prop-types';

const Summary = (props) => {
  const { data, filterAction, selectedType } = props;

  return (
    <div className="summary-container d-flex flex-wrap align-items-center">
      {data.summary &&
        data?.summary.total.map((itm, index) => (
          <button
            type="button"
            key={index}
            onClick={filterAction(
              itm.meta !== null ? `${itm.meta}` : '',
              itm.display_name,
            )}
            className={`summary-el ${
              selectedType === itm.display_name ? 'isSelected' : ''
            }`}
          >
            <span>{itm.display_name}: </span>
            <span>{itm.amount}</span>
          </button>
        ))}
    </div>
  );
};

Summary.defaultProps = {
  data: undefined,
  filterAction: () => null,
  selectedType: undefined,
};

Summary.propTypes = {
  data: PropTypes.shape({
    summary: PropTypes.shape({
      total: PropTypes.arrayOf(
        PropTypes.shape({
          amount: PropTypes.number,
          display_name: PropTypes.string,
        }),
      ),
    }),
  }),
  filterAction: PropTypes.func,
  selectedType: PropTypes.string,
};

export default Summary;
