import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  UpOutlined,
  DownOutlined,
  FolderOpenOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { Image } from 'antd';
import Space from '../../../../uiKitComponents/space';
import Row from '../../../../uiKitComponents/row';
import Col from '../../../../uiKitComponents/col';
import Rate from '../../../../uiKitComponents/rate';
import List from '../../../../uiKitComponents/list';
import Avatar from '../../../../uiKitComponents/avatar';
import Button from '../../../../uiKitComponents/button';
import Card from '../../../../uiKitComponents/card';
import Tag from '../../../../uiKitComponents/tag';
import Progress from '../../../../uiKitComponents/progress';
import notification from '../../../../uiKitComponents/notification';
import api from '../../../../utils/appApi';
import { dateFormats, userRoles } from '../../../../utils/constants';
import condStrings from '../../../../utils/condStrings';
import toRating from '../../../../utils/toRating';
import FormattedText from '../../../../components/formattedText';
import getApiErrorMessages from '../../../../utils/getApiErrorMessages';
import { ReactComponent as ExLink } from '../../../../assets/icons/ex-link.svg';
import { ReactComponent as HistoryLink } from '../../../../assets/icons/history.svg';

import styles from './reviewsTab.module.sass';

const directions = {
  ASC: 'asc',
  DESC: 'desc',
};

const ReviewsTab = (props) => {
  const { ASC, DESC } = directions;
  const [sortDirection, setSortDirection] = useState(DESC);
  const { reviewsData, setReviewsData } = props;

  const updateRatingData = useCallback(
    (padId) => {
      api.review
        .getFullListByPad(padId)
        .then((res) => setReviewsData(res.data));
    },
    [setReviewsData],
  );

  const toggleReviewMode = useCallback(
    (reviewId) => () => {
      api.review
        .toggleReviewMode(reviewId)
        .then((res) => {
          setReviewsData((prevState) => ({
            ...prevState,
            reviews: prevState?.reviews.map((itm) =>
              itm.id === reviewId ? res.data : itm,
            ),
          }));
          updateRatingData(res.data.pad_id);
        })
        .catch((err) =>
          notification.error({
            message: 'Error',
            description: getApiErrorMessages(err),
          }),
        );
    },
    [setReviewsData, updateRatingData],
  );

  const emptyComments = (
    <div>
      <FolderOpenOutlined style={{ fontSize: 36 }} />
      <p className="ant-empty-description">No comments</p>
    </div>
  );

  const getReviewToggleButton = (item) => {
    const { id, is_reported, is_review_approved } = item;

    if (is_reported) {
      return (
        <Button
          key="hide-show-comment"
          htmlType="button"
          shape="round"
          disabled
        >
          <span className="error">Reported</span>
        </Button>
      );
    }

    return (
      <Button
        key="hide-show-comment"
        htmlType="button"
        shape="round"
        icon={is_review_approved ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        onClick={toggleReviewMode(id)}
      >
        {is_review_approved ? 'Hide' : 'Show'}
      </Button>
    );
  };

  return (
    <div>
      <Row gutter={16} className="mb-4">
        <Col
          span={12}
          className="d-flex justify-content-center align-items-center"
        >
          <Space align="baseline" size="middle">
            <Rate
              value={reviewsData?.stars?.rating}
              disabled
              allowHalf
              style={{ zoom: 2.5 }}
            />
            <span style={{ fontSize: 30 }}>
              {toRating(reviewsData?.stars?.rating)}
            </span>
          </Space>
        </Col>
        <Col span={12}>
          <Space align="baseline" size="middle" className="d-flex">
            <Rate
              value={reviewsData?.stars?.landing}
              disabled
              allowHalf
              className="d-block"
              style={{ zoom: 1.5 }}
            />
            <span style={{ fontSize: '1.2em' }}>
              {toRating(reviewsData?.stars?.landing)} for ease of landings
            </span>
          </Space>
          <Space align="baseline" size="middle" className="d-flex">
            <Rate
              value={reviewsData?.stars?.helifriendliness}
              disabled
              allowHalf
              className="d-block"
              style={{ zoom: 1.5 }}
            />
            <span style={{ fontSize: '1.2em' }}>
              {toRating(reviewsData?.stars?.helifriendliness)} for
              heli-friendliness
            </span>
          </Space>
          <Space align="baseline" size="middle" className="d-flex">
            <Rate
              value={reviewsData?.stars?.food}
              disabled
              allowHalf
              className="d-block"
              style={{ zoom: 1.5 }}
            />
            <span style={{ fontSize: '1.2em' }}>
              {toRating(reviewsData?.stars?.food)} for food
            </span>
          </Space>
          <Space align="baseline" size="middle" className="d-flex">
            <Rate
              value={reviewsData?.stars?.service}
              disabled
              allowHalf
              className="d-block"
              style={{ zoom: 1.5 }}
            />
            <span style={{ fontSize: '1.2em' }}>
              {toRating(reviewsData?.stars?.service)} for service
            </span>
          </Space>
          <div style={{ height: 47 }} className="d-flex align-items-center">
            <Progress
              style={{ width: 197, marginRight: 16 }}
              percent={reviewsData?.stars?.visits}
              showInfo={false}
            />
            <span style={{ fontSize: '1.2em' }}>
              {reviewsData?.stars?.total_ratings} of{' '}
              {reviewsData?.stars?.total_is_visited_again} would visit again
            </span>
          </div>
        </Col>
      </Row>
      <div className="mb-2">
        <Button
          htmlType="button"
          type="text"
          icon={sortDirection === ASC ? <UpOutlined /> : <DownOutlined />}
          onClick={() =>
            setSortDirection((prevState) => (prevState === ASC ? DESC : ASC))
          }
        >
          {sortDirection === DESC ? 'Date Descending' : 'Date Ascending'}
        </Button>
      </div>
      <Card className={styles.commentsCard}>
        <List
          itemLayout="vertical"
          loading={{
            spinning: !reviewsData?.reviews,
            wrapperClassName: styles.listSpinner,
          }}
          locale={{ emptyText: emptyComments }}
          dataSource={
            sortDirection === ASC
              ? [...reviewsData?.reviews].reverse()
              : reviewsData?.reviews
          }
          renderItem={(itm) =>
            !!itm.rating && (
              <List.Item
                className={condStrings(
                  styles.comment,
                  !itm.is_review_approved && 'inactive',
                  !itm.is_reported && 'inavtive',
                )}
                extra={[getReviewToggleButton(itm)]}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar>
                      {itm?.user?.first_name?.substring(0, 1)}
                      {itm?.user?.last_name?.substring(0, 1)}
                    </Avatar>
                  }
                  description={
                    <>
                      {dayjs(itm.created_at).format(dateFormats[0])}
                      <Rate
                        defaultValue={itm.rating}
                        disabled
                        allowHalf
                        className="d-block"
                      />
                    </>
                  }
                  title={
                    <>
                      <Link
                        target="_blank"
                        to={`/dashboard/user-view/${itm.user.id}`}
                        className="d-flex align-items-center"
                      >
                        <span className="icon mr-1">
                          <ExLink width="12" />
                        </span>
                        <span className="text">
                          {itm.user.first_name} {itm.user.last_name}
                        </span>
                        <span className={styles.reviewUpdatedAt}>
                          <HistoryLink />
                          <span>
                            {dayjs(itm.updated_at).format(dateFormats[0])}
                          </span>
                        </span>
                      </Link>
                      {itm.user.is_anonymous && ' (Anonymously)'}
                      {itm.user.role === userRoles.ROLE_ADMIN && (
                        <Tag className="ml-2">Admin</Tag>
                      )}
                    </>
                  }
                />
                <div style={{ paddingLeft: 48 }}>
                  <FormattedText text={itm.comment ? itm.comment : ''} />
                  {!!itm.uploads_count && (
                    <Image.PreviewGroup>
                      <div className="attachments">
                        {itm.uploads.map((u) => (
                          <Image
                            wrapperClassName="attach-item"
                            key={u.id}
                            src={u.file_url}
                            alt={u.title}
                          />
                        ))}
                      </div>
                    </Image.PreviewGroup>
                  )}
                </div>
              </List.Item>
            )
          }
        />
      </Card>
    </div>
  );
};

ReviewsTab.defaultProps = {
  reviewsData: [],
  setReviewsData: () => null,
};

ReviewsTab.propTypes = {
  reviewsData: PropTypes.shape({
    reviews: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        rating: PropTypes.number,
        created_at: PropTypes.string,
        comment: PropTypes.string,
        is_review_approved: PropTypes.boolean,
        is_anonymous: PropTypes.boolean,
        user: PropTypes.shape({
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          role: PropTypes.number,
        }),
      }),
    ),
    stars: PropTypes.shape({
      food: PropTypes.number,
      helifriendliness: PropTypes.number,
      landing: PropTypes.number,
      rating: PropTypes.number,
      service: PropTypes.number,
      total_comments: PropTypes.number,
      total_reviews: PropTypes.number,
      visits: PropTypes.number,
      total_is_visited_again: PropTypes.number,
      total_ratings: PropTypes.number,
    }),
  }),
  setReviewsData: PropTypes.func,
};

export default ReviewsTab;
