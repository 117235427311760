import InitialReportPage from './initialReportPage';

const reportPageProps = {
  sessionStorageName: 'pad-stats-views',
  pageTitle: 'Engagement - All',
  stateName: 'padStatsViews',
  actionName: 'getPadStatsViews',
  default_sort_column: 'id',
};

const PadStatsViews = () => (
  <InitialReportPage reportPageProps={reportPageProps} show_listings />
);

export default PadStatsViews;
