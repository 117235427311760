import { Descriptions, Skeleton, Space } from 'antd';

export default function PadSurveyLoading() {
  return (
    <Descriptions labelStyle={{ width: 120 }} size="small" bordered column={1}>
      <Descriptions.Item
        contentStyle={{ padding: '0.25rem' }}
        labelStyle={{ padding: '10px', fontWeight: 500 }}
        label="General"
      >
        <Skeleton
          active
          title={false}
          paragraph={{ rows: 1, className: 'm-0 pl-1', style: { width: 200 } }}
          className="m-0"
        />
        <Skeleton
          active
          title={false}
          paragraph={{
            rows: 1,
            className: 'm-0 mt-2 pl-1',
            style: { width: 250 },
          }}
          className="m-0"
        />
      </Descriptions.Item>
      <Descriptions.Item
        contentStyle={{ padding: '0.25rem' }}
        labelStyle={{ padding: '10px', fontWeight: 500 }}
        label="Landing"
      >
        <Skeleton
          active
          title={false}
          paragraph={{ rows: 1, className: 'm-0 pl-1', style: { width: 200 } }}
          className="m-0"
        />
        <Skeleton
          active
          title={false}
          paragraph={{
            rows: 1,
            className: 'm-0 mt-2 pl-1',
            style: { width: 250 },
          }}
          className="m-0"
        />
        <Skeleton
          active
          title={false}
          paragraph={{
            rows: 1,
            className: 'm-0 mt-2 pl-1',
            style: { width: 160 },
          }}
          className="m-0"
        />
        <Skeleton
          active
          title={false}
          paragraph={{
            rows: 1,
            className: 'm-0 mt-2 pl-1',
            style: { width: 300 },
          }}
          className="m-0"
        />
      </Descriptions.Item>
      <Descriptions.Item
        contentStyle={{ padding: '0.25rem' }}
        labelStyle={{ padding: '10px', fontWeight: 500 }}
        label="Hazards"
      >
        <Space>
          <Skeleton
            active
            title={false}
            paragraph={{
              rows: 1,
              className: 'm-0 pl-1 li-100',
              style: { width: 30 },
            }}
            className="m-0"
          />
          <Skeleton
            active
            title={false}
            paragraph={{
              rows: 1,
              className: 'm-0 pl-1 li-100',
              style: { width: 69 },
            }}
            className="m-0"
          />
          <Skeleton
            active
            title={false}
            paragraph={{
              rows: 1,
              className: 'm-0 pl-1 li-100',
              style: { width: 40 },
            }}
            className="m-0"
          />
        </Space>
        <Skeleton
          active
          title={false}
          paragraph={{ rows: 1, className: 'm-0  pl-1', style: { width: 200 } }}
          className="m-0"
        />
      </Descriptions.Item>
      <Descriptions.Item
        contentStyle={{ padding: '0.25rem' }}
        labelStyle={{ padding: '10px', fontWeight: 500 }}
        label="Approach"
      >
        <Skeleton
          active
          title={false}
          paragraph={{ rows: 1, className: 'm-0 pl-1', style: { width: 200 } }}
          className="m-0"
        />
        <Skeleton
          active
          title={false}
          paragraph={{
            rows: 1,
            className: 'm-0 mt-2 pl-1',
            style: { width: 250 },
          }}
          className="m-0"
        />
      </Descriptions.Item>
      <Descriptions.Item
        contentStyle={{ padding: '0.25rem' }}
        labelStyle={{ padding: '10px', fontWeight: 500 }}
        label="Facilities"
      >
        <Space>
          <Skeleton
            active
            title={false}
            paragraph={{
              rows: 1,
              className: 'm-0 pl-1 li-100',
              style: { width: 30 },
            }}
            className="m-0"
          />
          <Skeleton
            active
            title={false}
            paragraph={{
              rows: 1,
              className: 'm-0 pl-1 li-100',
              style: { width: 69 },
            }}
            className="m-0"
          />
          <Skeleton
            active
            title={false}
            paragraph={{
              rows: 1,
              className: 'm-0 pl-1 li-100',
              style: { width: 40 },
            }}
            className="m-0"
          />
        </Space>
        <Skeleton
          active
          title={false}
          paragraph={{ rows: 1, className: 'm-0  pl-1', style: { width: 200 } }}
          className="m-0"
        />
        <Skeleton
          active
          title={false}
          paragraph={{
            rows: 1,
            className: 'm-0 mt-2  pl-1',
            style: { width: 180 },
          }}
          className="m-0"
        />
        <Skeleton
          active
          title={false}
          paragraph={{
            rows: 1,
            className: 'm-0 mt-2 pl-1',
            style: { width: 215 },
          }}
          className="m-0"
        />
      </Descriptions.Item>
    </Descriptions>
  );
}
