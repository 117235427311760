import React, { useEffect, useState } from 'react';
import { Input, Select, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import api from '../../../../../utils/appApi';

const PadHiscInput = (props) => {
  const { onChange, pad, defaultValue, setIsHiscErr, form } = props;
  const [hiscCountryCodes, setHiscCountryCodes] = useState([]);
  const [selectedCode, setSelectedCode] = useState(null);
  const [inpValue, setInpValue] = useState('');

  useEffect(() => {
    api.pad.hiscCountryCodes().then((res) => {
      const countryCodes = [];
      Object.entries(res.data).forEach(([k, v]) => {
        countryCodes.push({ label: `${k} - ${v}`, value: k });
      });
      setHiscCountryCodes(countryCodes);

      return res;
    });
  }, []);

  useEffect(() => {
    if (!selectedCode || !inpValue) {
      form.setFieldsValue({ hisc: '' });
    };
  }, [selectedCode, inpValue, form]);

  useEffect(() => {
    if (!pad) return;
    const { hisc } = pad;

    setInpValue(hisc.slice(2, 8));
    setSelectedCode(hisc.slice(0, 2));
  }, [pad]);

  // on change select country code
  useEffect(() => {
    if (!selectedCode) return;
    onChange(`${selectedCode}${inpValue}`);
  }, [selectedCode, inpValue, onChange]);

  // set default value
  useEffect(() => {
    if (!defaultValue) return;

    const code = defaultValue.slice(0, 2);
    const value = defaultValue.slice(2, 8);

    setSelectedCode(code);
    setInpValue(value);
  }, [defaultValue]);

  const onChangeCode = (value) => {
    if (setIsHiscErr) setIsHiscErr(null);
    setSelectedCode(value);
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const handleOnChange = (e) => {
    if (setIsHiscErr) setIsHiscErr(null);
    const value = e?.target?.value;

    // first 4 letters transform to uppercase and rest can any format
    const newValue = value?.slice(0, 4).toUpperCase() + value?.slice(4);
    setInpValue(newValue);
    onChange(`${selectedCode}${newValue}`);
  };

  return (
    <div className="d-flex">
      <Select
        showSearch
        placeholder="hisc country code"
        optionFilterProp="countryCodes"
        onChange={onChangeCode}
        filterOption={filterOption}
        options={hiscCountryCodes}
        value={selectedCode}
        style={{ maxWidth: 170 }}
        allowClear
        onClear={() => {
          setSelectedCode(null);
          setInpValue('');
          // form.setFieldsValue({ hisc: '' });
          // form.setFields([{ name: 'hisc', errors: [] }]);
        }}
      />

      <Tooltip title={!selectedCode ? 'Please select hisc country code' : ''}>
        <span>
          <Input
            style={{ maxWidth: 200 }}
            placeholder="NNNNnn"
            onChange={handleOnChange}
            disabled={!selectedCode}
            value={inpValue}
          />
        </span>
      </Tooltip>
    </div>
  );
};

PadHiscInput.propTypes = {
  onChange: PropTypes.func,
  pad: PropTypes.object,
  defaultValue: PropTypes.string,
  form: PropTypes.object,
  setIsHiscErr: PropTypes.func || null,
};

export default PadHiscInput;
