import PropTypes from 'prop-types';
import { useRef, useEffect, useContext } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ReactComponent as BarsIcon } from '../../assets/icons/bars.svg';
import condStrings from '../../utils/condStrings';
import TableBodyContext from './TableBodyContext';

const ACCEPT_TYPE = 'draggable-row';

export default function TableRow(props) {
  const { className, index, id, children } = props;
  const { moveRowHandler, setIsDragging } = useContext(TableBodyContext);
  const rowRef = useRef();
  const dragRef = useRef();

  const [{ handlerID }, drop] = useDrop({
    accept: ACCEPT_TYPE,
    collect(monitor) {
      return {
        handlerID: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!rowRef.current) return;

      const dragIdx = item.index;
      const hoverIdx = index;

      if (dragIdx === hoverIdx) return;

      const hoverRect = rowRef.current.getBoundingClientRect();
      const hoverMiddleY = (hoverRect.bottom - hoverRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverRect.top;

      if (dragIdx < hoverIdx && hoverClientY < hoverMiddleY) return;
      if (dragIdx > hoverIdx && hoverClientY > hoverMiddleY) return;

      moveRowHandler(dragIdx, hoverIdx);

      item.index = hoverIdx;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ACCEPT_TYPE,
    item: () => ({ id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => setIsDragging(isDragging), [isDragging, setIsDragging]);

  preview(drop(rowRef));
  drag(drop(dragRef));

  return (
    <tr
      className={condStrings(className, isDragging && 'is-dragging')}
      ref={rowRef}
      data-handler-id={handlerID}
    >
      <td className="ant-table-cell drag-cell" ref={dragRef}>
        <button type="button" className="draggable-table-btn">
          <BarsIcon />
        </button>
      </td>
      {children}
    </tr>
  );
}

TableRow.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  index: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
};
