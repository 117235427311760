import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDebounce, useMount, useUpdateEffect } from 'react-use';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import jsonToFormData from 'json-form-data';
import PageHeader from '../../uiKitComponents/pageHeader';
import Form from '../../uiKitComponents/form';
import Input from '../../uiKitComponents/input';
import Select from '../../uiKitComponents/select';
import Checkbox from '../../uiKitComponents/checkbox';
import Row from '../../uiKitComponents/row';
import Col from '../../uiKitComponents/col';
import Divider from '../../uiKitComponents/divider';
import Space from '../../uiKitComponents/space';
import Result from '../../uiKitComponents/result';
import MapWithPads from '../../components/mapWithPads';
import PhoneInput from '../../components/phoneInput';
import api from '../../utils/appApi';
import Button from '../../uiKitComponents/button';
import SearchLocation from '../../components/searchLocation';
import fieldsErrors from '../../utils/fieldsErrors';
import GoogleMapsContext from '../../context/googleMapsContext';
import NumericInput from '../../components/numericInput';
import CheckableTagList from '../../components/checkableTagList';
import IcaoInput from '../../components/icaoInput';
import convertDMS from '../../utils/convertDMS';
import condStrings from '../../utils/condStrings';
import getAddressComponentByName from '../../utils/getAddressComponentByName';
import { dateFormats, UKLocation } from '../../utils/constants';
import PicturesWall from '../../components/picturesWall';
import Alert from '../../uiKitComponents/alert';
import {
  loadingBarDisable,
  loadingBarEnable,
} from '../../store/actions/loadingBarAction';
import history from '../../utils/history';
import useApiErrorsWithAntd from '../../hooks/useApiErrorsWithAntd';
import CheckboxInverted from '../../components/checkboxInverted';
import simulateNativeLink from '../../utils/simulateNativeLink';
import {
  selectCategoryOptions,
  selectContactTypeOptions,
  selectFacilityOptions,
  selectRegionOptions,
  selectTagOptions,
  selectWarningOptions,
} from '../../store/selectors/bootstrapSelector';

import styles from './padCreatePage.module.sass';
import phoneWithPlus from '../../utils/phoneWithPlus';
import { PadCreateLandingFee } from './padCreateLandingFee';
import { Tooltip, Typography, notification } from 'antd';
import { ReactComponent as InfoCircleInfo } from '../../assets/icons/info-circle.svg';
import PadHiscInput from '../../modules/PadView/components/ContactTab/parts/PadHiscInput';
import getApiErrorMessages, { getApiError } from '../../utils/getApiErrorMessages';
import getFieldName from '../../utils/getFieldName';

const formLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 13 },
};

export const formItemVerticalLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const mainInfoLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 11 },
};

const checkboxLayout = {
  ...formLayout.wrapperCol,
  offset: formLayout.labelCol.span,
};

const latValidationRules = [
  {
    validator: (itm, value) =>
      (!isNaN(value) && parseFloat(value) < 91) || isNaN(value) || !value
        ? Promise.resolve()
        : Promise.reject(new Error(fieldsErrors.max(90))),
  },
  {
    validator: (itm, value) =>
      (!isNaN(value) && parseFloat(value) > -91) || isNaN(value) || !value
        ? Promise.resolve()
        : Promise.reject(new Error(fieldsErrors.min(-90))),
  },
];

const lngValidationRules = [
  {
    validator: (itm, value) =>
      (!isNaN(value) && parseFloat(value) < 181) || isNaN(value) || !value
        ? Promise.resolve()
        : Promise.reject(new Error(fieldsErrors.max(180))),
  },
  {
    validator: (itm, value) =>
      (!isNaN(value) && parseFloat(value) > -181) || isNaN(value) || !value
        ? Promise.resolve()
        : Promise.reject(new Error(fieldsErrors.min(-180))),
  },
];

const twitterUsernameHandle = [
  {
    validator: (itm, value) =>
      (value && !value.includes('@')) || !value
        ? Promise.resolve()
        : Promise.reject(new Error(fieldsErrors.withoutAt)),
  },
];

let geocoderService;

const getGeoCodeByLatLng = (lat, lng) =>
  new Promise((resolve, reject) =>
    geocoderService.geocode(
      {
        location: { lat: parseFloat(lat), lng: parseFloat(lng) },
      },
      (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK)
          return resolve(results);
        return reject();
      },
    ),
  );

const PadCreatePage = () => {
  const {
    loadingBarEnabled,
    categories,
    regions,
    tags,
    contactTypes,
    warnings,
    facilities,
  } = useSelector((store) => ({
    loadingBarEnabled: store.loadingBar.enabled,
    categories: selectCategoryOptions(store),
    regions: selectRegionOptions(store),
    tags: selectTagOptions(store),
    contactTypes: selectContactTypeOptions(store),
    warnings: selectWarningOptions(store),
    facilities: selectFacilityOptions(store),
  }));
  const [form] = Form.useForm();
  const { googleMapsIsLoaded } = useContext(GoogleMapsContext);
  const [isHiscErr, setIsHiscErr] = useState(null);
  const [location, setLocation] = useState({});
  const [mapPlaces, setMapPlaces] = useState([]);
  const [success, setSuccess] = useState({
    status: false,
  });
  const [map, setMap] = useState();
  const dispatch = useDispatch();
  const { setApiErrorsToAntdForm } = useApiErrorsWithAntd(form);
  const globalErrors = form.getFieldError('global');

  useMount(() => {
    api.pad.getMapPlaces().then((res) => setMapPlaces(res.data.data));
  });

  useEffect(() => {
    if (googleMapsIsLoaded) {
      // https://developers.google.com/maps/documentation/javascript/geocoding#place-id
      geocoderService = new window.google.maps.Geocoder();
    }
  }, [googleMapsIsLoaded]);

  useDebounce(
    () => {
      if (
        location.getGeoCode &&
        !isNaN(location.latitude) &&
        location.latitude > -91 &&
        location.latitude < 91 &&
        !isNaN(location.longitude) &&
        location.longitude > -181 &&
        location.longitude < 181
      )
        getGeoCodeByLatLng(location.latitude, location.longitude).then(
          (results) => {
            if (results.length) {
              const value = {
                address: results[0].formatted_address,
                latitude: location.latitude,
                longitude: location.longitude,
                country: getAddressComponentByName(
                  results[0].address_components,
                  'country',
                ).long_name,
                country_short: getAddressComponentByName(
                  results[0].address_components,
                  'country',
                ).short_name,
                county: getAddressComponentByName(
                  results[0].address_components,
                  'administrative_area_level_2',
                ).long_name,
              };
              setLocation({
                ...value,
                getGeoCode: false,
                withoutZoom: true,
              });
              form.setFields([
                {
                  name: 'location#name',
                  value,
                },
              ]);
            }
          },
        );
    },
    300,
    [location.latitude, location.longitude, location.getGeoCode],
  );

  useUpdateEffect(() => {
    if (location.country_short) {
      api.region
        .searchRegionByCountry({
          params: { country_code: location.country_short },
        })
        .then((res) =>
          form.setFields([
            {
              name: 'location#region',
              value: regions.find((itm) => itm.label === res.data.region)
                ?.value,
            },
          ]),
        );
    }
  }, [location.country_short]);

  const debouncedLocationInput = debounce((key, value, limit) => {
    if (value >= -limit && value <= limit) {
      setLocation((prevState) => ({
        ...prevState,
        [key]: value,
        getGeoCode: true,
      }));
    }
  }, 300);

  const onFormChange = useCallback(
    (changedValue) => {
      const {
        'location#name': locationName,
        'location#latitude': latitude,
        'location#longitude': longitude,
      } = changedValue;
      if (locationName) {
        setLocation(locationName);
        form.setFields([
          { name: 'location#latitude', value: locationName.latitude },
          { name: 'location#longitude', value: locationName.longitude },
        ]);
      }
      if (latitude) debouncedLocationInput('latitude', latitude, 90);
      if (longitude) debouncedLocationInput('longitude', longitude, 180);
    },
    [form, setLocation, debouncedLocationInput],
  );

  const onSubmit = useCallback(
    (values) => {
      const data = {
        ...values,
        'location#name': values['location#name'].address,
        'location#country': values['location#name'].country_short,
        'location#county': values['location#name'].county,
        'location#region':
          regions.find((itm) => itm.value === values['location#region'])
            ?.label || values['location#region'],
        contacts: values.contacts.map((itm) => ({
          ...itm,
          phone: phoneWithPlus(itm.phone),
        })),
        uploads: values.uploads.map((itm) => itm.originFileObj),
      };

      if (values['pad_social#trip_advisor_rating'])
        data[
          'pad_social#trip_advisor_rating'
        ] = `${values['pad_social#trip_advisor_rating']}`;
      if (values['pad_social#twitter_handle'])
        data[
          'pad_social#twitter_handle'
        ] = `@${values['pad_social#twitter_handle']}`;

      const fd = jsonToFormData(data);

      loadingBarEnable()(dispatch);

      api.pad
        .store(fd)
        .then((res) => {
          loadingBarDisable()(dispatch);
          setSuccess({ status: true, data: res.data.data });
          form.resetFields();
          setLocation({});
          map.panTo(UKLocation);
          map.setZoom(5);
          setMapPlaces((prevState) => [
            ...prevState,
            {
              name: res.data.data.name,
              latitude: parseFloat(res.data.data.location.latitude),
              longitude: parseFloat(res.data.data.location.longitude),
            },
          ]);
        })
        .catch((err) => {
          const errors = getApiErrorMessages(err);

          notification.error({
            message: 'Error',
            description: getApiError(err),
          });

          // if exist hisc error set message
          if (err?.response?.data?.errors?.hisc) {
            setIsHiscErr(err?.response?.data?.errors?.hisc[0]);
          }

          if (Array.isArray(errors)) {
            errors.forEach(([name, msgs]) => {
              if (name.includes('contacts')) {
                form.setFields([
                  {
                    name: getFieldName(name),
                    errors: msgs,
                  },
                ]);
              }
            });
          }
          loadingBarDisable()(dispatch);
          setApiErrorsToAntdForm(err);
        });
    },
    [dispatch, form, map, setApiErrorsToAntdForm, regions],
  );

  const mapOnReadyHandle = useCallback((mapProps, _map) => setMap(_map), []);

  const locationOnDragendHandle = useCallback(
    (data, marker) => {
      const latitude = marker.position.lat();
      const longitude = marker.position.lng();
      setLocation((prevState) => ({
        ...prevState,
        latitude,
        longitude,
        getGeoCode: true,
        withoutZoom: true,
      }));
      form.setFields([
        { name: 'location#latitude', value: latitude },
        { name: 'location#longitude', value: longitude },
      ]);
    },
    [form],
  );

  const mapOnClickHandle = useCallback(
    (mapProps, _map, e) => {
      const latitude = e.latLng.lat();
      const longitude = e.latLng.lng();
      setLocation((prevState) => ({
        ...prevState,
        latitude,
        longitude,
        getGeoCode: true,
        withoutZoom: true,
      }));
      form.setFields([
        { name: 'location#latitude', value: latitude },
        { name: 'location#longitude', value: longitude },
      ]);
    },
    [form],
  );

  const newPadLocation = useMemo(
    () =>
      !isNaN(location.latitude) && !isNaN(location.longitude)
        ? {
          lat: parseFloat(location.latitude),
          lng: parseFloat(location.longitude),
          withoutZoom: !!location.withoutZoom,
        }
        : null,
    [location.latitude, location.longitude, location.withoutZoom],
  );

  const photosGetValueFromEvent = useCallback((e) => e.fileList, []);

  const photosBeforeUpload = useCallback(() => false, []);

  const onBackHandle = useCallback(() => {
    history.goBack();
  }, []);

  const onRemovePhoto = useCallback(
    (file) => {
      const newFileList = form.getFieldValue('uploads');

      form.setFields([
        {
          name: 'uploads',
          value: newFileList.filter((f) => f.uid !== file.uid),
        },
      ]);
    },
    [form],
  );

  const convertFirstSixLettersToUpperCase = (value) => {
    if (value.length > 6) {
      return;
    }
    const newValue = value.toUpperCase();
    form.setFieldsValue({ hisc: newValue });
  };

  return (
    <div>
      <PageHeader
        onBack={onBackHandle}
        className="page-main-header no-children"
        title="Create pad"
        extra={[
          <Button
            key="pad-import-btn"
            onClick={simulateNativeLink('/dashboard/pad-import', () =>
              history.push('/dashboard/pad-import'),
            )}
          >
            Import pad
          </Button>,
        ]}
      />
      <div className="page-main-content">
        <MapWithPads
          onReady={mapOnReadyHandle}
          map={map}
          containerClassName={success.status ? 'd-none' : ''}
          pads={mapPlaces}
          categories={categories}
          locationOnDragend={locationOnDragendHandle}
          location={newPadLocation}
          onClick={mapOnClickHandle}
        />
        {!!globalErrors.length && (
          <Alert
            className="mt-3"
            message="Error"
            description={globalErrors}
            type="error"
            showIcon
          />
        )}
        {success.status && success.data ? (
          <Result
            status="success"
            title={`Pad "${success.data.name}" is successfully created`}
            subTitle={`ID: ${success.data.id}, Created at: ${dayjs(
              success.data.created_at,
            ).format(dateFormats[0])}`}
            extra={[
              <Button
                key="create-another-one"
                type="primary"
                onClick={() => setSuccess({ status: false })}
              >
                Create another one
              </Button>,
            ]}
          />
        ) : (
          <Form
            {...formLayout}
            form={form}
            initialValues={{
              contacts: [null],
              address: {},
              is_landing_permitted: true,
              uploads: [],
              is_api_accessible: true,
            }}
            onValuesChange={onFormChange}
            onFinish={onSubmit}
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col span={10}>
                <Divider>Location</Divider>
                <Form.Item
                  wrapperCol={checkboxLayout}
                  name="is_api_accessible"
                  className="mb-0"
                  valuePropName="checked"
                >
                  <Checkbox>API Access</Checkbox>
                </Form.Item>
                <Form.Item
                  wrapperCol={checkboxLayout}
                  name="is_moderated"
                  className="mb-0"
                  valuePropName="checked"
                >
                  <Checkbox>Moderated</Checkbox>
                </Form.Item>
                <Form.Item
                  wrapperCol={checkboxLayout}
                  name="is_landing_permitted"
                  valuePropName="checked"
                >
                  <CheckboxInverted>Landing not permitted</CheckboxInverted>
                </Form.Item>
                <Form.Item label="Geolocation">
                  <Space align="start">
                    <Form.Item
                      name="location#latitude"
                      rules={latValidationRules}
                      className="mb-0"
                    >
                      <NumericInput placeholder="Latitude" />
                    </Form.Item>
                    <Form.Item
                      name="location#longitude"
                      rules={lngValidationRules}
                      className="mb-0"
                    >
                      <NumericInput placeholder="Longitude" />
                    </Form.Item>
                  </Space>
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues['location#latitude'] !==
                    curValues['location#latitude'] ||
                    prevValues['location#longitude'] !==
                    curValues['location#longitude']
                  }
                >
                  {({ getFieldValue }) => (
                    <Form.Item label="DMS Format">
                      <Input
                        readOnly
                        placeholder="Read only"
                        className={condStrings(
                          styles.dmsInput,
                          'font-weight-semi-bold',
                        )}
                        value={convertDMS(
                          getFieldValue('location#latitude'),
                          getFieldValue('location#longitude'),
                        )}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
                <Form.Item
                  name="location#name"
                  label="Address"
                  trigger="onSelect"
                  validateTrigger="onSelect"
                  rules={[
                    {
                      required: true,
                      message: fieldsErrors.required,
                    },
                  ]}
                >
                  <SearchLocation autoComplete="new-password">
                    <Input.TextArea autoSize />
                  </SearchLocation>
                </Form.Item>
                <Form.Item name="location#region" label="Region">
                  <Select placeholder="Choose" options={regions} />
                </Form.Item>
                <Form.Item
                  name="category_id"
                  label="Category"
                  rules={[
                    {
                      required: true,
                      message: fieldsErrors.required,
                    },
                  ]}
                >
                  <Select placeholder="Choose" options={categories} />
                </Form.Item>
                <Form.Item
                  name="hisc"
                  label={
                    <span className="d-flex align-items-center">
                      <Typography className="mr-1">HISC</Typography>
                      <Tooltip
                        title="Helipaddy International Site Code"
                        className="tooltip-responsive"
                      >
                        <InfoCircleInfo style={{ fontSize: '10px' }} />
                      </Tooltip>
                    </span>
                  }
                  dependencies={['category_id']}
                  rules={[
                    ({ getFieldValue }) => ({
                      required: getFieldValue('category_id') !== 1 && getFieldValue('category_id') !== 7,
                      message: 'Please enter HISC',
                    }),
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (getFieldValue('category_id') !== 1 && getFieldValue('category_id') !== 7) {
                          return Promise.resolve();
                        }
                        if (!value || /^[A-Z]{6}[0-9a-zA-Z]{2}$/.test(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Invalid HISC format. Expected format: ex: NNNNnn'));
                      },
                    }),
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!isHiscErr) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error(isHiscErr));
                      },
                    }),
                  ]}
                >
                  <PadHiscInput
                    onChange={convertFirstSixLettersToUpperCase}
                    setIsHiscErr={setIsHiscErr}
                    form={form}
                  />
                </Form.Item>
              </Col>
              <Col span={14}>
                <Divider>Main information</Divider>
                <Form.Item
                  {...mainInfoLayout}
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: fieldsErrors.required,
                    },
                  ]}
                >
                  <Input placeholder="Pad name" />
                </Form.Item>
                <Form.Item
                  {...mainInfoLayout}
                  label="Tags"
                  name="tags"
                  help={'To create new one just type and press "Enter"'}
                >
                  <Select
                    mode="tags"
                    placeholder="Select existing or create"
                    options={tags}
                  />
                </Form.Item>
                <Divider>Contacts</Divider>
                <Form.List name="contacts">
                  {(fields, { add, remove }) => (
                    <Row gutter={[16, 24]}>
                      {fields.map((field, i, arr) => (
                        <Col span={8} key={field.key}>
                          <Space align="baseline">
                            <Space direction="vertical" size={0}>
                              <Form.Item
                                {...field}
                                name={[field.name, 'contact_type_id']}
                                fieldKey={[field.fieldKey, 'contact_type_id']}
                                wrapperCol={{ span: 24 }}
                                initialValue={1}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Contact type is required',
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Type"
                                  options={contactTypes}
                                  disabled
                                />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                name={[field.name, 'name']}
                                fieldKey={[field.fieldKey, 'name']}
                                wrapperCol={{ span: 24 }}
                              >
                                <Input placeholder="Name" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                name={[field.name, 'phone']}
                                fieldKey={[field.fieldKey, 'phone']}
                                wrapperCol={{ span: 24 }}
                              >
                                <PhoneInput placeholder="Phone number" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                name={[field.name, 'email']}
                                fieldKey={[field.fieldKey, 'email']}
                                wrapperCol={{ span: 24 }}
                              >
                                <Input type="email" placeholder="Email" />
                              </Form.Item>
                            </Space>
                            <MinusCircleOutlined
                              className={arr.length === 1 && 'invisible'}
                              onClick={() => remove(field.name)}
                            />
                          </Space>
                        </Col>
                      ))}
                      {fields.length === 0 && (
                        <Col span={8}>
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add({})}
                              icon={<PlusOutlined />}
                            >
                              Add contact
                            </Button>
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                  )}
                </Form.List>
              </Col>
              <Col span={10}>
                <Divider>Photos</Divider>
                <Form.Item
                  {...formItemVerticalLayout}
                  name="uploads"
                  trigger="onChange"
                  getValueFromEvent={photosGetValueFromEvent}
                  valuePropName="fileList"
                >
                  <PicturesWall
                    multipl
                    limit={4}
                    onRemove={onRemovePhoto}
                    beforeUpload={photosBeforeUpload}
                  />
                </Form.Item>
                <Divider>Social</Divider>
                <Form.Item name="pad_social#website" label="Website">
                  <Input type="url" placeholder="https://website.com" />
                </Form.Item>
                <Form.Item
                  name="pad_social#twitter_handle"
                  label="Twitter handle"
                  rules={twitterUsernameHandle}
                >
                  <Input prefix="@" placeholder="username" />
                </Form.Item>
              </Col>
              <Col span={14}>
                <Divider>Notes & Description</Divider>
                <Form.Item
                  {...formItemVerticalLayout}
                  name="site_information"
                  label="Site Description"
                >
                  <Input.TextArea
                    placeholder="Do NOT include technical landing information here. This is only for interesting or offering information."
                    autoSize={{ minRows: 4 }}
                    showCount
                  />
                </Form.Item>
                <Form.Item
                  {...formItemVerticalLayout}
                  name="landing_advice"
                  label="Landing advice"
                >
                  <Input.TextArea
                    placeholder="ONLY technical landing information here."
                    autoSize={{ minRows: 4 }}
                  />
                </Form.Item>
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) =>
                    getFieldValue('category_id') === 7 ? (
                      <>
                        <Divider>
                          Airfield facilities{' '}
                        </Divider>
                        <Form.Item
                          {...formItemVerticalLayout}
                          label="Click on list item to select"
                          name="facilities"
                        >
                          <CheckableTagList
                            options={facilities}
                            style={{ margin: '0 -7px', display: 'block' }}
                          />
                        </Form.Item>
                      </>
                    ) : (
                      <>
                        <Divider>
                          Warnings{' '}
                        </Divider>
                        <Form.Item
                          {...formItemVerticalLayout}
                          label="Click on list item to select"
                          name="warnings"
                        >
                          <CheckableTagList
                            options={warnings}
                            style={{ margin: '0 -7px', display: 'block' }}
                          />
                        </Form.Item>
                      </>
                    )
                  }
                </Form.Item>
                <Space align="baseline">
                  <PadCreateLandingFee />
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) =>
                      [3, 7].includes(getFieldValue('category_id')) && (
                        <Form.Item
                          {...formItemVerticalLayout}
                          label="Airfield info ICAO code"
                          name="icao"
                          style={{ width: 200 }}
                        >
                          <IcaoInput style={{ width: 65 }} />
                        </Form.Item>
                      )
                    }
                  </Form.Item>
                </Space>
              </Col>
              <Col span={24}>
                <div className="text-right">
                  <Form.Item hidden name="global">
                    <Input />
                  </Form.Item>
                  <Form.Item wrapperCol={{ span: 24 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={loadingBarEnabled}
                    >
                      Create
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </div>
    </div>
  );
};

export default PadCreatePage;
