import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Alert, Descriptions, Form, Input, Select, Space } from 'antd';
import PadViewContext from '../../PadView.context';
import {
  selectCategoryOptions,
  selectRegionOptions,
} from '../../../../store/selectors/bootstrapSelector';
import CheckboxInverted from '../../../../components/checkboxInverted';
import SearchLocation from '../../../../components/searchLocation';
import convertDMS from '../../../../utils/convertDMS';
import NumericInput from '../../../../components/numericInput';
import { latValidationRules, lngValidationRules } from './ContactTab.utils';
import { ContactsListEdit, PadPhotos } from './parts';

export default function ContactTabEdit({
  form,
  setIsChangedOnMap,
  setAnotherSelected,
}) {
  const { categories, regions } = useSelector((store) => ({
    categories: selectCategoryOptions(store),
    regions: selectRegionOptions(store),
  }));
  const { pad, location } = React.useContext(PadViewContext);

  React.useEffect(() => {
    form.setFields([
      { name: 'location#latitude', value: location?.latitude },
      { name: 'location#longitude', value: location?.longitude },
    ]);
  }, [location, form]);

  const onSelectHandler = () => {
    setIsChangedOnMap(false);
    setAnotherSelected(true);
  };

  return (
    <>
      <Form.Item noStyle shouldUpdate>
        {() => {
          const errors = form.getFieldError('global');

          return (
            !!errors.length && (
              <Alert
                message="Error"
                description={errors}
                type="error"
                showIcon
                className="mb-3"
              />
            )
          );
        }}
      </Form.Item>
      <Form.Item hidden name="global">
        <Input />
      </Form.Item>
      <Descriptions
        labelStyle={{ width: 160 }}
        size="small"
        bordered
        column={1}
      >
        <Descriptions.Item label="Pad Name">
          <Form.Item name="name" className="mb-0">
            <Input
              style={{ maxWidth: 200 }}
              size="small"
              placeholder="Pad name"
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label="Category">
          <Form.Item name="category_id" className="mb-0">
            <Select
              size="small"
              style={{ maxWidth: 200 }}
              placeholder="Choose"
              options={categories}
            />
          </Form.Item>
        </Descriptions.Item>
        {pad?.category_id !== 1 && (
          <Descriptions.Item label="Landing not Possible">
            <Form.Item
              name="is_landing_permitted"
              className="mb-0"
              valuePropName="checked"
            >
              <CheckboxInverted />
            </Form.Item>
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Coordinates">
          <div style={{ maxWidth: 400 }}>
            <Form.Item label="Coordinates" className="mb-0">
              <Space align="start">
                <Form.Item
                  name="location#latitude"
                  initialValue={pad?.location?.latitude}
                  rules={latValidationRules}
                  className="mb-0"
                >
                  <NumericInput size="small" placeholder="Latitude" />
                </Form.Item>
                <Form.Item
                  name="location#longitude"
                  initialValue={pad?.location?.longitude}
                  rules={lngValidationRules}
                  className="mb-0"
                >
                  <NumericInput size="small" placeholder="Longitude" />
                </Form.Item>
              </Space>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) =>
                prevValues['location#latitude'] !==
                  curValues['location#latitude'] ||
                prevValues['location#longitude'] !==
                  curValues['location#longitude']
              }
            >
              {({ getFieldValue }) => (
                <Form.Item label="DMS Format" className="mb-0">
                  <Input
                    readOnly
                    placeholder="Read only"
                    className="font-weight-semi-bold"
                    size="small"
                    value={convertDMS(
                      getFieldValue('location#latitude'),
                      getFieldValue('location#longitude'),
                    )}
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item
              name="location#name"
              initialValue={{
                latitude: pad?.location?.latitude,
                longitude: pad?.location?.longitude,
                address: pad?.location?.name,
              }}
              label="Address"
              trigger="onSelect"
              validateTrigger="onSelect"
              className="mb-0"
            >
              <SearchLocation size="small" onSelect={onSelectHandler} />
            </Form.Item>
            <Form.Item
              name="location#region"
              initialValue={pad?.location?.region}
              label="Region"
              className="mb-0"
            >
              <Select size="small" placeholder="Choose" options={regions} />
            </Form.Item>
          </div>
        </Descriptions.Item>
        <Descriptions.Item label="Photos">
          <PadPhotos editing />
        </Descriptions.Item>
        <Descriptions.Item label="Contacts">
          <ContactsListEdit />
        </Descriptions.Item>
      </Descriptions>
    </>
  );
}

ContactTabEdit.propTypes = {
  form: PropTypes.object,
  isChangedOnMap: PropTypes.bool,
  setIsChangedOnMap: PropTypes.func,
  setOldLocation: PropTypes.func,
  setAnotherSelected: PropTypes.func,
};
