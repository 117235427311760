import React, { useEffect } from 'react';
import { Alert, PageHeader, Spin, Tabs, Tooltip, Typography } from 'antd';
import { useSessionStorage } from 'react-use';
import { LoadingOutlined, StarFilled } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import {
  AdminTab,
  ContactTab,
  LandingTab,
  PadViewMap,
  ReviewsTab,
} from './components';
import PadViewContext from './PadView.context';
import api from '../../utils/appApi';
import getApiErrorMessages from '../../utils/getApiErrorMessages';
import { usePadViewLocation } from './hooks';

import './PadView.styles.scss';
import { useDispatch } from 'react-redux';
import { setSelectPadUpload } from '../../store/actions/padListAction';

const antSpinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const initialPadViewState = {
  loading: true,
  error: false,
  activeTabKey: 'contact',
};

export function PadView() {
  const { padId } = useParams();
  const [pad, setPad] = React.useState();
  const [padViewState, setPadViewState] = useSessionStorage(
    'pad-view-state',
    initialPadViewState,
  );
  const {
    location,
    setLocation,
    mapDragPinActive,
    setMapDragPinActive,
  } = usePadViewLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const updatePadViewState = React.useCallback(
    (data) => setPadViewState((prev) => ({ ...prev, ...data })),
    [setPadViewState],
  );

  useEffect(() => {
    updatePadViewState({ error: false });
  }, [padId, updatePadViewState]);

  React.useEffect(() => {
    const search = new URLSearchParams(window.location.search);

    const state = {};

    if (search.has('tab')) state.activeTabKey = search.get('tab');

    if (search.has('reported_id')) {
      state.reported_id = search.get('reported_id');
    }

    if (search.has('edit_mode') && search.has('tab')) {
      state.edit_active = search.get('tab');
    }

    updatePadViewState(state);
  }, [updatePadViewState]);

  React.useEffect(() => {
    api.pad
      .get(padId)
      .then((res) => {
        setPad({
          ...res.data.data,
          editable_contacts: res.data.data.contacts.filter((c) =>
            [1, 4].includes(c?.contact_type_id),
          ),
        });
        setLocation({
          ...res.data.data.location,
          address: res.data.data.location?.name,
        });
      })
      .catch((err) => updatePadViewState({ error: getApiErrorMessages(err) }))
      .finally(() => updatePadViewState({ loading: false }));
  }, [padId, setLocation, updatePadViewState]);

  useEffect(() => {
    if (pad) {
      // set pad upload (pad view item)
      setSelectPadUpload(pad.uploads)(dispatch);
    }
  }, [pad, dispatch]);

  const updatePad = React.useCallback((data = {}) => {
    setPad((prev) => ({ ...prev, ...data }));
  }, []);

  const isPadRecommended = React.useMemo(
    () => pad?.contacts?.find((c) => c.is_premium && c.contact_type_id === 4),
    [pad],
  );

  const PadHeader = React.useMemo(() => {
    const title = [<span key="title_name">{pad?.name}</span>];

    if (isPadRecommended) {
      title.unshift(
        <Tooltip title="Helipaddy Recommended" key="title_star">
          <StarFilled
            color="#88173f"
            className="cursor-pointer accent-icon mr-1"
          />
        </Tooltip>,
      );
    }
    if (pad?.is_private) {
      title.push(<b key="title_private">(Private)</b>);
    }
    if (!pad?.is_phonable && !pad?.is_private) {
      title.push(<b key="title_phonable">(Invalid Phone)</b>);
    }

    return title.map((t) => t);
  }, [isPadRecommended, pad?.is_private, pad?.name, pad?.is_phonable]);

  if (padViewState.error) {
    return (
      <div className="d-flex justify-content-center flex-column py-5 my-5">
        <Alert
          message="Error"
          description={padViewState.error}
          type="error"
          showIcon
        />
      </div>
    );
  }

  return (
    <PadViewContext.Provider
      value={{
        pad,
        updatePad,
        location,
        setLocation,
        mapDragPinActive,
        setMapDragPinActive,
        padViewState,
        updatePadViewState,
      }}
    >
      <div className="page-main-content">
        <PageHeader
          className="page-main-header no-children"
          onBack={history.goBack}
          title={PadHeader}
          subTitle={
            <>
              #
              <Typography.Paragraph
                style={{ color: 'inherit' }}
                className="mb-0 d-inline"
                copyable
              >
                {pad?.id}
              </Typography.Paragraph>
            </>
          }
        />
        <PadViewMap />
        {padViewState.loading || !pad?.id ? (
          <div className="d-flex justify-content-center flex-column py-5 my-5">
            <Spin indicator={antSpinIcon} />
            <h3 className="mb-0 mt-2 text-center">Loading Pad data...</h3>
          </div>
        ) : (
          <Tabs
            className="pad-view-tabs"
            activeKey={padViewState.activeTabKey}
            onChange={(key) => updatePadViewState({ activeTabKey: key })}
            size="large"
          >
            <Tabs.TabPane tab="Contact" key="contact">
              <ContactTab />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Landing" key="landing">
              <LandingTab />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <>
                  Reviews {!!pad?.comments_count && `(${pad.comments_count})`}
                </>
              }
              key="reviews"
            >
              <ReviewsTab />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <>
                  Admin ({pad?.pad_notes.length || 0}){' '}
                  {pad?.resolvable_actions && (
                    <div className="d-inline-block bullet-point ml-2" />
                  )}
                </>
              }
              key="admin"
            >
              <AdminTab />
            </Tabs.TabPane>
          </Tabs>
        )}
      </div>
    </PadViewContext.Provider>
  );
}
