import { useEffect, useState, useCallback } from 'react';
import {
  useMount,
  useSessionStorage,
  useUnmount,
  useUpdateEffect,
} from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { DownOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import PageHeader from '../../uiKitComponents/pageHeader';
import FiltersSection from '../../components/filtersSection';
import Alert from '../../uiKitComponents/alert';
import Button from '../../uiKitComponents/button';
import Dropdown from '../../uiKitComponents/dropdown';
import {
  getAnnoucementsList,
  setCleanOnUnmountFalse,
  setCleanOnUnmountTrue,
  cleanAnnoucementsList,
} from '../../store/actions/announcementsListAction';
import formattingFilters from '../../utils/formattingFilters';
import { getUserFilters } from '../../store/actions/userFiltersAction';
import AnnouncementCreate from './announcementCreateModal';
import DraggableRowsTable from '../../components/draggableRowsTable';
import LoadingBar from '../../components/loadingBar';
import buildTableColumns from '../../utils/buildTableColumns';
import api from '../../utils/appApi';

const initialState = {
  page: 1,
  sort_column: 'id',
  sort_direction: 'descend',
  filters: [{}],
  keyword: undefined,
  filter_info: undefined,
  filtersTrigger: false,
};

const AnnouncementsList = () => {
  const [state, setState] = useSessionStorage(
    'announcements-list-state',
    initialState,
  );
  const {
    page,
    sort_column,
    sort_direction,
    filters,
    keyword,
    filter_info,
    filtersTrigger,
  } = state;
  const [error, setError] = useState();
  const [isDragging, setIsDragging] = useState(false);
  const [dragInited, setDragInited] = useState(false);
  const [loading, setLoading] = useState({
    full: false,
    simple: false,
  });
  const [tableRows, setTableRows] = useState([]);
  const [announcementCreateStatus, setAnnouncementCreateStatus] = useState(
    false,
  );
  const [selectedFilter, setSelectedFilter] = useState('initial');
  const { cleanOnUnmount, announcementsList, savedFilters } = useSelector(
    (store) => ({
      cleanOnUnmount: store.announcementsList.cleanOnUnmount,
      announcementsList: store.announcementsList.payload,
      savedFilters: store.userFilters.payload,
    }),
  );
  const dispatch = useDispatch();

  useMount(() => {
    setCleanOnUnmountTrue()(dispatch);
  });

  useEffect(() => {
    if (announcementsList?.data) {
      setTableRows(announcementsList.data.sort((a, b) => a.order - b.order));
    } else setTableRows([]);
  }, [announcementsList]);

  useEffect(() => {
    const advancedFilters = {};
    filters.forEach((filter) => {
      const temp_filter = formattingFilters(filter, filter.keyword, 'STRING');
      Object.keys(temp_filter).forEach((key) => {
        advancedFilters[`filters[${key}]`] = temp_filter[key];
      });
    });

    setLoading((prevState) => ({ ...prevState, full: true }));

    getAnnoucementsList({
      params: {
        page,
        sort_column,
        sort_direction: sort_direction || 'ascend',
        keyword,
        ...advancedFilters,
      },
    })(dispatch)
      .then(() => setLoading((prevState) => ({ ...prevState, full: false })))
      .catch((err) => {
        setLoading((prevState) => ({ ...prevState, full: false }));
        if (err.response.status === 500) {
          setError({
            status: err.response.status,
            message: err.response.data.message,
          });
          sessionStorage.removeItem('announcements-list-state');
        }
      });
    // eslint-disable-next-line
  }, [page, sort_column, sort_direction, filtersTrigger, dispatch]);

  useUnmount(() => {
    if (cleanOnUnmount) {
      sessionStorage.removeItem('announcements-list-state');
      cleanAnnoucementsList()(dispatch);
    }
  });

  const onSubmitFilters = useCallback(
    (values) =>
      setState((prevState) => ({
        ...prevState,
        ...values,
        filtersTrigger: !prevState.filtersTrigger,
      })),
    [setState],
  );

  const clearFilters = useCallback(
    () =>
      setState((prevState) => ({
        ...initialState,
        filtersTrigger: !prevState.filtersTrigger,
      })),
    [setState],
  );

  const setSavedFilter = (id = null) => {
    if (id) {
      let filter = {};
      if (savedFilters.length) {
        filter = savedFilters.find((itm) => itm.id === id);
      } else {
        filter = savedFilters;
      }
      const rows = Object.keys(filter.filters).map((row) =>
        formattingFilters(row, filter.filters[row], 'OBJECT'),
      );
      onSubmitFilters({
        filters: rows,
        keyword: undefined,
        filter_info: { id, name: filter.name, is_public: filter.is_public },
      });
    }
  };

  useEffect(() => {
    if (selectedFilter) {
      getUserFilters(announcementsList?.entity)(dispatch)
        .then(() => {
          if (selectedFilter !== 'initial') {
            setSavedFilter(selectedFilter);
            setSelectedFilter(undefined);
          }
        })
        .catch((err) => {
          if (err.response?.status === 500) {
            setError({
              status: err.response.status,
              message: err.response.data.message,
            });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announcementsList?.entity, selectedFilter]);

  const menu = (
    <Menu>
      {savedFilters.length &&
        savedFilters.map((filter, idx) => (
          <Menu.Item key={idx} onClick={() => setSavedFilter(filter.id)}>
            {filter.name}
          </Menu.Item>
        ))}
    </Menu>
  );

  const checkIfAnnouncementIsArchived = useCallback(
    (record) => (record.deleted_at ? 'transparent-el' : ''),
    [],
  );

  const updateOrder = async () => {
    setLoading((prevState) => ({ ...prevState, simple: true }));

    await api.announcements.updateOrder({
      announcement: tableRows.map((row, idx) => ({
        id: row.id,
        order: idx + 1,
      })),
    });

    setTableRows(
      tableRows.map((row, idx) => ({
        ...row,
        order: idx + 1,
      })),
    );

    setLoading((prevState) => ({ ...prevState, simple: false }));
  };

  useUpdateEffect(() => {
    if (!isDragging && dragInited) {
      updateOrder();
    }
  }, [isDragging]);

  const onRowOrderUpdate = useCallback((rows) => {
    setTableRows(rows);
    setDragInited(true);
  }, []);

  const statusRender = (rowData) => (rowData ? 'Published' : 'Unpublished');

  if (error)
    return (
      <PageHeader className="page-main-header" title="Reviews">
        <Alert
          showIcon
          type="error"
          message={`Error ${error.status}`}
          description={error.message}
        />
      </PageHeader>
    );

  return (
    <div>
      <PageHeader
        className="page-main-header"
        title="Announcements"
        extra={[
          <Button
            key="announcements-create-btn"
            type="primary"
            onClick={() => setAnnouncementCreateStatus(true)}
          >
            Create Announcement
          </Button>,
          <Dropdown
            overlay={menu}
            trigger={['click']}
            placement="bottomRight"
            disabled={!savedFilters.length}
            key="saved_filters"
          >
            <Button loading={!savedFilters}>
              Saved Filters
              <DownOutlined />
            </Button>
          </Dropdown>,
        ]}
      >
        <div className="filters-container">
          <FiltersSection
            clearFilters={clearFilters}
            onSubmit={onSubmitFilters}
            filters={filters}
            keyword={keyword}
            columnOptions={announcementsList.filterable}
            loading={!announcementsList.filterable}
            filter_info={filter_info}
            filter_columns={announcementsList?.columns}
            entity={announcementsList?.entity}
            onUpdateFilter={setSelectedFilter}
            defaultCollapseStatus={{
              name: 'collapseStatus1',
              status: '1',
            }}
          />
        </div>
      </PageHeader>
      <AnnouncementCreate
        visible={announcementCreateStatus}
        hide={() => setAnnouncementCreateStatus(false)}
        listLength={tableRows.length}
      />
      <div className="page-main-content">
        {loading.simple && <LoadingBar className="page-main-content-bar" />}
        <DraggableRowsTable
          onDragOrderChanged={onRowOrderUpdate}
          setIsDragging={setIsDragging}
          dataSource={tableRows}
          fullLoading={loading.full}
          rowClassName={checkIfAnnouncementIsArchived}
          dataColumns={[
            ...buildTableColumns(announcementsList?.columns, {
              promisedClbk: () => setCleanOnUnmountFalse()(dispatch),
              // order: {
              //   render: (_, rowData) =>
              //      rowData.order
              //   ,
              // },
              is_published_ios: {
                render: statusRender,
              },
              is_published_android: {
                render: statusRender,
              },
            }),
          ]}
        />
      </div>
    </div>
  );
};

export default AnnouncementsList;
