import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { Button, Form, Input, notification } from 'antd';
import {
  EditOutlined,
  EnterOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { updateCampaign } from '../../../store/actions/campaignsAction';
import api from '../../../utils/appApi';
import getApiErrorMessages from '../../../utils/getApiErrorMessages';

import './campaigns.scss';

export default function CampaignName(props) {
  const { campaign } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onEditSuccess = useCallback(
    (updatedCampaign) => {
      dispatch(updateCampaign(updatedCampaign));
    },
    [dispatch],
  );

  const stopEditing = useCallback((event = null) => {
    if (event) {
      if (event.key === 'Escape') {
        setIsEditing(false);
      }
    } else {
      setIsEditing(false);
    }

    window.removeEventListener('keydown', stopEditing);
  }, []);

  const startEditing = useCallback(() => {
    window.addEventListener('keydown', stopEditing);
    setIsEditing(true);
  }, [stopEditing]);

  const onEditHandler = useCallback(
    async (values) => {
      if (values.name !== campaign.name) {
        setEditLoading(true);
        try {
          const res = await api.campaign.update(campaign.id, values);

          onEditSuccess(res.data);
          stopEditing();
        } catch (error) {
          notification.error({
            message: 'Error',
            description: getApiErrorMessages(error),
          });
        } finally {
          setEditLoading(false);
        }
      } else {
        stopEditing();
      }
    },
    [campaign, onEditSuccess, stopEditing],
  );

  if (isEditing) {
    return (
      <Form
        layout="inline"
        onFinish={onEditHandler}
        initialValues={{ name: campaign.name }}
        form={form}
        size="small"
      >
        <Form.Item
          name="name"
          className="mb-0"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Input
            placeholder="Name of the campaign"
            autoComplete="off"
            autoFocus
            onBlur={form.submit}
            disabled={editLoading}
            addonAfter={editLoading ? <LoadingOutlined /> : <EnterOutlined />}
          />
        </Form.Item>
      </Form>
    );
  }

  return (
    <Button
      type="text"
      className="p-0 m-0 h-100"
      onClick={startEditing}
      icon={<EditOutlined />}
    >
      {campaign.name}
    </Button>
  );
}

CampaignName.propTypes = {
  campaign: PropTypes.object.isRequired,
};
