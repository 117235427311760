export const separatedArray = (arr) => {
  const middleElemnt = Math.round((arr.length - 1) / 2);

  const leftArray = [...arr];
  const rightArray = leftArray.splice(0, middleElemnt);

  return [
    { name: 'left', values: leftArray },
    { name: 'right', values: rightArray },
  ];
};
