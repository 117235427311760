import notification from '../uiKitComponents/notification';

/**
 * History push with ctrl + click functionality
 *
 * @param {string} href
 * @returns {function(*): void}
 */
const simulateNativeLink = (href, fn) => (e) => {
  if (e.ctrlKey || e.metaKey || e.which === 2 || e.button === 4) {
    const link = document.createElement('a');
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.href = href;
    link.click();
  } else if (fn && typeof fn === 'function') {
    fn();
  } else {
    notification.error({
      message: 'Error',
      description: 'Something went wrong!',
    });
  }
};

export default simulateNativeLink;
