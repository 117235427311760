import PropTypes from 'prop-types';
import { Col, Progress, Rate, Row, Space } from 'antd';
import toRating from '../../../../../utils/toRating';

export function RatingSection({ reviews }) {
  return (
    <Row gutter={16} className="mb-4">
      <Col
        span={12}
        className="d-flex justify-content-center align-items-center"
      >
        <Space align="baseline" size="middle">
          <Rate
            value={reviews?.stars?.rating}
            disabled
            allowHalf
            style={{ zoom: 2.5 }}
          />
          <span style={{ fontSize: 30 }}>
            {toRating(reviews?.stars?.rating)}
          </span>
        </Space>
      </Col>
      <Col span={12}>
        <Space align="baseline" size="middle" className="d-flex">
          <Rate
            value={reviews?.stars?.landing}
            disabled
            allowHalf
            className="d-block"
            style={{ zoom: 1.5 }}
          />
          <span style={{ fontSize: '1.2em' }}>
            {toRating(reviews?.stars?.landing)} for ease of landings
          </span>
        </Space>
        <Space align="baseline" size="middle" className="d-flex">
          <Rate
            value={reviews?.stars?.helifriendliness}
            disabled
            allowHalf
            className="d-block"
            style={{ zoom: 1.5 }}
          />
          <span style={{ fontSize: '1.2em' }}>
            {toRating(reviews?.stars?.helifriendliness)} for heli-friendliness
          </span>
        </Space>
        <Space align="baseline" size="middle" className="d-flex">
          <Rate
            value={reviews?.stars?.food}
            disabled
            allowHalf
            className="d-block"
            style={{ zoom: 1.5 }}
          />
          <span style={{ fontSize: '1.2em' }}>
            {toRating(reviews?.stars?.food)} for food
          </span>
        </Space>
        <Space align="baseline" size="middle" className="d-flex">
          <Rate
            value={reviews?.stars?.service}
            disabled
            allowHalf
            className="d-block"
            style={{ zoom: 1.5 }}
          />
          <span style={{ fontSize: '1.2em' }}>
            {toRating(reviews?.stars?.service)} for service
          </span>
        </Space>
        <div style={{ height: 47 }} className="d-flex align-items-center">
          <Progress
            style={{ width: 197, marginRight: 16 }}
            percent={reviews?.stars?.visits}
            showInfo={false}
          />
          <span style={{ fontSize: '1.2em' }}>
            {reviews?.stars?.total_ratings} of{' '}
            {reviews?.stars?.total_is_visited_again} would visit again
          </span>
        </div>
      </Col>
    </Row>
  );
}

RatingSection.propTypes = {
  reviews: PropTypes.object,
};
