import { notification } from 'antd';
import React from 'react';
import api from '../../../../utils/appApi';
import getApiErrorMessages from '../../../../utils/getApiErrorMessages';
import PadViewContext from '../../PadView.context';
import { CommentsSection, RatingSection } from './parts';

export function ReviewsTab() {
  const { pad, updatePad } = React.useContext(PadViewContext);
  const [reviews, setReviews] = React.useState({});

  const updateRatingData = React.useCallback(() => {
    api.review.getFullListByPad(pad?.id).then((res) => {
      setReviews(res.data);
      updatePad({
        comments_count: res.data.reviews.filter((r) => r.is_review_approved)
          .length,
      });
    });
  }, [pad?.id, updatePad]);

  React.useEffect(updateRatingData, [updateRatingData]);

  const toggleReviewMode = React.useCallback(
    (reviewId) => () => {
      api.review
        .toggleReviewMode(reviewId)
        .then(() => {
          updateRatingData();
        })
        .catch((err) =>
          notification.error({
            message: 'Error',
            description: getApiErrorMessages(err),
          }),
        );
    },
    [updateRatingData],
  );

  return (
    <>
      <RatingSection reviews={reviews} />
      <CommentsSection reviews={reviews} toggleReviewMode={toggleReviewMode} />
    </>
  );
}
