import React from 'react';
import { Button, notification, Tooltip } from 'antd';
import PadViewContext from '../../../PadView.context';
import { ReactComponent as PhoneSlashIcon } from '../../../../../assets/icons/phone-slash.svg';
import { ReactComponent as ArchivedIcon } from '../../../../../assets/svg/archived.svg';
import api from '../../../../../utils/appApi';
import getApiErrorMessages from '../../../../../utils/getApiErrorMessages';

export function PadTitle() {
  const { pad, updatePad } = React.useContext(PadViewContext);
  const [loading, setLoading] = React.useState(false);

  const saveAsPrivateHanlder = React.useCallback(() => {
    setLoading(true);
    api.pad
      .saveAsPrivate(pad?.id)
      .then((res) => {
        updatePad({ is_private: true });

        notification.success({
          message: 'Success',
          description: 'Done!',
        });
        return Promise.resolve(res);
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(err),
        });
      })
      .finally(() => setLoading(false));
  }, [pad, updatePad]);

  const privateActionHidden = React.useMemo(
    () =>
      [
        pad?.status_id !== 1,
        pad?.deleted_at,
        pad?.category_id === 1,
        !pad?.submitters?.length,
        !pad?.submitters?.some((submitter) => submitter.is_submitter_pilot),
      ].some(Boolean),
    [pad],
  );

  return (
    <div className="d-flex align-items-center">
      {!!pad?.deleted_at && (
        <Tooltip title="Pad is archived">
          <span className="pad-name_prefix d-inline-flex align-items-center">
            <ArchivedIcon width="15" height="15" />
          </span>
        </Tooltip>
      )}
      <span className="pad-name">{`#${pad?.id} ${pad?.name}`}</span>
      {!pad?.is_phonable && (
        <PhoneSlashIcon width="12" className="ml-1" style={{ minWidth: 12 }} />
      )}
      {!privateActionHidden && (
        <span className="pad-name_suffix ml-3">
          <Button
            size="small"
            disabled={pad?.is_private}
            loading={loading}
            onClick={saveAsPrivateHanlder}
          >
            {pad?.is_private ? 'Private' : 'Save as Private'}
          </Button>
        </span>
      )}
    </div>
  );
}
