import {
  Alert,
  Button,
  Form,
  Input,
  PageHeader,
  Select,
  Table,
  DatePicker,
} from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMount, useSessionStorage, useUnmount } from 'react-use';
import * as reports from '../../store/actions/reportPagesAction';
import {
  selectCountriesOptions,
  selectUSStatesOptions,
} from '../../store/selectors/bootstrapSelector';
import simulateNativeLink from '../../utils/simulateNativeLink';

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';

const initialState = {
  page: 1,
  sort_column: 'public_pads_moderated',
  sort_direction: 'descend',
  filtersTrigger: false,
  keyword: '',
  country: '',
  us_state: '',
  dates: { from: { string: '', locale: '' }, to: { string: '', locale: '' } },
};

const ConcurrentLoginsPage = () => {
  const [state, setState] = useSessionStorage(
    'concurrent-logins',
    initialState,
  );

  const {
    page,
    sort_column,
    sort_direction,
    keyword,
    country,
    us_state,
    filtersTrigger,
    dates,
  } = state;

  const [error, setError] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [isUSSelected, setIsUSSelected] = React.useState(false);

  const { cleanOnUnmount, concurrentLogins, countries, USStates } = useSelector(
    (store) => ({
      cleanOnUnmount: store.reportPages.cleanOnUnmount,
      concurrentLogins: store.reportPages.payload.concurrentLogins,
      countries: selectCountriesOptions(store),
      USStates: selectUSStatesOptions(store),
    }),
  );

  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    const fetchPadSubmissions = () => {
      setLoading(true);

      reports
        .getConcurrentLoginsList({
          params: {
            page: 1,
            sort_column,
            sort_direction: sort_direction || 'ascend',
            keyword,
            country,
            state: us_state,
            from: dates.from.string,
            to: dates.to.string,
          },
        })(dispatch)
        .catch((err) => {
          if (err.response.status === 500) {
            setError({
              status: err.response.status,
              message: err.response.data.message,
            });
            sessionStorage.removeItem('pad-submissions');
          }
        })
        .finally(() => setLoading(false));
    };

    fetchPadSubmissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersTrigger, dispatch, keyword, country, us_state]);

  const onTableChange = React.useCallback(
    (pagination, _, sorter, extra) => {
      const newState = {};
      if (extra.action === 'paginate') newState.page = pagination.current;
      else if (extra.action === 'sort') {
        newState.sort_column = sorter.field;
        newState.sort_direction = sorter.order;
      }
      setState((prevState) => ({ ...prevState, ...newState }));
    },
    [setState],
  );

  const onSubmitFilters = React.useCallback(
    (values) => {
      const mockValues = {
        keyword: null,
        country: null,
        us_state: null,
      };
      const data = { ...mockValues, ...values };

      data.dates = {
        from: { string: '', locale: '' },
        to: { string: '', locale: '' },
      };

      const { range } = data;

      if (range && range.length && range.every(Boolean)) {
        const date_from = dayjs(range[0].$d).format('DD-MM-YYYY');
        const date_to = dayjs(range[1].$d).format('DD-MM-YYYY');

        data.dates = {
          from: { string: date_from, locale: range[0] },
          to: { string: date_to, locale: range[1] },
        };
      }

      setState((prevState) => ({ ...prevState, ...data }));
    },
    [setState],
  );

  useMount(() => {
    reports.setCleanOnUnmountTrue()(dispatch);

    if (country === 'US') setIsUSSelected(true);
  });

  useUnmount(() => {
    if (cleanOnUnmount) {
      sessionStorage.removeItem('concurrent-logins');
      reports.cleanReports()(dispatch);
    }
  });

  const goTo = React.useCallback(
    (path) =>
      simulateNativeLink(path, () =>
        reports
          .setCleanOnUnmountFalse()(dispatch)
          .then(() => history.push(path)),
      ),
    [dispatch, history],
  );

  const getMomentDate = (date) => {
    if (date) return moment(date, dateFormat);

    return null;
  };

  const columns = React.useMemo(
    () => [
      {
        dataIndex: 'full_name',
        title: 'Name',
        render: (data, rowData) => (
          <Button
            type="link"
            onClick={goTo(`/dashboard/user-view/${rowData.id}`)}
          >
            {data}
          </Button>
        ),
      },
      {
        dataIndex: 'email',
        title: 'Email',
      },
      {
        dataIndex: 'username',
        title: 'Username',
      },
      {
        dataIndex: 'active_tokens',
        title: 'Active Tokens',
        sortDirections: ['descend', 'ascend', 'descend'],
        sortOrder: sort_column === 'active_tokens' && sort_direction,
        sorter: (a, b) => a.active_tokens - b.active_tokens,
      },
    ],
    [goTo, sort_column, sort_direction],
  );

  if (error) {
    return (
      <PageHeader className="page-main-header" title="Concurrent Logins">
        <Alert
          showIcon
          type="error"
          message={`Error ${error.status}`}
          description={error.message}
        />
      </PageHeader>
    );
  }

  return (
    <div>
      <PageHeader className="page-main-header" title="Concurrent Logins">
        <div className="filters-container pad-submissions-filters">
          <Form
            layout="inline"
            onFinish={onSubmitFilters}
            initialValues={{
              keyword,
              country: country || null,
              us_state: us_state || null,
            }}
          >
            <Form.Item label="Name" name="keyword">
              <Input placeholder="John Doe" />
            </Form.Item>
            <Form.Item
              name="range"
              label="Select Dates"
              initialValue={[
                getMomentDate(dates.from?.locale),
                getMomentDate(dates.to?.locale),
              ]}
            >
              <RangePicker
                format="DD/MM/YYYY"
                disabledDate={(date) =>
                  date.isBefore(moment().subtract(3, 'month')) ||
                  date.isAfter(moment())
                }
              />
            </Form.Item>
            <div>
              <Form.Item label="Country" name="country">
                <Select
                  placeholder="United Kingdom"
                  options={countries}
                  optionFilterProp="label"
                  onChange={(value) =>
                    value === 'US'
                      ? setIsUSSelected(true)
                      : setIsUSSelected(false)
                  }
                  showSearch
                />
              </Form.Item>
              {isUSSelected && (
                <Form.Item label="State" name="us_state" className="mt-2">
                  <Select
                    placeholder="Alaska"
                    options={USStates}
                    optionFilterProp="label"
                    showSearch
                  />
                </Form.Item>
              )}
            </div>
            <Button htmlType="submit" type="primary">
              Search
            </Button>
          </Form>
        </div>
      </PageHeader>
      <div className="page-main-content pad-submissions">
        <Table
          columns={columns}
          rowKey="id"
          dataSource={concurrentLogins || []}
          loading={loading}
          onChange={onTableChange}
          pagination={{
            position: ['topRight', 'bottomRight'],
            showSizeChanger: false,
            showQuickJumper: true,
            pageSize: 25,
            total: concurrentLogins?.length,
            current: page,
            showTotal: (total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total} listings`,
          }}
        />
      </div>
    </div>
  );
};

export default ConcurrentLoginsPage;
