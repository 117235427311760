import {
  ANNOUNCEMENTS_LIST_GET,
  ANNOUNCEMENTS_LIST_CLEAN,
  CLEAN_ON_UNMOUNT_FALSE,
  CLEAN_ON_UNMOUNT_TRUE,
  UPDATE_LOCAL_ANNOUNCEMENT_IN_LIST,
} from '../actionTypes';
import api from '../../utils/appApi';

export const getAnnoucementsList = (config) => (dispatch) =>
  api.announcements
    .get(config)
    .then((res) => {
      dispatch({ type: ANNOUNCEMENTS_LIST_GET, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

/**
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export const updateLocalAnnoucementInList = (id, data) => (dispatch) =>
  new Promise((resolve, reject) => {
    try {
      dispatch({
        type: UPDATE_LOCAL_ANNOUNCEMENT_IN_LIST,
        payload: {
          id,
          data,
        },
      });
      return resolve();
    } catch (error) {
      return reject(error);
    }
  });

export const cleanAnnoucementsList = () => (dispatch) =>
  dispatch({
    type: ANNOUNCEMENTS_LIST_CLEAN,
  });

export const setCleanOnUnmountTrue = () => (dispatch) =>
  new Promise((resolve, reject) => {
    try {
      dispatch({
        type: CLEAN_ON_UNMOUNT_TRUE,
      });
      return resolve();
    } catch (e) {
      return reject(Error(e));
    }
  });

export const setCleanOnUnmountFalse = () => (dispatch) =>
  new Promise((resolve, reject) => {
    try {
      dispatch({
        type: CLEAN_ON_UNMOUNT_FALSE,
      });
      return resolve();
    } catch (e) {
      return reject(Error(e));
    }
  });
