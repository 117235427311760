import { useCallback, useContext, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Map as GoogleMap, Marker } from 'google-maps-react';
import MarkerCluster from './markerCluster';
import GoogleMapsContext from '../../context/googleMapsContext';
import aerialLandmarkIcon from '../../assets/padsCategories/aerial_landmark.png';
import eatIcon from '../../assets/padsCategories/eat.png';
import stayIcon from '../../assets/padsCategories/stay.png';
import landingSiteIcon from '../../assets/padsCategories/landing_site.png';
import aerodromeIcon from '../../assets/padsCategories/aerodrome.png';
import { UKLocation } from '../../utils/constants';

const padsMarkersEventHandlers = {
  click: ({ infoWindow, entry, map: _map }) => {
    [...document.getElementsByClassName('gm-ui-hover-effect')].forEach((e) =>
      e.click(),
    );

    infoWindow.open(_map, entry);
  },
};

const MapRoot = memo((props) => {
  const {
    pads,
    location,
    locationOnDragend,
    map,
    clustererConfig,
    readOnly,
    onClick,
    center,
    zoom,
    showLocationPin,
    urlTarget,
    ...rest
  } = props;

  useEffect(() => {
    if (location && map) {
      if (!location.withoutZoom) map.setZoom(17);
      if (!location.withoutPan) map.panTo(location);
    }
  }, [location, map]);

  useEffect(() => {
    if (center && map) {
      if (center.lat && center.lng) {
        map.setCenter(center);
        map.setZoom(5);
      }
    }
  }, [center, map]);

  const onClickHandle = useCallback(
    (...args) => (readOnly ? null : onClick(...args)),
    [readOnly, onClick],
  );

  return (
    <GoogleMap
      minZoom={5}
      zoom={zoom}
      clickableIcons
      initialCenter={UKLocation}
      {...rest}
      google={window.google}
      onClick={onClickHandle}
    >
      <MarkerCluster
        pads={pads}
        markerEventHandlers={padsMarkersEventHandlers}
        urlTarget={urlTarget}
        {...clustererConfig}
      />
      {showLocationPin && readOnly && (
        <Marker
          key="not-draggable-marker"
          position={
            location ||
            (map &&
              !!map.getBounds() && {
                lat: map.getBounds().getCenter().lat(),
                lng: map.getBounds().getCenter().lng(),
              }) ||
            null
          }
        />
      )}
      {showLocationPin && !readOnly && (
        <Marker
          key="draggable-marker"
          onDragend={locationOnDragend}
          position={
            location ||
            (map &&
              !!map.getBounds() && {
                lat: map.getBounds().getCenter().lat(),
                lng: map.getBounds().getCenter().lng(),
              }) ||
            null
          }
          draggable
        />
      )}
    </GoogleMap>
  );
});

MapRoot.defaultProps = {
  location: null,
  locationOnDragend: () => null,
  pads: [],
  categories: [],
  map: null,
  clustererConfig: {},
  readOnly: false,
  showLocationPin: true,
  onClick: () => null,
  center: null,
  zoom: 7,
};

MapRoot.propTypes = {
  location: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
    withoutZoom: PropTypes.bool,
    withoutPan: PropTypes.bool,
  }),
  locationOnDragend: PropTypes.func,
  pads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      category_id: PropTypes.number,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
  ),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
  ),
  map: PropTypes.object,
  clustererConfig: PropTypes.object,
  readOnly: PropTypes.bool,
  showLocationPin: PropTypes.bool,
  onClick: PropTypes.func,
  center: PropTypes.object,
  zoom: PropTypes.number,
  urlTarget: PropTypes.string,
};

const MapWithPads = (props) => {
  const {
    containerClassName,
    mapStyle,
    height,
    errorContainerStyle,
    map,
    displayCategories,
    urlTarget,
    ...rest
  } = props;
  const { googleMapsIsLoaded, googleMapsError } = useContext(GoogleMapsContext);

  return (
    <div className={containerClassName}>
      {displayCategories && (
        <div className="d-flex mb-2">
          <div className="d-flex align-items-center mr-2">
            <img src={stayIcon} alt="stayIcon" className="mr-2" />
            <span className="font-weight-semi-bold">Stay</span>
          </div>
          <div className="d-flex align-items-center mr-2">
            <img src={eatIcon} alt="eatIcon" className="mr-2" />
            <span className="font-weight-semi-bold">Eat</span>
          </div>
          <div className="d-flex align-items-center mr-2">
            <img src={aerodromeIcon} alt="aerodromeIcon" className="mr-2" />
            <span className="font-weight-semi-bold">Aerodrome</span>
          </div>
          <div className="d-flex align-items-center mr-2">
            <img src={landingSiteIcon} alt="landingSiteIcon" className="mr-2" />
            <span className="font-weight-semi-bold">Landing Site</span>
          </div>
          <div className="d-flex align-items-center">
            <img
              src={aerialLandmarkIcon}
              alt="aerialLandmarkIcon"
              className="mr-2"
            />
            <span className="font-weight-semi-bold">APOI</span>
          </div>
        </div>
      )}
      {googleMapsIsLoaded ? (
        <MapRoot
          {...rest}
          containerStyle={{
            position: 'relative',
            width: '100%',
            ...mapStyle,
            height,
          }}
          map={map}
          urlTarget={urlTarget}
        />
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ ...errorContainerStyle, height }}
        >
          {googleMapsError || null}
        </div>
      )}
    </div>
  );
};

MapWithPads.defaultProps = {
  containerClassName: '',
  mapStyle: {},
  height: 400,
  errorContainerStyle: {},
  location: null,
  pads: [],
  map: null,
  displayCategories: true,
  urlTarget: '/dashboard/pad-view',
};

MapWithPads.propTypes = {
  containerClassName: PropTypes.string,
  mapStyle: PropTypes.object,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errorContainerStyle: PropTypes.object,
  location: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
    withoutZoom: PropTypes.bool,
    withoutPan: PropTypes.bool,
  }),
  pads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      category_id: PropTypes.number,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
  ),
  map: PropTypes.object,
  displayCategories: PropTypes.bool,
  urlTarget: PropTypes.string,
};

export default memo(MapWithPads);
