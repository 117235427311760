import { useState, useCallback, useMemo, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useMount, useUpdateEffect } from 'react-use';
import {useDispatch, useSelector} from 'react-redux';
import api from '../../utils/appApi';
import PageHeader from '../../uiKitComponents/pageHeader';
import Tabs from '../../uiKitComponents/tabs';
import Typography from '../../uiKitComponents/typography';
import history from '../../utils/history';
import InfoTab from './tabs/infoTab';
import PadsTab from './tabs/padsTab';
import LogsTab from './tabs/logsTab';
import { getCampaings } from '../../store/actions/campaignsAction';
import { selectUserTagOptions } from "../../store/selectors/bootstrapSelector";

import styles from '../PadViewPage/padViewPage.module.sass';

const UserViewPage = () => {
  const [activeTab, setActiveTab] = useState('info');
  const [activeFilterTab, setActiveFilterTab] = useState(undefined);
  const [info, setInfo] = useState();
  const [infoEditMode, setInfoEditMode] = useState(false);
  const { userId } = useParams();
  const dispatch = useDispatch();
  const userTags = useSelector((store) => ({
    userTags: selectUserTagOptions(store),
  }));

  const fetcher = useCallback(async () => {
    await dispatch(getCampaings());
  }, [dispatch]);

  useMount(fetcher);

  const useQuery = useLocation();

  useEffect(() => {
    const search = new URLSearchParams(useQuery.search);

    if (search.has('tab')) {
      setActiveTab(search.get('tab'));
    }

    if (search.has('filter_id')) {
      setActiveFilterTab(search.get('filter_id'));
    }
  }, [useQuery]);

  useMount(() => {
    api.users.get(userId).then((res) => {
      setInfo(res.data);
    });
  });

  const onBackHandle = useCallback(() => {
    history.goBack();
  }, []);

  const tabOnChange = useCallback((key) => {
    setActiveTab(key);
  }, []);

  useUpdateEffect(() => {
    if (activeTab !== 'info' && infoEditMode) {
      setInfoEditMode(false);
    }
  }, [activeTab, infoEditMode]);

  const handleInfoOnChange = useCallback((newInfo) => {
    setInfo(newInfo);
  }, []);

  const tabs = useMemo(
    () => [
      {
        tab: 'Info',
        key: 'info',
        content: (
          <InfoTab
            info={info}
            editMode={infoEditMode}
            setEditMode={setInfoEditMode}
            infoOnChange={handleInfoOnChange}
            userTags={userTags}
          />
        ),
      },
      {
        tab: `Pads (${info?.pads_count || 0})`,
        key: 'pads',
        content: <PadsTab data={{ filter: activeFilterTab, id: info?.id }} />,
      },
      {
        tab: 'Activity',
        key: 'activity',
        content: <LogsTab data={userId} />,
      },
    ],
    [
      info,
      infoEditMode,
      setInfoEditMode,
      handleInfoOnChange,
      userId,
      activeFilterTab,
      userTags,
    ],
  );

  return (
    <div>
      <PageHeader
        className="page-main-header no-children"
        onBack={onBackHandle}
        title={`${info?.first_name} ${info?.last_name}`}
        subTitle={
          <>
            #
            <Typography.Paragraph
              style={{ color: 'inherit' }}
              className="mb-0 d-inline"
              copyable
            >
              {info?.id}
            </Typography.Paragraph>
          </>
        }
      />
      <div className="page-main-content">
        <Tabs
          activeKey={activeTab}
          onChange={tabOnChange}
          className={styles.tabs}
          size="large"
        >
          {tabs.map((itm) => (
            <Tabs.TabPane tab={itm.tab} key={itm.key} className="flex-grow-1">
              {activeTab === itm.key && itm.content}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default UserViewPage;
