import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSessionStorage, useUnmount } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import MapWithPads from '../../components/mapWithPads';
import {
  cleanPadListMap,
  getPadListMap,
  getPadListMapConsumerSubmitted,
  getPadListMapLandingNotPossible,
  getPadListMapUnmoderated,
  getPadListMapPrivate,
  getPadListMapArchived,
  setCleanOnUnmountFalse,
  setCleanOnUnmountTrue,
  getPadListMapModerated,
  getPadListMapInvalidPhone,
} from '../../store/actions/padListMapAction';

const actions = {
  getPadList: getPadListMap,
  getPadListConsumerSubmitted: getPadListMapConsumerSubmitted,
  getPadListLandingNotPossible: getPadListMapLandingNotPossible,
  getPadListUnmoderated: getPadListMapUnmoderated,
  getPadListModerated: getPadListMapModerated,
  getPadListPrivate: getPadListMapPrivate,
  getPadListArchived: getPadListMapArchived,
  getPadListInvalidPhone: getPadListMapInvalidPhone,
};

const initialState = {
  url: null,
  params: {
    page: 1,
    sort_column: 'id',
    sort_direction: 'descend',
  },
};

const PadListMap = (props) => {
  const { action, status, categories, onDisableAction, cleanOnUnmount } = props;
  const [state, setState] = useSessionStorage(
    'pad-list-map-state',
    initialState,
  );
  const [map, setMap] = useState();
  const [location, setLocation] = useState({});
  const [mapPlaces, setMapPlaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const mapContainer = useRef();

  const { padListMap } = useSelector((store) => ({
    padListMap: store.padListMap.payload,
  }));

  const dispatch = useDispatch();

  const getPadsFromMap = useCallback(
    (opt) => {
      if (opt.url && actions[opt.url]) {
        setLoading(true);
        actions[opt.url]({ params: opt.params })(dispatch)
          .catch((err) => {
            if (err.response.status === 500) {
              sessionStorage.removeItem('pad-list-map-state');
            }
          })
          .finally(() => setLoading(false));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    setState(action);

    if (JSON.stringify(action) !== JSON.stringify(state)) {
      getPadsFromMap(action);
    }
  }, [action, getPadsFromMap, setState, state]);

  useUnmount(() => {
    if (cleanOnUnmount) {
      sessionStorage.removeItem('pad-list-map-state');
      cleanPadListMap()(dispatch);
    }
  });

  useEffect(() => {
    if (cleanOnUnmount) setCleanOnUnmountTrue()(dispatch);
    else setCleanOnUnmountFalse()(dispatch);
  }, [cleanOnUnmount, dispatch]);

  const disableMap = useCallback(() => {
    setLocation({});
    onDisableAction();
  }, [onDisableAction]);

  useEffect(() => {
    if (!status) {
      disableMap();
    }
  }, [disableMap, status]);

  useEffect(() => {
    if (padListMap.data) {
      setMapPlaces(
        padListMap.data.filter((p) => !!p.latitude && !!p.longitude),
      );
    }
  }, [padListMap]);

  const mapOnReadyHandle = useCallback((_, _map) => {
    setMap(_map);
  }, []);

  const locationOnDragendHandle = useCallback((_, marker) => {
    const latitude = marker.position.lat();
    const longitude = marker.position.lng();
    setLocation((prevState) => ({
      ...prevState,
      latitude,
      longitude,
      getGeoCode: true,
      withoutZoom: true,
    }));
  }, []);

  const mapOnClickHandle = useCallback((_, _map, e) => {
    const latitude = e.latLng.lat();
    const longitude = e.latLng.lng();
    setLocation((prevState) => ({
      ...prevState,
      latitude,
      longitude,
      getGeoCode: true,
      withoutZoom: true,
    }));
  }, []);

  const newPadLocation = useMemo(
    () =>
      !isNaN(location.latitude) && !isNaN(location.longitude)
        ? {
            lat: parseFloat(location.latitude),
            lng: parseFloat(location.longitude),
            withoutZoom: !!location.withoutZoom,
          }
        : null,
    [location.latitude, location.longitude, location.withoutZoom],
  );

  return (
    <div className="map-container" ref={mapContainer}>
      <Spin spinning={loading}>
        <MapWithPads
          height={400}
          zoom={5}
          onReady={mapOnReadyHandle}
          map={map}
          pads={mapPlaces}
          locationOnDragend={locationOnDragendHandle}
          location={newPadLocation}
          onClick={mapOnClickHandle}
          categories={categories}
          containerClassName="mb-2_5"
          showLocationPin={false}
          clustererConfig={{
            gridSize: 45,
          }}
        />
      </Spin>
    </div>
  );
};

PadListMap.defaultProps = {
  action: {},
  status: false,
  categories: [],
  onDisableAction: () => null,
  cleanOnUnmount: false,
};

PadListMap.propTypes = {
  action: PropTypes.object,
  status: PropTypes.bool,
  categories: PropTypes.array,
  onDisableAction: PropTypes.func,
  cleanOnUnmount: PropTypes.bool,
};

export default PadListMap;
