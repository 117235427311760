import { Form, Select } from 'antd';
import { useSelector } from 'react-redux';
import { selectLandingFeeOptions } from '../../store/selectors/bootstrapSelector';
import { formItemVerticalLayout } from './padCreatePage';

export const PadCreateLandingFee = () => {
  const { fees } = useSelector((store) => ({
    fees: selectLandingFeeOptions(store),
  }));

  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue }) => {
        let warningSelected = false;

        if (getFieldValue('category_id') === 7) {
          if (getFieldValue('facilities')?.includes(13)) {
            warningSelected = true;
          }
        }

        if (getFieldValue('warnings')?.includes(6)) {
          warningSelected = true;
        }

        return (
          warningSelected && (
            <Form.Item
              {...formItemVerticalLayout}
              label="Landing fees category"
              name="landing_fees"
              style={{ width: 200 }}
              rules={[{ required: true }]}
            >
              <Select placeholder="Choose" options={fees} />
            </Form.Item>
          )
        );
      }}
    </Form.Item>
  );
};
