import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import Tooltip from '../../uiKitComponents/tooltip';
import Tabs from '../../uiKitComponents/tabs';
import Button from '../../uiKitComponents/button';
import Popconfirm from '../../uiKitComponents/popconfirm';
import PageHeader from '../../uiKitComponents/pageHeader';
import TabContent from './announcementsTabContent';
import InlineEditInput from '../../components/inlineEditInput';
import Skeleton from '../../uiKitComponents/skeleton';
import notification from '../../uiKitComponents/notification';
import api from '../../utils/appApi';
import getApiErrorMessages from '../../utils/getApiErrorMessages';

import './announcements.scss';
import { updateLocalAnnoucementInList } from '../../store/actions/announcementsListAction';

export default function AnnouncementsViewPage() {
  const [activeTab, setActiveTab] = useState('android');
  const [announcement, setAnnouncement] = useState({});
  const [orderLoading, setOrderLoading] = useState(false);
  const [toggleArchiveStatus, setToggleArchiveStatus] = useState({
    visible: false,
    loading: false,
  });
  const { id: announcementId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const getAnnoucement = async () => {
    try {
      const response = await api.announcements.single(announcementId);

      if (response && response.data) {
        setAnnouncement(response.data);
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: getApiErrorMessages(error),
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getAnnoucement(), []);

  const tabOnChange = (key) => setActiveTab(key);

  const updateList = useCallback(
    (key) => (propKey, data) => {
      let updateKey;
      let updateData;

      if (propKey === 'uploads_add') {
        updateKey = 'uploads';
        updateData = data.filter(
          (file, index, self) =>
            index === self.findIndex((t) => t.id === file.id),
        );
      } else {
        updateKey = propKey;
        updateData = data;
      }

      setAnnouncement((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          [updateKey]: updateData,
        },
      }));
    },
    [],
  );

  const changeNameHandler = async ({ name }) => {
    try {
      await api.announcements.handler({ name }, announcement.id);
      setAnnouncement((prevState) => ({
        ...prevState,
        name,
      }));
    } catch (error) {
      notification.error({
        message: 'Error',
        description: getApiErrorMessages(error),
      });
    }
  };

  const deleteAnnouncementHandler = async () => {
    try {
      setToggleArchiveStatus((prevState) => ({
        ...prevState,
        loading: true,
      }));
      const response = await api.announcements.toggleArchive(announcement.id);

      if (response) {
        notification.success({
          message: 'Success',
          description: 'Announcement successfully archived',
        });
      } else {
        notification.success({
          message: 'Success',
          description: 'Announcement successfully unarchived',
        });
      }

      setAnnouncement((prevState) => ({
        ...prevState,
        deleted_at: response.data,
      }));
      updateLocalAnnoucementInList(Number(announcementId), {
        deleted_at: response.data,
      })(dispatch);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: getApiErrorMessages(error),
      });
    } finally {
      setToggleArchiveStatus((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const onBackHandle = useCallback(() => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push('/dashboard/announcements');
    }
  }, [history]);

  const tabs = [
    {
      title: `Android ${
        (!announcement.android?.is_enabled && '(off)') || '(on)'
      }`,
      key: 'android',
      content: (
        <TabContent
          announcement={announcement.android}
          updateList={updateList('android')}
          deviceType="android"
          announcementId={announcementId}
          orderLoading={setOrderLoading}
          isArchived={!!announcement.deleted_at}
        />
      ),
    },
    {
      title: `iOS ${(!announcement.ios?.is_enabled && '(off)') || '(on)'}`,
      key: 'ios',
      content: (
        <TabContent
          announcement={announcement.ios}
          updateList={updateList('ios')}
          deviceType="ios"
          announcementId={announcementId}
          orderLoading={setOrderLoading}
          isArchived={!!announcement.deleted_at}
        />
      ),
    },
  ];

  return (
    <div className="announcements-view">
      <PageHeader
        className="page-main-header no-children"
        onBack={onBackHandle}
        title="Back to list"
      />
      <div
        className="announcements-view-content position-relative"
        data-archived={!!announcement.deleted_at}
      >
        <div className="page-main-content position-relative">
          <div className="announcements-view_info d-flex align-items-center">
            <Tooltip
              title={orderLoading ? 'Order updating...' : 'Drag to reorder'}
            >
              {orderLoading ? (
                <LoadingOutlined className="accent-svg" spin />
              ) : (
                <InfoCircleOutlined />
              )}
            </Tooltip>
          </div>
          {(!announcement.name && (
            <Skeleton
              avatar={false}
              active
              paragraph={{
                rows: 0,
              }}
            />
          )) || (
            <InlineEditInput
              value={announcement.name}
              onFinish={changeNameHandler}
              name="name"
            />
          )}

          <Tabs
            activeKey={activeTab}
            onChange={tabOnChange}
            size="large"
            className="position-relative"
          >
            {tabs.map((tab) => (
              <Tabs.TabPane
                tab={tab.title}
                disabled={!announcement.name}
                key={tab.key}
                className="flex-grow-1"
              >
                {tab.content}
              </Tabs.TabPane>
            ))}
          </Tabs>
        </div>
        <div className="d-flex justify-content-end">
          <Popconfirm
            title="Are you sure to delete this Announcement?"
            okText="Yes"
            onConfirm={deleteAnnouncementHandler}
            cancelText="No"
            placement="leftTop"
            visible={toggleArchiveStatus.visible}
            onVisibleChange={(v) =>
              setToggleArchiveStatus((prevState) => ({
                ...prevState,
                visible: v,
              }))
            }
          >
            <Button loading={toggleArchiveStatus.loading} type="primary">
              {announcement.deleted_at ? 'Unarchive' : 'Archive'}
            </Button>
          </Popconfirm>
        </div>
      </div>
    </div>
  );
}

AnnouncementsViewPage.propTypes = {};

AnnouncementsViewPage.defaultProps = {};
