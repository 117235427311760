export const selectRegionOptions = (store) =>
  store.bootstrap.payload.regions?.map((itm) => ({
    value: itm.id,
    label: itm.region,
    country_code: itm.country_code,
  }));

export const selectCategoryOptions = (store) =>
  store.bootstrap.payload?.categories?.map((itm) => ({
    value: itm.id,
    label: itm.display_name,
  }));

export const selectTagOptions = (store) =>
  store.bootstrap.payload?.tags?.map((itm) => ({
    value: itm.name,
    label: itm.name,
  }));

export const selectUserTagOptions = (store) =>
  store.bootstrap.payload?.userTags?.map((itm) => ({
    value: itm.name,
    label: itm.name,
  }));

export const selectLandingFeeOptions = (store) =>
  store.bootstrap.payload?.landingFees
    ? Object.entries(store.bootstrap.payload?.landingFees || {}).map(
        ([value, label]) => ({
          value,
          label,
        }),
      )
    : [];

export const selectStatusesForDisplay = (store) =>
  store.bootstrap.payload?.statuses
    ? Object.fromEntries(
        store.bootstrap.payload?.statuses?.map((itm) => [
          itm.id,
          itm.display_name,
        ]),
      )
    : {};

export const selectContactTypeOptions = (store, filter = (c) => c) => {
  if (store.bootstrap.payload?.contactTypes) {
    const { contactTypes } = store.bootstrap.payload;

    return contactTypes.filter(filter).map((itm) => ({
      value: itm.id,
      label: itm.display_name,
    }));
  }

  return [];
};

export const selectWarningOptions = (store) =>
  store.bootstrap.payload?.warnings?.map((itm) => ({
    value: itm.id,
    label: itm.display_name,
  }));

export const selectFacilityOptions = (store) =>
  store.bootstrap.payload?.facilities?.map((itm) => ({
    value: itm.id,
    label: itm.display_name,
  }));

export const selectCountriesOptions = (store, addAll = true) => {
  const defaultValue = {
    value: 0,
    label: 'All',
  };
  const list = Object.entries(store.bootstrap.payload?.countries || {}).map(
    ([k, v]) => ({
      value: k,
      label: v,
    }),
  );

  if (addAll) {
    list.push(defaultValue);
  }

  return list.sort((a) => {
    if (a.value === 'GB') return -2;
    if (a.value === 0) return -1;
    return 1;
  });
};

export const selectUSStatesOptions = (store, addAll = true) => {
  const defaultValue = {
    value: 0,
    label: 'All',
  };
  const list = Object.entries(store.bootstrap.payload?.USStates || {}).map(
    ([k, v]) => ({
      value: k,
      label: v,
    }),
  );

  if (addAll) {
    list.push(defaultValue);
  }

  return list.sort((a) => (a.value === 0 ? -1 : 1));
};
