import PropTypes from 'prop-types';
import condStrings from '../../utils/condStrings';

import styles from './loadingBar.module.sass';

const LoadingBar = (props) => {
  const { className, ...rest } = props;
  return (
    <div {...rest} className={condStrings(styles.root, className)}>
      <div className={styles.line} />
      <div className={styles.inc} />
      <div className={styles.dec} />
    </div>
  );
};

LoadingBar.defaultProps = {
  className: '',
};

LoadingBar.propTypes = {
  className: PropTypes.string,
};

export default LoadingBar;
