import {
  PAD_LIST_GET,
  PAD_LIST_CLEAN,
  CLEAN_ON_UNMOUNT_TRUE,
  CLEAN_ON_UNMOUNT_FALSE,
  UPLOAD_PAD_ITEM,
} from '../actionTypes';
import api from '../../utils/appApi';

export const getPadList = (config) => (dispatch) =>
  api.pad
    .getList(config)
    .then((res) => {
      dispatch({ type: PAD_LIST_GET, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const setSelectPadUpload = (uploads) => (dispatch) => 
  dispatch({
    type: UPLOAD_PAD_ITEM,
    payload: uploads,
  });

export const getPadListUnmoderated = (config) => (dispatch) =>
  api.pad
    .getList({
      ...config,
      params: {
        ...config.params,
        'filters[status.identifier:equals]': 1,
      },
    })
    .then((res) => {
      dispatch({ type: PAD_LIST_GET, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const getPadListModerated = (config) => (dispatch) =>
  api.pad
    .getList({
      ...config,
      params: {
        ...config.params,
        'filters[status.identifier:equals]': 3,
        'filters[is_landing_permitted:equals]': 1,
      },
    })
    .then((res) => {
      dispatch({ type: PAD_LIST_GET, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const getPadListInvalidPhone = (config) => (dispatch) =>
  api.pad
    .getList({
      ...config,
      params: {
        ...config.params,
        'filters[is_phonable:equals]': 0,
        'filters[status.identifier:equals]': 3,
        'filters[is_landing_permitted:equals]': 1,
      },
    })
    .then((res) => {
      dispatch({ type: PAD_LIST_GET, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const getPadListConsumerSubmitted = (config) => (dispatch) =>
  api.pad
    .getList({
      ...config,
      params: {
        ...config.params,
        'filters[submitter.user.role:is]': 'users.role != 1',
      },
    })
    .then((res) => {
      dispatch({ type: PAD_LIST_GET, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const getPadListLandingNotPossible = (config) => (dispatch) =>
  api.pad
    .getList({
      ...config,
      params: {
        ...config.params,
        'filters[is_landing_permitted:equals]': 0,
      },
    })
    .then((res) => {
      dispatch({ type: PAD_LIST_GET, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const getPadListPrivate = (config) => (dispatch) =>
  api.pad
    .getList({
      ...config,
      params: {
        ...config.params,
        'filters[type:is]': 'user_id IS NOT NULL',
      },
    })
    .then((res) => {
      dispatch({ type: PAD_LIST_GET, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const getPadListArchived = (config) => (dispatch) =>
  api.pad
    .getList({
      ...config,
      params: {
        ...config.params,
        'filters[deleted_at:is]': 'deleted_at IS NOT NULL',
      },
    })
    .then((res) => {
      dispatch({ type: PAD_LIST_GET, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const cleanPadList = () => (dispatch) =>
  dispatch({
    type: PAD_LIST_CLEAN,
  });

export const setCleanOnUnmountTrue = () => (dispatch) =>
  new Promise((resolve, reject) => {
    try {
      dispatch({
        type: CLEAN_ON_UNMOUNT_TRUE,
      });
      return resolve();
    } catch (e) {
      return reject(Error(e));
    }
  });

export const setCleanOnUnmountFalse = () => (dispatch) =>
  new Promise((resolve, reject) => {
    try {
      dispatch({
        type: CLEAN_ON_UNMOUNT_FALSE,
      });
      return resolve();
    } catch (e) {
      return reject(Error(e));
    }
  });
