import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Row, Typography } from 'antd';
import { WalletOutlined, WarningOutlined, FilePdfOutlined } from '@ant-design/icons';
import MapWithSinglePad from '../../mapWithSinglePad';
import history from '../../../utils/history';
import { selectCategoryOptions } from '../../../store/selectors/bootstrapSelector';

export default function DupeResolverTop(props) {
  const {
    prev,
    next,
    setFields,
    prevTitle,
    finalTitle,
    nextTitle,
    newLocation,
  } = props;
  const [finalMap, setFinalMap] = useState();
  const [location, setLocation] = useState(newLocation);
  const { categories } = useSelector((store) => ({
    categories: selectCategoryOptions(store),
  }));

  useEffect(() => setLocation(newLocation), [newLocation]);

  const goToPad = useCallback(
    (padId) => () => history.push(`/dashboard/pad-view/${padId}`),
    [],
  );

  const finalMapOnReadyHandle = useCallback((_, _map) => setFinalMap(_map), []);

  const locationOnDragendHandle = useCallback(
    (_, marker) => {
      const latitude = marker.position.lat();
      const longitude = marker.position.lng();
      setLocation((prevState) => ({
        ...prevState,
        latitude,
        longitude,
        getGeoCode: true,
        withoutZoom: true,
      }));
      setFields([
        { name: 'location#latitude', value: latitude },
        { name: 'location#longitude', value: longitude },
      ]);
    },
    [setFields],
  );

  const prevPadLocation = useMemo(
    () => ({
      lat: prev.location?.latitude,
      lng: prev.location?.longitude,
    }),
    [prev.location?.latitude, prev.location?.longitude],
  );

  const newPadLocation = useMemo(
    () =>
      location && !isNaN(location.latitude) && !isNaN(location.longitude)
        ? {
          lat: parseFloat(location.latitude),
          lng: parseFloat(location.longitude),
          withoutZoom: !!location.withoutZoom,
        }
        : null,
    [location],
  );

  const nextPadLocation = useMemo(
    () => ({
      lat: next.location?.latitude,
      lng: next.location?.longitude,
    }),
    [next.location?.latitude, next.location?.longitude],
  );

  const hasOwner = (
    <>
      <WarningOutlined
        style={{ fontSize: 16, marginLeft: 10, marginRight: 3 }}
      />
      <span style={{ fontSize: 13, marginRight: 10, fontWeight: 'bold' }}>
        Owned
      </span>
    </>
  );

  const isArchived = (
    <>
      <WalletOutlined
        style={{ fontSize: 16, marginLeft: 10, marginRight: 3 }}
      />
      <span style={{ fontSize: 13, marginRight: 10, fontWeight: 'bold' }}>
        Archived
      </span>
    </>
  );

  const isSurvey = (
    <>
      <FilePdfOutlined
        style={{ fontSize: 16, marginLeft: 10, marginRight: 3 }}
      />
      <span style={{ fontSize: 13, marginRight: 10, fontWeight: 'bold' }}>
        Survey
      </span>
    </>
  );

  return (
    <>
      <Row>
        <Col span={8}>
          <Typography.Title
            className="mb-0 d-flex align-items-center"
            level={3}
          >
            {prevTitle}
            {prev.is_owned && hasOwner} {prev.deleted_at && isArchived} {prev.survey_id && isSurvey}
          </Typography.Title>
        </Col>
        <Col span={8}>
          <Typography.Title className="mb-0" level={3}>
            {finalTitle}
          </Typography.Title>
        </Col>
        <Col span={8}>
          <Typography.Title
            className="mb-0 d-flex align-items-center"
            level={3}
          >
            {nextTitle}
            {next.is_owned && hasOwner} {next.deleted_at && isArchived} {next.survey_id && isSurvey}
          </Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Button
            type="link"
            htmlType="button"
            className="custom-btn mb-2"
            onClick={goToPad(prev.id)}
          >
            {prev.name}
          </Button>
        </Col>
        <Col span={8} />
        <Col span={8}>
          <Button
            type="link"
            htmlType="button"
            className="custom-btn mb-2"
            onClick={goToPad(next.id)}
          >
            {next.name}
          </Button>
        </Col>
      </Row>
      <Row gutter={[16, 24]} style={{ marginBottom: 30 }}>
        <Col span={8}>
          <MapWithSinglePad
            location={prevPadLocation}
            // Common props
            mapTypeId="hybrid"
            categories={categories}
            containerClassName="dupe-resolver-map"
            height={300}
            readOnly
            mapTypeControl={false}
            streetViewControl={false}
          />
        </Col>
        <Col span={8}>
          <MapWithSinglePad
            onReady={finalMapOnReadyHandle}
            map={finalMap}
            location={newPadLocation}
            locationOnDragend={locationOnDragendHandle}
            // Common props
            mapTypeId="hybrid"
            categories={categories}
            containerClassName="dupe-resolver-map"
            height={300}
            streetViewControl={false}
          />
        </Col>
        <Col span={8}>
          <MapWithSinglePad
            location={nextPadLocation}
            // Common props
            mapTypeId="hybrid"
            categories={categories}
            containerClassName="dupe-resolver-map"
            height={300}
            readOnly
            mapTypeControl={false}
            streetViewControl={false}
          />
        </Col>
      </Row>
    </>
  );
}

DupeResolverTop.propTypes = {
  prev: PropTypes.shape({
    id: PropTypes.number,
    location: PropTypes.object,
    name: PropTypes.string,
    is_owned: PropTypes.bool,
    deleted_at: PropTypes.string,
    survey_id: PropTypes.number,
  }),
  next: PropTypes.shape({
    id: PropTypes.number,
    location: PropTypes.object,
    name: PropTypes.string,
    is_owned: PropTypes.bool,
    deleted_at: PropTypes.string,
    survey_id: PropTypes.number,
  }),
  prevTitle: PropTypes.string,
  nextTitle: PropTypes.string,
  finalTitle: PropTypes.string,
  setFields: PropTypes.func.isRequired,
  newLocation: PropTypes.object,
};

DupeResolverTop.defaultProps = {
  prev: {},
  next: {},
  prevTitle: '',
  nextTitle: '',
  finalTitle: '',
  newLocation: null,
};
