import {
  PAD_LIST_MAP_GET,
  PAD_LIST_MAP_CLEAN,
  CLEAN_ON_UNMOUNT_TRUE,
  CLEAN_ON_UNMOUNT_FALSE,
} from '../actionTypes';
import api from '../../utils/appApi';

export const getPadListMap = (config) => (dispatch) =>
  api.pad
    .gapMapList(config)
    .then((res) => {
      dispatch({ type: PAD_LIST_MAP_GET, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const getPadListMapUnmoderated = (config) => (dispatch) =>
  api.pad
    .gapMapList({
      ...config,
      params: { ...config.params, 'filters[status.identifier:equals]': 1 },
    })
    .then((res) => {
      dispatch({ type: PAD_LIST_MAP_GET, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const getPadListMapModerated = (config) => (dispatch) =>
  api.pad
    .gapMapList({
      ...config,
      params: {
        ...config.params,
        'filters[status.identifier:equals]': 3,
        'filters[is_landing_permitted:equals]': 1,
      },
    })
    .then((res) => {
      dispatch({ type: PAD_LIST_MAP_GET, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const getPadListMapInvalidPhone = (config) => (dispatch) =>
  api.pad
    .gapMapList({
      ...config,
      params: {
        ...config.params,
        'filters[is_phonable:equals]': 0,
        'filters[status.identifier:equals]': 3,
        'filters[is_landing_permitted:equals]': 1,
      },
    })
    .then((res) => {
      dispatch({ type: PAD_LIST_MAP_GET, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const getPadListMapConsumerSubmitted = (config) => (dispatch) =>
  api.pad
    .gapMapList({
      ...config,
      params: {
        ...config.params,
        'filters[submitter.user.role:is]': 'users.role != 1',
      },
    })
    .then((res) => {
      dispatch({ type: PAD_LIST_MAP_GET, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const getPadListMapLandingNotPossible = (config) => (dispatch) =>
  api.pad
    .gapMapList({
      ...config,
      params: {
        ...config.params,
        'filters[is_landing_permitted:equals]': 0,
      },
    })
    .then((res) => {
      dispatch({ type: PAD_LIST_MAP_GET, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const getPadListMapPrivate = (config) => (dispatch) =>
  api.pad
    .gapMapList({
      ...config,
      params: {
        ...config.params,
        'filters[type:is]': 'user_id IS NOT NULL',
      },
    })
    .then((res) => {
      dispatch({ type: PAD_LIST_MAP_GET, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const getPadListMapArchived = (config) => (dispatch) =>
  api.pad
    .gapMapList({
      ...config,
      params: {
        ...config.params,
        'filters[deleted_at:is]': 'deleted_at IS NOT NULL',
      },
    })
    .then((res) => {
      dispatch({ type: PAD_LIST_MAP_GET, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const cleanPadListMap = () => (dispatch) =>
  dispatch({
    type: PAD_LIST_MAP_CLEAN,
  });

export const setCleanOnUnmountTrue = () => (dispatch) =>
  new Promise((resolve, reject) => {
    try {
      dispatch({
        type: CLEAN_ON_UNMOUNT_TRUE,
      });
      return resolve();
    } catch (e) {
      return reject(Error(e));
    }
  });

export const setCleanOnUnmountFalse = () => (dispatch) =>
  new Promise((resolve, reject) => {
    try {
      dispatch({
        type: CLEAN_ON_UNMOUNT_FALSE,
      });
      return resolve();
    } catch (e) {
      return reject(Error(e));
    }
  });
