import PropTypes from 'prop-types';
import React from 'react';
import { Button, Dropdown, List, Menu } from 'antd';
import { AcceptAction, AcceptConfirmation, PendingAction, RejectAction } from './OwnerActions';

export const OwnerItem = React.memo((props) => {
  const { owner } = props;

  const [isLoading, setIsLoading] = React.useState(false);

  const visibleActions = React.useMemo(() => {
    if (!owner.is_claimed) {
      if (owner.is_pending) return ['accept', 'reject'];
      return ['accept', 'confirmation', 'pending', 'reject'];
    }

    return [];
  }, [owner.is_claimed, owner.is_pending]);

  return (
    <List.Item>
      <List.Item.Meta
        title={`#${owner.user_id} ${owner.name}`}
        description={owner.email}
      />
      {owner.is_claimed ? (
        <RejectAction
          owner={owner}
          isLoading={isLoading}
          toggleLoadingState={setIsLoading}
          as="button"
        />
      ) : (
        <Dropdown
          placement="bottomRight"
          trigger="click"
          arrow
          overlay={
            <Menu>
              {visibleActions.includes('accept') && (
                <Menu.Item>
                  <AcceptAction
                    owner={owner}
                    toggleLoadingState={setIsLoading}
                  />
                </Menu.Item>
              )}
              {visibleActions.includes('confirmation') && (
                <Menu.Item>
                  <AcceptConfirmation
                    owner={owner}
                    toggleLoadingState={setIsLoading}
                  />
                </Menu.Item>
              )}
              {visibleActions.includes('pending') && (
                <Menu.Item>
                  <PendingAction
                    owner={owner}
                    toggleLoadingState={setIsLoading}
                  />
                </Menu.Item>
              )}
              {visibleActions.includes('reject') && (
                <Menu.Item>
                  <RejectAction
                    owner={owner}
                    toggleLoadingState={setIsLoading}
                  />
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <Button loading={isLoading}>
            {owner.is_pending ? 'In Pending' : 'Actions'}
          </Button>
        </Dropdown>
      )}
    </List.Item>
  );
});

OwnerItem.propTypes = {
  owner: PropTypes.shape({
    id: PropTypes.number,
    user_id: PropTypes.number,
    contact_type_id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    is_claimed: PropTypes.bool,
    is_pending: PropTypes.bool,
  }),
};
