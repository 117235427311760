import PropTypes from 'prop-types';
import React from 'react';
import { Descriptions, Form, Select, Input, InputNumber } from 'antd';
import { usePadSurveyContext } from '../PadSurveyContext';
import { surveyOptions } from '../utils';

export default function SurveyLandingStep(props) {
  const { isEditing, toggleStepVisibility } = usePadSurveyContext();
  const { step } = props;
  const [content, setContent] = React.useState([]);

  React.useEffect(() => {
    const values = [];

    if (step?.size) {
      const label = `Size - ${step.size}${step.size_unit}`;
      const value = step.size_details || '---';
      values.push([label, value]);
    }

    if (step?.surface) {
      const label = `Surface - ${surveyOptions[step.surface]}`;
      const value = step?.surface_details || '---';
      values.push([label, value]);
    }

    if (step?.slope) {
      const label = `Slope - ${surveyOptions[step.slope]}`;
      const value = step?.slope_details || '---';
      values.push([label, value]);
    }

    if (step?.are_secondary_landing_area) {
      const label = `Secondary landing area - ${
        surveyOptions[step.are_secondary_landing_area]
      }`;
      const value = step?.surface_details || '---';
      values.push([label, value]);
    }

    if (values.length) toggleStepVisibility('landing', true);
    else toggleStepVisibility('landing', false);

    setContent(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  if (isEditing) {
    return (
      <Descriptions
        size="small"
        bordered
        column={1}
        labelStyle={{ width: 280 }}
      >
        <Descriptions.Item
          className="py-2 px-2"
          label={
            <div className="d-flex">
              <Form.Item
                className="mb-0 mr-2"
                label="Size"
                name={['landing', 'size']}
              >
                <InputNumber placeholder="Size" />
              </Form.Item>
              <Form.Item
                className="w-100 mb-0"
                name={['landing', 'size_unit']}
                style={{ marginTop: 27 }}
              >
                <Select className="w-100">
                  <Select.Option value="meters">Meters</Select.Option>
                  <Select.Option value="feet">Feet</Select.Option>
                </Select>
              </Form.Item>
            </div>
          }
        >
          <Form.Item
            name={['landing', 'size_details']}
            className="w-100 mb-0"
            style={{ marginTop: 27 }}
          >
            <Input.TextArea autoSize placeholder="Please give details" />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item
          className="py-2 px-2"
          label={
            <Form.Item
              className="mb-0"
              label="Surface"
              name={['landing', 'surface']}
            >
              <Select
                placeholder="Choose"
                options={[
                  {
                    value: 'concrete_tarmac',
                    label: 'Concrete/Tarmac',
                  },
                  {
                    value: 'maintained_grass',
                    label: 'Grass - Maintained',
                  },
                  {
                    value: 'partially_maintained_grass',
                    label: 'Grass - Partially maintained',
                  },
                  {
                    value: 'unmaintained_grass',
                    label: 'Grass - Unmaintained',
                  },
                  { value: 'gravel', label: 'Gravel' },
                  { value: 'sand', label: 'Sand' },
                  { value: 'other', label: 'Other' },
                ]}
              />
            </Form.Item>
          }
        >
          <Form.Item
            name={['landing', 'surface_details']}
            className="w-100 mb-0"
            style={{ marginTop: 27 }}
          >
            <Input.TextArea autoSize placeholder="Please give details" />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item
          className="py-2 px-2"
          label={
            <Form.Item
              className="mb-0"
              label="Slope"
              name={['landing', 'slope']}
            >
              <Select
                placeholder="Choose"
                options={[
                  { value: 'flat', label: 'Flat' },
                  { value: 'gentle', label: 'Gentle' },
                  { value: 'steep', label: 'Steep' },
                  { value: 'extreme', label: 'Extreme' },
                ]}
              />
            </Form.Item>
          }
        >
          <Form.Item
            name={['landing', 'slope_details']}
            className="w-100 mb-0"
            style={{ marginTop: 27 }}
          >
            <Input.TextArea
              autoSize
              placeholder="Please provide more details of the above slope and it's direction of fall, along with details of any surrounding sloped ground"
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item
          className="py-2 px-2"
          label={
            <Form.Item
              className="mb-0"
              label="Secondary landing area"
              name={['landing', 'are_secondary_landing_area']}
            >
              <Select
                placeholder="Choose"
                options={[
                  { value: 'yes', label: 'Yes' },
                  { value: 'no', label: 'No' },
                ]}
              />
            </Form.Item>
          }
        >
          <Form.Item
            noStyle
            shouldUpdate={(prev, curr) =>
              prev.landing?.are_secondary_landing_area !==
              curr.landing?.are_secondary_landing_area
            }
          >
            {({ getFieldValue }) => {
              if (
                getFieldValue(['landing', 'are_secondary_landing_area']) ===
                'yes'
              ) {
                return (
                  <Form.Item
                    name={['landing', 'secondary_landing_area_details']}
                    className="w-100 mb-0"
                    style={{ marginTop: 27 }}
                  >
                    <Input.TextArea
                      autoSize
                      placeholder="Please provide details of the landing area, it's location in relation to the primary landing area, along with any specific hazards or obstacles."
                    />
                  </Form.Item>
                );
              }

              return null;
            }}
          </Form.Item>
        </Descriptions.Item>
      </Descriptions>
    );
  }

  return (
    <Descriptions
      size="small"
      bordered
      column={1}
      labelStyle={{ width: '240px' }}
      contentStyle={{ whiteSpace: 'pre-line' }}
    >
      {content.map(([label, value]) => (
        <Descriptions.Item
          key={label.toLowerCase().replace(' ', '_')}
          className="py-2 px-2"
          label={label}
        >
          {value}
        </Descriptions.Item>
      ))}
    </Descriptions>
  );
}

SurveyLandingStep.propTypes = {
  step: PropTypes.object,
};

SurveyLandingStep.defaultProps = {
  step: {},
};
