import dayjs from 'dayjs';
import axiosInstance from './apiConfig';
import objectRemoveEmpty from './objectRemoveEmpty';

const api = {
  user: {
    login: (data) => axiosInstance.post('/auth/login', data),
    logout: () => axiosInstance.post('/auth/logout'),
    get: () => axiosInstance.get('/auth/user'),
    update: (userId, data) => axiosInstance.put(`/admin/users/${userId}`, data),
    store: (config) => axiosInstance.post('admin/users/', config),
    toggleMap: () => axiosInstance.put('/admin/users/toggle-map'),
  },
  filters: {
    get: (entity) => axiosInstance.get(`/admin/reports?entity=${entity}`),
    update: (filter_id, data) =>
      axiosInstance.put(`/admin/reports/${filter_id}`, data),
    remove: (filter_id) => axiosInstance.delete(`admin/reports/${filter_id}`),
    store: (config) => axiosInstance.post('/admin/reports', config),
  },
  pad: {
    getList: (config) => axiosInstance.get('/admin/pads', config),
    gapMapList: (config) =>
      axiosInstance.get('/admin/pads/index-pads-for-map', config),
    get: (padId, config) => axiosInstance.get(`/admin/pads/${padId}`, config),
    store: (data) => axiosInstance.post('/admin/pads', data),
    update: (padId, data) => axiosInstance.put(`/admin/pads/${padId}`, data),
    toggleModeration: (padId) =>
      axiosInstance.patch(`/admin/pads/toggle-moderation/${padId}`),
    getLandingFees: (config) => axiosInstance.get('/pads/landing-fees', config),
    getMapPlaces: (config) =>
      axiosInstance.get('/admin/pads/pads-for-map', config),
    addNote: (padId, data) =>
      axiosInstance.post(`/admin/pads/add-note/${padId}`, data),
    updateNote: (noteId, data) =>
      axiosInstance.put(`/admin/pads/update-note/${noteId}`, data),
    deleteNote: (noteId) =>
      axiosInstance.delete(`/admin/pads/delete-note/${noteId}`),
    getLogs: (padId, config) =>
      axiosInstance.get(`/admin/logs/pad-logs/${padId}`, config),
    searchOwners: (config) =>
      axiosInstance.get('/admin/users/search-owner-by-email', config),
    getOwners: (padId) => axiosInstance.get(`/admin/pads/get-owners/${padId}`),
    addOwner: (padId, userId) =>
      axiosInstance.patch(`/admin/pads/add-owner/${padId}/${userId}`),
    acceptOwner: (data) =>
      axiosInstance.patch(`/admin/pads/accept-owner`, data),
    deleteOwner: (padId, userId, type) =>
      axiosInstance.delete(
        `/admin/contacts/${padId}/${userId}${type ? `/${type}` : ''}`,
      ),
    validateImport: (data, config) =>
      axiosInstance.post('/admin/pads/import/validate', data, config),
    importAccept: (data) => axiosInstance.post('/admin/pads/import', data),
    updateRecommended: (padId) =>
      axiosInstance.put(`/admin/pads/toggle-helipaddy-recommended/${padId}`),
    getReports: (padId) =>
      axiosInstance.get(
        `/admin/revisions?filters[type:includes][]=4&filters[type:includes][]=6&filters[type:includes][]=7&filters[status:includes][]=1&filters[pad_id]=${padId}`,
      ),
    acceptRevision: (revisionId) =>
      axiosInstance.get(`/admin/revisions/accept/${revisionId}?type=4`),
    cancelRevision: (revisionId) =>
      axiosInstance.get(`/admin/revisions/cancel/${revisionId}`),
    uploadsOrder: (data) =>
      axiosInstance.post('/uploads/add-upload-order', data),
    addToPurgatory: (padId) =>
      axiosInstance.get(`admin/pads/add-to-purgatory/${padId}`),
    archive: (padId, config) =>
      axiosInstance.post(`/admin/pads/toggle-pad-deletion/${padId}`, config),
    generateLink: (padId, type) =>
      axiosInstance.get(`/admin/pads/generate-link/${padId}?device=${type}`),
    saveAsPrivate: (padId) =>
      axiosInstance.put(`/admin/pads/toggle-pad-private-status/${padId}`),
    createOwner: (data) =>
      axiosInstance.post('/admin/pads/add-owner-contact-by-email', data),
    changePrimaryContact: (padId, contactId, contactTypeId) =>
      axiosInstance.put(
        `/pads/set-primary-contact/${padId}/${contactId}/${contactTypeId}`,
      ),
    export: (params = {}) =>
      axiosInstance.get('/admin/pads/download-csv', { params }),
    toggleMap: () => axiosInstance.put('/admin/pads/toggle-map'),
    pendingOwner: (padId, contactId, data) =>
      axiosInstance.put(
        `/admin/contacts/update-pending-status/${padId}/${contactId}`,
        data,
      ),
    hiscCountryCodes: () =>
      axiosInstance.get('admin/regions/get-hisc-country-codes'),
  },
  users: {
    getList: (config) => axiosInstance.get('/admin/users', config),
    getMapList: (config) =>
      axiosInstance.get('/admin/users/index-users-for-map', config),
    getAdminList: (config) =>
      axiosInstance.get('/admin/users/get-admins', config),
    toggleRole: (id) =>
      axiosInstance.put(`/admin/users/toggle-user-role/${id}`),
    get: (userId, config) =>
      axiosInstance.get(`/admin/users/${userId}`, config),
    getPads: (userId, filterType) =>
      axiosInstance.get(`/admin/users/get-pads/${userId}?type=${filterType}`),
    update: (userId, config) =>
      axiosInstance.put(`/admin/users/${userId}`, config),
    archive: (userId, config) =>
      axiosInstance.post(`/admin/users/toggle-user-deletion/${userId}`, config),
    getLogs: (userId, config) =>
      axiosInstance.get(`/admin/logs/user-logs/${userId}`, config),
    export: (params) =>
      axiosInstance.get('/admin/users/download-csv', { params }),
    toggleMap: () => axiosInstance.put('/admin/users/toggle-map'),
  },
  reviews: {
    getList: (config) => axiosInstance.get('/admin/reviews', config),
    toggleStatus: (id) =>
      axiosInstance.put(`/admin/reviews/toggle-review-mode/${id}`),
    getRaiting: (id) => axiosInstance.get(`/admin/reviews/${id}`),
    getRevision: (revisionId) =>
      axiosInstance.get(
        `/admin/review-reports/show-review-report/${revisionId}`,
      ),
    getSingleReview: (reviewId) =>
      axiosInstance.get(`/admin/review-reports/show/${reviewId}`),
    acceptRevision: (reviewReportId) =>
      axiosInstance.delete(
        `/admin/review-reports/approve-review-report/${reviewReportId}`,
      ),
    ignoreRevision: (reviewReportId) =>
      axiosInstance.delete(
        `/admin/review-reports/ignore-review-report/${reviewReportId}`,
      ),
  },
  region: {
    getList: (config) => axiosInstance.get('/regions', config),
    getCountries: (config) => axiosInstance.get('/regions/countries', config),
    getUSStates: (config) => axiosInstance.get('/regions/us-states', config),
    searchRegionByCountry: (config) =>
      axiosInstance.get('/admin/pads/search-region', config),
  },
  category: {
    getList: (config) => axiosInstance.get('/categories', config),
  },
  contactType: {
    getList: (config) => axiosInstance.get('/contact-types', config),
  },
  warning: {
    getList: (config) => axiosInstance.get('/warnings', config),
  },
  facility: {
    getList: (config) => axiosInstance.get('/facilities', config),
  },
  tag: {
    getList: (config) => axiosInstance.get('/tags', config),
    getListUserTags: (config) => axiosInstance.get('/user-tags', config),
  },
  status: {
    getList: (config) => axiosInstance.get('/statuses', config),
  },
  upload: {
    store: (entity, id, data, config) =>
      axiosInstance.post(`/uploads/${entity}/${id}`, data, config),
    delete: (fileId) => axiosInstance.delete(`/uploads/${fileId}`),
    acceptRevision: (uploadId) =>
      axiosInstance.delete(`/admin/upload-report/accept/${uploadId}`),
    ignoreRevision: (uploadId) =>
      axiosInstance.patch(`/admin/upload-report/ignore/${uploadId}`),
    updateOrder: (data = []) =>
      axiosInstance.post('/uploads/add-upload-order', data),
  },
  review: {
    getList: (config) => axiosInstance.get('/admin/reviews', config),
    getListByPad: (padId, config) =>
      axiosInstance.get(`/reviews/${padId}`, config),
    getFullListByPad: (padId, config) =>
      axiosInstance(`/admin/pads/reviews/${padId}`, config),
    toggleReviewMode: (reviewId) =>
      axiosInstance.put(`/admin/reviews/toggle-review-mode/${reviewId}`),
  },
  revision: {
    getList: (config) =>
      axiosInstance.get('/admin/revisions', {
        params: {
          ...config.params,
          'filters[created_at:between]': `2021-01-01T15:46:56.880Z,${dayjs()
            .add(1, 'day')
            .toISOString()}`,
          "filters[type:doesn't include][]": 5,
          'filters[status:includes][]': 1,
        },
      }),
    get: (revisionId, config) =>
      axiosInstance.get(`/admin/revisions/${revisionId}`, config),
    requestModeration: (padId) =>
      axiosInstance.get(`/admin/revisions/send-moderation-link/${padId}`),
    cancelRevision: (padId) =>
      axiosInstance.get(`/admin/revisions/revert-moderation/${padId}`),
    updatePad: (revisionId, data) =>
      axiosInstance.post(`/admin/revisions/pad/update/${revisionId}`, data),
    filterByType: (type, data) =>
      axiosInstance.get(
        `/admin/revisions?filters[status:includes][]=1${
          type && `&filters[type]=${type}`
        } ${
          (!type && '&filters[created_at:is after]=2021-01-01T15:46:56.880Z') ||
          ''
        }`,
        data,
      ),
    filterByTypeReported: (data) =>
      axiosInstance.get(
        `/admin/revisions?filters[type:includes][]=4&filters[type:includes][]=6&filters[type:includes][]=7&filters[status:includes][]=1`,
        data,
      ),
    filterByTypePadAdditions: (data) =>
      axiosInstance.get(
        `/admin/revisions?filters[created_at:is+after]=2021-01-01T15:46:56.880Z&filters[status:includes][]=1&filters[type]=1`,
        data,
      ),
    filterByTypePurgatory: (data) =>
      axiosInstance.get(
        `/admin/revisions?filters[created_at:is+before+or+equal+to]=2021-01-01T15:46:56.880Z&filters[status:includes][]=1&filters[type]=1`,
        data,
      ),
  },
  reportsPages: {
    getPadSubmissions: (config) =>
      axiosInstance.get('/admin/pad-contacts/pads-report', config),
    getLogReports: (config) => axiosInstance.get('/admin/logs', config),
    getDuplicateReport: (config) =>
      axiosInstance.get('/admin/dupe-checker/index', config),
    getPadStatsFav: ({ from, to }) => {
      if (from && to)
        return axiosInstance.get(
          `/admin/pads/pad-stats-with-calendar?from=${from}&to=${to}`,
        );

      return axiosInstance.get(`/admin/pads/pad-stats-with-calendar`);
    },
    getPadStatsViews: (config) =>
      axiosInstance.get('/admin/pads/pad-stats', config),
    getUndefinedUsers: (config) =>
      axiosInstance.get(`/admin/users/undefined-users-report`, {
        params: objectRemoveEmpty(config),
      }),
    getPilotsCountry: (config) =>
      axiosInstance.get('/admin/get-country-report', { params: config }),
    getModerationEngagement: (config) =>
      axiosInstance.get('/admin/get-moderation-report', { params: config }),
    getSubsReport: (config) =>
      axiosInstance.get('/admin/get-premium-subs-report', { params: config }),
    getConcurrentLogins: (config) =>
      axiosInstance.get('/admin/users/concurrent-logins-report', config),
    getPadsCountry: (config) =>
      axiosInstance.get('/admin/pads/get-country-report', { params: config }),
    getSurveysReports: (config) =>
      axiosInstance.get('/admin/surveys/pad-surveys-report', {
        params: objectRemoveEmpty(config),
      }),
    getPilotAircraftsReports: (config) =>
      axiosInstance.get('/admin/users/pilot-aircrafts-report', {
        params: config,
      }),
  },
  helipaddyStats: {
    get: (config) => axiosInstance.get('/admin/stats', config),
  },
  duplicates: {
    get: (prevPadId, nextPadId) =>
      axiosInstance.get(`/admin/dupe-checker/show/${prevPadId}/${nextPadId}`),
    merge: (prevPadId, nextPadId, data) =>
      axiosInstance.post(
        `/admin/dupe-checker/merge-pads/${prevPadId}/${nextPadId}`,
        data,
      ),
    markAsNotDuplicate: ({ prev, next }) =>
      axiosInstance.put(`/admin/pads/toggle-not-duplicated/${prev}/${next}`),
    duplicatesCount: () =>
      axiosInstance.get('/admin/dupe-checker/dupe-report-count'),
  },
  announcements: {
    get: (config) => axiosInstance.get('/admin/announcement', config),
    handler: ({ name, order }, id = null) =>
      axiosInstance.post('/admin/announcement', {
        name,
        order,
        announcement_id: id,
      }),
    single: (id) => axiosInstance.get(`/admin/announcement/show/${id}`),
    toggle: (id, device) =>
      axiosInstance.put(`/admin/announcement/toggle-published-device/${id}`, {
        device,
      }),
    toggleArchive: (id) =>
      axiosInstance.put(
        `/admin/announcement/toggle-announcement-deletion/${id}`,
      ),
    updateOrder: (rows) =>
      axiosInstance.put('/admin/announcement/order-announcements', rows),
    multipleUpload: (annId) => (file) =>
      axiosInstance.post(
        `/uploads/multiple-upload/announcement/${annId}`,
        file,
      ),
  },
  campaign: {
    list: () => axiosInstance.get('/admin/campaigns'),
    create: (data) => axiosInstance.post('/admin/campaigns', data),
    remove: (id) => axiosInstance.delete(`/admin/campaigns/${id}`),
    update: (id, data) => axiosInstance.put(`/admin/campaigns/${id}`, data),
  },
  survey: {
    get: (surveyId) => axiosInstance.get(`/surveys/${surveyId}`),
    update: (surveyId, surveyData) =>
      axiosInstance.put(`/admin/surveys/${surveyId}`, surveyData),
    downloadPDFData: (surveyId) =>
      axiosInstance.get(`/surveys/download-pdf-data/${surveyId}`),
  },
};

export default api;
