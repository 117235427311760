import PropTypes from 'prop-types';
import React from 'react';
import { Descriptions, Form, Input } from 'antd';
import { usePadSurveyContext } from '../PadSurveyContext';

export default function SurveyApproachStep(props) {
  const { isEditing, toggleStepVisibility } = usePadSurveyContext();
  const { step } = props;
  const [content, setContent] = React.useState([]);

  React.useEffect(() => {
    const values = [];

    if (step?.approach_procedures) {
      const label = 'Approach Procedures';
      const value = step.approach_procedures;
      values.push([label, value]);
    }

    if (step?.visual_reference) {
      const label = 'Nearby Visual Reference Points';
      const value = step.visual_reference;
      values.push([label, value]);
    }

    if (values.length) toggleStepVisibility('approach', true);
    else toggleStepVisibility('approach', false);

    setContent(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  if (isEditing) {
    return (
      <Descriptions
        size="small"
        bordered
        column={1}
        labelStyle={{ width: 280 }}
      >
        <Descriptions.Item className="py-2 px-2" label="Approach procedures">
          <Form.Item
            name={['approach', 'approach_procedures']}
            className="w-100 mb-0"
          >
            <Input.TextArea
              autoSize
              placeholder="Please provide a description of any known approach procedures, areas to not overfly or local noise sensitive areas"
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item
          className="py-2 px-2"
          label="Nearby Visual Reference Points"
        >
          <Form.Item
            name={['approach', 'visual_reference']}
            className="w-100 mb-0"
          >
            <Input.TextArea
              autoSize
              placeholder="Please provide details & rough bearing/distance of any landmarks or obvious nearby visual cues that could help the pilot locate the site. Road junctions, rivers, lakes & tall buildings etc"
            />
          </Form.Item>
        </Descriptions.Item>
      </Descriptions>
    );
  }

  return (
    <Descriptions
      size="small"
      bordered
      column={1}
      labelStyle={{ width: '240px' }}
      contentStyle={{ whiteSpace: 'pre-line' }}
    >
      {content.map(([label, value]) => (
        <Descriptions.Item
          key={label.toLowerCase().replace(' ', '_')}
          className="py-2 px-2"
          label={label}
        >
          {value}
        </Descriptions.Item>
      ))}
    </Descriptions>
  );
}

SurveyApproachStep.propTypes = {
  step: PropTypes.object,
};

SurveyApproachStep.defaultProps = {
  step: {},
};
