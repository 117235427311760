import axios from 'axios';
import axiosInstance from '../../utils/apiConfig';

export async function getPadSurvey(padId) {
  try {
    const res = await axiosInstance.get(`/admin/surveys/${padId}`);
    return Promise.resolve(res.data);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return Promise.reject(error.response.data.message);
    }

    return Promise.reject(error.toString());
  }
}

export async function getPadSurveyUploads(surveyId) {
  try {
    const res = await axiosInstance.get(
      `/admin/surveys/get-uploads/${surveyId}`,
    );
    return Promise.resolve(res.data);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return Promise.reject(error.response.data.message);
    }

    return Promise.reject(error.toString());
  }
}

function validateSurveyStep(stepName, values) {
  const validation = [];

  let completed = false;
  let method = 'every';

  if (stepName === 'general') {
    validation.push(Boolean(values?.name));
    validation.push(Boolean(values?.category_id));
    validation.push(Boolean(values?.phone));
    validation.push(Boolean(values?.email));
    validation.push(Boolean(values?.address));
    validation.push(Boolean(values?.minimum_notice));
    validation.push(Boolean(values?.minimum_notice_details));
    validation.push(Boolean(values?.ppr));
    validation.push(Boolean(values?.ppr_details));
  }

  if (stepName === 'landing') {
    validation.push(Boolean(values?.size));
    validation.push(Boolean(values?.size_unit));
    validation.push(Boolean(values?.size_details));
    validation.push(Boolean(values?.surface));
    validation.push(Boolean(values?.slope));

    if (values?.surface === 'other') {
      validation.push(Boolean(values?.surface_details));
    }

    if (values?.are_secondary_landing_area === 'yes') {
      validation.push(Boolean(values?.secondary_landing_area_details));
    }
  }

  if (stepName === 'hazards') {
    if (values?.hazards_categories?.length) {
      if (values.hazards_categories.includes('other')) {
        validation.push(Boolean(values?.hazards_details));
      }
    } else {
      validation.push(Boolean(values.hasOwnProperty('no_hazards_within')));
    }
  }

  if (stepName === 'approach') {
    validation.push(Boolean(values?.approach_procedures));
    validation.push(Boolean(values?.visual_reference));
    validation.push(Boolean(values.hasOwnProperty('no_hazards_within')));

    method = 'some';
  }

  if (stepName === 'facilities') {
    if (values?.categories?.includes('other')) {
      validation.push(Boolean(values?.categories_details));
    }

    if (values?.is_charge_landing_fee === 'yes') {
      validation.push(Boolean(values?.range_fee));
      validation.push(Boolean(values?.range_fee_details));
    }
  }

  if (stepName === 'view') {
    validation.push(values?.count === 4);
  }

  if (method === 'some') completed = validation.some(Boolean);
  else completed = validation.every(Boolean);

  return {
    completed,
    values,
  };
}

export function prepareSurveyBeforeUpdate(data) {
  const preparedSurvey = {};

  Object.keys(data).forEach((step) => {
    preparedSurvey[step] = validateSurveyStep(step, data[step]);
  });

  return preparedSurvey;
}

export async function updatePadSurvey(padId, data) {
  try {
    const response = await axiosInstance.post(`/surveys/${padId}`, data);
    return Promise.resolve(response.data);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return Promise.reject(error.response.data.message);
    }

    return Promise.reject(error.toString());
  }
}

export async function updateSurveyStatus(padId, status) {
  await axiosInstance.post(`/surveys/${padId}`, { status });
  return Promise.resolve();
}
