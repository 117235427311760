import { useEffect, useState, useCallback } from 'react';
import { useMount, useSessionStorage, useUnmount } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  DownOutlined,
  FlagOutlined,
} from '@ant-design/icons';
import { Menu, Modal, notification, Popconfirm, Spin } from 'antd';
import PageHeader from '../../uiKitComponents/pageHeader';
import Table from '../../uiKitComponents/table';
import buildTableColumns from '../../utils/buildTableColumns';
import FiltersSection from '../../components/filtersSection';
import Alert from '../../uiKitComponents/alert';
import Button from '../../uiKitComponents/button';
import Dropdown from '../../uiKitComponents/dropdown';
import Tooltip from '../../uiKitComponents/tooltip';
import Space from '../../uiKitComponents/space';
import Rate from '../../uiKitComponents/rate';
import Progress from '../../uiKitComponents/progress';
import {
  getReviewsList,
  cleanReviewsList,
  toggleSingleReviewStatus,
  setCleanOnUnmountTrue,
  setCleanOnUnmountFalse,
  removeSingleReviewReport,
} from '../../store/actions/reviewsListAction';
import api from '../../utils/appApi';
import formattingFilters from '../../utils/formattingFilters';
import toRating from '../../utils/toRating';
import { getUserFilters } from '../../store/actions/userFiltersAction';
import Descriptions from '../../uiKitComponents/descriptions';
import getApiErrorMessages from '../../utils/getApiErrorMessages';

const initialState = {
  page: 1,
  sort_column: 'id',
  sort_direction: 'descend',
  filters: [{}],
  keyword: undefined,
  filter_info: undefined,
  filtersTrigger: false,
};

const CommentsList = () => {
  const [state, setState] = useSessionStorage(
    'reviews-list-state',
    initialState,
  );
  const {
    page,
    sort_column,
    sort_direction,
    filters,
    keyword,
    filter_info,
    filtersTrigger,
  } = state;
  const [error, setError] = useState();
  const [selectedFilter, setSelectedFilter] = useState('initial');
  const [revisionData, setRevisionData] = useState();
  const { cleanOnUnmount, reviewsList, savedFilters } = useSelector(
    (store) => ({
      cleanOnUnmount: store.reviewsList.cleanOnUnmount,
      reviewsList: store.reviewsList.payload,
      savedFilters: store.userFilters.payload,
    }),
  );
  const dispatch = useDispatch();

  useMount(() => {
    setCleanOnUnmountTrue()(dispatch);
  });

  useEffect(() => {
    const advancedFilters = {};
    filters.forEach((filter) => {
      const temp_filter = formattingFilters(filter, filter.keyword, 'STRING');
      Object.keys(temp_filter).forEach((key) => {
        advancedFilters[`filters[${key}]`] = temp_filter[key];
      });
    });

    getReviewsList({
      params: {
        page,
        sort_column,
        sort_direction: sort_direction || 'ascend',
        keyword,
        ...advancedFilters,
      },
    })(dispatch).catch((err) => {
      if (err.response.status === 500) {
        setError({
          status: err.response.status,
          message: err.response.data.message,
        });
        sessionStorage.removeItem('reviews-list-state');
      }
    });
    // eslint-disable-next-line
  }, [page, sort_column, sort_direction, filtersTrigger, dispatch]);

  useUnmount(() => {
    if (cleanOnUnmount) {
      sessionStorage.removeItem('reviews-list-state');
      cleanReviewsList()(dispatch);
    }
  });

  const onTableChange = useCallback(
    (pagination, tableFilters, sorter, extra) => {
      const newState = {};
      if (extra.action === 'paginate') newState.page = pagination.current;
      else if (extra.action === 'sort') {
        newState.sort_column = sorter.field;
        newState.sort_direction = sorter.order;
      }
      setState((prevState) => ({ ...prevState, ...newState }));
    },
    [setState],
  );

  const onSubmitFilters = useCallback(
    (values) =>
      setState((prevState) => ({
        ...prevState,
        ...values,
        filtersTrigger: !prevState.filtersTrigger,
      })),
    [setState],
  );

  const clearFilters = useCallback(
    () =>
      setState((prevState) => ({
        ...initialState,
        filtersTrigger: !prevState.filtersTrigger,
      })),
    [setState],
  );

  const toggleCommentStatus = (reviewId) => {
    api.reviews
      .toggleStatus(reviewId)
      .then(() => {
        toggleSingleReviewStatus(reviewId)(dispatch);
        notification.success({ message: 'Success!' });
      })
      .catch((err) =>
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(err),
        }),
      );
  };

  const setSavedFilter = (id = null) => {
    if (id) {
      let filter = {};
      if (savedFilters.length) {
        filter = savedFilters.find((itm) => itm.id === id);
      } else {
        filter = savedFilters;
      }
      const rows = Object.keys(filter.filters).map((row) =>
        formattingFilters(row, filter.filters[row], 'OBJECT'),
      );
      onSubmitFilters({
        filters: rows,
        keyword: undefined,
        filter_info: { id, name: filter.name, is_public: filter.is_public },
      });
    }
  };

  useEffect(() => {
    if (selectedFilter) {
      getUserFilters(reviewsList?.entity)(dispatch)
        .then(() => {
          if (selectedFilter !== 'initial') {
            setSavedFilter(selectedFilter);
            setSelectedFilter(undefined);
          }
        })
        .catch((err) => {
          if (err.response?.status === 500) {
            setError({
              status: err.response.status,
              message: err.response.data.message,
            });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewsList?.entity, selectedFilter]);

  const menu = (
    <Menu>
      {savedFilters.length &&
        savedFilters.map((filter, idx) => (
          <Menu.Item key={idx} onClick={() => setSavedFilter(filter.id)}>
            {filter.name}
          </Menu.Item>
        ))}
    </Menu>
  );

  const reportContent = (
    <Descriptions size="small" bordered column={1}>
      <Descriptions.Item label="Date">
        {revisionData?.created_at}
      </Descriptions.Item>
      <Descriptions.Item label="User">
        {revisionData?.user.name}
      </Descriptions.Item>
      <Descriptions.Item label="Type">
        {revisionData?.user.role_name}
      </Descriptions.Item>
      <Descriptions.Item label="Comment">
        {revisionData?.comment}
      </Descriptions.Item>
    </Descriptions>
  );

  const showReportData = useCallback(
    (id) => () => {
      api.reviews.getRevision(id).then((res) => {
        setRevisionData(res.data);
      });
    },
    [],
  );

  const acceptBtnHandle = useCallback(
    () => () => {
      api.reviews.acceptRevision(revisionData?.id).then(() => {
        removeSingleReviewReport(revisionData?.review.id)(dispatch);
      });
    },
    [revisionData, dispatch],
  );

  const ignoreBtnHandle = useCallback(
    () => () => {
      api.reviews.ignoreRevision(revisionData?.id).then(() => {
        removeSingleReviewReport(revisionData?.review.id)(dispatch);
      });
    },
    [revisionData, dispatch],
  );

  const commentRatings = async (id) => {
    let content = <Spin />;
    let title = 'Loading...';
    try {
      const data = await api.reviews.getRaiting(id);
      title = `Comment #${data.data.id}`;
      content = (
        <>
          <Space align="baseline" size="middle" className="d-flex">
            <Rate
              value={data?.data?.landing}
              disabled
              allowHalf
              className="d-block"
              style={{ zoom: 1.1 }}
            />
            <span style={{ fontSize: '1.2em' }}>
              {toRating(data?.data?.landing)} for ease of landings
            </span>
          </Space>
          <Space align="baseline" size="middle" className="d-flex">
            <Rate
              value={data?.data?.helifriendliness}
              disabled
              allowHalf
              className="d-block"
              style={{ zoom: 1.1 }}
            />
            <span style={{ fontSize: '1.2em' }}>
              {toRating(data?.data?.helifriendliness)} for heli-friendliness
            </span>
          </Space>
          <Space align="baseline" size="middle" className="d-flex">
            <Rate
              value={data?.data?.food}
              disabled
              allowHalf
              className="d-block"
              style={{ zoom: 1.1 }}
            />
            <span style={{ fontSize: '1.2em' }}>
              {toRating(data?.data?.food)} for food
            </span>
          </Space>
          <Space align="baseline" size="middle" className="d-flex">
            <Rate
              value={data?.data?.service}
              disabled
              allowHalf
              className="d-block"
              style={{ zoom: 1.1 }}
            />
            <span style={{ fontSize: '1.2em' }}>
              {toRating(data?.data?.service)} for service
            </span>
          </Space>
          <Space align="baseline" size="middle" className="d-flex">
            <Progress
              style={{ width: 145, height: 38 }}
              className="d-flex align-items-center"
              percent={data?.data.is_visited_again}
              showInfo={false}
              status="success"
            />
            <span style={{ fontSize: '1.2em' }}>
              {data?.data.is_visited_again ? '100%' : '0%'} would visit again
            </span>
          </Space>
        </>
      );
    } catch (e) {
      content = `Error: ${e}`;
    }

    Modal.info({
      title,
      content,
      onOk() {},
    });
  };

  const getReviewToggleButton = (item) => {
    const { id, is_reported, is_review_approved } = item;

    if (is_reported) {
      return (
        <Tooltip title="Reported">
          <Button shape="circle" className="error" icon={<FlagOutlined />} />
        </Tooltip>
      );
    }

    return (
      <Tooltip title={is_review_approved ? 'Hide' : 'Show'}>
        <Button
          shape="circle"
          onClick={() => toggleCommentStatus(id)}
          icon={is_review_approved ? <EyeOutlined /> : <EyeInvisibleOutlined />}
        />
      </Tooltip>
    );
  };

  if (error)
    return (
      <PageHeader className="page-main-header" title="Reviews">
        <Alert
          showIcon
          type="error"
          message={`Error ${error.status}`}
          description={error.message}
        />
      </PageHeader>
    );

  return (
    <div>
      <PageHeader
        className="page-main-header"
        title="Reviews"
        extra={[
          <Dropdown
            overlay={menu}
            trigger={['click']}
            placement="bottomRight"
            disabled={!savedFilters.length}
            key="saved_filters"
          >
            <Button loading={!savedFilters}>
              Saved Filters
              <DownOutlined />
            </Button>
          </Dropdown>,
        ]}
      >
        <div className="filters-container">
          <FiltersSection
            clearFilters={clearFilters}
            onSubmit={onSubmitFilters}
            filters={filters}
            keyword={keyword}
            columnOptions={reviewsList.filterable}
            loading={!reviewsList.filterable}
            filter_info={filter_info}
            filter_columns={reviewsList?.columns}
            entity={reviewsList?.entity}
            onUpdateFilter={setSelectedFilter}
          />
        </div>
      </PageHeader>
      <div className="page-main-content">
        <Space
          className="d-flex justify-content-end"
          style={{ color: '#d9d9d9' }}
        >
          Showing {reviewsList?.meta?.from}-{reviewsList?.meta?.to} of{''}
          {reviewsList?.meta?.total} listings
        </Space>
        <Table
          className="cell-fixed-width"
          columns={[
            ...buildTableColumns(reviewsList?.columns, {
              promisedClbk: () => setCleanOnUnmountFalse()(dispatch),
              rating: {
                render: (data, rowData) => (
                  <Button
                    size="small"
                    disabled={!data}
                    onClick={() => commentRatings(rowData.id)}
                  >
                    {data || '-'}
                  </Button>
                ),
              },
              comment: {
                render: (_, rowData) =>
                  rowData.is_reported ? (
                    <Popconfirm
                      title={reportContent}
                      onConfirm={acceptBtnHandle()}
                      onCancel={ignoreBtnHandle()}
                      okText="Remove"
                      cancelText="Ignore"
                      onClick={showReportData(rowData.id)}
                    >
                      <Button
                        style={{
                          height: 'unset',
                          textAlign: 'left',
                          whiteSpace: 'unset',
                        }}
                      >
                        {rowData.comment}
                      </Button>
                    </Popconfirm>
                  ) : (
                    rowData.comment
                  ),
              },
            }),
            {
              title: 'Action',
              key: 'action',
              render: (_, rowData) => getReviewToggleButton(rowData),
            },
          ]}
          rowKey="id"
          rowClassName={(record) =>
            !record.is_review_approved ? 'transparent-el' : ''
          }
          dataSource={reviewsList?.data}
          loading={!reviewsList?.data}
          onChange={onTableChange}
          pagination={{
            position: ['topRight', 'bottomRight'],
            showSizeChanger: false,
            showQuickJumper: true,
            pageSize: reviewsList?.meta?.per_page,
            total: reviewsList?.meta?.total,
            current: page,
          }}
        />
        <Space
          className="d-flex justify-content-end"
          style={{ color: '#d9d9d9' }}
        >
          Showing {reviewsList?.meta?.from}-{reviewsList?.meta?.to} of{''}
          {reviewsList?.meta?.total} listings
        </Space>
      </div>
    </div>
  );
};

export default CommentsList;
