import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import PadViewContext from '../../PadView.context';

export function EditActions(props) {
  const { isEditing, setIsEditing } = props;
  const { pad } = React.useContext(PadViewContext);
  const { loadingBarEnabled } = useSelector((state) => ({
    loadingBarEnabled: state.loadingBar.enabled,
  }));

  const actionDisabled = React.useMemo(
    () => [pad?.is_private, pad?.deleted_at].some(Boolean),
    [pad],
  );

  return (
    <Button.Group>
      <Button
        htmlType="button"
        disabled={actionDisabled}
        onClick={() => setIsEditing(!isEditing)}
        key={isEditing ? 'cancel-editing-pad-page' : 'start-editing-pad-page'}
      >
        {isEditing ? 'Cancel' : 'Edit pad'}
      </Button>
      {isEditing && (
        <Button type="primary" htmlType="submit" disabled={loadingBarEnabled}>
          Save changes
        </Button>
      )}
    </Button.Group>
  );
}

EditActions.propTypes = {
  isEditing: PropTypes.bool,
  setIsEditing: PropTypes.func,
};
