export default function openCSV(data, { filename = 'data.csv' }) {
  const downloadLink = document.createElement('a');
  const blob = new Blob(['\ufeff', data], {
    type: 'text/csv;charset=utf-8;',
  });
  const url = URL.createObjectURL(blob);

  downloadLink.href = url;
  downloadLink.download = filename;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}
