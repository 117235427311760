import { useEffect, useState, useCallback } from 'react';
import { useMount, useSessionStorage, useUnmount } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import moment from 'moment';
import PageHeader from '../../uiKitComponents/pageHeader';
import Table from '../../uiKitComponents/table';
import Form from '../../uiKitComponents/form';
import DatePicker from '../../uiKitComponents/datePicker';
import Alert from '../../uiKitComponents/alert';
import Button from '../../uiKitComponents/button';
import * as reports from '../../store/actions/reportPagesAction';
import simulateNativeLink from '../../utils/simulateNativeLink';
import { ReactComponent as NoDates } from '../../assets/svg/no-dates.svg';

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';

const initialState = {
  page: 1,
  sort_column: 'id',
  sort_direction: 'descend',
  dates: { from: { string: '', locale: '' }, to: { string: '', locale: '' } },
  filtersTrigger: false,
};

const PadStatsFav = () => {
  const [state, setState] = useSessionStorage('pad-stats-fav', {
    ...initialState,
  });
  const { page, filtersTrigger, dates } = state;
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const { cleanOnUnmount, reportsList } = useSelector((store) => ({
    cleanOnUnmount: store.reportPages.cleanOnUnmount,
    reportsList: store.reportPages.payload.padStatsFav,
  }));

  const dispatch = useDispatch();
  const history = useHistory();

  useMount(() => {
    reports.setCleanOnUnmountTrue()(dispatch);
  });

  const goTo = (path) => (e) => {
    reports
      .setCleanOnUnmountFalse()(dispatch)
      .then(() => {
        simulateNativeLink(path, () => history.push(path))(e);
      });
  };

  const fetchPadStats = () => {
    setLoading(true);

    reports
      .getPadStatsFav({ from: dates.from.string, to: dates.to.string })(
        dispatch,
      )
      .catch((err) => {
        if (err.response.status === 500) {
          setError({
            status: err.response.status,
            message: err.response.data.message,
          });
          sessionStorage.removeItem('pad-stats-fav');
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchPadStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersTrigger, dispatch, dates]);

  useUnmount(() => {
    if (cleanOnUnmount) {
      sessionStorage.removeItem('pad-stats-fav');
      reports.cleanReports()(dispatch);
    }
  });

  useEffect(() => {
    if (reportsList?.data) {
      setDataSource(() => reportsList.data.map((data) => data));
    }
  }, [reportsList]);

  const onTableChange = useCallback(
    (pagination, tableFilters, sorter, extra) => {
      const newState = {};
      if (extra.action === 'paginate') newState.page = pagination.current;
      else if (extra.action === 'sort') {
        newState.sort_column = sorter.field;
        newState.sort_direction = sorter.order;
      }
      setState((prevState) => ({ ...prevState, ...newState }));
    },
    [setState],
  );

  const columns = [
    {
      dataIndex: 'id',
      title: 'ID',
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
      render: (data) => data,
    },
    {
      dataIndex: 'name',
      title: 'Name',
      render: (data, rowData) => (
        <Button
          type="link"
          onClick={goTo(`/dashboard/pad-view/${rowData?.id}`)}
        >
          {data}
        </Button>
      ),
    },
    {
      dataIndex: 'favourites',
      title: 'Favourites',
      sortDirections: ['descend', 'ascend', 'descend'],
      sorter: (a, b) => a.favourites - b.favourites,
      render: (data) => data,
    },
    {
      dataIndex: 'reviews',
      title: 'Reviews',
      sortDirections: ['descend', 'ascend', 'descend'],
      sorter: (a, b) => a.reviews - b.reviews,
      render: (data) => data,
    },
  ];

  const getTableEmptyText = useCallback(() => {
    if (dates.from?.string && dates.to?.string && !dataSource.length) {
      return {
        code: 'no_data',
        content: (
          <div className="ant-empty ant-empty-normal">
            <div className="ant-empty-image">
              <svg
                className="ant-empty-img-simple"
                width="64"
                height="41"
                viewBox="0 0 64 41"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                  <ellipse
                    className="ant-empty-img-simple-ellipse"
                    cx="32"
                    cy="33"
                    rx="32"
                    ry="7"
                  />
                  <g className="ant-empty-img-simple-g" fillRule="nonzero">
                    <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z" />
                    <path
                      d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                      className="ant-empty-img-simple-path"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <p className="ant-empty-description">No Data</p>
          </div>
        ),
      };
    }

    return {
      code: 'no_dates',
      content: (
        <div className="ant-empty ant-empty-normal">
          <div className="ant-empty-image">
            <NoDates />
          </div>
          <p className="ant-empty-description">Select dates rage</p>
        </div>
      ),
    };
  }, [dates, dataSource]);

  const onSelectedDatesHandler = (values) => {
    const { range } = values;

    if (range && range.length) {
      const date_from = dayjs(range[0].$d).format('DD-MM-YYYY');
      const date_to = dayjs(range[1].$d).format('DD-MM-YYYY');

      setState((prevState) => ({
        ...prevState,
        dates: {
          from: { string: date_from, locale: range[0] },
          to: { string: date_to, locale: range[1] },
        },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        dates: {
          from: { string: '', locale: '' },
          to: { string: '', locale: '' },
        },
      }));
    }
  };

  const getMomentDate = (date) => {
    if (date) return moment(date, dateFormat);

    return null;
  };

  if (error)
    return (
      <PageHeader className="page-main-header" title="Engagement - Fav/Rev">
        <Alert
          showIcon
          type="error"
          message={`Error ${error.status}`}
          description={error.message}
        />
      </PageHeader>
    );

  return (
    <div>
      <PageHeader className="page-main-header" title="Engagement - Fav/Rev" />
      <Form layout="inline" className="mb-4" onFinish={onSelectedDatesHandler}>
        <Form.Item
          name="range"
          label="Select Dates"
          initialValue={[
            getMomentDate(dates.from?.locale),
            getMomentDate(dates.to?.locale),
          ]}
        >
          <RangePicker
            format="DD/MM/YYYY"
            disabledDate={(date) => date.isAfter(moment())}
          />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Select
        </Button>
      </Form>
      <div className="page-main-content">
        <Table
          className={getTableEmptyText().code}
          columns={columns}
          rowKey={(e) => e.id}
          dataSource={dataSource}
          locale={{ emptyText: getTableEmptyText().content }}
          loading={loading}
          onChange={onTableChange}
          pagination={{
            position: ['topRight', 'bottomRight'],
            showSizeChanger: false,
            showQuickJumper: true,
            pageSize: 25,
            total: reportsList?.meta?.total,
            current: page,
          }}
        />
      </div>
    </div>
  );
};

PadStatsFav.defaultProps = {
  reportPageProps: {},
  row_action: [],
  row_inner_action: [],
};

PadStatsFav.propTypes = {
  reportPageProps: PropTypes.shape({
    sessionStorageName: PropTypes.string,
    pageTitle: PropTypes.string,
    stateName: PropTypes.string,
    actionName: PropTypes.string,
    default_sort_column: PropTypes.string,
  }),
  row_action: PropTypes.array,
  row_inner_action: PropTypes.array,
};

export default PadStatsFav;
