import arrIsEqual from '../../utils/arrIsEqual';
import fieldsErrors from '../../utils/fieldsErrors';
import objectsAreSame from '../../utils/objectsAreSame';

const SELECTED_NEXT = { conflicts: true, checked: 'next' };
const SELECTED_PREV = { conflicts: false, checked: 'prev' };
const SELECTED_PREV_CONFLICT = { conflicts: true, checked: 'prev' };
const NULL_INFO = { conflicts: false, checked: null };

export function defineRowConfig(type, readOnly = false, additionalChecks) {
  const byText = (prev, next) => {
    if (!additionalChecks?.deletedAtNext?.length && additionalChecks.deletedAtPrev?.length) return SELECTED_NEXT;
    if (!additionalChecks?.deletedAtPrev?.length && additionalChecks.deletedAtNext?.length) return SELECTED_PREV_CONFLICT;

    if (!additionalChecks.isOwnerNext && additionalChecks.isOwnerPrev) return SELECTED_PREV_CONFLICT;
    if (!additionalChecks.isOwnerPrev && additionalChecks.isOwnerNext) return SELECTED_NEXT;


    if (!prev && !next) return NULL_INFO;
    if (!next) return SELECTED_PREV;
    if (!prev) return SELECTED_NEXT;

    if (prev !== next) return SELECTED_NEXT;

    return SELECTED_PREV;
  };

  const byBool = (prev, next) => {
    if (!additionalChecks.deletedAtNext?.length && additionalChecks.deletedAtPrev?.length) return SELECTED_NEXT;
    if (!additionalChecks.deletedAtPrev?.length && additionalChecks.deletedAtNext?.length) return SELECTED_PREV_CONFLICT;

    if (!additionalChecks.isOwnerNext && additionalChecks.isOwnerPrev) return SELECTED_PREV_CONFLICT;
    if (!additionalChecks.isOwnerPrev && additionalChecks.isOwnerNext) return SELECTED_NEXT;

    if (prev === next) return NULL_INFO;
    if (prev === null) return SELECTED_NEXT;
    if (next === null) return SELECTED_PREV;
    return SELECTED_NEXT;
  };

  const bySurvey = (prev, next) => {
    if (!additionalChecks.deletedAtNext?.length && additionalChecks.deletedAtPrev?.length) return SELECTED_NEXT;
    if (!additionalChecks.deletedAtPrev?.length && additionalChecks.deletedAtNext?.length) return SELECTED_PREV_CONFLICT;


    if (!additionalChecks.isOwnerNext && additionalChecks.isOwnerPrev) return SELECTED_PREV_CONFLICT;
    if (!additionalChecks.isOwnerPrev && additionalChecks.isOwnerNext) return SELECTED_NEXT;

    if (additionalChecks.surveyProgressPrev < additionalChecks.surveyProgressNext) return SELECTED_NEXT;
    if (additionalChecks.surveyProgressPrev > additionalChecks.surveyProgressNext) return SELECTED_PREV_CONFLICT;

    if (!prev && !next) return NULL_INFO;
    if (!next) return SELECTED_PREV;
    if (!prev) return SELECTED_NEXT;

    return SELECTED_PREV;
  };

  const byArray = (prev, next) => {
    if (!additionalChecks.deletedAtNext?.length && additionalChecks.deletedAtPrev?.length) return SELECTED_NEXT;
    if (!additionalChecks.deletedAtPrev?.length && additionalChecks.deletedAtNext?.length) return SELECTED_PREV_CONFLICT;

    if (!additionalChecks.isOwnerNext && additionalChecks.isOwnerPrev) return SELECTED_PREV_CONFLICT;
    if (!additionalChecks.isOwnerPrev && additionalChecks.isOwnerNext) return SELECTED_NEXT;

    if (!prev.length && !next.length) return NULL_INFO;
    if (prev.length && !next.length) return SELECTED_PREV_CONFLICT;
    if (!prev.length && next.length) return SELECTED_NEXT;
    if (!prev.length) return SELECTED_NEXT;
    if (!next.length) return SELECTED_PREV;
    if (arrIsEqual(prev, next)) return SELECTED_PREV;

    return SELECTED_NEXT;
  };

  const byArrayObjects = (prev, next) => {
    if (!additionalChecks.deletedAtNext?.length && additionalChecks.deletedAtPrev?.length) return SELECTED_NEXT;
    if (!additionalChecks.deletedAtPrev?.length && additionalChecks.deletedAtNext?.length) return SELECTED_PREV_CONFLICT;

    if (!additionalChecks.isOwnerNext && additionalChecks.isOwnerPrev) return SELECTED_PREV_CONFLICT;
    if (!additionalChecks.isOwnerPrev && additionalChecks.isOwnerNext) return SELECTED_NEXT;

    if (!prev.length && !next.length) return NULL_INFO;
    if (prev.length === next.length) {
      return prev.every((o, i) => objectsAreSame(o, next[i])) === true
        ? SELECTED_PREV
        : SELECTED_NEXT;
    }

    if (prev.length < next.length) {
      return SELECTED_NEXT;
    }

    return SELECTED_PREV;
  };

  const byContacts = (prev, next) => {
    if (!additionalChecks.deletedAtNext?.length && additionalChecks.deletedAtPrev?.length) return SELECTED_NEXT;
    if (!additionalChecks.deletedAtPrev?.length && additionalChecks.deletedAtNext?.length) return SELECTED_PREV_CONFLICT;

    if (!additionalChecks.isOwnerNext && additionalChecks.isOwnerPrev) return SELECTED_PREV_CONFLICT;
    if (!additionalChecks.isOwnerPrev && additionalChecks.isOwnerNext) return SELECTED_NEXT;

    // Automatic merge
    if (readOnly) return NULL_INFO;

    let conflict = true;

    const contactsPrevLen = prev.length;
    const contactsNextLen = next.length;

    if (!contactsPrevLen && !contactsNextLen) return NULL_INFO;
    if (contactsPrevLen > contactsNextLen) return SELECTED_PREV;
    if (contactsPrevLen < contactsNextLen) return SELECTED_NEXT;

    if (contactsPrevLen === contactsNextLen) {
      const sidesScore = { prev: 0, next: 0 };

      prev.forEach((c) => {
        if (c.name) sidesScore.prev += 1;
        if (c.phone) sidesScore.prev += 1;
        if (c.email) sidesScore.prev += 1;
      });
      next.forEach((c) => {
        if (c.name) sidesScore.next += 1;
        if (c.phone) sidesScore.next += 1;
        if (c.email) sidesScore.next += 1;
      });

      if (sidesScore.prev !== 0 && sidesScore.prev === sidesScore.next) {
        for (let i = 0; i < prev.length; i++) {
          const prevCurrentContact = prev[i];
          const nextCurrentContact = next.find(
            (c) => c.id === prevCurrentContact.id,
          );

          if (!nextCurrentContact) return SELECTED_NEXT;

          if (!objectsAreSame(prevCurrentContact, nextCurrentContact)) {
            return SELECTED_NEXT;
          }
        }
      }

      if (sidesScore.prev === sidesScore.next) conflict = false;
      if (sidesScore.prev < sidesScore.next) return SELECTED_NEXT;
    }

    return { ...SELECTED_PREV, conflicts: conflict };
  };

  const bySelectOpt = (prev, next) => {
    if (!additionalChecks.deletedAtNext?.length && additionalChecks.deletedAtPrev?.length) return SELECTED_NEXT;
    if (!additionalChecks.deletedAtPrev?.length && additionalChecks.deletedAtNext?.length) return SELECTED_PREV_CONFLICT;

    if (!additionalChecks.isOwnerNext && additionalChecks.isOwnerPrev) return SELECTED_PREV_CONFLICT;
    if (!additionalChecks.isOwnerPrev && additionalChecks.isOwnerNext) return SELECTED_NEXT;

    if (!prev?.value && !next?.value) return NULL_INFO;

    if (!prev?.value) return SELECTED_NEXT;
    if (!next?.value) return SELECTED_PREV;

    if (prev?.value !== next?.value) return SELECTED_NEXT;

    return SELECTED_PREV;
  };

  switch (type) {
    case 'tags':
    case 'photos':
      return byArray;
    case 'tags_input':
      return byArrayObjects;
    case 'contacts':
      return byContacts;
    case 'select':
      return bySelectOpt;
    case "bool":
      return byBool;
    case "survey":
      return bySurvey;
    default:
      return byText;
  }
}

export const twitterUsernameHandle = [
  {
    validator: (itm, value) =>
      (value && !value.includes('@')) || !value
        ? Promise.resolve()
        : Promise.reject(new Error(fieldsErrors.withoutAt)),
  },
];

export const latValidationRules = [
  {
    validator: (itm, value) =>
      (!isNaN(value) && parseFloat(value) < 91) || isNaN(value) || !value
        ? Promise.resolve()
        : Promise.reject(new Error(fieldsErrors.max(90))),
  },
  {
    validator: (itm, value) =>
      (!isNaN(value) && parseFloat(value) > -91) || isNaN(value) || !value
        ? Promise.resolve()
        : Promise.reject(new Error(fieldsErrors.min(-90))),
  },
];

export const lngValidationRules = [
  {
    validator: (itm, value) =>
      (!isNaN(value) && parseFloat(value) < 181) || isNaN(value) || !value
        ? Promise.resolve()
        : Promise.reject(new Error(fieldsErrors.max(180))),
  },
  {
    validator: (itm, value) =>
      (!isNaN(value) && parseFloat(value) > -181) || isNaN(value) || !value
        ? Promise.resolve()
        : Promise.reject(new Error(fieldsErrors.min(-180))),
  },
];

export const parseUploadsToPicturesWall = (uploads) =>
  uploads?.map((itm) => ({
    uid: `-${itm.id}`,
    name: itm.title,
    url: itm.file_url,
    status: 'done',
  }));
