import PropTypes from 'prop-types';
import React from 'react';
import { Col, Descriptions, Form, Input, InputNumber, Row, Select } from 'antd';

export const SurveyLandingStep = React.memo(({ stepValues }) => (
  <Descriptions
    labelStyle={{ width: 150 }}
    size="small"
    bordered
    column={1}
    layout="vertical"
    className="mb-3"
  >
    <Descriptions.Item label={`Loading: ${stepValues.is_completed ? 'Completed' : 'Incomplete'}`}>
      <Row gutter={20} className="mb-3">
        <Col span={4} align="bottom">
          <Form.Item
            label="Size"
            name={['landing', 'landing_size']}
            className="m-0"
            required
          >
            <InputNumber placeholder="Size" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Size Unit"
            name={['landing', 'landing_size_measurement']}
            className="m-0"
            required
          >
            <Select>
              <Select.Option value={1}>Meters</Select.Option>
              <Select.Option value={2}>Feet</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item
            label="Size Details"
            name={['landing', 'landing_size_comment']}
            className="m-0"
            rules={[
              {
                min: 3,
                max: 200,
                message: 'Must be between 3 and 200 characters',
              },
            ]}
          >
            <Input.TextArea autoSize placeholder="Please give details" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20} className="mb-3">
        <Col span={8}>
          <Form.Item
            label="Surface"
            name={['landing', 'landing_surface']}
            className="m-0"
            required
          >
            <Select
              placeholder="Choose"
              options={[
                {
                  value: 1,
                  label: 'Concrete/Tarmac',
                },
                {
                  value: 2,
                  label: 'Grass - Maintained',
                },
                {
                  value: 3,
                  label: 'Grass - Partially maintained',
                },
                {
                  value: 4,
                  label: 'Grass - Unmaintained',
                },
                { value: 5, label: 'Gravel' },
                { value: 6, label: 'Sand' },
                { value: 99, label: 'Other' },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item
            label="Surface Details"
            name={['landing', 'landing_surface_comment']}
            className="m-0"
            required={stepValues.landing_surface === 99}
            rules={[
              {
                min: 3,
                max: 200,
                message: 'Must be between 3 and 200 characters',
              },
            ]}
          >
            <Input.TextArea autoSize placeholder="Please give details" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20} className="mb-3">
        <Col span={8}>
          <Form.Item label="Slope" name={['landing', 'landing_slope']} required>
            <Select
              placeholder="Choose"
              options={[
                { value: 1, label: 'Flat' },
                { value: 2, label: 'Gentle' },
                { value: 3, label: 'Steep' },
                { value: 4, label: 'Extreme' },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name={['landing', 'landing_slope_comment']}
            className="m-0"
            rules={[
              {
                min: 3,
                max: 200,
                message: 'Must be between 3 and 200 characters',
              },
            ]}
          >
            <Input.TextArea
              autoSize
              placeholder="Please provide more details of the above slope and it's direction of fall, along with details of any surrounding sloped ground"
            />
          </Form.Item>
        </Col>
      </Row>
    </Descriptions.Item>
  </Descriptions>
));

SurveyLandingStep.propTypes = {
  stepValues: PropTypes.object,
};
