import { useCallback, useState, useEffect } from 'react';
import { useLogger, useSessionStorage } from 'react-use';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import jsonToFormData from 'json-form-data';
import { notification } from 'antd';
import Button from '../../uiKitComponents/button';
import Result from '../../uiKitComponents/result';
import DuplicatesResolver from '../../components/dupeResolver';
import api from '../../utils/appApi';
import { selectRegionOptions } from '../../store/selectors/bootstrapSelector';
import history from '../../utils/history';
import {
  loadingBarDisable,
  loadingBarEnable,
} from '../../store/actions/loadingBarAction';
import getApiErrorMessages from '../../utils/getApiErrorMessages';

const DuplicatesCheckerPage = () => {
  const { prevPadId, nextPadId } = useParams();
  const { regions } = useSelector((store) => ({
    regions: selectRegionOptions(store),
  }));
  const dispatch = useDispatch();
  const [extraLoading1, setExtraLoading1] = useState(false);
  const [error, setError] = useState();
  const [nextOriginalId, setNextOriginalId] = useState();
  const [nextDupeId, setNextDupeId] = useState();

  const [dupeReportList, setDupeReportList] = useSessionStorage(
    'dupeReportList',
    [],
  );

  const setNextIndexes = useCallback(() => {
    const newDupeReportList = [...dupeReportList];

    const index = newDupeReportList.findIndex(
      (itm) => itm.original_id === Number(prevPadId),
    );

    if (index !== -1) {
      if (index + 2 <= newDupeReportList.length) {
        setNextOriginalId(newDupeReportList[index + 1].original_id);
        setNextDupeId(newDupeReportList[index + 1].dupe_id);
      } else if (
        index + 2 > newDupeReportList.length &&
        newDupeReportList.data.length > 1
      ) {
        setNextOriginalId(newDupeReportList[0].original_id);
        setNextDupeId(newDupeReportList[0].dupe_id);
      } else {
        history.push(`/dashboard/reports/duplicate-report`);
      }
      newDupeReportList.splice(index, 1);
      setDupeReportList(newDupeReportList);
    } else {
      history.push(`/dashboard/reports/duplicate-report`);
    }
  }, [dupeReportList, prevPadId, setDupeReportList]);

  useLogger('DuplicatesCheckerPage');

  useEffect(() => {
    if (nextOriginalId && nextDupeId) {
      history.push(
        `/dashboard/reports/duplicate-report/${nextOriginalId}/${nextDupeId}`,
      );
    }
  }, [nextOriginalId, nextDupeId]);

  const getRevision = useCallback(
    () =>
      api.duplicates
        .get(prevPadId, nextPadId)
        .then((res) => Promise.resolve(res))
        .catch((err) => {
          setError({
            status: err.response.status,
            message: err.response.data.message,
          });
          return Promise.reject(err);
        }),
    [prevPadId, nextPadId],
  );

  const onSubmit = useCallback(
    (values) => {
      const data = {
        ...values,
        'location#name': values['location#name'].address,
        'location#country': values['location#name'].country_short,
        'location#county': values['location#name'].county,
        'location#region': regions?.find(
          (itm) => itm?.value === values['location#region'],
        ),
      };

      if (values['pad_social#trip_advisor_rating'])
        data[
          'pad_social#trip_advisor_rating'
        ] = `${values['pad_social#trip_advisor_rating']}`;
      if (values['pad_social#twitter_handle'])
        data[
          'pad_social#twitter_handle'
        ] = `@${values['pad_social#twitter_handle']}`;

      const fd = jsonToFormData(data);

      return api.duplicates.merge(prevPadId, nextPadId, fd).then((res) => {
        setNextIndexes();
        notification.success({
          duration: 10,
          message: 'Success!',
          description: (
            <>
              <span>Go to pad: </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`/dashboard/pad-view/${res.data.data.id}`}
              >
                {res.data.data.name}
              </a>
            </>
          ),
        });
      });
    },
    [prevPadId, nextPadId, regions, setNextIndexes],
  );

  const goBackHandle = useCallback(() => history.goBack(), []);

  const reloadPageHandle = useCallback(() => window.location.reload(), []);

  const markAsNotDuplicate = useCallback(
    (config) => () => {
      setExtraLoading1(true);
      loadingBarEnable()(dispatch);
      api.duplicates
        .markAsNotDuplicate(config)
        .catch((err) =>
          notification.error({
            message: `Error ${err.status}`,
            description: getApiErrorMessages(err),
          }),
        )
        .finally(() => {
          setExtraLoading1(false);
          loadingBarDisable()(dispatch);
          setNextIndexes();
        });
    },
    [dispatch, setNextIndexes],
  );

  const extra = useCallback(
    (data) => (
      <Button
        htmlType="button"
        size="large"
        onClick={markAsNotDuplicate({ prev: data.prev.id, next: data.next.id })}
        loading={extraLoading1}
      >
        Mark as NOT duplicate
      </Button>
    ),
    [markAsNotDuplicate, extraLoading1],
  );

  if (error)
    return (
      <Result
        style={{ backgroundColor: '#fff' }}
        status={error.status}
        title={error.status}
        subTitle={`Error: ${error.message}`}
        extra={[
          <Button key="go-back-btn" type="primary" onClick={goBackHandle}>
            Go back
          </Button>,
          <Button key="reload-page-btn" onClick={reloadPageHandle}>
            Reload page
          </Button>,
        ]}
      />
    );

  return (
    <DuplicatesResolver
      type="report-duplicates"
      prevTitle="Original"
      nextTitle="Duplicate"
      getData={getRevision}
      onSubmit={onSubmit}
      extraRight={extra}
    />
  );
};

export default DuplicatesCheckerPage;
