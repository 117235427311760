import PropTypes from 'prop-types';
import { useRef, useEffect, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { ReactComponent as Times } from '../../assets/icons/times.svg';
import Tooltip from '../../uiKitComponents/tooltip';
import Button from '../../uiKitComponents/button';
import api from '../../utils/appApi';
import getApiErrorMessages from '../../utils/getApiErrorMessages';

const type = 'AnnouncementItem';

const AnnouncementItem = (props) => {
  const {
    layer,
    onRemove,
    moveLayer,
    index,
    isPreview,
    listStatus,
    disabled,
    onClickAction,
    ...rest
  } = props;
  const ref = useRef(null);
  const [removeLoad, setRemoveLoad] = useState(false);
  const [{ handlerId }, drop] = useDrop({
    accept: type,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveLayer(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    type,
    item: () => ({
      id: layer.id,
      index,
      name: layer.title,
      file_url: layer.file_url,
    }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  drag(drop(ref));

  const removeLayerHandler = async () => {
    try {
      setRemoveLoad(true);
      await api.upload.delete(layer.id);
    } catch (error) {
      getApiErrorMessages(error);
    } finally {
      setRemoveLoad(false);
      if (onRemove) onRemove();
    }
  };

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      data-disabled={disabled}
      className={`announcements-item ${(!!isDragging && 'drag') || ''} ${
        (!!isPreview && 'preview') || ''
      }`}
      {...rest}
    >
      {!isPreview && listStatus && (
        <Tooltip title="Remove Layer">
          <Button
            loading={removeLoad}
            className="remove-layer-btn d-flex align-items-center justify-content-center"
            shape="circle"
            icon={<Times />}
            onClick={removeLayerHandler}
            disabled={!!isDragging || disabled}
          />
        </Tooltip>
      )}
      <div className="image" aria-hidden="true" onClick={onClickAction}>
        <img src={layer.file_url} alt={layer.title} />
      </div>
    </div>
  );
};

AnnouncementItem.propTypes = {
  layer: PropTypes.object,
  onRemove: PropTypes.func,
  moveLayer: PropTypes.func,
  index: PropTypes.number,
  isPreview: PropTypes.bool,
  listStatus: PropTypes.bool,
  disabled: PropTypes.bool,
  onClickAction: PropTypes.func,
};

AnnouncementItem.defaultProps = {
  layer: {},
  onRemove: () => null,
  moveLayer: () => null,
  index: 0,
  isPreview: false,
  listStatus: true,
  disabled: false,
  onClickAction: () => null,
};

export default AnnouncementItem;
