import { useEffect, useState, useCallback } from 'react';
import { useMount, useSessionStorage, useUnmount } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { DownOutlined } from '@ant-design/icons';
import { Badge, Menu } from 'antd';
import { Link } from 'react-router-dom';
import PageHeader from '../../uiKitComponents/pageHeader';
import Table from '../../uiKitComponents/table';
import buildTableColumns from '../../utils/buildTableColumns';
import FiltersSection from '../../components/filtersSection';
import Alert from '../../uiKitComponents/alert';
import Button from '../../uiKitComponents/button';
import Dropdown from '../../uiKitComponents/dropdown';
import Space from '../../uiKitComponents/space';
import {
  getModerationList,
  cleanModerationList,
  setCleanOnUnmountTrue,
  setCleanOnUnmountFalse,
  filterModerationsByType,
  filterModerationsByTypeReported,
  filterModerationsByTypePadAdditions,
  filterModerationsByTypePurgatory,
} from '../../store/actions/moderationListAction';
import { moderationTypes } from '../../utils/constants';
import formattingFilters from '../../utils/formattingFilters';
import { getUserFilters } from '../../store/actions/userFiltersAction';
import Summary from '../../components/summary';
import history from '../../utils/history';
import simulateNativeLink from '../../utils/simulateNativeLink';
import api from '../../utils/appApi';

const initialState = {
  page: 1,
  sort_column: 'id',
  sort_direction: 'descend',
  filters: [{}],
  keyword: undefined,
  filter_info: undefined,
  filtersTrigger: false,
  selectedType: {
    name: 'All',
  },
};

const ModerationListPage = () => {
  const [state, setState] = useSessionStorage(
    'moderations-list-state',
    initialState,
  );
  const {
    page,
    sort_column,
    sort_direction,
    filters,
    keyword,
    filter_info,
    filtersTrigger,
    selectedType,
  } = state;
  const [error, setError] = useState();
  const [selectedFilter, setSelectedFilter] = useState('initial');
  const [duplicatesCount, setDuplicatesCount] = useState(0);
  const { cleanOnUnmount, moderationList, savedFilters } = useSelector(
    (store) => {
      const moderationListPayload = { ...store.moderationList.payload };

      moderationListPayload.data = moderationListPayload?.data?.map((el) => ({
        ...el,
        type: moderationTypes[el.type],
      }));

      return {
        cleanOnUnmount: store.moderationList.cleanOnUnmount,
        moderationList: moderationListPayload,
        savedFilters: store.userFilters.payload,
      };
    },
  );
  const dispatch = useDispatch();

  const fetchDuplicatesCount = useCallback(() => {
    api.duplicates.duplicatesCount().then((res) => {
      if (res.data) {
        setDuplicatesCount(res.data.dupe_count);
      }
    });
  }, []);

  useMount(() => {
    setCleanOnUnmountTrue()(dispatch);
    fetchDuplicatesCount();
  });

  useEffect(() => {
    const advancedFilters = {};
    filters.forEach((filter) => {
      const temp_filter = formattingFilters(filter, filter.keyword, 'STRING');
      Object.keys(temp_filter).forEach((key) => {
        advancedFilters[`filters[${key}]`] = temp_filter[key];
      });
    });

    const moderationListFunc = (config) => {
      const { name, type } = selectedType;

      switch (name) {
        case 'All':
          return getModerationList(config);
        case 'Pad Additions':
          return filterModerationsByTypePadAdditions(config);
        case 'Purgatory':
          return filterModerationsByTypePurgatory(config);
        case 'Reported':
          return filterModerationsByTypeReported(config);
        default:
          return filterModerationsByType(type, config);
      }
    };

    moderationListFunc({
      params: {
        page,
        sort_column,
        sort_direction: sort_direction || 'ascend',
        keyword,
        ...advancedFilters,
      },
    })(dispatch).catch((err) => {
      if (err.response.status === 500) {
        setError({
          status: err.response.status,
          message: err.response.data.message,
        });
        sessionStorage.removeItem('moderations-list-state');
      }
    });
    // eslint-disable-next-line
  }, [
    page,
    sort_column,
    sort_direction,
    filtersTrigger,
    dispatch,
    selectedType,
  ]);

  useUnmount(() => {
    if (cleanOnUnmount) {
      sessionStorage.removeItem('moderations-list-state');
      cleanModerationList()(dispatch);
    }
  });

  const onTableChange = useCallback(
    (pagination, tableFilters, sorter, extra) => {
      const newState = {};
      if (extra.action === 'paginate') newState.page = pagination.current;
      else if (extra.action === 'sort') {
        newState.sort_column = sorter.field;
        newState.sort_direction = sorter.order;
      }
      setState((prevState) => ({ ...prevState, ...newState }));
    },
    [setState],
  );

  const onSubmitFilters = useCallback(
    (values) =>
      setState((prevState) => ({
        ...prevState,
        ...values,
        filtersTrigger: !prevState.filtersTrigger,
      })),
    [setState],
  );

  const filterByType = useCallback(
    (type, name) => () => {
      setState((prevState) => ({ ...prevState, selectedType: { type, name }, page: 1 }));
    },
    [setState],
  );

  const clearFilters = useCallback(
    () =>
      setState((prevState) => ({
        ...initialState,
        filtersTrigger: !prevState.filtersTrigger,
      })),
    [setState],
  );

  const setSavedFilter = (id = null) => {
    if (id) {
      let filter = {};
      if (savedFilters.length) {
        filter = savedFilters.find((itm) => itm.id === id);
      } else {
        filter = savedFilters;
      }
      const rows = Object.keys(filter.filters).map((row) =>
        formattingFilters(row, filter.filters[row], 'OBJECT'),
      );
      onSubmitFilters({
        filters: rows,
        keyword: undefined,
        filter_info: { id, name: filter.name, is_public: filter.is_public },
      });
    }
  };

  useEffect(() => {
    if (selectedFilter) {
      getUserFilters(moderationList?.entity)(dispatch)
        .then(() => {
          if (selectedFilter !== 'initial') {
            setSavedFilter(selectedFilter);
            setSelectedFilter(undefined);
          }
        })
        .catch((err) => {
          if (err.response?.status === 500) {
            setError({
              status: err.response.status,
              message: err.response.data.message,
            });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moderationList?.entity, selectedFilter]);

  const generateTypeURL = useCallback(
    (e, rowData, withQuery) => {
      setCleanOnUnmountFalse()(dispatch).then(() => {
        const { type, id, prev } = rowData;

        let path = '/';

        if (type === 'Update') {
          path = `/dashboard/moderation-revision/${id}`;
        } else if (type === 'Claim') {
          path = `/dashboard/pad-view/${prev.id}?tab=admin`;
        } else if (
          (withQuery && type === 'Reported upload') ||
          type === 'Reported review' ||
          type === 'Reported pad'
        ) {
          path = `/dashboard/pad-view/${prev.id}?tab=admin&reported_id=${id}`;
        } else {
          path = `/dashboard/pad-view/${rowData.prev.id}`;
        }

        simulateNativeLink(path, () => history.push(path))(e);
      });
    },
    [dispatch],
  );

  const menu = (
    <Menu>
      {savedFilters.length &&
        savedFilters.map((filter, idx) => (
          <Menu.Item key={idx} onClick={() => setSavedFilter(filter.id)}>
            {filter.name}
          </Menu.Item>
        ))}
    </Menu>
  );

  const checkIfUnModerated = useCallback(
    (record) =>
      record.type === 'Addition' || record.type === 'Converted'
        ? 'transparent-el'
        : '',
    [],
  );

  const goToUser = (path) => {
    setCleanOnUnmountFalse()(dispatch).then(() => history.push(path));
  };

  const getPadUser = (_, rowData) => (
    <Button
      type="link"
      onClick={simulateNativeLink(
        `/dashboard/user-view/${rowData.user?.id}`,
        () => goToUser(`/dashboard/user-view/${rowData.user?.id}`),
      )}
    >
      <span className="d-flex align-item-center">
        {rowData.user?.name}
        {rowData.is_updated_by_own_owner && ' (Owner)'}
      </span>
    </Button>
  );

  if (error)
    return (
      <PageHeader className="page-main-header" title="Moderation List">
        <Alert
          showIcon
          type="error"
          message={`Error ${error.status}`}
          description={error.message}
        />
      </PageHeader>
    );

  return (
    <div>
      <PageHeader
        className="page-main-header"
        title="Moderation List"
        extra={[
          <Link
            to="/dashboard/reports/duplicate-report"
            key="duplicates-quick-action"
          >
            <Badge count={duplicatesCount} style={{ zIndex: 99 }}>
              <Button type="primary">Duplicates</Button>
            </Badge>
          </Link>,
          <Dropdown
            overlay={menu}
            trigger={['click']}
            placement="bottomRight"
            disabled={!savedFilters.length}
            key="saved_filters"
          >
            <Button loading={!savedFilters}>
              Saved Filters
              <DownOutlined />
            </Button>
          </Dropdown>,
        ]}
      >
        <div className="filters-container">
          <FiltersSection
            clearFilters={clearFilters}
            onSubmit={onSubmitFilters}
            filters={filters}
            keyword={keyword}
            columnOptions={moderationList.filterable}
            loading={!moderationList.filterable}
            filter_info={filter_info}
            filter_columns={moderationList?.columns}
            entity={moderationList?.entity}
            onUpdateFilter={setSelectedFilter}
          />
        </div>
      </PageHeader>

      <Summary
        data={moderationList}
        filterAction={filterByType}
        selectedType={selectedType.name}
      />

      <div className="page-main-content">
        <Space
          className="d-flex justify-content-end"
          style={{ color: '#d9d9d9' }}
        >
          Showing {moderationList?.meta?.from}-{moderationList?.meta?.to} of{''}
          {moderationList?.meta?.total} listings
        </Space>
        <Table
          columns={[
            ...buildTableColumns(moderationList?.columns, {
              promisedClbk: () => setCleanOnUnmountFalse()(dispatch),
              user: {
                render: getPadUser,
              },
              type: {
                render: (data, rowData) => (
                  <Button
                    type="secondary"
                    htmlType="button"
                    onClick={(e) => generateTypeURL(e, rowData, true)}
                    key={rowData.id}
                  >
                    {`${data || 'Uncontactable'} ${
                      (rowData?.pad_report_type_count &&
                        `[${rowData.pad_report_type_count}]`) ||
                      ''
                    }`}
                  </Button>
                ),
              },
            }),
          ]}
          rowKey="id"
          dataSource={moderationList?.data}
          loading={!moderationList?.data}
          onChange={onTableChange}
          rowClassName={checkIfUnModerated}
          pagination={{
            position: ['topRight', 'bottomRight'],
            showSizeChanger: false,
            showQuickJumper: true,
            pageSize: moderationList?.meta?.per_page,
            total: moderationList?.meta?.total,
            current: page,
          }}
        />
        <Space
          className="d-flex justify-content-end"
          style={{ color: '#d9d9d9' }}
        >
          Showing {moderationList?.meta?.from}-{moderationList?.meta?.to} of{''}
          {moderationList?.meta?.total} listings
        </Space>
      </div>
    </div>
  );
};

export default ModerationListPage;
