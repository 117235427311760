import { Tag } from 'antd';

export function generateWaringsOrFacilities(pad) {
  if (pad?.category_id === 1) return null;
  if (pad?.category_id === 7)
    return {
      label: (
        <>
          <div>Airfield facilities</div>
        </>
      ),
      content: (
        <>
          <div className="mb-2" style={{ maxWidth: 400 }}>
            {pad?.facilities?.map((itm) => (
              <Tag key={`facility-${itm.id}`}>{itm.display_name}</Tag>
            ))}
          </div>
        </>
      ),
      content_type: 'facilities',
    };

  return {
    label: (
      <>
        <div>Establishment Warnings</div>
      </>
    ),
    content: (
      <>
        <div style={{ maxWidth: 400 }}>
          {pad?.warnings?.map((itm) => (
            <Tag key={`warning-${itm.id}`}>{itm.display_name}</Tag>
          ))}
        </div>
      </>
    ),
    content_type: 'warning',
  };
}
