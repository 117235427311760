import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Form from '../../uiKitComponents/form';
import Input from '../../uiKitComponents/input';
import Modal from '../../uiKitComponents/modal';
import notification from '../../uiKitComponents/notification';
import api from '../../utils/appApi';
import getApiErrorMessages from '../../utils/getApiErrorMessages';

export default function AnnouncementCreate(props) {
  const { visible, hide, listLength } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();

  const onSubmitHandler = async ({ name }) => {
    try {
      setLoading(true);

      const response = await api.announcements.handler({
        name,
        order: listLength + 1,
      });

      if (response.data && response.data.id) {
        history.push(`/dashboard/announcements/${response.data.id}`);
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: getApiErrorMessages(error),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={hide}
      okText="Create"
      okButtonProps={{
        loading,
      }}
      onOk={form.submit}
    >
      <h3 className="mb-3">Create Announcement</h3>
      <Form layout="vertical" form={form} onFinish={onSubmitHandler}>
        <Form.Item
          name="name"
          className="mb-0"
          rules={[
            { required: true, message: 'Please enter name' },
            { min: 3, message: 'Must be at least 3 characters' },
          ]}
        >
          <Input size="large" placeholder="Announcement Name" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

AnnouncementCreate.propTypes = {
  visible: PropTypes.bool,
  hide: PropTypes.func,
  listLength: PropTypes.number.isRequired,
};

AnnouncementCreate.defaultProps = {
  visible: false,
  hide: () => null,
};
