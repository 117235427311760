import { Button, Form, Modal, notification, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  SurveyApproachStep,
  SurveyFacilitiesStep,
  SurveyGeneralStep,
  SurveyHazardsStep,
  SurveyLandingStep,
  SurveyLoading,
  SurveyViewStep,
} from './components';
import { surveyStatuses } from './PadSurvey.static';
import { useSurveyData, useSurveyUpdate } from './hooks';
import updateObjectByPath from '../../utils/updateObjectByPath';

import api from '../../utils/appApi';
import axios from 'axios';
import getApiErrorMessages from '../../utils/getApiErrorMessages';
import { fileDownload } from '../../utils/fileDownload';

import './styles/index.scss';

export function PadSurvey(props) {
  const { surveyId } = props;

  const {
    isLoading,
    surveyData,
    setSurveyData,
    updateSurveyOnFormValuesChange,
  } = useSurveyData(surveyId);

  const { isLoading: isSubmitting, updateSurvey } = useSurveyUpdate(surveyId);
  const [isDownloading, setIsDownloading] = useState(false);
  const [PDFDownloadId, setPDFDownloadId] = useState(null);

  const [form] = Form.useForm();

  const updateFormFields = React.useCallback(
    (fields = []) => {
      // Update form object
      form.setFields(fields);

      // Update survey object
      const newSurveyObject = { ...surveyData };

      fields.forEach((field) => {
        updateObjectByPath(newSurveyObject, field.value, field.name);
      });

      setSurveyData(newSurveyObject);
    },
    [form, surveyData, setSurveyData],
  );

  const beforeUpdateHandler = React.useCallback(
    (values) => {
      const valuesToUpdate = Object.values(values).reduce(
        (survey, step) => ({ ...survey, ...step }),
        {},
      );

      valuesToUpdate.view_north_comment = surveyData.view.view_north.comment;
      valuesToUpdate.view_east_comment = surveyData.view.view_east.comment;
      valuesToUpdate.view_west_comment = surveyData.view.view_west.comment;
      valuesToUpdate.view_south_comment = surveyData.view.view_south.comment;

      updateSurvey({ values: valuesToUpdate });
    },
    [surveyData?.view, updateSurvey],
  );

  const confirmSurveyUpdate = React.useCallback(() => {
    Modal.confirm({
      title: 'Are you sure you want to submit changes?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      centered: true,
      onOk: form.submit,
      zIndex: 1002,
      confirmLoading: true,
      maskClosable: true,
    });
  }, [form.submit]);

  const downloadPDF = React.useCallback(async (id) => {
    setIsDownloading(true);
    setPDFDownloadId(id);

    try {
      const survey = await api.survey.downloadPDFData(id);
      const pdf = await axios.post(
        'https://pdf.helipaddy.com/',
        { ...survey.data },
        {
          responseType: 'blob',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
        },
      );

      fileDownload(
        pdf.data,
        `Survey_${survey.data?.general?.name?.replace(/\s/g, '_')}.pdf`,
        pdf.headers['content-type'],
      );
    } catch (error) {
      notification.error({
        message: 'Error',
        description: getApiErrorMessages(error),
      });
    } finally {
      setIsDownloading(false);
      setPDFDownloadId(null);
    }
  }, []);

  if (isLoading || !surveyData) return <SurveyLoading />;

  return (
    <Form
      form={form}
      initialValues={{
        ...surveyData,
        landing: {
          ...surveyData.landing,
          landing_size_measurement:
            surveyData.landing.landing_size_measurement || 1,
        },
      }}
      onValuesChange={updateSurveyOnFormValuesChange}
      onFinish={beforeUpdateHandler}
      layout="vertical"
      className="survey-form"
      validateMessages={{
        required: 'This field is required',
      }}
    >
      <div className='d-flex justify-content-between align-items-center'>
        <Typography.Title level={5} className="mt-2 mb-3">
          Status: {`${surveyStatuses[surveyData.survey_status]} (${surveyData.progress_percentage}%)`}
        </Typography.Title>
        <Button
          loading={isDownloading}
          onClick={() => downloadPDF(surveyId)}
        >
          Download PDF
        </Button>
      </div>
      <SurveyGeneralStep stepValues={surveyData.general} />
      <SurveyLandingStep stepValues={surveyData.landing} />
      <SurveyViewStep
        stepValues={surveyData.view}
        surveyId={surveyId}
        updateFormFields={updateFormFields}
      />
      <SurveyHazardsStep
        categoryId={surveyData.general?.category_id}
        stepValues={surveyData.hazards}
      />
      <SurveyApproachStep stepValues={surveyData.approach}/>
      <SurveyFacilitiesStep stepValues={surveyData.facilities} />

      <div className="d-flex justify-content-end mt-3">
        <Button
          type="primary"
          onClick={confirmSurveyUpdate}
          loading={isSubmitting}
        >
          Update
        </Button>
      </div>
    </Form>
  );
}

PadSurvey.propTypes = {
  surveyId: PropTypes.number,
};
