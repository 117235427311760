import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Space, Tooltip } from 'antd';
import { StarFilled } from '@ant-design/icons';
import PadViewContext from '../../../../PadView.context';
import { selectContactTypeOptions } from '../../../../../../store/selectors/bootstrapSelector';
import { ContactPending } from './ContactPending';

export function ContactsList({ updateFormValues }) {
  const { contactTypes } = useSelector((store) => ({
    contactTypes: selectContactTypeOptions(store, (c) => c.identifier !== 2),
  }));
  const { pad } = React.useContext(PadViewContext);

  const getContactTypeById = (contact_type_id) => {
    if (contactTypes && contactTypes.length) {
      return contactTypes.find((itm) => itm.value === contact_type_id);
    }

    return null;
  };

  const onContactUpdate = React.useCallback(
    (contacts) => {
      updateFormValues([{ name: 'editable_contacts', value: contacts }]);
    },
    [updateFormValues],
  );

  return (
    <Space size="large" wrap>
      {pad?.editable_contacts?.map((itm) => (
        <div
          key={`${itm.id}${itm?.contact_type_id}`}
          className="mb-2 position-relative"
        >
          Name: {itm.name} <br />
          Email: <a href={`mailto:${itm.email}`}>{itm.email}</a>
          <br />
          Phone: <a href={`tel:${itm.phone}`}>{itm.phone}</a>
          <br />
          Contact type: {getContactTypeById(itm.contact_type_id)?.label}
          <br />
          {itm.contact_type_id === 4 && !itm?.is_claimed && (
            <>
              Claim:
              <ContactPending
                contact={itm}
                onClaimAccept={() =>
                  onContactUpdate(
                    pad.editable_contacts.map((c) =>
                      c.id === itm.id ? { ...c, is_claimed: true } : c,
                    ),
                  )
                }
                onClaimReject={() =>
                  onContactUpdate(
                    pad.editable_contacts.filter((c) => c.id !== itm.id),
                  )
                }
              />
              <br />
            </>
          )}
          {itm?.is_primary && (
            <span className="font-weight-bold">Primary Contact</span>
          )}
          {itm.is_premium && (
            <div className="premium-user-icon">
              <Tooltip title="Premium User" key="title_star">
                <StarFilled
                  color="#88173f"
                  className="cursor-pointer accent-icon"
                />
              </Tooltip>
            </div>
          )}
        </div>
      ))}
    </Space>
  );
}

ContactsList.propTypes = {
  updateFormValues: PropTypes.func,
};
