export const surveyOptions = {
  short_notice: 'Short Notice',
  24: '24 Hours',
  48: '48 Hours',
  other: 'Other',
  email: 'Email',
  phone: 'Phone',
  meters: 'Meters',
  feet: 'Feet',
  concrete_tarmac: 'Concrete/Tarmac',
  maintained_grass: 'Grass - Maintained',
  partially_maintained_grass: 'Grass - Partially maintained',
  unmaintained_grass: 'Grass - Unmaintained',
  gravel: 'Gravel',
  sand: 'Sand',
  flat: 'Flat',
  gentle: 'Gentle',
  steep: 'Steep',
  extreme: 'Extreme',
  yes: 'Yes',
  no: 'No',
  animals: 'Animals',
  power_lines: 'Power lines',
  high_trees: 'High trees',
  loose_debris: 'Loose debris',
  tall_building: 'Tall building',
  public_right_way: 'Public right of way across landing area',
  unsecured_items: 'Unsecured items (downwash issue)',
  changeable_surface: 'Changeable surface (after heavy rain/snow)',
  fuel: 'Fuel',
  marked_h: 'Marked H',
  wind_indicator: 'Wind Indicator',
  food_available: 'Food Available',
  lodging: 'Lodging',
  sport_facilites: 'Sport Facilities',
  more_facilities: 'More Facilities',
  specific_lighting: 'Specific Lighting',
};

export const calculateSurveyStatus = (steps) => {
  let survey_completed_steps_count = 0;
  let survey_status = null;

  Object.values(steps).forEach((step) => {
    if (step.completed) survey_completed_steps_count += 1;
  });

  if (survey_completed_steps_count === 7) {
    survey_status = 3;
  } else if (survey_completed_steps_count === 0) {
    survey_status = 1;
  } else {
    survey_status = 2;
  }

  return { survey_completed_steps_count, survey_status };
};
