import PropTypes from 'prop-types';
import React from 'react';
import { Descriptions, Form, Input } from 'antd';

export const SurveyFacilitiesStep = React.memo(({ stepValues }) => (
  <Descriptions
    labelStyle={{ width: 150 }}
    size="small"
    bordered
    column={1}
    layout="vertical"
    className="mb-3"
  >
    <Descriptions.Item label={`Features: ${stepValues.is_completed ? 'Completed' : 'Incomplete'}`}>
      <Form.Item
        name={['facilities', 'facilities_comment']}
        label="Features details"
        className='mb-4'
        rules={[
          {
            min: 3,
            max: 500,
            message: 'Must be between 3 and 500 characters',
          },
        ]}
      >
        <Input.TextArea
          autoSize
          placeholder="Please provide any further details here"
        />
      </Form.Item>
      {stepValues.facilities_is_landing_fee && (
        <Form.Item
          name={['facilities', 'facilities_landing_fee_comment']}
          label="Range fee details"
          className="mb-0"
          rules={[
            {
              min: 1,
              max: 200,
              message: 'Must be between 1 and 200 characters',
            },
          ]}
        >
          <Input.TextArea autoSize placeholder="Please provide details" />
        </Form.Item>
      )}
    </Descriptions.Item>
  </Descriptions>
));

SurveyFacilitiesStep.propTypes = {
  stepValues: PropTypes.object,
};
