import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

export const visualizeBool = (value) =>
  value !== null &&
  value !== undefined &&
  (value ? (
    <CheckCircleOutlined className="bool-success" />
  ) : (
    <CloseCircleOutlined className="bool-error" />
  ));
