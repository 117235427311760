import React from 'react';
import { Button, notification, Tooltip } from 'antd';
import api from '../../../../../utils/appApi';
import getApiErrorMessages from '../../../../../utils/getApiErrorMessages';
import PadViewContext from '../../../PadView.context';

export function PadRecommended() {
  const { pad, updatePad } = React.useContext(PadViewContext);
  const [loading, setLoading] = React.useState(false);

  const isPadRecommended = React.useMemo(
    () => pad?.contacts?.find((c) => c.is_premium && c.contact_type_id === 4),
    [pad],
  );

  const actionDisabled = React.useMemo(
    () => [pad?.is_private, pad?.deleted_at].some(Boolean),
    [pad],
  );

  const toggleRecommendedRequest = React.useCallback(() => {
    setLoading(true);
    api.pad
      .updateRecommended(pad?.id)
      .then((res) => {
        updatePad({
          is_helipaddy_recommended_admin: !pad?.is_helipaddy_recommended_admin,
        });

        notification.success({
          message: 'Success',
          description: 'Done!',
        });
        return Promise.resolve(res);
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(err),
        });
      })
      .finally(() => setLoading(false));
  }, [pad, updatePad]);

  return (
    <Tooltip
      title={
        (isPadRecommended && 'Has Premium Owner') ||
        (pad?.is_private && 'Private Pad')
      }
    >
      <Button
        className="mr-2"
        htmlType="button"
        disabled={isPadRecommended || actionDisabled}
        onClick={toggleRecommendedRequest}
        loading={loading}
      >
        {pad?.is_helipaddy_recommended_admin
          ? 'Remove from recommended'
          : 'Add to recommended'}
      </Button>
    </Tooltip>
  );
}
