import { notification, Select, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../../../../../utils/appApi';
import getApiErrorMessages from '../../../../../utils/getApiErrorMessages';

const contactTypes = {
  1: 'Pad Contact',
  4: 'Owner',
};

export default function PrimaryContact(props) {
  const { pad, editing, onChange } = props;
  const [contactsOpts, setContactsOpts] = useState([]);
  const [primaryContact, setPrimaryContact] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (pad?.contacts?.length) {
      const primaryIndex = pad?.contacts.findIndex((user) =>
        Boolean(user?.is_primary),
      );

      if (primaryIndex !== -1) {
        setPrimaryContact(pad?.contacts[primaryIndex]);
      }

      setContactsOpts(
        pad?.contacts
          .filter((user) => [1, 4].includes(user.contact_type_id))
          .filter((user) => user.email || user.phone)
          .map((user) => ({
            value: user.id,
            label: `${user.name || user.email || user.phone} (${
              contactTypes[user.contact_type_id]
            })`,
          })),
      );
    }
  }, [pad?.contacts]);

  const onPrimaryContactChange = useCallback(
    async (value) => {
      const newPad = { ...pad };

      const newPrimaryContact = newPad.contacts.find(
        (user) => user.id === value,
      );

      setLoading(true);

      try {
        await api.pad.changePrimaryContact(
          pad?.id,
          value,
          newPrimaryContact.contact_type_id,
        );
      } catch (error) {
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(error),
        });
      } finally {
        setLoading(false);
      }

      newPad.contacts = newPad.contacts
        .filter((user) => [1, 4].includes(user.contact_type_id))
        .map((user) => {
          if (user.id === value) {
            return { ...user, is_primary: true };
          }
          return { ...user, is_primary: false };
        });

      onChange({ data: newPad });
    },
    [pad, onChange],
  );

  const PrimaryContactName = useMemo(() => {
    if (primaryContact) {
      if (primaryContact.user_id) {
        return (
          <Link to={`/dashboard/user-view/${primaryContact.user_id}`}>
            {primaryContact.name}
          </Link>
        );
      }
      return primaryContact.name;
    }

    return '-';
  }, [primaryContact]);

  if (!editing) {
    return (
      <Tooltip
        title={
          contactsOpts.length
            ? ''
            : 'None of the contacts match the requirement. The contact must have a valid email address or phone number.'
        }
        placement="top"
      >
        <Select
          size="small"
          style={{ minWidth: 160 }}
          placeholder="Select contact"
          options={contactsOpts}
          value={primaryContact?.id || null}
          onChange={onPrimaryContactChange}
          loading={loading}
          disabled={
            !!pad?.deleted_at || !contactsOpts.length || pad?.is_private
          }
        />
      </Tooltip>
    );
  }

  return PrimaryContactName;
}

PrimaryContact.propTypes = {
  pad: PropTypes.object,
  editing: PropTypes.bool,
  onChange: PropTypes.func,
};

PrimaryContact.defaultProps = {
  pad: {},
  editing: false,
  onChange: () => null,
};
