import { useEffect, useState, useCallback, useMemo } from 'react';
import { useMount, useSessionStorage, useUnmount } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PageHeader from '../../uiKitComponents/pageHeader';
import Table from '../../uiKitComponents/table';
import Alert from '../../uiKitComponents/alert';
import * as reports from '../../store/actions/reportPagesAction';
import buildTableColumns from '../../utils/buildTableColumns';
import { Button, Form, Input, Space } from 'antd';

const initialState = {
  page: 1,
  sort_column: 'tail_number',
  sort_direction: 'descend',
  keyword: '',
};

const PilotAircraftsPage = () => {
  const [state, setState] = useSessionStorage('pilot-aircrafts', {
    ...initialState,
  });

  const { page, dates } = state;
  const [form] = Form.useForm();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { cleanOnUnmount, pilotAircrafts } = useSelector((store) => ({
    cleanOnUnmount: store.reportPages.cleanOnUnmount,
    pilotAircrafts: store.reportPages.payload.pilotAircraftsReport,
  }));

  const columns = useMemo(
    () =>
      buildTableColumns(pilotAircrafts?.columns)?.map((column) => {
        let sortOrder = false;
        if (column.dataIndex === state.sort_column) {
          sortOrder = state.sort_direction;
        }
        return {
          ...column,
          sorter: (a, b) =>
            a?.[column.dataIndex]?.length - b?.[column.dataIndex]?.length,
          sortOrder,
          sortDirections: ['descend', 'ascend', 'descend'],
        };
      }),
    [pilotAircrafts, state],
  );

  const dispatch = useDispatch();

  useMount(() => {
    reports.setCleanOnUnmountTrue()(dispatch);
  });

  const fetchPilotAircraftsReports = () => {
    setLoading(true);
    reports
      .getPilotAircraftsReports({
        ...state,
      })(dispatch)
      .catch((err) => {
        if (err.response.status === 500) {
          setError({
            status: err.response.status,
            message: err.response.data.message,
          });
          sessionStorage.removeItem('pilot-aircrafts');
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchPilotAircraftsReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, dates, state]);

  useUnmount(() => {
    if (cleanOnUnmount) {
      sessionStorage.removeItem('pilot-aircrafts');
      reports.cleanReports()(dispatch);
    }
  });

  const onTableChange = useCallback(
    (pagination, tableFilters, sorter, extra) => {
      const newState = {};
      if (extra.action === 'paginate') newState.page = pagination.current;
      else if (extra.action === 'sort') {
        newState.sort_column = sorter.field;
        newState.sort_direction = sorter.order;
      }
      setState((prevState) => ({ ...prevState, ...newState }));
    },
    [setState],
  );

  const handleClearFilters = useCallback(() => {
    setState((prevState) => ({
      ...initialState,
      page: prevState.page,
    }));
    form.resetFields();
  }, [setState, form]);

  const onFinish = useCallback(
    (values) => {
      setState((prevState) => ({ ...prevState, ...values, page: 1 }));
    },
    [setState],
  );

  if (error)
    return (
      <PageHeader className="page-main-header" title="Pilot aircrafts">
        <Alert
          showIcon
          type="error"
          message={`Error ${error.status}`}
          description={error.message}
        />
      </PageHeader>
    );

  return (
    <div>
      <PageHeader className="page-main-header" title="Pilot aircrafts">
        <div className="filters-container">
          <div className="bg-white p-4">
            <Form
              initialValues={{ keyword: initialState.keyword }}
              onFinish={onFinish}
              form={form}
            >
              <div className="d-flex flex-wrap justify-content-between align-items-start">
                <div>
                  <h3>Search</h3>
                  <Form.Item
                    name="keyword"
                    autoComplete="keyword"
                    rules={[{ min: 3, message: 'At least 3 characters' }]}
                  >
                    <Input placeholder="Keyword" />
                  </Form.Item>
                </div>
                <Space style={{ marginTop: 'calc(23px + 0.5em)' }}>
                  <Form.Item>
                    <Button
                      type="dashed"
                      htmlType="button"
                      onClick={handleClearFilters}
                    >
                      Clear
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Space>
              </div>
            </Form>
          </div>
        </div>
      </PageHeader>

      <div className="page-main-content">
        <Table
          columns={columns}
          rowKey={'tail_number'}
          dataSource={pilotAircrafts?.data || []}
          loading={loading}
          onChange={onTableChange}
          pagination={{
            position: ['topRight', 'bottomRight'],
            showSizeChanger: false,
            showQuickJumper: true,
            pageSize: 25,
            total: pilotAircrafts?.meta?.total,
            current: page,
            hideOnSinglePage: true,
          }}
        />
      </div>
    </div>
  );
};

PilotAircraftsPage.defaultProps = {
  reportPageProps: {},
  row_action: [],
  row_inner_action: [],
};

PilotAircraftsPage.propTypes = {
  reportPageProps: PropTypes.shape({
    sessionStorageName: PropTypes.string,
    pageTitle: PropTypes.string,
    stateName: PropTypes.string,
    actionName: PropTypes.string,
    default_sort_column: PropTypes.string,
  }),
  row_action: PropTypes.array,
  row_inner_action: PropTypes.array,
};

export default PilotAircraftsPage;
