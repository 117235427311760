import { useEffect, useState, useCallback } from 'react';
import { useMount, useSessionStorage, useUnmount } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DownOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import PageHeader from '../../uiKitComponents/pageHeader';
import Table from '../../uiKitComponents/table';
import buildTableColumns from '../../utils/buildTableColumns';
import FiltersSection from '../../components/filtersSection';
import Alert from '../../uiKitComponents/alert';
import Button from '../../uiKitComponents/button';
import Space from '../../uiKitComponents/space';
import Dropdown from '../../uiKitComponents/dropdown';
import formattingFilters from '../../utils/formattingFilters';
import * as reports from '../../store/actions/reportPagesAction';
import { getUserFilters } from '../../store/actions/userFiltersAction';

const initialState = {
  page: 1,
  sort_column: 'id',
  sort_direction: 'descend',
  filters: [{}],
  keyword: undefined,
  filter_info: undefined,
  filtersTrigger: false,
};

const InitialReportPage = (props) => {
  const {
    reportPageProps,
    row_action,
    row_inner_action,
    disableFilters,
    default_sort_column,
    show_listings,
  } = props;
  const [state, setState] = useSessionStorage(
    reportPageProps.sessionStorageName,
    {
      ...initialState,
      sort_column: default_sort_column || initialState.sort_column,
    },
  );

  const {
    page,
    sort_column,
    sort_direction,
    filters,
    keyword,
    filter_info,
    filtersTrigger,
  } = state;
  const [error, setError] = useState();
  const [selectedFilter, setSelectedFilter] = useState('initial');
  const { cleanOnUnmount, reportsList, savedFilters } = useSelector(
    (store) => ({
      cleanOnUnmount: store.reportPages.cleanOnUnmount,
      reportsList: store.reportPages.payload[reportPageProps.stateName],
      savedFilters: store.userFilters.payload,
    }),
  );

  const dispatch = useDispatch();
  useMount(() => {
    reports.setCleanOnUnmountTrue()(dispatch);
  });

  useEffect(() => {
    const advancedFilters = {};
    filters.forEach((filter) => {
      const temp_filter = formattingFilters(filter, filter.keyword, 'STRING');
      Object.keys(temp_filter).forEach((key) => {
        advancedFilters[`filters[${key}]`] = temp_filter[key];
      });
    });

    reports[reportPageProps.actionName]({
      params: {
        page,
        sort_column,
        sort_direction: sort_direction || 'ascend',
        keyword,
        ...advancedFilters,
      },
    })(dispatch).catch((err) => {
      if (err.response.status === 500) {
        setError({
          status: err.response.status,
          message: err.response.data.message,
        });
        sessionStorage.removeItem(reportPageProps.sessionStorageName);
      }
    });
    // eslint-disable-next-line
  }, [page, sort_column, sort_direction, filtersTrigger, dispatch]);

  useUnmount(() => {
    if (cleanOnUnmount) {
      sessionStorage.removeItem(reportPageProps.sessionStorageName);
      reports.cleanReports()(dispatch);
    }
  });

  const onTableChange = useCallback(
    (pagination, tableFilters, sorter, extra) => {
      const newState = {};
      if (extra.action === 'paginate') newState.page = pagination.current;
      else if (extra.action === 'sort') {
        newState.sort_column = sorter.field;
        newState.sort_direction = sorter.order;
      }
      setState((prevState) => ({ ...prevState, ...newState }));
    },
    [setState],
  );

  const onSubmitFilters = useCallback(
    (values) => {
      setState((prevState) => ({
        ...prevState,
        ...values,
        filtersTrigger: !prevState.filtersTrigger,
      }));
    },
    [setState],
  );

  const clearFilters = useCallback(
    () =>
      setState((prevState) => ({
        ...initialState,
        filtersTrigger: !prevState.filtersTrigger,
      })),
    [setState],
  );

  const setSavedFilter = (id = null) => {
    if (id) {
      let filter = {};
      if (savedFilters.length) {
        filter = savedFilters.find((itm) => itm.id === id);
      } else {
        filter = savedFilters;
      }
      const rows = Object.keys(filter.filters).map((row) =>
        formattingFilters(row, filter.filters[row], 'OBJECT'),
      );
      onSubmitFilters({
        filters: rows,
        keyword: undefined,
        filter_info: { id, name: filter.name, is_public: filter.is_public },
      });
    }
  };

  useEffect(() => {
    if (selectedFilter && !disableFilters) {
      getUserFilters(reportsList?.entity)(dispatch)
        .then(() => {
          if (selectedFilter !== 'initial') {
            setSavedFilter(selectedFilter);
            setSelectedFilter(undefined);
          }
        })
        .catch((err) => {
          if (err.response?.status === 500) {
            setError({
              status: err.response.status,
              message: err.response.data.message,
            });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsList?.entity, selectedFilter]);

  const menu = (
    <Menu>
      {savedFilters.length &&
        savedFilters.map((filter, idx) => (
          <Menu.Item key={idx} onClick={() => setSavedFilter(filter.id)}>
            {filter.name}
          </Menu.Item>
        ))}
    </Menu>
  );

  const tableColumns = () => {
    let table_columns = [
      ...buildTableColumns(reportsList?.columns, {
        promisedClbk: () => reports.setCleanOnUnmountTrue()(dispatch),
        actions: row_inner_action,
      }),
    ];

    if (row_action?.length) {
      table_columns = [
        ...table_columns,
        {
          title: 'Action',
          key: 'action',
          render: (row) =>
            row_action.map((action) => action.action_body(row.contact_id)),
        },
      ];
    }

    return table_columns;
  };

  if (error)
    return (
      <PageHeader
        className="page-main-header"
        title={reportPageProps.pageTitle}
      >
        <Alert
          showIcon
          type="error"
          message={`Error ${error.status}`}
          description={error.message}
        />
      </PageHeader>
    );

  return (
    <div>
      <PageHeader
        className="page-main-header"
        title={reportPageProps.pageTitle}
        extra={[
          !disableFilters && (
            <Dropdown
              overlay={menu}
              trigger={['click']}
              placement="bottomRight"
              disabled={!savedFilters.length}
              key="saved_filters"
            >
              <Button loading={!savedFilters}>
                Saved Filters
                <DownOutlined />
              </Button>
            </Dropdown>
          ),
        ]}
      >
        {!disableFilters && (
          <div className="filters-container">
            <FiltersSection
              clearFilters={clearFilters}
              onSubmit={onSubmitFilters}
              filters={filters}
              keyword={keyword}
              columnOptions={reportsList?.filterable}
              loading={!reportsList?.filterable}
              filter_info={filter_info}
              filter_columns={reportsList?.columns}
              entity={reportsList?.entity}
              onUpdateFilter={setSelectedFilter}
            />
          </div>
        )}
      </PageHeader>
      <div className="page-main-content">
        {!!show_listings && (
          <Space
            className="d-flex justify-content-end"
            style={{ color: '#d9d9d9' }}
          >
            Showing {reportsList?.meta?.from}-{reportsList?.meta?.to} of{''}
            {reportsList?.meta?.total} listings
          </Space>
        )}
        <Table
          columns={tableColumns()}
          rowKey={default_sort_column || sort_column}
          dataSource={reportsList?.data}
          loading={!reportsList?.data}
          onChange={onTableChange}
          pagination={{
            position: ['topRight', 'bottomRight'],
            showSizeChanger: false,
            showQuickJumper: true,
            pageSize: reportsList?.meta?.per_page,
            total: reportsList?.meta?.total,
            current: page,
          }}
        />
        {!!show_listings && (
          <Space
            className="d-flex justify-content-end"
            style={{ color: '#d9d9d9' }}
          >
            Showing {reportsList?.meta?.from}-{reportsList?.meta?.to} of{''}
            {reportsList?.meta?.total} listings
          </Space>
        )}
      </div>
    </div>
  );
};

InitialReportPage.defaultProps = {
  reportPageProps: {},
  row_action: [],
  row_inner_action: [],
  disableFilters: false,
  default_sort_column: 'id',
  show_listings: false,
};

InitialReportPage.propTypes = {
  reportPageProps: PropTypes.shape({
    sessionStorageName: PropTypes.string,
    pageTitle: PropTypes.string,
    stateName: PropTypes.string,
    actionName: PropTypes.string,
    default_sort_column: PropTypes.string,
  }),
  row_action: PropTypes.array,
  row_inner_action: PropTypes.array,
  disableFilters: PropTypes.bool,
  default_sort_column: PropTypes.string,
  show_listings: PropTypes.bool,
};

export default InitialReportPage;
