import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import DataRowContext from './DataRowContext';
import StaticData from './Controllers/StaticData';
import RowCheckbox from './RowCheckbox';
import { defineRowConfig } from './dataRowUtils';
import RowControllerInput from './Controllers/RowControllerInput';
import RowControllerPhotos from './Controllers/RowControllerPhotos';
import DataRowControllerContact from './Controllers/DataRowControllerContact';
import RowControllerSelect from './Controllers/RowControllerSelect';
import DataRowControllerTags from './Controllers/DataRowControllerTags';
import condStrings from '../../utils/condStrings';

import './styles/index.scss';

export default function DataRow(props) {
  const {
    type,
    label,
    prev,
    next,
    setValue,
    readOnly,
    options,
    children,
    prevExtra,
    nextExtra,
    setValueHandler,
    onUploadSelect,
    selectedUploads,
    automatic,
    additionalChecks,
  } = props;
  const [checkedSide, setCheckedSide] = useState('prev');
  const [isConflict, setIsConflict] = useState(false);
  const [noValues, setNoValues] = useState(false);
  const [initiated, setInitiated] = useState(false);
  const getRowConfig = useRef(
    defineRowConfig(type, readOnly, additionalChecks),
  );

  useEffect(() => {
    if (!initiated) {
      const config = getRowConfig.current(prev, next);

      setCheckedSide(config.checked);
      setIsConflict(config.conflicts);

      if (config.checked === null) {
        setNoValues(true);
      }
    }

    setInitiated(true);
  }, [initiated, next, prev, type]);

  useEffect(() => {
    if (checkedSide) {
      const checkedSideValue = checkedSide === 'prev' ? prev : next;

      if (setValueHandler) {
        setValue(setValueHandler(checkedSideValue));
      } else {
        setValue(checkedSideValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedSide]);

  const onChangeCheckedSide = useCallback(
    (side) => () => {
      setCheckedSide(side);
    },
    [],
  );

  return (
    <DataRowContext.Provider
      value={{
        isConflict,
        setCheckedSide,
        label,
        prev,
        next,
        type,
        readOnly,
        options,
        checkedSide,
        onUploadSelect,
        selectedUploads,
      }}
    >
      <div className={condStrings('data-row-item', type)}>
        <Row align="top" className="mb-2">
          <Col span="7">
            <StaticData
              side="prev"
              additionalChecks={additionalChecks}
              content={prev}
              extra={prevExtra}
              noValues={(noValues || !isConflict) && !automatic}
            />
          </Col>
          <Col span="2">
            <RowCheckbox
              side="prev"
              checked={checkedSide === 'prev'}
              onChange={onChangeCheckedSide('prev')}
              noValues={(noValues || !isConflict) && !automatic}
            />
          </Col>
          <Col span="6">{children}</Col>
          <Col span="2">
            <RowCheckbox
              side="next"
              checked={checkedSide === 'next'}
              onChange={onChangeCheckedSide('next')}
              noValues={(noValues || !isConflict) && !automatic}
            />
          </Col>
          <Col span="7">
            <StaticData
              side="next"
              additionalChecks={additionalChecks}
              content={next}
              extra={nextExtra}
              noValues={(noValues || !isConflict) && !automatic}
            />
          </Col>
        </Row>
      </div>
    </DataRowContext.Provider>
  );
}

DataRow.propTypes = {
  type: PropTypes.oneOf([
    'text',
    'select',
    'tags',
    'textarea',
    'tags_input',
    'url',
    'contacts',
    'photos',
    'bool',
    'survey',
  ]),
  children: PropTypes.element.isRequired,
  label: PropTypes.string,
  prev: PropTypes.any,
  next: PropTypes.any,
  setValue: PropTypes.func,
  readOnly: PropTypes.bool,
  options: PropTypes.array,
  prevExtra: PropTypes.any,
  nextExtra: PropTypes.any,
  setValueHandler: PropTypes.func,
  onUploadSelect: PropTypes.func,
  selectedUploads: PropTypes.array,
  automatic: PropTypes.bool,
  additionalChecks: PropTypes.shape({
    isOwnerNext: PropTypes.bool,
    isOwnerPrev: PropTypes.bool,
    deletedAtPrev: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(null),
    ]),
    deletedAtNext: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(null),
    ]),
    surveyProgressPrev: PropTypes.number,
    surveyProgressNext: PropTypes.number,
  }),
};

DataRow.defaultProps = {
  type: 'text',
  label: '',
  setValue: () => null,
  readOnly: false,
  prev: null,
  next: null,
  options: [],
  prevExtra: null,
  nextExtra: null,
  setValueHandler: null,
  onUploadSelect: () => null,
  selectedUploads: [],
  automatic: false,
  additionalChecks: {
    isOwnerNext: false,
    isOwnerPrev: false,
    deletedAtPrev: null,
    deletedAtNext: null,
  },
};

DataRow.Input = RowControllerInput;
DataRow.Select = RowControllerSelect;
DataRow.Tags = DataRowControllerTags;
DataRow.Contacts = DataRowControllerContact;
DataRow.Photos = RowControllerPhotos;
