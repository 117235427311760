import {
  GET_USER_FILTERS,
  UPDATE_FILTER,
  REMOVE_FILTER,
  ADD_NEW_FILTER,
} from '../actionTypes';

const initialState = {
  payload: {},
};

const userFilters = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_USER_FILTERS:
      return {
        ...state,
        payload: action.payload,
      };
    case UPDATE_FILTER:
      return {
        ...state,
        payload: action.payload,
      };
    case REMOVE_FILTER:
      return initialState;
    case ADD_NEW_FILTER:
      return {
        ...state,
        payload: action.payload,
      };
    default:
      return state;
  }
};

export default userFilters;
