import PropTypes from 'prop-types';
import React from 'react';
import { notification, Select, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import api from '../../../../../utils/appApi';
import getApiErrorMessages from '../../../../../utils/getApiErrorMessages';
import PadViewContext from '../../../PadView.context';

const contactTypes = {
  1: 'Pad Contact',
  4: 'Owner',
};

export function PadPrimaryContact(props) {
  const { editing, onPrimaryChanged } = props;
  const { pad, updatePad } = React.useContext(PadViewContext);
  const [contactsOpts, setContactsOpts] = React.useState([]);
  const [primaryContact, setPrimaryContact] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (pad?.editable_contacts?.length) {
      const primaryIndex = pad?.editable_contacts.findIndex((user) =>
        Boolean(user?.is_primary),
      );

      if (primaryIndex !== -1) {
        setPrimaryContact(pad?.editable_contacts[primaryIndex]);
      }

      setContactsOpts(
        pad?.editable_contacts
          .filter((user) => user.email || user.phone)
          .map((user) => ({
            value: user.id,
            label: `${user.name || user.email || user.phone} (${
              contactTypes[user.contact_type_id]
            })`,
          })),
      );
    }
  }, [pad?.editable_contacts]);

  const onPrimaryContactChange = React.useCallback(
    async (value) => {
      const newPad = { ...pad };

      const newPrimaryContact = newPad.editable_contacts.find(
        (user) => user.id === value,
      );

      setLoading(true);

      try {
        await api.pad.changePrimaryContact(
          pad?.id,
          value,
          newPrimaryContact.contact_type_id,
        );
      } catch (error) {
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(error),
        });
      } finally {
        setLoading(false);
      }

      newPad.editable_contacts = newPad.editable_contacts
        .filter((user) => [1, 4].includes(user.contact_type_id))
        .map((user) => {
          if (user.id === value) {
            return { ...user, is_primary: true };
          }
          return { ...user, is_primary: false };
        });

      updatePad(newPad);
      onPrimaryChanged(newPad.editable_contacts);
    },
    [pad, updatePad, onPrimaryChanged],
  );

  const actionDisabled = React.useMemo(
    () =>
      [pad?.deleted_at || !contactsOpts.length || pad?.is_private].some(
        Boolean,
      ),
    [contactsOpts, pad],
  );

  const PrimaryContactName = React.useMemo(() => {
    if (primaryContact) {
      if (primaryContact.user_id) {
        return (
          <Link to={`/dashboard/user-view/${primaryContact.user_id}`}>
            {primaryContact.name}
          </Link>
        );
      }
      return primaryContact.name;
    }

    return '-';
  }, [primaryContact]);

  if (!editing) {
    return (
      <Tooltip
        title={
          contactsOpts.length
            ? ''
            : 'None of the contacts match the requirement. The contact must have a valid email address or phone number.'
        }
        placement="top"
      >
        <Select
          size="small"
          style={{ minWidth: 160 }}
          placeholder="Select contact"
          options={contactsOpts}
          value={primaryContact?.id || null}
          onChange={onPrimaryContactChange}
          loading={loading}
          disabled={actionDisabled}
        />
      </Tooltip>
    );
  }

  return PrimaryContactName;
}

PadPrimaryContact.propTypes = {
  editing: PropTypes.bool,
  onPrimaryChanged: PropTypes.func,
};
