import { useCallback, useState } from 'react';
import { Collapse, List, notification } from 'antd';
import { useDispatch } from 'react-redux';
import PageHeader from '../../uiKitComponents/pageHeader';
import Upload from '../../uiKitComponents/upload';
import Button from '../../uiKitComponents/button';
import history from '../../utils/history';
import api from '../../utils/appApi';
import simulateNativeLink from '../../utils/simulateNativeLink';
import Alert from '../../uiKitComponents/alert';
import { ReactComponent as UploadIcon } from '../../assets/svg/upload.svg';
import { errorParser } from './padImportUtils';
import PadImportTable from './padImportTable';
import {
  loadingBarDisable,
  loadingBarEnable,
} from '../../store/actions/loadingBarAction';
import getApiErrorMessages from '../../utils/getApiErrorMessages';

import './index.scss';

const parseValidatedPads = (response) => {
  let pads = [];

  if (Array.isArray(response)) {
    pads = response;
  } else {
    pads = Object.values(response);
  }

  return pads.map((itm, index) => {
    const pad = {
      ...itm,
      is_auto_updated: !!itm.id,
      children: itm?.duplicate
        ? [
          {
            ...itm.duplicate,
            parentIndex: index,
            tags: itm?.tags?.map((itm2) => (itm2.id ? itm2.name : itm2)),
            facilities:
              itm?.facilities &&
              itm?.facilities?.map((itm2) =>
                itm2.id ? itm.display_name : itm2,
              ),
          },
        ]
        : null,
    };

    delete pad.duplicate;

    return pad;
  });
};

const PadImportPage = () => {
  const [fileList, setFileList] = useState([]);
  const [padList, setPadList] = useState();
  const [padListUpdated, setPadListUpdated] = useState([]);
  const [apiError, setApiError] = useState();
  const [selected, setSelected] = useState({});
  const [importLoading, setImportLoading] = useState(false);

  const dispatch = useDispatch();

  const onBackHandle = useCallback(() => {
    history.goBack();
  }, []);

  const uploadOnChange = useCallback((e) => {
    setFileList([e.file]);
  }, []);

  const handleImport = useCallback(
    (customPads = []) => {
      let padsToUpload = [];

      if (customPads.length) {
        padsToUpload = customPads;
      } else {
        padsToUpload = [...(padList?.data || []), ...padListUpdated];
      }

      const data = padsToUpload.map((itm, i) => {
        const pad = { ...itm };

        if (pad.is_duplicated) {
          pad.is_updated = !!selected[i];
          if (selected[i] === null || selected[i] === undefined) {
            pad.is_zaibalsa = true;
          }
        }

        delete pad.children;

        return pad;
      });

      loadingBarEnable()(dispatch);
      setImportLoading(true);
      setApiError(undefined);

      api.pad
        .importAccept(data)
        .then(() => {
          notification.success({
            message: 'Success',
            description: 'Pads are successfully imported!',
          });
          setPadList(undefined);
          setSelected({});
          setFileList([]);
        })
        .catch((err) => {
          setApiError(getApiErrorMessages(err));
        })
        .finally(() => {
          loadingBarDisable()(dispatch);
          setImportLoading(false);
        });
    },
    [dispatch, padList, selected, padListUpdated],
  );

  const uploadFileHandle = useCallback(
    (uploadConfig) => {
      const { file, onError, onProgress, onSuccess } = uploadConfig;
      const formData = new FormData();
      formData.append('csv_import', file);

      setPadList(undefined);
      setApiError(undefined);

      api.pad
        .validateImport(formData, {
          onUploadProgress: ({ total, loaded }) => {
            onProgress(
              { percent: Math.round((loaded / total) * 100).toFixed(2) },
              file,
            );
          },
        })
        .then((res) => {
          onSuccess('Success', file);

          const pads = parseValidatedPads(res.data.data);
          const padsUpdated = [];

          pads.forEach((pad, index) => {
            if (pad.is_auto_updated) {
              padsUpdated.push(...pads.splice(index, 1));
            }
          });

          if (pads.length) {
            setPadListUpdated(padsUpdated);
            setPadList({
              ...res.data,
              data: pads,
            });
          } else {
            handleImport(padsUpdated);
          }
        })
        .catch((err) => {
          setApiError(errorParser(err));
          onError();
        });
    },
    [handleImport],
  );

  return (
    <div>
      <PageHeader
        onBack={onBackHandle}
        className="page-main-header no-children"
        title="Import pad"
        extra={[
          <Button
            key="pad-create-btn"
            onClick={simulateNativeLink('/dashboard/pad-create', () =>
              history.push('/dashboard/pad-create'),
            )}
          >
            Create pad
          </Button>,
        ]}
      />
      <div className="page-main-content">
        {apiError && (
          <Alert
            className="mb-4"
            message="Error"
            description={apiError}
            type="error"
            showIcon
          />
        )}
        {padList ? (
          <PadImportTable
            pads={padList.data || []}
            fileName={fileList[0]?.name}
            setFileList={setFileList}
            setPadList={setPadList}
            handleImport={handleImport}
            importLoading={importLoading}
            selected={selected}
            setSelected={setSelected}
          />
        ) : (
          <div className="import-pad_example">
            <h1 className="pl-1">Allowed fields</h1>
            <Collapse bordered defaultActiveKey="1" className="mb-3">
              <Collapse.Panel key="1" header="Main Information">
                <List className="import-pad_list" size="small">
                  <List.Item>
                    1. ID - to update the pad | Empty cell - to create a pad
                  </List.Item>
                  <List.Item>2. Name</List.Item>
                  <List.Item>3. *Category (number)</List.Item>
                  <List.Item>4. Latitute</List.Item>
                  <List.Item>5. Longitude</List.Item>
                  <List.Item>6. Internal Notes</List.Item>
                  <List.Item>7. ICAO</List.Item>
                  <List.Item>8. Country</List.Item>
                </List>
              </Collapse.Panel>
              <Collapse.Panel key="2" header="Contact Information">
                <List className="import-pad_list" size="small">
                  <List.Item>9. Contact Name</List.Item>
                  <List.Item>10. Contact Phone</List.Item>
                  <List.Item>11. Contact Email</List.Item>
                  <List.Item>12. Pad Website</List.Item>
                </List>
              </Collapse.Panel>
              <Collapse.Panel key="3" header="Other Information">
                <List className="import-pad_list" size="small">
                  <List.Item>13. Jet A1? (0 / 1)</List.Item>
                  <List.Item>14. 100LL/Avgas? (0 / 1)</List.Item>
                  <List.Item>15. Mogas? (0 / 1)</List.Item>
                  <List.Item>16. Custom facility? (0 / 1)</List.Item>
                  <List.Item>17. Landing fees? (0 / 1)</List.Item>
                  <List.Item>18. Site Information</List.Item>
                  <List.Item>19. Landing Advice</List.Item>
                  <List.Item>20. Tags</List.Item>
                  <List.Item>21. Is Pad Moderated (0 / 1)</List.Item>
                  <List.Item>22. Is Landing Permited (0 / 1)</List.Item>
                </List>
              </Collapse.Panel>
            </Collapse>

            <div className="mb-3">
              <h3>
                <strong>Category*</strong>
              </h3>
              <h4>Aerial Point of Interest =&gt; 1</h4>
              <h4>B&B =&gt; 2</h4>
              <h4>Landing Site =&gt; 3</h4>
              <h4>Hotel =&gt; 4</h4>
              <h4>Pub =&gt; 5</h4>
              <h4>Restaurant =&gt; 6</h4>
              <h4>Aerodrome =&gt; 7</h4>
            </div>

            <div className="mb-2">
              <h3>
                <i>
                  Note: The Tags field is one csv column and it accepts only
                  existing tags separated by commas, example:{' '}
                  <strong>National Trust,Clay Shooting,Fishing</strong>
                </i>
              </h3>
            </div>

            <div className="mb-3">
              <h3>
                <i>
                  Note: Fields (12 - 13, 17) accept only <strong>0/1</strong> -{' '}
                  <strong>0 =&gt; or blank = unchecked</strong>;{' '}
                  <strong>1 =&gt; checked</strong>
                </i>
              </h3>
            </div>

            <div className="mb-3">
              <h3>
                Example:{' '}
                <strong>
                  Hinksey Heights Golf Club, 3, 51.7339715,-1.2758061,notes,ICAO, United Kingdom, David Brimble,37367332122,danny@test.com, www.oxford-golf.co.uk,0, 0 , 1, 1, 1, Slightly sloping grass field adjacent to golf course, close to the car park. Call ahead for precise landing information - Pre-arranged landings only. Circular mown area will be prepared about 50m south of the main car park. £120 +VAT landing fee, Golf, 1, 1
                </strong>
              </h3>
            </div>

            <div>
              <Upload
                accept=".csv"
                showUploadList={{
                  showRemoveIcon: false,
                }}
                fileList={fileList}
                onChange={uploadOnChange}
                customRequest={uploadFileHandle}
              >
                <Button
                  icon={<UploadIcon height="12" className="mr-1" />}
                  size="large"
                  block
                >
                  <strong>Validate CSV</strong>
                </Button>
              </Upload>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PadImportPage;
