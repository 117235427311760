import {
  REVIEWS_LIST_CLEAN,
  REVIEWS_LIST_GET,
  REVIEWS_TOGGLE_REVIEW_STATUS,
  REVIEWS_REMOVE_REVIEW_REPORTED,
  CLEAN_ON_UNMOUNT_TRUE,
  CLEAN_ON_UNMOUNT_FALSE,
} from '../actionTypes';

const initialState = {
  cleanOnUnmount: true,
  payload: {},
};

const reviewsList = (state = initialState, action = {}) => {
  switch (action.type) {
    case REVIEWS_LIST_GET:
      return {
        ...state,
        payload: action.payload,
      };
    case REVIEWS_TOGGLE_REVIEW_STATUS:
      return {
        ...state,
        payload: {
          ...state.payload,
          data: state.payload.data.map((comment) =>
            comment.id === action.payload
              ? { ...comment, is_review_approved: !comment.is_review_approved }
              : comment,
          ),
        },
      };
    case REVIEWS_REMOVE_REVIEW_REPORTED:
      return {
        ...state,
        payload: {
          ...state.payload,
          data: state.payload.data.map((comment) =>
            comment.id === action.payload
              ? { ...comment, is_reported: false }
              : comment,
          ),
        },
      };
    case REVIEWS_LIST_CLEAN:
      return initialState;
    case CLEAN_ON_UNMOUNT_TRUE:
      return {
        ...state,
        cleanOnUnmount: true,
      };
    case CLEAN_ON_UNMOUNT_FALSE:
      return {
        ...state,
        cleanOnUnmount: false,
      };
    default:
      return state;
  }
};

export default reviewsList;
