import { notification } from 'antd';
import React from 'react';
import api from '../../../utils/appApi';
import { getApiError } from '../../../utils/getApiErrorMessages';

export default function useSurveyUpdate(id) {
  const [isLoading, setIsLoading] = React.useState(false);

  const updateSurvey = React.useCallback(
    async (surveyData) => {
      setIsLoading(true);

      try {
        const response = await api.survey.update(id, surveyData);
        return Promise.resolve(response.data);
      } catch (error) {
        notification.error({
          message: 'Error',
          description: getApiError(error),
        });
        return Promise.reject(error);
      } finally {
        setIsLoading(false);
      }
    },
    [id],
  );

  return { isLoading, updateSurvey };
}
