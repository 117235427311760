import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Popconfirm from '../../uiKitComponents/popconfirm';

const AsyncPopconfirm = (props) => {
  const {
    component: Component,
    onConfirmPromise,
    onConfirm,
    onCancel,
    okButtonProps,
    ...rest
  } = props;
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = useCallback(() => setVisible(true), []);

  const handleConfirm = useCallback(() => {
    setConfirmLoading(true);
    onConfirmPromise()
      .then(() => onConfirm())
      .finally(() => {
        setVisible(false);
        setConfirmLoading(false);
      });
  }, [onConfirmPromise, onConfirm]);

  const handleCancel = useCallback(() => {
    setVisible(false);
    onCancel();
  }, [onCancel]);

  return (
    <Popconfirm
      {...rest}
      visible={visible}
      onConfirm={handleConfirm}
      okButtonProps={{ loading: confirmLoading, ...okButtonProps }}
      onCancel={handleCancel}
    >
      <Component onClick={showPopconfirm} />
    </Popconfirm>
  );
};

AsyncPopconfirm.defaultProps = {
  component: () => null,
  onConfirmPromise: () => null,
  onConfirm: () => null,
  onCancel: () => null,
  okButtonProps: {},
};

AsyncPopconfirm.propTypes = {
  component: PropTypes.func,
  onConfirmPromise: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  okButtonProps: PropTypes.object,
};

export default AsyncPopconfirm;
