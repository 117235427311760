import { useEffect, useState, useCallback } from 'react';
import {
  useMount,
  useSessionStorage,
  useUnmount,
  useUpdateEffect,
} from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { DownOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import PageHeader from '../../uiKitComponents/pageHeader';
import Table from '../../uiKitComponents/table';
import buildTableColumns from '../../utils/buildTableColumns';
import FiltersSection from '../../components/filtersSection';
import Alert from '../../uiKitComponents/alert';
import Button from '../../uiKitComponents/button';
import Dropdown from '../../uiKitComponents/dropdown';
import Switch from '../../uiKitComponents/switch';
import Space from '../../uiKitComponents/space';
import history from '../../utils/history';
import simulateNativeLink from '../../utils/simulateNativeLink';
import formattingFilters from '../../utils/formattingFilters';
import {
  cleanPadList,
  getPadList,
  getPadListConsumerSubmitted,
  getPadListLandingNotPossible,
  getPadListUnmoderated,
  getPadListPrivate,
  setCleanOnUnmountFalse,
  setCleanOnUnmountTrue,
  getPadListArchived,
  getPadListModerated,
  getPadListInvalidPhone,
} from '../../store/actions/padListAction';
import { selectCategoryOptions } from '../../store/selectors/bootstrapSelector';
import { getUserFilters } from '../../store/actions/userFiltersAction';
import getPadIcon from '../../utils/getPadIcon';
import { ReactComponent as BanIcon } from '../../assets/icons/ban.svg';
import { ReactComponent as PhoneSlashIcon } from '../../assets/icons/phone-slash.svg';
import Summary from '../../components/summary';
import PadsOnMap from './padListMap';
import api from '../../utils/appApi';
import { updateUserSettings } from '../../store/actions/userAction';
import condStrings from '../../utils/condStrings';
import withURLFilters from '../../utils/withURLFilters';

const initialState = {
  page: 1,
  sort_column: 'id',
  sort_direction: 'descend',
  filters: [{}],
  keyword: undefined,
  filter_info: undefined,
  filtersTrigger: false,
  selected_type: 'All',
  listOnMap: false,
};

const PadListPage = () => {
  const [mapEndpoint, setMapEndpoint] = useState({
    url: null,
    params: null,
  });
  const [error, setError] = useState();
  const [selectedFilter, setSelectedFilter] = useState('initial');
  const {
    cleanOnUnmount,
    padList,
    savedFilters,
    categories,
    adminSettings,
  } = useSelector((store) => ({
    cleanOnUnmount: store.padList.cleanOnUnmount,
    padList: store.padList.payload,
    savedFilters: store.userFilters.payload,
    categories: selectCategoryOptions(store),
    adminSettings: store.user.payload.settings.admin_settings,
  }));
  const [state, setState] = useSessionStorage('pad-list-state', {
    ...initialState,
    listOnMap: adminSettings.is_map_on,
  });
  const {
    page,
    sort_column,
    sort_direction,
    filters,
    keyword,
    filter_info,
    filtersTrigger,
    selected_type,
    listOnMap,
  } = state;
  const dispatch = useDispatch();

  useMount(() => {
    setCleanOnUnmountTrue()(dispatch);
    setState(withURLFilters(state, history.location.search));
  });

  useUpdateEffect(() => {
    const advancedFilters = {};
    filters.forEach((filter) => {
      const temp_filter = formattingFilters(filter, filter.keyword, 'STRING');
      Object.keys(temp_filter).forEach((key) => {
        advancedFilters[`filters[${key}]`] = temp_filter[key];
      });
    });

    const getPadListAction = (() => {
      if (selected_type === 'Unmoderated') {
        return {
          fn: getPadListUnmoderated,
          str: 'getPadListUnmoderated',
        };
      }

      if (selected_type === 'Moderated') {
        return {
          fn: getPadListModerated,
          str: 'getPadListModerated',
        };
      }

      if (selected_type === 'Invalid Phone') {
        return {
          fn: getPadListInvalidPhone,
          str: 'getPadListInvalidPhone',
        };
      }

      if (selected_type === 'Consumer Submitted') {
        return {
          fn: getPadListConsumerSubmitted,
          str: 'getPadListConsumerSubmitted',
        };
      }

      if (selected_type === 'Landing Not Possible') {
        return {
          fn: getPadListLandingNotPossible,
          str: 'getPadListLandingNotPossible',
        };
      }

      if (selected_type === 'Private Pads') {
        return { fn: getPadListPrivate, str: 'getPadListPrivate' };
      }

      if (selected_type === 'Archived Pads') {
        return { fn: getPadListArchived, str: 'getPadListArchived' };
      }

      return { fn: getPadList, str: 'getPadList' };
    })();

    setMapEndpoint({
      url: getPadListAction.str,
      params: {
        page,
        sort_column,
        sort_direction: sort_direction || 'ascend',
        keyword,
        ...advancedFilters,
      },
    });

    getPadListAction
      .fn({
        params: {
          page,
          sort_column,
          sort_direction: sort_direction || 'ascend',
          keyword,
          ...advancedFilters,
        },
      })(dispatch)
      .catch((err) => {
        if (err.response.status === 500) {
          setError({
            status: err.response.status,
            message: err.response.data.message,
          });
          sessionStorage.removeItem('pad-list-state');
        }
      });
    // eslint-disable-next-line
  }, [
    page,
    sort_column,
    sort_direction,
    filtersTrigger,
    dispatch,
    selected_type,
  ]);

  useUnmount(() => {
    if (cleanOnUnmount) {
      sessionStorage.removeItem('pad-list-state');
      cleanPadList()(dispatch);
    }
  });

  const onTableChange = useCallback(
    (pagination, tableFilters, sorter, extra) => {
      const newState = {};
      if (extra.action === 'paginate') newState.page = pagination.current;
      else if (extra.action === 'sort') {
        newState.sort_column = sorter.field;
        newState.sort_direction = sorter.order;
      }
      setState((prevState) => ({ ...prevState, ...newState }));
    },
    [setState],
  );

  const onSubmitFilters = useCallback(
    (values) => {
      setState((prevState) => ({
        ...prevState,
        ...values,
        filtersTrigger: !prevState.filtersTrigger,
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setState],
  );

  const clearFilters = useCallback(
    () =>
      setState((prevState) => ({
        ...initialState,
        filtersTrigger: !prevState.filtersTrigger,
      })),
    [setState],
  );

  const checkIfUnModerated = useCallback(
    (record) =>
      record.status_id === 1 || !!record.deleted_at ? 'transparent-el' : '',
    [],
  );

  const checkIfIsArchived = useCallback(
    (record) => (record.deleted_at ? 'archived-row' : ''),
    [],
  );

  const getRowStatus = useCallback(
    (record) => `${checkIfUnModerated(record)} ${checkIfIsArchived(record)}`,
    [checkIfIsArchived, checkIfUnModerated],
  );

  const setSavedFilter = (id = null) => {
    if (id) {
      let filter = {};
      if (savedFilters.length) {
        filter = savedFilters.find((itm) => itm.id === id);
      } else {
        filter = savedFilters;
      }
      const rows = Object.keys(filter.filters).map((row) =>
        formattingFilters(row, filter.filters[row], 'OBJECT'),
      );
      onSubmitFilters({
        filters: rows,
        keyword: undefined,
        filter_info: { id, name: filter.name, is_public: filter.is_public },
      });
    }
  };

  useEffect(() => {
    if (selectedFilter) {
      getUserFilters(padList?.entity)(dispatch)
        .then(() => {
          if (selectedFilter !== 'initial') {
            setSavedFilter(selectedFilter);
            setSelectedFilter(undefined);
          }
        })
        .catch((err) => {
          if (err.response?.status === 500) {
            setError({
              status: err.response.status,
              message: err.response.data.message,
            });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [padList?.entity, selectedFilter]);

  const menu = (
    <Menu>
      {savedFilters.length &&
        savedFilters.map((filter, idx) => (
          <Menu.Item key={idx} onClick={() => setSavedFilter(filter.id)}>
            {filter.name}
          </Menu.Item>
        ))}
    </Menu>
  );

  const filterByType = useCallback(
    (_, name) => () => {
      setState((prevState) => ({ ...prevState, selected_type: name }));
    },
    [setState],
  );

  const goToPad = (path) => {
    setCleanOnUnmountFalse()(dispatch).then(() => history.push(path));
  };

  const getPadName = (data, rowData) => (
    <Button
      type="link"
      className={condStrings(
        'custom-btn custom-icon',
        rowData?.is_private && 'private',
      )}
      icon={getPadIcon(data, rowData.category_id, rowData.status_id)}
      onClick={simulateNativeLink(`/dashboard/pad-view/${rowData.id}`, () =>
        goToPad(`/dashboard/pad-view/${rowData.id}`),
      )}
    >
      <span className="d-flex align-item-center">
        {data}
        {!rowData?.is_phonable && (
          <PhoneSlashIcon
            width="12"
            className="ml-1"
            style={{ minWidth: 12 }}
          />
        )}
        {!rowData?.is_landing_permitted && (
          <BanIcon width="12" className="ml-1" style={{ minWidth: 12 }} />
        )}
      </span>
    </Button>
  );

  const toggleListOnMap = async () => {
    const res = await api.pad.toggleMap();
    await updateUserSettings(res.data)(dispatch);

    setState((prevState) => ({
      ...prevState,
      listOnMap: !prevState.listOnMap,
    }));
  };

  if (error)
    return (
      <PageHeader className="page-main-header" title="Pads">
        <Alert
          showIcon
          type="error"
          message={`Error ${error.status}`}
          description={error.message}
        />
      </PageHeader>
    );

  return (
    <div>
      <PageHeader
        className="page-main-header"
        title="Pads"
        extra={[
          <Button
            key="pad-import-btn"
            onClick={simulateNativeLink('/dashboard/pad-import', () =>
              history.push('/dashboard/pad-import'),
            )}
          >
            Import pad
          </Button>,
          <Button
            key="pad-create-btn"
            onClick={simulateNativeLink('/dashboard/pad-create', () =>
              history.push('/dashboard/pad-create'),
            )}
          >
            Create pad
          </Button>,
          <Dropdown
            overlay={menu}
            trigger={['click']}
            placement="bottomRight"
            disabled={!savedFilters.length}
            key="saved_filters"
          >
            <Button loading={!savedFilters}>
              Saved Filters
              <DownOutlined />
            </Button>
          </Dropdown>,
        ]}
      >
        <div className="filters-container">
          <FiltersSection
            clearFilters={clearFilters}
            onSubmit={onSubmitFilters}
            filters={filters}
            keyword={keyword}
            columnOptions={padList.filterable}
            loading={!padList.filterable}
            filter_info={filter_info}
            filter_columns={padList?.columns}
            entity={padList?.entity}
            onUpdateFilter={setSelectedFilter}
            exportOptions={{
              text: 'Export Pads (CSV)',
              action: api.pad.export,
              filename: 'helipaddy_pads.csv',
            }}
          />
        </div>
      </PageHeader>

      <Summary
        data={padList}
        filterAction={filterByType}
        selectedType={selected_type}
      />

      <div className="toggle-pads-view d-inline-flex align-items-center">
        View on the Map
        <Switch
          size="small"
          checked={listOnMap}
          onClick={toggleListOnMap}
          className="ml-2"
          disabled={!padList?.data}
        />
      </div>

      {listOnMap && (
        <PadsOnMap
          categories={categories}
          action={mapEndpoint}
          status={listOnMap}
          onDisableAction={toggleListOnMap}
          cleanOnUnmount={cleanOnUnmount}
        />
      )}

      <div className="page-main-content position-relative">
        <Space
          className="d-flex justify-content-end"
          style={{ color: '#d9d9d9' }}
        >
          Showing {padList?.meta?.from}-{padList?.meta?.to} of{''}
          {padList?.meta?.total} listings
        </Space>
        <Table
          columns={[
            ...buildTableColumns(padList?.columns, {
              promisedClbk: () => setCleanOnUnmountFalse()(dispatch),
              name: {
                render: getPadName,
              },
            }),
          ]}
          className={(!padList?.data?.length && 'mt-5') || ''}
          rowKey={(e) => e.id}
          dataSource={padList?.data}
          loading={!padList?.data}
          onChange={onTableChange}
          rowClassName={getRowStatus}
          pagination={{
            position: ['topRight', 'bottomRight'],
            showSizeChanger: false,
            showQuickJumper: true,
            pageSize: padList?.meta?.per_page,
            total: padList?.meta?.total,
            current: page,
          }}
        />
        <Space
          className="d-flex justify-content-end"
          style={{ color: '#d9d9d9' }}
        >
          Showing {padList?.meta?.from}-{padList?.meta?.to} of{''}
          {padList?.meta?.total} listings
        </Space>
      </div>
    </div>
  );
};

export default PadListPage;
