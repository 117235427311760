import { useEffect } from 'react';
import ReactGoogleMapLoader from 'react-google-maps-loader';
import { useDispatch, useSelector } from 'react-redux';
import Router from './router';
import routes from './router/routes';
import { getUser } from './store/actions/userAction';
import GoogleMapsContext from './context/googleMapsContext';
import {
  setGlobalPrivateRequisites,
  setGlobalPublicRequisites,
} from './store/actions/bootstrapAction';

import styles from './App.module.sass';

function App() {
  const dispatch = useDispatch();
  const { isAuthorized } = useSelector((state) => ({
    isAuthorized: state.user.isAuthorized,
  }));

  useEffect(() => {
    if (isAuthorized) {
      getUser()(dispatch);
      setGlobalPrivateRequisites()(dispatch);
    }
    setGlobalPublicRequisites()(dispatch);
  }, [dispatch, isAuthorized]);

  return (
    <div className={styles.root}>
      <ReactGoogleMapLoader
        params={{
          key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
          libraries: 'places',
          language: 'en',
        }}
        render={(googleMaps, googleMapsError) => (
          <GoogleMapsContext.Provider
            value={{ googleMapsIsLoaded: !!googleMaps, googleMapsError }}
          >
            <Router routes={routes} />
          </GoogleMapsContext.Provider>
        )}
      />
    </div>
  );
}

export default App;
