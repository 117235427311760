import React from 'react';
import { useDebounce } from 'react-use';
import GoogleMapsContext from '../../../context/googleMapsContext';
import getAddressComponentByName from '../../../utils/getAddressComponentByName';

let geocoderService;

const getGeoCodeByLatLng = (lat, lng) =>
  new Promise((resolve, reject) =>
    geocoderService.geocode(
      {
        location: { lat: parseFloat(lat), lng: parseFloat(lng) },
      },
      (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK)
          return resolve(results);
        return reject();
      },
    ),
  );

export function usePadViewLocation() {
  const [location, setLocation] = React.useState({});
  const [mapDragPinActive, setMapDragPinActive] = React.useState(false);
  const { googleMapsIsLoaded } = React.useContext(GoogleMapsContext);

  React.useEffect(() => {
    if (googleMapsIsLoaded) {
      geocoderService = new window.google.maps.Geocoder();
    }
  }, [googleMapsIsLoaded]);

  useDebounce(
    () => {
      if (
        location.getGeoCode &&
        !isNaN(location.latitude) &&
        location.latitude > -91 &&
        location.latitude < 91 &&
        !isNaN(location.longitude) &&
        location.longitude > -181 &&
        location.longitude < 181
      )
        getGeoCodeByLatLng(location.latitude, location.longitude).then(
          (results) => {
            if (results.length) {
              setLocation({
                address: results[0].formatted_address,
                latitude: location.latitude,
                longitude: location.longitude,
                country: getAddressComponentByName(
                  results[0].address_components,
                  'country',
                ).long_name,
                country_short: getAddressComponentByName(
                  results[0].address_components,
                  'country',
                ).short_name,
                county: getAddressComponentByName(
                  results[0].address_components,
                  'administrative_area_level_2',
                ).long_name,
              });
            }
          },
        );
    },
    300,
    [location.latitude, location.longitude, location.getGeoCode],
  );

  return { location, setLocation, mapDragPinActive, setMapDragPinActive };
}
