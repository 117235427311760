import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
import { Form, Input, Modal, notification } from 'antd';
import { useParams } from 'react-router-dom';
import SearchOwner from '../../../../../components/searchOwner';
import Space from '../../../../../uiKitComponents/space';
import Button from '../../../../../uiKitComponents/button';
import isEmailValid from '../../../../../utils/isEmailValid';
import styles from '../adminTab.module.sass';
import PhoneInput from '../../../../../components/phoneInput';
import getApiErrorMessages from '../../../../../utils/getApiErrorMessages';
import api from '../../../../../utils/appApi';
import phoneWithPlus from '../../../../../utils/phoneWithPlus';

const AddOwner = (props) => {
  const { onAddOwner, updatePadContacts, isArchived } = props;
  const [owner, setOwner] = useState();
  const [createOwnerConfig, setCreateOwnerConfig] = useState({
    email: '',
    active: false,
  });
  const { padId } = useParams();

  const [createOwnerVisible, setCreateOwnerVisibile] = useState(false);
  const [form] = Form.useForm();

  const handleSelectOwner = useCallback((e) => {
    setOwner(e.target.value);
  }, []);

  const handleAddOwner = useCallback(() => {
    onAddOwner(owner);
    setOwner(undefined);
  }, [owner, onAddOwner]);

  const onSearched = useCallback(
    (query, list) => {
      if (isEmailValid(query)) {
        if (!list || !list.length) {
          setCreateOwnerConfig({ email: query, active: true });
        }
      } else if (createOwnerConfig.active) {
        setCreateOwnerConfig({ email: '', active: false });
      }
    },
    [createOwnerConfig.active],
  );

  const startCreateOwner = useCallback(() => {
    setCreateOwnerVisibile(true);
    form.setFields([{ name: 'email', value: createOwnerConfig.email }]);
  }, [createOwnerConfig.email, form]);

  const cancelCreateOwner = useCallback(() => {
    form.resetFields();
    setCreateOwnerVisibile(false);
    setCreateOwnerConfig({ email: '', active: false });
  }, [form]);

  const onFormSubmit = useCallback(
    async (values) => {
      const data = {
        name: null,
        email: null,
        phone: null,
        ...values,
      };

      data.contact_type_id = 4;
      data.pad_id = +padId;
      data.phone = phoneWithPlus(data.phone);

      try {
        const response = await api.pad.createOwner(data);

        data.id = response.data.id;
        data.is_claimed = true;
        data.is_premium = false;
        data.user_id = null;

        updatePadContacts(data);

        form.resetFields();
        setCreateOwnerVisibile(false);
        setCreateOwnerConfig({ email: '', active: false });
      } catch (error) {
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(error),
        });
      }
    },
    [form, padId, updatePadContacts],
  );

  return (
    <Space>
      <SearchOwner
        style={{ width: 250 }}
        value={owner}
        onSelect={handleSelectOwner}
        onAfterSearch={onSearched}
        disabled={isArchived}
      />
      {createOwnerConfig.active ? (
        <>
          <Button type="primary" onClick={startCreateOwner}>
            Create
          </Button>
          <Modal
            wrapClassName={styles.createOwner}
            width={380}
            title="Create New Owner"
            visible={createOwnerVisible}
            centered
            okText="Create"
            onOk={form.submit}
            onCancel={cancelCreateOwner}
          >
            <Form form={form} onFinish={onFormSubmit} layout="vertical">
              <Form.Item label="Email" name="email">
                <Input type="email" disabled />
              </Form.Item>
              <Form.Item label="Name" name="name">
                <Input />
              </Form.Item>
              <Form.Item className="mb-0" label="Phone" name="phone">
                <PhoneInput />
              </Form.Item>
            </Form>
          </Modal>
        </>
      ) : (
        <Button htmlType="button" disabled={!owner} onClick={handleAddOwner}>
          Add
        </Button>
      )}
    </Space>
  );
};

AddOwner.defaultProps = {
  onAddOwner: () => null,
  updatePadContacts: () => null,
  isArchived: false,
};

AddOwner.propTypes = {
  onAddOwner: PropTypes.func,
  updatePadContacts: PropTypes.func,
  isArchived: PropTypes.bool,
};

export default AddOwner;
