import { Skeleton } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import Lightbox from 'react-image-lightbox';

import './index.scss';

export default function PadSurveyUploads(props) {
  const { loading, images, hideDescription } = props;
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [isPreviewActive, setIsPreviewActive] = React.useState(false);

  const onImageClick = (index) => () => {
    setCurrentIndex(index);
    setIsPreviewActive(true);
  };

  if (loading) {
    return (
      <div className="survey-uploads-grid">
        <div className="survey-upload">
          <div className="survey-upload-img">
            <Skeleton.Avatar
              active
              shape="square"
              style={{ borderRadius: 4, width: 42, height: 42 }}
            />
          </div>
          <div className="survey-upload-caption" style={{ width: 100 }}>
            <Skeleton
              active
              title={false}
              paragraph={{
                rows: 1,
                className: 'm-0 pl-1 li-100',
                style: { width: '75%' },
              }}
              className="m-0"
            />
            <Skeleton
              active
              title={false}
              paragraph={{
                rows: 1,
                className: 'm-0 mt-2 pl-1 li-100',
                style: { width: '100%' },
              }}
              className="m-0"
            />
          </div>
        </div>
        <div className="survey-upload">
          <div className="survey-upload-img">
            <Skeleton.Avatar
              active
              shape="square"
              style={{ borderRadius: 4, width: 42, height: 42 }}
            />
          </div>
          <div className="survey-upload-caption" style={{ width: 100 }}>
            <Skeleton
              active
              title={false}
              paragraph={{
                rows: 1,
                className: 'm-0 pl-1 li-100',
                style: { width: '75%' },
              }}
              className="m-0"
            />
            <Skeleton
              active
              title={false}
              paragraph={{
                rows: 1,
                className: 'm-0 mt-2 pl-1 li-100',
                style: { width: '100%' },
              }}
              className="m-0"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="survey-uploads-grid">
        {images.map((img, idx) => (
          <div className="survey-upload" key={img.id}>
            <div className="survey-upload-img">
              <img
                alt={img.direction}
                src={img.url}
                aria-hidden="true"
                onClick={onImageClick(idx)}
              />
            </div>
            <div className="survey-upload-caption">
              <h4>{img.dirLabel}</h4>
              {!hideDescription && <p>{img.descrp || '---'}</p>}
            </div>
          </div>
        ))}
      </div>
      {isPreviewActive && (
        <Lightbox
          mainSrc={images[currentIndex].url}
          nextSrc={images[(currentIndex + 1) % images.length].url}
          prevSrc={
            images[(currentIndex + images.length - 1) % images.length].url
          }
          mainSrcThumbnail={images[currentIndex]?.dir}
          nextSrcThumbnail={images[(currentIndex + 1) % images.length]?.dir}
          prevSrcThumbnail={
            images[(currentIndex + images.length - 1) % images.length]?.dir
          }
          onCloseRequest={() => setIsPreviewActive(false)}
          onMovePrevRequest={() =>
            setCurrentIndex((currentIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setCurrentIndex((currentIndex + 1) % images.length)
          }
        />
      )}
    </>
  );
}

PadSurveyUploads.propTypes = {
  loading: PropTypes.bool,
  images: PropTypes.array,
  hideDescription: PropTypes.bool,
};

PadSurveyUploads.defaultProps = {
  loading: false,
  images: [],
  hideDescription: false,
};
