import {
  MODERATION_LIST_GET,
  MODERATION_LIST_CLEAN,
  CLEAN_ON_UNMOUNT_TRUE,
  CLEAN_ON_UNMOUNT_FALSE,
} from '../actionTypes';
import api from '../../utils/appApi';
import { loadingBarDisable, loadingBarEnable } from './loadingBarAction';

export const getModerationList = (config) => (dispatch) =>
  api.revision
    .getList(config)
    .then((res) => {
      dispatch({ type: MODERATION_LIST_GET, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const cleanModerationList = () => (dispatch) =>
  dispatch({
    type: MODERATION_LIST_CLEAN,
  });

export const setCleanOnUnmountTrue = () => (dispatch) =>
  new Promise((resolve, reject) => {
    try {
      dispatch({
        type: CLEAN_ON_UNMOUNT_TRUE,
      });
      return resolve();
    } catch (e) {
      return reject(Error(e));
    }
  });

export const setCleanOnUnmountFalse = () => (dispatch) =>
  new Promise((resolve, reject) => {
    try {
      dispatch({
        type: CLEAN_ON_UNMOUNT_FALSE,
      });
      return resolve();
    } catch (e) {
      return reject(Error(e));
    }
  });

export const filterModerationsByType = (type, data) => (dispatch) => {
  loadingBarEnable()(dispatch);
  return api.revision
    .filterByType(type, data)
    .then((res) => {
      dispatch({ type: MODERATION_LIST_GET, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err))
    .finally(() => loadingBarDisable()(dispatch));
};

export const filterModerationsByTypeReported = (data) => (dispatch) => {
  loadingBarEnable()(dispatch);
  return api.revision
    .filterByTypeReported(data)
    .then((res) => {
      dispatch({ type: MODERATION_LIST_GET, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err))
    .finally(() => loadingBarDisable()(dispatch));
};

export const filterModerationsByTypePadAdditions = (data) => (dispatch) => {
  loadingBarEnable()(dispatch);
  return api.revision
    .filterByTypePadAdditions(data)
    .then((res) => {
      dispatch({ type: MODERATION_LIST_GET, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err))
    .finally(() => loadingBarDisable()(dispatch));
};

export const filterModerationsByTypePurgatory = (data) => (dispatch) => {
  loadingBarEnable()(dispatch);
  return api.revision
    .filterByTypePurgatory(data)
    .then((res) => {
      dispatch({ type: MODERATION_LIST_GET, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err))
    .finally(() => loadingBarDisable()(dispatch));
};
