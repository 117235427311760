import PhoneInput2 from 'react-phone-input-2';
import PropTypes from 'prop-types';
import condStrings from '../../utils/condStrings';

const inputSizes = {
  small: 'ant-input-sm',
  middle: '',
  large: 'ant-input-lg',
};

const PhoneInput = (props) => {
  const { containerClass, inputClass, name, onChange, size, ...rest } = props;

  const handleOnChange = (value) =>
    onChange({
      target: {
        name,
        value,
      },
    });

  return (
    <PhoneInput2
      {...rest}
      containerClass={condStrings('phone-input-container', containerClass)}
      inputClass={condStrings('ant-input', inputClass, inputSizes[size])}
      country="gb"
      onChange={handleOnChange}
      specialLabel={null}
      countryCodeEditable
      enableSearch
      searchPlaceholder="Search..."
      disableSearchIcon
    />
  );
};

PhoneInput.defaultProps = {
  containerClass: '',
  inputClass: '',
  name: '',
  onChange: () => null,
  size: 'middle',
};

PhoneInput.propTypes = {
  containerClass: PropTypes.string,
  inputClass: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['small', 'middle', 'large']),
};

export default PhoneInput;
