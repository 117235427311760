import FormItem from 'antd/lib/form/FormItem';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import PicturesWall from '../../picturesWall';
import DataRowContext from '../DataRowContext';
import { parseUploadsToPicturesWall } from '../dataRowUtils';

export default function RowControllerPhotos(props) {
  const { fileList, name } = props;
  const { label } = useContext(DataRowContext);

  return (
    <FormItem label={label} name={name}>
      <PicturesWall
        fileList={parseUploadsToPicturesWall(fileList)}
        showUploadBtn={false}
        showRemoveIcon={false}
      />
    </FormItem>
  );
}

RowControllerPhotos.propTypes = {
  fileList: PropTypes.array,
  name: PropTypes.string.isRequired,
};

RowControllerPhotos.defaultProps = {
  fileList: [],
};
