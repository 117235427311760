import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, notification } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import Alert from '../../uiKitComponents/alert';
import {
  loadingBarDisable,
  loadingBarEnable,
} from '../../store/actions/loadingBarAction';
import Form from '../../uiKitComponents/form';
import useApiErrorsWithAntd from '../../hooks/useApiErrorsWithAntd';
import Descriptions from '../../uiKitComponents/descriptions';
import Input from '../../uiKitComponents/input';
import { updateUser } from '../../store/actions/userAction';
import Space from '../../uiKitComponents/space';
import formattingFilters from '../../utils/formattingFilters';
import buildTableColumns from '../../utils/buildTableColumns';
import Table from '../../uiKitComponents/table';
import Modal from '../../uiKitComponents/modal';
import api from '../../utils/appApi';

const initialState = {
  page: 1,
  sort_column: 'id',
  sort_direction: 'descend',
  filters: [
    {
      column: 'role',
      keyword: '1',
      operator: 'equals',
    },
  ],
  filtersTrigger: false,
};

const AdminProfilePage = () => {
  const [editDataMode, setEditDataMode] = useState(false);
  const [editPassMode, setEditPassMode] = useState(false);
  const [passMatch, setPassMatch] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showInactiveUser, setShowInactiveUser] = useState(true);

  const [formData] = Form.useForm();
  const [formPass] = Form.useForm();
  const { setApiErrorsToAntdForm } = useApiErrorsWithAntd(formData);
  const { setApiErrorsToAntdFormPass } = useApiErrorsWithAntd(formPass);
  const globalErrors = formData.getFieldError('global');
  const passNotMatch = formPass.getFieldError('passNotMatch');
  const passResetSuccessful = formPass.getFieldError('passResetSuccessful');
  const dispatch = useDispatch();
  const { adminData } = useSelector((state) => ({
    adminData: state.user.payload,
  }));

  // ADMIN CRUD
  const [adminListState, setAdminListState] = useState(initialState);
  const [createAdminForm, setCreateAdminForm] = useState(false);
  const [confirmCreatedAdmin, setConfirmCreatedAdmin] = useState(false);
  const [form] = Form.useForm();
  const {
    page,
    sort_column,
    sort_direction,
    filters,
    filtersTrigger,
  } = adminListState;
  const [adminList, setAdminList] = useState(null);

  useEffect(() => {
    const advancedFilters = {};
    filters.forEach((filter) => {
      const temp_filter = formattingFilters(filter, filter.keyword, 'STRING');

      Object.keys(temp_filter).forEach((key) => {
        advancedFilters[`filters[${key}]`] = temp_filter[key];
      });
    });

    api.users
      .getAdminList({
        params: {
          page,
          sort_column,
          sort_direction: sort_direction || 'ascend',
          type: showInactiveUser ? 1 : null,
          ...advancedFilters,
        },
      })
      .then((res) => {
        setAdminList(res.data);
      })
      .catch((err) => console.log(err));
  }, [
    page,
    sort_column,
    sort_direction,
    filtersTrigger,
    filters,
    showInactiveUser,
  ]);

  const handleCreateAdmin = () => form.submit();

  const createAdmin = async (values) => {
    setConfirmCreatedAdmin(true);

    try {
      const response = await api.user.store({
        ...values,
        role: 1,
        is_tac_accepted: true,
      });

      setConfirmCreatedAdmin(false);
      form.resetFields();
      setCreateAdminForm(false);
      setAdminList({
        ...adminList,
        data: [...adminList?.data, response?.data],
      });

      notification.success({
        message: 'Success',
        description: 'Admin was created successfully',
      });
    } catch (err) {
      setConfirmCreatedAdmin(false);

      notification.error({
        message: 'Error',
        description: err?.message || 'Unknown error',
      });
    }
  };

  const onTableChange = useCallback(
    (pagination, tableFilters, sorter, extra) => {
      const newState = {};
      if (extra.action === 'paginate') newState.page = pagination.current;
      else if (extra.action === 'sort') {
        newState.sort_column = sorter.field;
        newState.sort_direction = sorter.order;
      }
      setAdminListState((prevState) => ({ ...prevState, ...newState }));
    },
    [setAdminListState],
  );

  const enableEditDataMode = useCallback(() => {
    formData.resetFields();
    setEditDataMode(true);
  }, [formData]);

  const disableEditableMode = useCallback(() => {
    formData.resetFields();
    setEditDataMode(false);
  }, [formData]);

  const enableEditPassMode = useCallback(() => {
    formPass.resetFields();
    setEditPassMode(true);
    setPassMatch('');
  }, [formPass]);

  const disableEditPassMode = useCallback(() => {
    formPass.resetFields();
    setEditPassMode(false);
    setPassMatch('');
  }, [formPass]);

  const setPasswordState = useCallback((event) => {
    setPassword(event.target.value);
  }, []);

  const setConfirmPasswordState = useCallback((event) => {
    setConfirmPassword(event.target.value);
  }, []);

  const enableMatch = useCallback(() => {
    setPassMatch(true);
  }, []);

  const disableMatch = useCallback(() => {
    setPassMatch(false);
  }, []);

  const onSubmitData = useCallback(
    (values) => {
      if (adminData?.id) {
        loadingBarEnable()(dispatch);
        updateUser(
          adminData?.id,
          values,
        )(dispatch)
          .then(() => {
            loadingBarDisable()(dispatch);
            setEditDataMode(false);
            setEditPassMode(false);
          })
          .catch((err) => {
            loadingBarDisable()(dispatch);
            setApiErrorsToAntdForm(err);
            setApiErrorsToAntdFormPass(err);
          });
      }
    },
    [
      adminData?.id,
      dispatch,
      setApiErrorsToAntdForm,
      setApiErrorsToAntdFormPass,
    ],
  );

  const onSubmitPass = useCallback(
    (values) => {
      const passMatches = password === confirmPassword;
      if (passMatches) {
        onSubmitData(values);
        enableMatch();
      } else {
        disableMatch();
      }
    },
    [onSubmitData, enableMatch, disableMatch, password, confirmPassword],
  );

  return (
    <div>
      <div className="page-main-content">
        <Form
          form={formData}
          onFinish={onSubmitData}
          autoComplete="off"
          className="pb-1"
        >
          <Space className="d-flex align-content-center justify-content-between mb-2">
            <h2>General profile data</h2>
            {editDataMode ? (
              <>
                <Button.Group>
                  <Button htmlType="button" onClick={disableEditableMode}>
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Save changes
                  </Button>
                </Button.Group>
              </>
            ) : (
              <Button htmlType="button" onClick={enableEditDataMode}>
                Edit profile data
              </Button>
            )}
          </Space>
          <Descriptions
            labelStyle={{ width: 150 }}
            size="small"
            bordered
            column={1}
          >
            <Descriptions.Item label="First Name">
              {editDataMode ? (
                <Space className="d-flex justify-content-between">
                  <Form.Item
                    name="first_name"
                    initialValue={adminData?.first_name}
                    className="mb-0"
                  >
                    <Input
                      size="small"
                      placeholder="First Name"
                      maxLength={200}
                    />
                  </Form.Item>
                </Space>
              ) : (
                <span>{adminData?.first_name}</span>
              )}
            </Descriptions.Item>

            <Descriptions.Item label="Last Name">
              {editDataMode ? (
                <Space>
                  <Form.Item
                    name="last_name"
                    initialValue={adminData?.last_name}
                    className="mb-1"
                  >
                    <Input
                      size="small"
                      placeholder="Last Name"
                      maxLength={200}
                    />
                  </Form.Item>
                </Space>
              ) : (
                <span>{adminData?.last_name}</span>
              )}
            </Descriptions.Item>

            <Descriptions.Item label="Username">
              {editDataMode ? (
                <Space>
                  <Form.Item
                    name="username"
                    initialValue={adminData?.username}
                    className="mb-1"
                  >
                    <Input
                      size="small"
                      placeholder="Username"
                      maxLength={200}
                    />
                  </Form.Item>
                </Space>
              ) : (
                <span>{adminData?.username}</span>
              )}
            </Descriptions.Item>

            <Descriptions.Item label="Email">
              {editDataMode ? (
                <Space>
                  <Form.Item
                    name="email"
                    initialValue={adminData?.email}
                    className="mb-1"
                  >
                    <Input size="small" placeholder="Email" maxLength={200} />
                  </Form.Item>
                </Space>
              ) : (
                <span>{adminData?.email}</span>
              )}
            </Descriptions.Item>
          </Descriptions>
          {!!globalErrors.length && (
            <Alert
              message="Error"
              description={globalErrors}
              type="error"
              showIcon
            />
          )}
        </Form>
      </div>
      <div className="page-main-content">
        <Form form={formPass} onFinish={onSubmitPass} autoComplete="off">
          <Space className="d-flex align-content-center justify-content-between pt-1 pb-1">
            <h2 className="mb-0">Password</h2>
            {editPassMode ? (
              <>
                <Button.Group>
                  <Button htmlType="button" onClick={disableEditPassMode}>
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Save new password
                  </Button>
                </Button.Group>
              </>
            ) : (
              <Button htmlType="button" onClick={enableEditPassMode}>
                Edit password
              </Button>
            )}
          </Space>

          {editPassMode ? (
            <div>
              <Descriptions
                labelStyle={{ width: 150 }}
                size="small"
                bordered
                column={1}
              >
                <Descriptions.Item label="Password">
                  <Space className="d-flex justify-content-between">
                    <Form.Item name="password" className="m-0">
                      <Input.Password
                        size="large"
                        prefix={<LockOutlined />}
                        type="password"
                        placeholder="Password"
                        onChange={setPasswordState}
                      />
                    </Form.Item>
                  </Space>
                </Descriptions.Item>
                <Descriptions.Item label="Confirm password">
                  <Space className="d-flex justify-content-between">
                    <Form.Item name="password_confirmation" className="m-0">
                      <Input.Password
                        size="large"
                        prefix={<LockOutlined />}
                        type="password"
                        placeholder="Confirm password"
                        onChange={setConfirmPasswordState}
                      />
                    </Form.Item>
                  </Space>
                </Descriptions.Item>
              </Descriptions>
              {passMatch === false && (
                <Space className="mt-2">
                  <Alert
                    message="Password confirmation does not match"
                    description={passNotMatch}
                    type="error"
                    showIcon
                  />
                </Space>
              )}
            </div>
          ) : (
            ''
          )}
          {passMatch && (
            <Space>
              <Alert
                message="Your password has been changed successfully!"
                description={passResetSuccessful}
                type="success"
                showIcon
              />
            </Space>
          )}

          {!!globalErrors.length && (
            <Alert
              message="Error"
              description={globalErrors}
              type="error"
              showIcon
            />
          )}
        </Form>
      </div>
      <div className="page-main-content">
        <Space className="pt-2 mb-4 d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <h2 className="pl-1 mb-0">List of Admins</h2>
            <Button
              htmlType="button"
              className="ml-3"
              onClick={() => setShowInactiveUser((prevState) => !prevState)}
            >
              {showInactiveUser ? 'Hide' : 'Show'} inactive users
            </Button>
          </div>
          <Button htmlType="button" onClick={() => setCreateAdminForm(true)}>
            Add a new admin
          </Button>
        </Space>
        <Table
          columns={[
            ...buildTableColumns(adminList?.columns, {
              deleted_at: {
                render: (data) => (data ? 'Inactive' : 'Active'),
              },
            }),
          ]}
          rowKey="id"
          dataSource={adminList?.data}
          loading={!adminList?.data}
          onChange={onTableChange}
          pagination={{
            position: ['topRight', 'bottomRight'],
            showSizeChanger: false,
            showQuickJumper: true,
            pageSize: adminList?.meta?.per_page,
            total: adminList?.meta?.total,
            current: page,
            hideOnSinglePage: true,
          }}
        />
        <Modal
          title="Register a new Admin"
          visible={createAdminForm}
          onOk={handleCreateAdmin}
          confirmLoading={confirmCreatedAdmin}
          onCancel={() => {
            setCreateAdminForm(false);
            form.resetFields();
          }}
        >
          <Form layout="vertical" form={form} onFinish={createAdmin}>
            <Form.Item
              name="first_name"
              label="Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="last_name"
              label="Surname"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="username"
              label="Username"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ type: 'email', required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="password_confirmation"
              label="Confirm Password"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        'The two passwords that you entered do not match!',
                      ),
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default AdminProfilePage;
