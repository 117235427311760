import {
  PAD_LIST_GET,
  PAD_LIST_CLEAN,
  CLEAN_ON_UNMOUNT_TRUE,
  CLEAN_ON_UNMOUNT_FALSE,
  UPLOAD_PAD_ITEM,
} from '../actionTypes';

const initialState = {
  cleanOnUnmount: true,
  payload: {},
};

const padList = (state = initialState, action = {}) => {
  switch (action.type) {
    case PAD_LIST_GET:
      return {
        ...state,
        payload: action.payload,
      };
    case PAD_LIST_CLEAN:
      return initialState;
    case CLEAN_ON_UNMOUNT_TRUE:
      return {
        ...state,
        cleanOnUnmount: true,
      };
    case CLEAN_ON_UNMOUNT_FALSE:
      return {
        ...state,
        cleanOnUnmount: false,
      };
    case UPLOAD_PAD_ITEM:
      return {
        ...state,
        ...action,
        upload: action.payload,
      };
    default:
      return state;
  }
};

export default padList;
