import React from 'react';
import api from '../../../utils/appApi';

export default function useSurveyData(id) {
  const [surveyData, setSurveyData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    api.survey
      .get(id)
      .then((res) => {
        setSurveyData(res.data);
      })
      .finally(() => setIsLoading(false));
  }, [id]);

  const updateSurveyOnFormValuesChange = React.useCallback((changedValues) => {
    const changedValuesStepKey = Object.keys(changedValues)[0];

    setSurveyData((prev) => ({
      ...prev,
      [changedValuesStepKey]: {
        ...prev[changedValuesStepKey],
        ...changedValues[changedValuesStepKey],
      },
    }));
  }, []);

  return {
    isLoading,
    surveyData,
    setSurveyData,
    updateSurveyOnFormValuesChange,
  };
}
