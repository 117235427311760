import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { containerSizes } from '../../utils/constants';

const ContainerLayoutRule = (props) => {
  const { nextRule: NextRule, ...rest } = props;

  const route = (
    <Route
      render={() =>
        (NextRule && <NextRule {...rest} />) || <props.component {...props} />
      }
    />
  );

  if (props.containerSize && containerSizes.includes(props.containerSize))
    return <div className={`${props.containerSize}-container`}>{route}</div>;

  return route;
};

ContainerLayoutRule.defaultProps = {
  containerSize: undefined,
  nextRule: null,
};

ContainerLayoutRule.propTypes = {
  containerSize: PropTypes.oneOf(['sm', 'lg', undefined, null, false]),
  nextRule: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
    PropTypes.func,
  ]),
};

export default ContainerLayoutRule;
