import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import { setUserToAuthorized } from './store/actions/userAction';
import reportWebVitals from './reportWebVitals';
import history from './utils/history';

import 'react-phone-input-2/lib/style.css'; // before antd because antd must override react-phone-input styles
import './less/antd.less'; // antd.less - contain ant design styles + theme override
import './index.sass'; // index.sass (what contains all global styles) need to be imported before App import

import App from './App';

// Set user to Authorized on page refresh
if (localStorage.getItem('isAuthorized') === 'true')
  store.dispatch(setUserToAuthorized());

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
