import PropTypes from 'prop-types';
import { useCallback, useState, useEffect } from 'react';
import { Spin } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import TableHead from './TableHead';
import TableBody from './TableBody';

import './draggable-table.scss';
import TableBodyContext from './TableBodyContext';
import DraggableTableEmpty from './DraggableTableEmpty';

export default function DraggableRowsTable(props) {
  const {
    onDragOrderChanged,
    setIsDragging,
    dataColumns,
    dataSource,
    loading,
    rowClassName,
    rowKey,
  } = props;
  const [rowsRenderKeys, setRowsRenderKeys] = useState([]);

  const renderCell = (column) => (data, rowData) => {
    if (column.render) {
      return column.render(data, rowData);
    }

    return data;
  };

  useEffect(
    () =>
      setRowsRenderKeys(
        dataColumns.map((column) => ({
          propery: column.dataIndex,
          render: renderCell(column),
        })),
      ),
    [dataColumns],
  );

  const moveRowHandler = useCallback(
    (dragIdx, hoverIdx) => {
      const dragFile = dataSource[dragIdx];
      onDragOrderChanged(
        update(dataSource, {
          $splice: [
            [dragIdx, 1],
            [hoverIdx, 0, dragFile],
          ],
        }),
      );
    },
    [onDragOrderChanged, dataSource],
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="ant-table-wrapper">
        <Spin spinning={loading}>
          <div className="ant-table">
            <div className="ant-table-container">
              <div className="ant-table-content">
                <table className="draggable-rows-table">
                  <TableHead columns={dataColumns} />
                  <TableBodyContext.Provider
                    value={{
                      moveRowHandler,
                      setIsDragging,
                      rowsRenderKeys,
                      rowClassName,
                      rowKey,
                    }}
                  >
                    {dataSource.length ? (
                      <TableBody rows={dataSource} />
                    ) : (
                      <DraggableTableEmpty span={dataColumns.length + 1} />
                    )}
                  </TableBodyContext.Provider>
                </table>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </DndProvider>
  );
}

DraggableRowsTable.propTypes = {
  onDragOrderChanged: PropTypes.func.isRequired,
  setIsDragging: PropTypes.func,
  dataColumns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      key: PropTypes.string,
      dataIndex: PropTypes.string,
      render: PropTypes.func,
    }),
  ),
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  rowClassName: PropTypes.func,
  rowKey: PropTypes.string,
};

DraggableRowsTable.defaultProps = {
  dataColumns: [],
  dataSource: [],
  loading: false,
  rowClassName: () => null,
  rowKey: 'id',
  setIsDragging: () => null,
};
