import PropTypes from 'prop-types';
import { useState, useCallback, useMemo } from 'react';
import { useMount } from 'react-use';
import {
  Affix,
  Button,
  Form,
  Input,
  Modal,
  notification,
  Space,
  Tooltip,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  selectCategoryOptions,
  selectContactTypeOptions,
  selectFacilityOptions,
  selectLandingFeeOptions,
  selectRegionOptions,
  selectTagOptions,
  selectWarningOptions,
} from '../../store/selectors/bootstrapSelector';
import useApiErrorsWithAntd from '../../hooks/useApiErrorsWithAntd';
import DataRow from './DataRow';
import FullPageLoading from '../../containers/FullPageLoading';
import convertDMS from '../../utils/convertDMS';
import {
  latValidationRules,
  lngValidationRules,
  twitterUsernameHandle,
} from './dataRowUtils';
import condStrings from '../../utils/condStrings';
import getApiErrorMessages from '../../utils/getApiErrorMessages';
import {
  loadingBarDisable,
  loadingBarEnable,
} from '../../store/actions/loadingBarAction';
import DupeResolverTop from './Controllers/DupeResolverTop';

export default function DuplicatesResolver(props) {
  const {
    onSubmit,
    onReject,
    getData,
    prevTitle,
    finalTitle,
    nextTitle,
    extraLeft,
    extraRight,
    type,
  } = props;
  const {
    categories,
    regions,
    tags,
    landingFees,
    warnings,
    facilities,
  } = useSelector((store) => ({
    categories: selectCategoryOptions(store),
    regions: selectRegionOptions(store),
    tags: selectTagOptions(store),
    landingFees: selectLandingFeeOptions(store),
    contactTypes: selectContactTypeOptions(store),
    warnings: selectWarningOptions(store),
    facilities: selectFacilityOptions(store),
  }));
  const [revision, setRevision] = useState({
    isLoaded: false,
    prev: {},
    next: {},
  });
  const [location, setLocation] = useState({});
  const [formConfig, setFormConfig] = useState({
    isAerodrome: false,
    isApi: false,
    contacts: [],
  });
  const [selectedPictures, setSelectedPictures] = useState({
    admin: [],
    owner: [],
    pilot: [],
  });
  const [form] = Form.useForm();
  const { setApiErrorsToAntdForm } = useApiErrorsWithAntd(form);
  const dispatch = useDispatch();
  const { prev, next } = revision;

  const [
    isWarningAddressSplitDisplayed,
    setIsWarningAddressSplitDisplayed,
  ] = useState(next?.location?.is_address_split);

  useMount(() => {
    getData().then((res) => {
      setFormConfig((prevState) => ({
        ...prevState,
        isAerodrome: res.data?.next?.category_id === 7,
        isApi: res.data?.next?.category_id === 1,
      }));
      setLocation({
        latitude: res?.data?.prev?.location?.latitude,
        longitude: res?.data?.prev?.location?.longitude,
        getGeoCode: !res?.data?.prev?.location?.region,
      });
      setRevision({ ...res.data, isLoaded: true });
    });
  });

  const editOneOfLocationCoord = (key, value, getGeoCode = true) =>
    setLocation((prevState) => ({
      ...prevState,
      [key]: value,
      getGeoCode,
    }));

  const debouncedLocationInput = useCallback((key, value, limit) => {
    if (+value >= -limit && +value <= limit) editOneOfLocationCoord(key, value);
  }, []);

  const setWarningAddressSplitCondition = useCallback(() => {
    if (
      form.getFieldValue('location#latitude') === prev?.location?.latitude &&
      form.getFieldValue('location#longitude') === prev?.location?.longitude &&
      form.getFieldValue('location#name') === prev?.location?.name
    ) {
      setIsWarningAddressSplitDisplayed(prev?.location?.is_address_split);
    } else if (
      form.getFieldValue('location#latitude') === next.location.latitude &&
      form.getFieldValue('location#longitude') === next.location.longitude &&
      form.getFieldValue('location#name') === next.location.name
    ) {
      setIsWarningAddressSplitDisplayed(next.location.is_address_split);
    } else {
      setIsWarningAddressSplitDisplayed(true);
    }
  }, [form, prev, next]);

  const updateFormConfig = useCallback(
    (values) => {
      const updatedFormConfig = {};

      if (Object.hasOwn(values, 'category_id')) {
        if (values.category_id === 7) updatedFormConfig.isAerodrome = true;
        else updatedFormConfig.isAerodrome = false;

        if (values.category_id === 1) updatedFormConfig.isApi = true;
        else updatedFormConfig.isApi = false;
      }

      if (Object.hasOwn(values, 'contacts')) {
        updatedFormConfig.contacts = values.contacts;
      }

      if (Object.hasOwn(values, 'location#latitude')) {
        debouncedLocationInput('latitude', values['location#latitude'], 90);
      }
      if (Object.hasOwn(values, 'location#longitude')) {
        debouncedLocationInput('longitude', values['location#longitude'], 180);
      }

      setWarningAddressSplitCondition();

      setFormConfig((prevState) => ({ ...prevState, ...updatedFormConfig }));
    },
    [debouncedLocationInput, setWarningAddressSplitCondition],
  );

  const updateFormField = useCallback(
    (name) => (value) => {
      form.setFields([{ name, value }]);

      if (name === 'location#latitude' || name === 'location#longitude') {
        updateFormConfig({ [name]: value });
      }
      setWarningAddressSplitCondition();
    },
    [form, updateFormConfig, setWarningAddressSplitCondition],
  );

  const getOptionById = (optionId, options = [], propName = 'value') =>
    [...options]?.find((itm) => itm[propName] === optionId);

  const onSubmitAction = useCallback(
    (values) => () =>
      new Promise((resolve, reject) => {
        loadingBarEnable()(dispatch);
        const data = { ...values };
        const moderators = prev.contacts.filter(
          (contact) => contact.contact_type_id === 2 && contact,
        );

        if (type !== 'moderation-revision') {
          data.upload_ids = [
            ...new Set([
              ...selectedPictures.admin.map((itm) => itm.id),
              ...selectedPictures.owner.map((itm) => itm.id),
              ...selectedPictures.pilot.map((itm) => itm.id),
            ]),
          ];
        }

        data.contacts = [
          ...data.contacts,
          ...data.owners,
          ...moderators,
          prev.submitter,
        ];

        data['location#is_address_split'] = isWarningAddressSplitDisplayed;
        // data['location#name'] = values['location#name'];

        delete data.owners;
        delete data['location#dms'];
        delete data.upd_owner;
        delete data.upd_admin;
        delete data.upd_pilot;

        onSubmit({
          ...data,
        })
          .then(() => resolve())
          .catch((err) => {
            setApiErrorsToAntdForm(err);
            reject(err);
            Modal.destroyAll();
            notification.warning({
              message: 'Warning',
              description: getApiErrorMessages(err),
            });
          })
          .finally(() => loadingBarDisable()(dispatch));
      }),
    [
      dispatch,
      prev.contacts,
      prev.submitter,
      type,
      onSubmit,
      selectedPictures,
      setApiErrorsToAntdForm,
      isWarningAddressSplitDisplayed,
    ],
  );

  const onSubmitForm = useCallback(
    (values) =>
      Modal.confirm({
        title: 'Are you sure you want to save changes ?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Yes',
        cancelText: 'No',
        centered: true,
        onOk: onSubmitAction(values),
        zIndex: 1002,
        confirmLoading: true,
        destroyOnClose: true,
      }),
    [onSubmitAction],
  );

  const onFinishFailed = useCallback(
    ({ errorFields }) => {
      form.scrollToField(errorFields[0].name[0], {
        block: 'center',
        behavior: 'smooth',
        scrollMode: 'if-needed',
      });

      notification.error({
        message: 'Validation Error',
        description: errorFields.map((itm) => (
          <div key={`error-notif-${itm.name[0]}`}>
            {itm.name.map((itm2) => `'${itm2}'`).join(',')}:{' '}
            {itm.errors.join(',')}
          </div>
        )),
      });
    },
    [form],
  );

  const setTagInputValue = useCallback(
    (data) => data.map(({ name }) => name),
    [],
  );

  const removeContactHandler = useCallback((side, id) => {
    const oppositeSide = side === 'prev' ? 'next' : 'prev';

    setRevision((prevState) => ({
      ...prevState,
      [side]: {
        ...prevState[side],
        contacts: prevState[side].contacts.filter((c) => c.id !== id),
      },
      [oppositeSide]: {
        ...prevState[oppositeSide],
        contacts: prevState[oppositeSide].contacts.map((c) =>
          c.id === id ? { ...c, removed: true } : c,
        ),
      },
    }));
  }, []);

  const updateSelectedUploads = useCallback(
    (key) => (list) => {
      setSelectedPictures((prevState) => {
        // Get the existing list for this key.
        const existingList = prevState[key] || [];

        // Filter out any items from 'list' that are already in 'existingList'.
        const uniqueList = list.filter(
          (listItem) =>
            !existingList.some(
              (existingItem) => existingItem.id === listItem.id,
            ),
        );
        return {
          ...prevState,
          [key]: [...existingList, ...uniqueList],
        };
      });
    },
    [],
  );

  const toggleSelectUpload = useCallback(
    (key) => (side) => (event) => {
      const uploads = [...selectedPictures[key]];
      const isUploadSelected = uploads.findIndex(
        (u) => +u.id === +event.target.value,
      );

      if (isUploadSelected === -1) {
        const uploadFromList = revision[side].uploads[key].find(
          (u) => u.id === +event.target.value,
        );
        uploads.push(uploadFromList);
      } else uploads.splice(isUploadSelected, 1);

      setSelectedPictures((prevState) => ({
        ...prevState,
        [key]: uploads,
      }));
    },
    [revision, selectedPictures],
  );

  const acceptSurveyOptions = useMemo(() => {
    if (!prev?.survey_id && !next?.survey_id) return [];

    const prevOption = {
      value:
        prev?.survey_id === null && next?.survey_id ? 'reset' : prev?.survey_id,
      label:
        prev?.survey_id === null && next?.survey_id
          ? 'Reset'
          : `Survey progress: ${prev?.survey_progress_percentage}%`,
    };

    const nextOption = {
      value:
        next?.survey_id === null && prev?.survey_id ? 'reset' : next?.survey_id,
      label:
        next?.survey_id === null && prev?.survey_id
          ? 'Reset'
          : `Survey progress: ${next?.survey_progress_percentage}%`,
    };

    return [prevOption, nextOption];
  }, [prev, next]);

  if (!revision.isLoaded)
    return (
      <FullPageLoading
        style={{
          height: 'inherit',
        }}
        className="fixed-to-center"
      />
    );

  return (
    <div>
      <Form
        form={form}
        autoComplete="off"
        onFinish={onSubmitForm}
        onFinishFailed={onFinishFailed}
        onValuesChange={updateFormConfig}
        layout="vertical"
      >
        <DupeResolverTop
          prev={{
            id: prev.id,
            location: prev.location,
            name: prev.name,
            is_owned: prev.is_owned,
            deleted_at: prev.deleted_at,
            survey_id: prev.survey_id,
          }}
          prevTitle={prevTitle}
          finalTitle={finalTitle}
          nextTitle={nextTitle}
          setFields={form.setFields}
          next={{
            id: next.id,
            location: next.location,
            name: next.name,
            is_owned: next.is_owned,
            deleted_at: next.deleted_at,
            survey_id: next.survey_id,
          }}
          newLocation={location}
        />
        <DataRow
          label="Name"
          type="text"
          prev={prev.name}
          next={next.name}
          additionalChecks={{
            isOwnerPrev: prev.is_owned,
            isOwnerNext: next.is_owned,
            deletedAtPrev: prev.deleted_at,
            deletedAtNext: next.deleted_at,
          }}
          setValue={updateFormField('name')}
        >
          <DataRow.Input name="name" />
        </DataRow>
        <DataRow
          label="Latitude"
          type="text"
          prev={prev?.location?.latitude}
          next={next?.location?.latitude}
          additionalChecks={{
            isOwnerPrev: prev.is_owned,
            isOwnerNext: next.is_owned,
            deletedAtPrev: prev.deleted_at,
            deletedAtNext: next.deleted_at,
          }}
          setValue={updateFormField('location#latitude')}
        >
          <DataRow.Input rules={latValidationRules} name="location#latitude" />
        </DataRow>
        <DataRow
          label="Longitude"
          type="text"
          prev={prev?.location?.longitude}
          next={next?.location?.longitude}
          additionalChecks={{
            isOwnerPrev: prev.is_owned,
            isOwnerNext: next.is_owned,
            deletedAtPrev: prev.deleted_at,
            deletedAtNext: next.deleted_at,
          }}
          setValue={updateFormField('location#longitude')}
        >
          <DataRow.Input rules={lngValidationRules} name="location#longitude" />
        </DataRow>
        <DataRow
          label="DMS Format"
          prev={convertDMS(prev?.location?.latitude, prev?.location?.longitude)}
          next={convertDMS(next?.location?.latitude, next?.location?.longitude)}
          additionalChecks={{
            isOwnerPrev: prev.is_owned,
            isOwnerNext: next.is_owned,
            deletedAtPrev: prev.deleted_at,
            deletedAtNext: next.deleted_at,
          }}
          setValue={updateFormField('location#dms')}
          readOnly
        >
          <DataRow.Input placeholder="DMS Format" name="location#dms" />
        </DataRow>
        <DataRow
          label="Landing not Possible"
          type="bool"
          prev={prev.is_landing_permitted}
          next={next.is_landing_permitted}
          additionalChecks={{
            isOwnerPrev: prev.is_owned,
            isOwnerNext: next.is_owned,
            deletedAtPrev: prev.deleted_at,
            deletedAtNext: next.deleted_at,
          }}
          setValue={updateFormField('is_landing_permitted')}
        >
          <DataRow.Select
            name="is_landing_permitted"
            placeholder="Choose"
            options={[
              { value: true, label: 'False' },
              { value: false, label: 'True' },
            ]}
          />
        </DataRow>
        <DataRow
          label="Address"
          type="text"
          prev={prev?.location?.name}
          next={next?.location?.name}
          additionalChecks={{
            isOwnerPrev: prev.is_owned,
            isOwnerNext: next.is_owned,
            deletedAtPrev: prev.deleted_at,
            deletedAtNext: next.deleted_at,
          }}
          setValue={updateFormField('location#name')}
        >
          <DataRow.Input name="location#name" />
        </DataRow>
        <DataRow
          label="Region"
          type="select"
          prev={getOptionById(prev?.location?.region, regions, 'label')}
          next={getOptionById(next?.location?.region, regions, 'label')}
          additionalChecks={{
            isOwnerPrev: prev.is_owned,
            isOwnerNext: next.is_owned,
            deletedAtPrev: prev.deleted_at,
            deletedAtNext: next.deleted_at,
          }}
          setValue={updateFormField('location#region')}
          setValueHandler={(opt) => opt?.value}
        >
          <DataRow.Select
            name="location#region"
            placeholder="Choose"
            options={regions}
          />
        </DataRow>
        <DataRow
          label="Category"
          type="select"
          prev={getOptionById(prev.category_id, categories)}
          next={getOptionById(next.category_id, categories)}
          setValue={updateFormField('category_id')}
          additionalChecks={{
            isOwnerPrev: prev.is_owned,
            isOwnerNext: next.is_owned,
            deletedAtPrev: prev.deleted_at,
            deletedAtNext: next.deleted_at,
          }}
          setValueHandler={(opt) => opt?.value}
        >
          <DataRow.Select
            name="category_id"
            placeholder="Choose"
            options={categories}
          />
        </DataRow>
        <DataRow
          label="Accept Survey"
          type="survey"
          prev={!prev.survey_id && next.survey_id ? 'reset' : prev.survey_id}
          next={!next.survey_id && prev.survey_id ? 'reset' : next.survey_id}
          additionalChecks={{
            isOwnerPrev: prev.is_owned,
            isOwnerNext: next.is_owned,
            deletedAtPrev: prev.deleted_at,
            deletedAtNext: next.deleted_at,
            surveyProgressNext: next.survey_progress_percentage,
            surveyProgressPrev: prev.survey_progress_percentage,
          }}
          setValue={updateFormField('accept_survey')}
        >
          <DataRow.Select
            name="accept_survey"
            placeholder="Choose"
            options={acceptSurveyOptions}
          />
        </DataRow>
        {!formConfig.isApi && (
          <>
            {formConfig.isAerodrome ? (
              <DataRow
                label="Facilities"
                type="tags"
                options={facilities}
                prev={prev.facilities?.map((itm) => itm.id) || []}
                next={next.facilities?.map((itm) => itm.id) || []}
                additionalChecks={{
                  isOwnerPrev: prev.is_owned,
                  isOwnerNext: next.is_owned,
                  deletedAtPrev: prev.deleted_at,
                  deletedAtNext: next.deleted_at,
                }}
                prevExtra="*Facilities available only for aerodromes"
                setValue={updateFormField('facilities')}
              >
                <DataRow.Tags name="facilities" />
              </DataRow>
            ) : (
              <DataRow
                label="Warnings"
                type="tags"
                options={warnings}
                prev={prev.warnings?.map((itm) => itm.id) || []}
                next={next.warnings?.map((itm) => itm.id) || []}
                additionalChecks={{
                  isOwnerPrev: prev.is_owned,
                  isOwnerNext: next.is_owned,
                  deletedAtPrev: prev.deleted_at,
                  deletedAtNext: next.deleted_at,
                }}
                setValue={updateFormField('warnings')}
              >
                <DataRow.Tags name="warnings" />
              </DataRow>
            )}
          </>
        )}
        {!formConfig.isApi && (
          <DataRow
            label="Landing fees"
            type="select"
            prev={getOptionById(prev.landing_fees.toString(), landingFees)}
            next={getOptionById(next.landing_fees.toString(), landingFees)}
            setValue={updateFormField('landing_fees')}
            setValueHandler={(opt) => opt?.value}
            additionalChecks={{
              isOwnerPrev: prev.is_owned,
              isOwnerNext: next.is_owned,
              deletedAtPrev: prev.deleted_at,
              deletedAtNext: next.deleted_at,
            }}
          >
            <DataRow.Select
              name="landing_fees"
              placeholder="Choose"
              options={landingFees}
            />
          </DataRow>
        )}
        {formConfig.isAerodrome && (
          <DataRow
            label="ICAO"
            type="text"
            prev={prev.icao}
            next={next.icao}
            additionalChecks={{
              isOwnerPrev: prev.is_owned,
              isOwnerNext: next.is_owned,
              deletedAtPrev: prev.deleted_at,
              deletedAtNext: next.deleted_at,
            }}
            setValue={updateFormField('icao')}
          >
            <DataRow.Input inputType="icao" name="icao" />
          </DataRow>
        )}

        <DataRow
          label={
            <Tooltip title="Helipaddy International Site Code">
              <span>HISC</span>
              <ExclamationCircleOutlined size="small" className="ml-1" />
            </Tooltip>
          }
          type="text"
          prev={prev.hisc}
          next={next.hisc}
          additionalChecks={{
            isOwnerPrev: prev.is_owned,
            isOwnerNext: next.is_owned,
            deletedAtPrev: prev.deleted_at,
            deletedAtNext: next.deleted_at,
          }}
          setValue={updateFormField('hisc')}
        >
          <DataRow.Input
            inputType="hisc"
            name="hisc"
            disabled
            rules={[
              () => ({
                validator(_, value) {
                  if (!value || /^[A-Z]{6}[0-9a-zA-Z]{2}$/.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('ex: RCNNNNnn'));
                },
              }),
            ]}
          />
        </DataRow>

        <DataRow
          label="Site Description"
          type="textarea"
          prev={prev.site_information}
          next={next.site_information}
          setValue={updateFormField('site_information')}
          additionalChecks={{
            isOwnerPrev: prev.is_owned,
            isOwnerNext: next.is_owned,
            deletedAtPrev: prev.deleted_at,
            deletedAtNext: next.deleted_at,
          }}
        >
          <DataRow.Input inputType="textarea" name="site_information" />
        </DataRow>
        <DataRow
          label="Landing advice"
          type="text"
          prev={prev.landing_advice}
          next={next.landing_advice}
          setValue={updateFormField('landing_advice')}
          additionalChecks={{
            isOwnerPrev: prev.is_owned,
            isOwnerNext: next.is_owned,
            deletedAtPrev: prev.deleted_at,
            deletedAtNext: next.deleted_at,
          }}
        >
          <DataRow.Input inputType="textarea" name="landing_advice" />
        </DataRow>
        <DataRow
          label="Tags"
          type="tags_input"
          prev={prev.tags}
          next={next.tags}
          setValue={updateFormField('tags')}
          setValueHandler={setTagInputValue}
          additionalChecks={{
            isOwnerPrev: prev.is_owned,
            isOwnerNext: next.is_owned,
            deletedAtPrev: prev.deleted_at,
            deletedAtNext: next.deleted_at,
          }}
        >
          <DataRow.Select
            mode="tags"
            name="tags"
            placeholder="Select existing or create"
            options={tags}
          />
        </DataRow>
        <DataRow
          label="Website"
          type="url"
          prev={prev.pad_social?.website}
          next={next.pad_social?.website}
          setValue={updateFormField('pad_social#website')}
          additionalChecks={{
            isOwnerPrev: prev.is_owned,
            isOwnerNext: next.is_owned,
            deletedAtPrev: prev.deleted_at,
            deletedAtNext: next.deleted_at,
          }}
        >
          <DataRow.Input name="pad_social#website" />
        </DataRow>
        <DataRow
          label="Twitter"
          type="text"
          prev={prev.pad_social?.twitter_handle?.replace('@', '')}
          next={next.pad_social?.twitter_handle?.replace('@', '')}
          setValue={updateFormField('pad_social#twitter_handle')}
          additionalChecks={{
            isOwnerPrev: prev.is_owned,
            isOwnerNext: next.is_owned,
            deletedAtPrev: prev.deleted_at,
            deletedAtNext: next.deleted_at,
          }}
        >
          <DataRow.Input
            name="pad_social#twitter_handle"
            prefix="@"
            rules={twitterUsernameHandle}
          />
        </DataRow>
        <DataRow
          label="Contacts"
          type="contacts"
          prev={prev.contacts.filter(
            (contact) => contact.contact_type_id === 1 && contact,
          )}
          next={next.contacts.filter(
            (contact) => contact.contact_type_id === 1 && contact,
          )}
          additionalChecks={{
            isOwnerPrev: prev.is_owned,
            isOwnerNext: next.is_owned,
            deletedAtPrev: prev.deleted_at,
            deletedAtNext: next.deleted_at,
          }}
          setValue={updateFormField('contacts')}
        >
          <DataRow.Contacts
            padId={{ prev: prev.id, next: next.id }}
            onContactRemove={removeContactHandler}
            name="contacts"
            contacts={{ prev: prev.contacts, next: next.contacts }}
          />
        </DataRow>
        <DataRow
          label="Owners"
          type="contacts"
          prev={prev.contacts.filter(
            (contact) => contact.contact_type_id === 4 && contact,
          )}
          next={next.contacts.filter(
            (contact) => contact.contact_type_id === 4 && contact,
          )}
          setValue={updateFormField('owners')}
          additionalChecks={{
            isOwnerPrev: prev.is_owned,
            isOwnerNext: next.is_owned,
            deletedAtPrev: prev.deleted_at,
            deletedAtNext: next.deleted_at,
          }}
        >
          <DataRow.Contacts
            padId={{ prev: prev.id, next: next.id }}
            onContactRemove={removeContactHandler}
            name="owners"
            contacts={{ prev: prev.contacts, next: next.contacts }}
          />
        </DataRow>
        <DataRow
          label="Submitter"
          type="contacts"
          prev={[prev.submitter]}
          next={[next.submitter]}
          additionalChecks={{
            isOwnerPrev: prev.is_owned,
            isOwnerNext: next.is_owned,
            deletedAtPrev: prev.deleted_at,
            deletedAtNext: next.deleted_at,
          }}
          readOnly
          automatic
        >
          <Form.Item label="Submitter">
            <Input value="Automatic merge" readOnly />
          </Form.Item>
        </DataRow>
        {type !== 'moderation-revision' && (
          <>
            <DataRow
              label="Admin's photos"
              type="photos"
              prev={prev.uploads.admin}
              next={next.uploads.admin}
              setValue={updateSelectedUploads('admin')}
              onUploadSelect={toggleSelectUpload('admin')}
              selectedUploads={selectedPictures.admin}
              additionalChecks={{
                isOwnerPrev: prev.is_owned,
                isOwnerNext: next.is_owned,
                deletedAtPrev: prev.deleted_at,
                deletedAtNext: next.deleted_at,
              }}
            >
              <DataRow.Photos
                name="upd_admin"
                fileList={selectedPictures.admin}
              />
            </DataRow>
            <DataRow
              label="Owner's photos"
              type="photos"
              prev={prev.uploads.owner}
              next={next.uploads.owner}
              additionalChecks={{
                isOwnerPrev: prev.is_owned,
                isOwnerNext: next.is_owned,
                deletedAtPrev: prev.deleted_at,
                deletedAtNext: next.deleted_at,
              }}
              setValue={updateSelectedUploads('owner')}
              onUploadSelect={toggleSelectUpload('owner')}
              selectedUploads={selectedPictures.owner}
            >
              <DataRow.Photos
                name="upd_owner"
                fileList={selectedPictures.owner}
              />
            </DataRow>
            <DataRow
              label="Pilot's photos"
              type="photos"
              prev={prev.uploads.pilot}
              next={next.uploads.pilot}
              setValue={updateSelectedUploads('pilot')}
              onUploadSelect={toggleSelectUpload('pilot')}
              selectedUploads={selectedPictures.pilot}
              additionalChecks={{
                isOwnerPrev: prev.is_owned,
                isOwnerNext: next.is_owned,
                deletedAtPrev: prev.deleted_at,
                deletedAtNext: next.deleted_at,
              }}
            >
              <DataRow.Photos
                name="upd_pilot"
                fileList={selectedPictures.pilot}
              />
            </DataRow>
          </>
        )}
        <Affix
          offsetBottom={15}
          className={condStrings('text-center', 'dupe-resolver-footer')}
        >
          <Space size={15}>
            {extraLeft(revision)}
            {type === 'moderation-revision' && (
              <Button htmlType="button" size="large" onClick={onReject} danger>
                Reject revision
              </Button>
            )}
            {type !== 'moderation-revision' && (
              <Form.Item className="mb-0">
                <Button
                  htmlType="submit"
                  type={type !== 'moderation-revision' ? 'primary' : 'default'}
                  size="large"
                >
                  Save changes
                </Button>
              </Form.Item>
            )}
            {type === 'moderation-revision' && (
              <Button htmlType="submit" size="large" type="primary">
                Accept & Moderate
              </Button>
            )}
            {extraRight(revision)}
          </Space>
        </Affix>
      </Form>
    </div>
  );
}

DuplicatesResolver.propTypes = {
  getData: PropTypes.func,
  onSubmit: PropTypes.func,
  onReject: PropTypes.func,
  prevTitle: PropTypes.string,
  finalTitle: PropTypes.string,
  nextTitle: PropTypes.string,
  extraLeft: PropTypes.func,
  extraRight: PropTypes.func,
  type: PropTypes.oneOf(['moderation-revision', 'report-duplicates'])
    .isRequired,
};

DuplicatesResolver.defaultProps = {
  getData: () => Promise.reject(),
  onSubmit: () => Promise.resolve(),
  onReject: () => Promise.resolve(),
  prevTitle: 'Current',
  finalTitle: 'Merged',
  nextTitle: 'Suggested',
  extraLeft: () => null,
  extraRight: () => null,
};
