import { notification } from 'antd';
import { Link } from 'react-router-dom';
import fieldsErrors from '../../../../utils/fieldsErrors';

export const generatePadSubmitters = (pad) => {
  if (pad?.submitters?.[0]) {
    return (
      <>
        &nbsp;by&nbsp;
        {pad.submitters
          .filter((submitter) => Boolean(submitter.user_id && submitter.email))
          .map((submitter) => (
            <Link
              key={submitter?.id}
              to={`/dashboard/user-view/${submitter.user_id}`}
              target="_blank"
              className="d-flex align-items-center submitter-link"
            >
              <span className="text">{`${submitter.email} `}</span>
            </Link>
          ))}
      </>
    );
  }

  return null;
};

export const latValidationRules = [
  {
    validator: (itm, value) =>
      (!isNaN(value) && parseFloat(value) < 91) || isNaN(value) || !value
        ? Promise.resolve()
        : Promise.reject(new Error(fieldsErrors.max(90))),
  },
  {
    validator: (itm, value) =>
      (!isNaN(value) && parseFloat(value) > -91) || isNaN(value) || !value
        ? Promise.resolve()
        : Promise.reject(new Error(fieldsErrors.min(-90))),
  },
];

export const lngValidationRules = [
  {
    validator: (itm, value) =>
      (!isNaN(value) && parseFloat(value) < 181) || isNaN(value) || !value
        ? Promise.resolve()
        : Promise.reject(new Error(fieldsErrors.max(180))),
  },
  {
    validator: (itm, value) =>
      (!isNaN(value) && parseFloat(value) > -181) || isNaN(value) || !value
        ? Promise.resolve()
        : Promise.reject(new Error(fieldsErrors.min(-180))),
  },
];

export const alertOnSave = (updatedPad = null) => {
  let description = 'Update Saved!';
  let type = 'success';

  if (updatedPad?.data) {
    const { has_uncontactable_revision, is_contactable } = updatedPad.data;

    if (has_uncontactable_revision && is_contactable) {
      description = 'Update saved - pad now visible to Pilots!';
    }

    if (has_uncontactable_revision && !is_contactable) {
      type = 'warning';
      description =
        "Update saved - pad is missing contact data so will not be shown to Pilots! Update it from 'Uncontactable'";
    }
  }

  notification[type]({
    message: 'Success',
    description,
  });
};
