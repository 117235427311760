import PropTypes from 'prop-types';
import React from 'react';
import { Descriptions, Tag, Form, Checkbox, Input } from 'antd';
import { surveyOptions } from '../utils';
import { usePadSurveyContext } from '../PadSurveyContext';

export default function SurveyHazardsStep(props) {
  const { isEditing, toggleStepVisibility } = usePadSurveyContext();
  const { step } = props;
  const [content, setContent] = React.useState([]);

  React.useEffect(() => {
    const values = [];

    if (step?.hazards_categories && step.hazards_categories.length) {
      const label = 'Relevant categories within 100m';
      const value = step.hazards_categories.map((cat) => (
        <Tag key={cat}>{surveyOptions[cat]}</Tag>
      ));
      values.push([label, value]);
    }

    if (step?.hazards_details) {
      const label = 'Details';
      const value = step.hazards_details;
      values.push([label, value]);
    }

    if (values.length) toggleStepVisibility('hazards', true);
    else toggleStepVisibility('hazards', false);

    setContent(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  if (isEditing) {
    return (
      <Descriptions
        size="small"
        bordered
        column={1}
        labelStyle={{ width: 280 }}
      >
        <Descriptions.Item
          className="py-2 px-2"
          label="Relevant categories within 100m"
        >
          <Form.Item name={['hazards', 'hazards_categories']} className="mb-0">
            <Checkbox.Group className="survey-checkbox-list w-100">
              <div className="d-flex">
                <div className="d-flex flex-column">
                  <Checkbox value="animals" className="mb-2 ml-0">
                    Animals
                  </Checkbox>
                  <Checkbox value="power_lines" className="mb-2 ml-0">
                    Power lines
                  </Checkbox>
                  <Checkbox value="high_trees" className="mb-2 ml-0">
                    High trees
                  </Checkbox>
                  <Checkbox value="loose_debris" className="mb-2 ml-0">
                    Loose debris
                  </Checkbox>
                  <Checkbox value="tall_building" className="ml-0">
                    Tall building
                  </Checkbox>
                </div>
                <div className="d-flex flex-column ml-5">
                  <Checkbox value="public_right_way" className="mb-2 ml-0">
                    Public right of way across landing area
                  </Checkbox>
                  <Checkbox value="unsecured_items" className="mb-2 ml-0">
                    Unsecured items (downwash issue)
                  </Checkbox>
                  <Checkbox value="changeable_surface" className="mb-2 ml-0">
                    Changeable surface (after heavy rain/snow)
                  </Checkbox>
                  <Checkbox value="other" className="ml-0">
                    Other
                  </Checkbox>
                </div>
              </div>
            </Checkbox.Group>
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item className="py-2 px-2" label="Hazard details">
          <Form.Item
            name={['hazards', 'hazards_details']}
            className="w-100 mb-0"
          >
            <Input.TextArea
              autoSize
              placeholder="Please elaborate on each hazard, giving as specific detail as possible including bearing and distance from Landing Area"
            />
          </Form.Item>
        </Descriptions.Item>
      </Descriptions>
    );
  }

  return (
    <Descriptions
      size="small"
      bordered
      column={1}
      labelStyle={{ width: '240px' }}
      contentStyle={{ whiteSpace: 'pre-line' }}
    >
      {content.map(([label, value]) => (
        <Descriptions.Item
          key={label.toLowerCase().replace(' ', '_')}
          className="py-2 px-2"
          label={label}
        >
          {value}
        </Descriptions.Item>
      ))}
    </Descriptions>
  );
}

SurveyHazardsStep.propTypes = {
  step: PropTypes.object,
};

SurveyHazardsStep.defaultProps = {
  step: {},
};
