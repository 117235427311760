import {
  GET_USER_FILTERS,
  UPDATE_FILTER,
  REMOVE_FILTER,
  ADD_NEW_FILTER,
} from '../actionTypes';
import api from '../../utils/appApi';

export const getUserFilters = (config) => (dispatch) =>
  api.filters
    .get(config)
    .then((res) => {
      dispatch({ type: GET_USER_FILTERS, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const updateUserFilter = (config, data) => (dispatch) =>
  api.filters
    .update(config, data)
    .then((res) => {
      dispatch({ type: UPDATE_FILTER, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const removeUserFilter = (config) => (dispatch) =>
  api.filters
    .remove(config)
    .then((res) => {
      dispatch({ type: REMOVE_FILTER });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const storeNewFilter = (config) => (dispatch) =>
  api.filters
    .store(config)
    .then((res) => {
      dispatch({ type: ADD_NEW_FILTER, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));
