import {
  MODERATION_LIST_GET,
  MODERATION_LIST_CLEAN,
  CLEAN_ON_UNMOUNT_TRUE,
  CLEAN_ON_UNMOUNT_FALSE,
} from '../actionTypes';

const initialState = {
  cleanOnUnmount: true,
  payload: {},
};

const moderationList = (state = initialState, action = {}) => {
  switch (action.type) {
    case MODERATION_LIST_GET:
      return {
        ...state,
        payload: action.payload,
      };
    case MODERATION_LIST_CLEAN:
      return initialState;
    case CLEAN_ON_UNMOUNT_TRUE:
      return {
        ...state,
        cleanOnUnmount: true,
      };
    case CLEAN_ON_UNMOUNT_FALSE:
      return {
        ...state,
        cleanOnUnmount: false,
      };

    default:
      return state;
  }
};

export default moderationList;
