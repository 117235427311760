import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Space,
} from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import PhoneInput from '../../../../../../components/phoneInput';
import { selectContactTypeOptions } from '../../../../../../store/selectors/bootstrapSelector';
import api from '../../../../../../utils/appApi';
import getApiErrorMessages from '../../../../../../utils/getApiErrorMessages';
import PadViewContext from '../../../../PadView.context';

export function ContactsListEdit() {
  const { contactTypes } = useSelector((store) => ({
    contactTypes: selectContactTypeOptions(store, (c) => c.identifier !== 2),
  }));
  const { pad, updatePad } = React.useContext(PadViewContext);

  const removeOwnerHandler = React.useCallback(
    (index, cb) => async () => {
      const existingContact = pad.editable_contacts[index];

      if (existingContact) {
        try {
          const response = await api.pad.deleteOwner(
            pad.id,
            existingContact.id,
            existingContact.contact_type_id,
          );

          const updatedContactsList = response.data.data.contacts.filter((c) =>
            [1, 4].includes(c?.contact_type_id),
          );

          updatePad({
            editable_contacts: updatedContactsList,
          });

          if (cb) cb(index);

          return Promise.resolve();
        } catch (error) {
          notification.error({
            message: 'Error',
            description: getApiErrorMessages(error),
          });
          return Promise.reject();
        }
      }

      if (cb) cb(index);

      return Promise.resolve();
    },
    [pad.editable_contacts, pad.id, updatePad],
  );

  return (
    <Form.List name="editable_contacts">
      {(fields, { add, remove }) => (
        <Row gutter={[0, 24]} style={{ margin: 0 }}>
          {fields.map((field, index, arr) => (
            <Col span={8} key={field.key}>
              <Space align="baseline" className="position-relative">
                <Space direction="vertical" size={0}>
                  <Form.Item
                    label="Type"
                    name={[index, 'contact_type_id']}
                    wrapperCol={{ span: 24 }}
                    className="mb-0"
                    initialValue={1} // access only Pad Contact
                  >
                    <Select
                      disabled
                      size="small"
                      placeholder="Type"
                      options={contactTypes}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Name"
                    name={[index, 'name']}
                    wrapperCol={{ span: 24 }}
                    className="mb-0"
                  >
                    <Input size="small" placeholder="Name" />
                  </Form.Item>
                  <Form.Item
                    label="Phone"
                    name={[index, 'phone']}
                    wrapperCol={{ span: 24 }}
                    className="mb-0"
                  >
                    <PhoneInput
                      size="small"
                      placeholder="Phone number"
                      dropdownStyle={{ bottom: '100%' }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    name={[index, 'email']}
                    wrapperCol={{ span: 24 }}
                    className="mb-0"
                  >
                    <Input size="small" type="email" placeholder="Email" />
                  </Form.Item>
                </Space>
                <MinusCircleOutlined
                  className={arr.length === 1 && 'invisible'}
                  onClick={removeOwnerHandler(index, remove)}
                />
              </Space>
            </Col>
          ))}
          {pad?.category_id !== 1 && (
            <Col span={8}>
              <Form.Item>
                <Button
                  size="small"
                  type="dashed"
                  onClick={() => add({})}
                  icon={<PlusOutlined />}
                >
                  Add contact
                </Button>
              </Form.Item>
            </Col>
          )}
        </Row>
      )}
    </Form.List>
  );
}
