import PropTypes from 'prop-types';
import React from 'react';
import { notification } from 'antd';
import { getPadSurveyUploads } from '../../../../../services/api';
import { PadSurveyUploads } from '../../../../../modules';
import getApiErrorMessages from '../../../../../utils/getApiErrorMessages';

export default function SurveyUploads(props) {
  const { padId, isArchived } = props;
  const [images, setImages] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const fetchSurveyUploads = async () => {
      try {
        setLoading(true);

        const uploads = await getPadSurveyUploads(padId);

        setImages(
          uploads.map((img) => ({
            id: img.id,
            url: img.file_url,
            dirLabel:
              img.direction.charAt(0).toUpperCase() + img.direction.slice(1),
            dir: img.direction,
          })),
        );
      } catch (error) {
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(error),
        });
      } finally {
        setLoading(false);
      }
    };

    if (padId && !isArchived) {
      fetchSurveyUploads();
    }
  }, [padId, isArchived]);

  if (!images.length) return null;

  return <PadSurveyUploads images={images} loading={loading} hideDescription />;
}

SurveyUploads.propTypes = {
  padId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isArchived: PropTypes.bool,
};

SurveyUploads.defaultProps = {
  padId: null,
  isArchived: false,
};
