import PropTypes from 'prop-types';
import React from 'react';
import { Descriptions, Tag, Form, Input, Checkbox, Select } from 'antd';
import { surveyOptions } from '../utils';
import { usePadSurveyContext } from '../PadSurveyContext';

export default function SurveyFacilitiesStep(props) {
  const { isEditing, toggleStepVisibility } = usePadSurveyContext();
  const { step } = props;
  const [content, setContent] = React.useState([]);

  React.useEffect(() => {
    const values = [];

    if (step?.categories && step.categories.length) {
      const label = 'Relevant categories within 100m';
      const value = step.categories.map((cat) => (
        <Tag key={cat}>{surveyOptions[cat]}</Tag>
      ));
      values.push([label, value]);
    }

    if (step?.categories_details) {
      const label = 'Categories details';
      const value = step.categories_details;
      values.push([label, value]);
    }

    if (step?.is_charge_landing_fee) {
      const label = 'Charge landing fee';
      const value = surveyOptions[step.is_charge_landing_fee];
      values.push([label, value]);
    }

    if (step?.range_fee) {
      const label = 'Range fee';
      const value = step.range_fee;
      values.push([label, value]);
    }

    if (step?.range_fee_details) {
      const label = 'Range fee details';
      const value = step.range_fee_details;
      values.push([label, value]);
    }

    if (values.length) toggleStepVisibility('facilities', true);
    else toggleStepVisibility('facilities', false);

    setContent(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  if (isEditing) {
    return (
      <Descriptions
        size="small"
        bordered
        column={1}
        labelStyle={{ width: 280 }}
      >
        <Descriptions.Item className="py-2 px-2" label="Relevant categories">
          <Form.Item name={['facilities', 'categories']} className="mb-0">
            <Checkbox.Group className="survey-checkbox-list w-100">
              <div className="d-flex">
                <div className="d-flex flex-column">
                  <Checkbox value="fuel" className="mb-2 ml-0">
                    Fuel
                  </Checkbox>
                  <Checkbox value="marked_h" className="mb-2 ml-0">
                    Marked H
                  </Checkbox>
                  <Checkbox value="wind_indicator" className="mb-2 ml-0">
                    Wind Indicator
                  </Checkbox>
                  <Checkbox value="food_available" className="mb-2 ml-0">
                    Food Available
                  </Checkbox>
                  <Checkbox value="lodging" className="mb-0 ml-0">
                    Lodging
                  </Checkbox>
                </div>
                <div className="d-flex flex-column ml-5">
                  <Checkbox value="sport_facilites" className="mb-2 ml-0">
                    Sport Facilities
                  </Checkbox>
                  <Checkbox value="more_facilities" className="mb-2 ml-0">
                    More Facilities
                  </Checkbox>
                  <Checkbox value="specific_lighting" className="mb-2 ml-0">
                    Specific Lighting
                  </Checkbox>
                  <Checkbox value="others" className="ml-0">
                    Other
                  </Checkbox>
                </div>
              </div>
            </Checkbox.Group>
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item className="py-2 px-2" label="Categories details">
          <Form.Item
            name={['facilities', 'categories_details']}
            className="w-100 mb-0"
          >
            <Input.TextArea
              autoSize
              placeholder="Please provide any further details here"
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item
          className="py-2 px-2"
          label={
            <Form.Item
              className="mb-0"
              label="Charge a landing fee?"
              name={['facilities', 'is_charge_landing_fee']}
            >
              <Select
                placeholder="Choose"
                options={[
                  { value: 'yes', label: 'Yes' },
                  { value: 'no', label: 'No' },
                ]}
              />
            </Form.Item>
          }
        >
          <Form.Item
            noStyle
            shouldUpdate={(prev, curr) =>
              prev.facilities?.is_charge_landing_fee !==
              curr.facilities?.is_charge_landing_fee
            }
          >
            {({ getFieldValue }) => {
              if (
                getFieldValue(['facilities', 'is_charge_landing_fee']) === 'yes'
              ) {
                return (
                  <div className="d-flex align-items-end">
                    <Form.Item
                      label="Range of fee"
                      className="mb-0 mr-2"
                      name={['facilities', 'range_fee']}
                    >
                      <Input placeholder="e.g. 40-55" />
                    </Form.Item>
                    <Form.Item
                      name={['facilities', 'range_fee_details']}
                      className="w-100 mb-0"
                    >
                      <Input.TextArea
                        autoSize
                        placeholder="Please provide details"
                      />
                    </Form.Item>
                  </div>
                );
              }

              return null;
            }}
          </Form.Item>
        </Descriptions.Item>
      </Descriptions>
    );
  }

  return (
    <Descriptions
      size="small"
      bordered
      column={1}
      labelStyle={{ width: '240px' }}
      contentStyle={{ whiteSpace: 'pre-line' }}
    >
      {content.map(([label, value]) => (
        <Descriptions.Item
          key={label.toLowerCase().replace(' ', '_')}
          className="py-2 px-2"
          label={label}
        >
          {value}
        </Descriptions.Item>
      ))}
    </Descriptions>
  );
}

SurveyFacilitiesStep.propTypes = {
  step: PropTypes.object,
};

SurveyFacilitiesStep.defaultProps = {
  step: {},
};
