import PropTypes from 'prop-types';
import React from 'react';
import { Button, notification, Popconfirm } from 'antd';
import api from '../../../../../../utils/appApi';
import PadViewContext from '../../../../PadView.context';
import getApiErrorMessages from '../../../../../../utils/getApiErrorMessages';

export function ContactPending({ contact, onClaimAccept, onClaimReject }) {
  const { pad, updatePad } = React.useContext(PadViewContext);

  const acceptHandler = React.useCallback(
    (userId, id) => () =>
      api.pad
        .acceptOwner({ pad_id: pad?.id, user_id: userId, contact_id: id })
        .then(() => {
          onClaimAccept();
          updatePad({
            editable_contacts: pad.editable_contacts.map((c) => {
              if (c.id === id) {
                return { ...c, is_claimed: true };
              }

              return c;
            }),
          });
        })
        .catch((err) => {
          notification.error({
            message: 'Error',
            description: getApiErrorMessages(err),
          });
        }),
    [pad, updatePad, onClaimAccept],
  );

  const removeHandler = React.useCallback(
    (id, typeId) => () => {
      if (typeId) {
        return api.pad
          .deleteOwner(pad?.id, id, typeId)
          .then(() => {
            onClaimReject();
            updatePad({
              editable_contacts: pad.editable_contacts.filter(
                (c) => c.id !== id,
              ),
            });
          })
          .catch((err) => {
            notification.error({
              message: 'Error',
              description: getApiErrorMessages(err),
            });
          });
      }

      return Promise.reject();
    },
    [pad, updatePad, onClaimReject],
  );

  return (
    <Popconfirm
      key="accept-owner-btn"
      placement="topRight"
      title="Are you sure you want to confirm this owner?"
      onConfirm={acceptHandler(contact.user_id, contact.id)}
      onCancel={removeHandler(contact.id, contact.contact_type_id)}
      okText="Confirm"
      cancelText="Reject"
    >
      <Button
        htmlType="button"
        type="secondary"
        className="pt-0 pb-0 ml-2"
        style={{ height: 20 }}
      >
        In pending
      </Button>
    </Popconfirm>
  );
}

ContactPending.propTypes = {
  contact: PropTypes.object,
  onClaimAccept: PropTypes.func,
  onClaimReject: PropTypes.func,
};

ContactPending.defaultProps = {
  contact: {},
};
