import { useEffect, useState, useCallback } from 'react';
import { useMount, useSessionStorage, useUnmount } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import moment from 'moment';
import { Switch } from 'antd';
import PageHeader from '../../uiKitComponents/pageHeader';
import Table from '../../uiKitComponents/table';
import Form from '../../uiKitComponents/form';
import DatePicker from '../../uiKitComponents/datePicker';
import Alert from '../../uiKitComponents/alert';
import Button from '../../uiKitComponents/button';
import * as reports from '../../store/actions/reportPagesAction';

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';

const initialState = {
  page: 1,
  sort_column: 'id',
  sort_direction: 'descend',
  dates: { from: { string: '', locale: '' }, to: { string: '', locale: '' } },
  is_not_included: true,
  filtersTrigger: false,
};

const ModerationEngagement = () => {
  const [state, setState] = useSessionStorage('moderation-engagement', {
    ...initialState,
  });
  const { page, filtersTrigger, dates, is_not_included } = state;
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { cleanOnUnmount, moderationENgagement } = useSelector((store) => ({
    cleanOnUnmount: store.reportPages.cleanOnUnmount,
    moderationENgagement: store.reportPages.payload.moderationENgagement,
  }));
  const dispatch = useDispatch();

  useMount(() => {
    reports.setCleanOnUnmountTrue()(dispatch);
  });

  const fetchUndefinedUsers = () => {
    setLoading(true);

    reports
      .getModerationEngagement({
        from: dates.from.string,
        to: dates.to.string,
        is_not_included: is_not_included ? 1 : 0,
      })(dispatch)
      .catch((err) => {
        if (err.response.status === 500) {
          setError({
            status: err.response.status,
            message: err.response.data.message,
          });
          sessionStorage.removeItem('moderation-engagement');
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchUndefinedUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersTrigger, dispatch, dates, is_not_included]);

  useUnmount(() => {
    if (cleanOnUnmount) {
      sessionStorage.removeItem('moderation-engagement');
      reports.cleanReports()(dispatch);
    }
  });

  const onTableChange = useCallback(
    (pagination, tableFilters, sorter, extra) => {
      const newState = {};
      if (extra.action === 'paginate') newState.page = pagination.current;
      else if (extra.action === 'sort') {
        newState.sort_column = sorter.field;
        newState.sort_direction = sorter.order;
      }
      setState((prevState) => ({ ...prevState, ...newState }));
    },
    [setState],
  );

  const onSelectedDatesHandler = (values) => {
    const { range } = values;

    if (range && range.length) {
      const date_from = dayjs(range[0].$d).format('DD-MM-YYYY');
      const date_to = dayjs(range[1].$d).format('DD-MM-YYYY');

      setState((prevState) => ({
        ...prevState,
        dates: {
          from: { string: date_from, locale: range[0] },
          to: { string: date_to, locale: range[1] },
        },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        dates: {
          from: { string: '', locale: '' },
          to: { string: '', locale: '' },
        },
      }));
    }
  };

  const getMomentDate = (date) => {
    if (date) return moment(date, dateFormat);

    return null;
  };

  const columns = [
    {
      dataIndex: 'type',
      title: 'Type',
      render: (data) => data,
    },
    {
      dataIndex: 'type_count',
      title: 'Count',
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.type_count - b.type_count,
      render: (data) => data,
    },
  ];

  if (error)
    return (
      <PageHeader className="page-main-header" title="Moderation Engagement">
        <Alert
          showIcon
          type="error"
          message={`Error ${error.status}`}
          description={error.message}
        />
      </PageHeader>
    );

  return (
    <div>
      <PageHeader className="page-main-header" title="Moderation Engagement" />
      <div className="d-flex align-items-center justify-content-between w-100 mb-3">
        <Form
          layout="inline"
          className="mb-0"
          onFinish={onSelectedDatesHandler}
        >
          <Form.Item
            name="range"
            label="Select Dates"
            initialValue={[
              getMomentDate(dates.from?.locale),
              getMomentDate(dates.to?.locale),
            ]}
          >
            <RangePicker
              format="DD/MM/YYYY"
              disabledDate={(date) => date.isAfter(moment())}
            />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Select
          </Button>
        </Form>
        <div className="d-flex align-items-center">
          <span className="mr-2">Remove test/internal results</span>
          <Switch
            defaultChecked={is_not_included}
            onChange={(status) =>
              setState((prevState) => ({
                ...prevState,
                is_not_included: status,
              }))
            }
          />
        </div>
      </div>
      <div className="page-main-content">
        <Table
          columns={columns}
          rowKey="type"
          dataSource={moderationENgagement || []}
          loading={loading}
          onChange={onTableChange}
          pagination={{
            position: ['topRight', 'bottomRight'],
            showSizeChanger: false,
            showQuickJumper: true,
            pageSize: 25,
            total: moderationENgagement?.meta?.total,
            current: page,
            hideOnSinglePage: true,
          }}
        />
      </div>
    </div>
  );
};

ModerationEngagement.defaultProps = {
  reportPageProps: {},
  row_action: [],
  row_inner_action: [],
};

ModerationEngagement.propTypes = {
  reportPageProps: PropTypes.shape({
    sessionStorageName: PropTypes.string,
    pageTitle: PropTypes.string,
    stateName: PropTypes.string,
    actionName: PropTypes.string,
    default_sort_column: PropTypes.string,
  }),
  row_action: PropTypes.array,
  row_inner_action: PropTypes.array,
};

export default ModerationEngagement;
