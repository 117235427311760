import { useEffect, useState, useCallback, useRef } from 'react';
import { useMount, useSessionStorage, useUnmount } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row, Space } from 'antd';
import PageHeader from '../../uiKitComponents/pageHeader';
import Table from '../../uiKitComponents/table';
import Form from '../../uiKitComponents/form';
import Select from '../../uiKitComponents/select';
import Alert from '../../uiKitComponents/alert';
import Button from '../../uiKitComponents/button';
import * as reports from '../../store/actions/reportPagesAction';
import {
  selectCountriesOptions,
  selectUSStatesOptions,
} from '../../store/selectors/bootstrapSelector';

const initialState = {
  page: 1,
  sort_column: 'id',
  sort_direction: 'descend',
  country_codes: [],
  state_codes: [],
  filtersTrigger: false,
};

const PadsCountryPage = () => {
  const [state, setState] = useSessionStorage('pads-country', {
    ...initialState,
  });
  const { page, filtersTrigger, country_codes, state_codes } = state;
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [isUSSelected, setIsUSSelected] = useState(false);
  const { cleanOnUnmount, padsCountry, countries, USStates } = useSelector(
    (store) => ({
      cleanOnUnmount: store.reportPages.cleanOnUnmount,
      padsCountry: store.reportPages.payload.padsCountry,
      countries: selectCountriesOptions(store),
      USStates: selectUSStatesOptions(store),
    }),
  );
  const paginationMeta = useRef({ range: [], total: 0 });

  const dispatch = useDispatch();

  useMount(() => {
    reports.setCleanOnUnmountTrue()(dispatch);

    if (country_codes.length === 1 && country_codes.includes('US')) {
      setIsUSSelected(true);
    }
  });

  const fetchPadCountry = () => {
    setLoading(true);

    reports
      .getPadsCountry({
        country_codes,
        state_codes,
      })(dispatch)
      .catch((err) => {
        if (err.response.status === 500) {
          setError({
            status: err.response.status,
            message: err.response.data.message,
          });
          sessionStorage.removeItem('pads-country');
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchPadCountry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersTrigger, dispatch, state_codes, country_codes]);

  useUnmount(() => {
    if (cleanOnUnmount) {
      sessionStorage.removeItem('pads-country');
      reports.cleanReports()(dispatch);
    }
  });

  useEffect(() => {
    if (padsCountry) {
      setDataSource(padsCountry);
    }
  }, [padsCountry]);

  const onTableChange = useCallback(
    (pagination, tableFilters, sorter, extra) => {
      const newState = {};
      if (extra.action === 'paginate') newState.page = pagination.current;
      else if (extra.action === 'sort') {
        newState.sort_column = sorter.field;
        newState.sort_direction = sorter.order;
      }
      setState((prevState) => ({ ...prevState, ...newState }));
    },
    [setState],
  );

  const columns = [
    {
      dataIndex: 'country',
      title: 'Country',
      render: (data) => data,
    },
    {
      dataIndex: 'moderated_pads',
      title: 'Moderated Pads',
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.moderated_pads - b.moderated_pads,
      render: (data) => data,
    },
    {
      dataIndex: 'unmoderated_pads',
      title: 'Unmoderated Pads',
      sortDirections: ['descend', 'ascend', 'descend'],
      sorter: (a, b) => a.unmoderated_pads - b.unmoderated_pads,
      render: (data) => data,
    },
    {
      dataIndex: 'private_pads',
      title: 'Private Pads',
      sortDirections: ['descend', 'ascend', 'descend'],
      sorter: (a, b) => a.private_pads - b.private_pads,
      render: (data) => data,
    },
  ];

  const onSelectedDatesHandler = (values) => {
    const { country_codes: codes, state_codes: states } = values;
    setState((prevState) => ({
      ...prevState,
      country_codes: codes,
      state_codes: states,
    }));
  };

  const onCountryChange = (_countries = []) => {
    if (_countries.length === 1 && _countries.includes('US'))
      setIsUSSelected(true);
    else setIsUSSelected(false);
  };

  if (error)
    return (
      <PageHeader className="page-main-header" title="Pad Country">
        <Alert
          showIcon
          type="error"
          message={`Error ${error.status}`}
          description={error.message}
        />
      </PageHeader>
    );

  return (
    <div>
      <PageHeader className="page-main-header" title="Pad Country" />
      <Form
        layout="vertical"
        className="mb-4"
        onFinish={onSelectedDatesHandler}
      >
        <Row justify="space-between">
          <Col className="d-flex flex-column justify-content-between">
            <Form.Item
              name="country_codes"
              label="Select Countries"
              initialValue={country_codes}
              style={{
                marginBottom: 18,
              }}
            >
              <Select
                mode="multiple"
                placeholder="Choose..."
                options={countries}
                optionFilterProp="label"
                onChange={onCountryChange}
                style={{
                  maxWidth: 294,
                  minWidth: 294,
                }}
              />
            </Form.Item>
            {isUSSelected && (
              <Form.Item
                name="state_codes"
                label="Select US States"
                initialValue={state_codes}
                style={{
                  marginBottom: 18,
                }}
              >
                <Select
                  mode="multiple"
                  placeholder="Choose..."
                  options={USStates}
                  optionFilterProp="label"
                  style={{
                    maxWidth: 294,
                    minWidth: 294,
                  }}
                />
              </Form.Item>
            )}
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Select
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className="page-main-content">
        <Space
          className="d-flex justify-content-end"
          style={{ color: '#d9d9d9' }}
        >
          Showing {paginationMeta.current.range[0]}-
          {paginationMeta.current.range[1]} of{''}
          {paginationMeta.current.total} listings
        </Space>
        <Table
          columns={columns}
          rowKey="country"
          dataSource={dataSource}
          loading={loading}
          onChange={onTableChange}
          pagination={{
            position: ['topRight', 'bottomRight'],
            showSizeChanger: false,
            showQuickJumper: true,
            pageSize: 25,
            total: padsCountry?.meta?.total,
            current: page,
            showTotal: (total, range) => {
              paginationMeta.current = { range, total };
            },
          }}
        />
        <Space
          className="d-flex justify-content-end"
          style={{ color: '#d9d9d9' }}
        >
          Showing {paginationMeta.current.range[0]}-
          {paginationMeta.current.range[1]} of{''}
          {paginationMeta.current.total} listings
        </Space>
      </div>
    </div>
  );
};

PadsCountryPage.defaultProps = {
  reportPageProps: {},
  row_action: [],
  row_inner_action: [],
};

PadsCountryPage.propTypes = {
  reportPageProps: PropTypes.shape({
    sessionStorageName: PropTypes.string,
    pageTitle: PropTypes.string,
    stateName: PropTypes.string,
    actionName: PropTypes.string,
    default_sort_column: PropTypes.string,
  }),
  row_action: PropTypes.array,
  row_inner_action: PropTypes.array,
};

export default PadsCountryPage;
