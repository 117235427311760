import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormOutlined } from '@ant-design/icons';
import DatePicker from '../../uiKitComponents/datePicker';
import Button from '../../uiKitComponents/button';

const InlineEdit = ({ data, id, path, onChange }) => {
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(data);
  const [isToday, setIsToday] = useState(false);

  const handleOnChange = (date, dateString) => {
    setEditMode(false);
    setValue(dateString);
    onChange(path, id, dateString);
    setIsToday(false);
  };

  const handleOnBlur = () => {
    setEditMode(false);
    setIsToday(false);
  };

  const handleOnClick = () => {
    setEditMode(true);
    setIsToday(true);
  };

  return editMode ? (
    <DatePicker
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      autoFocus
      open={isToday}
    />
  ) : (
    <Button type="text" onClick={handleOnClick} icon={<FormOutlined />}>
      {value}
    </Button>
  );
};

InlineEdit.defaultProps = {
  data: '',
  onChange: null,
  id: null,
  path: '',
};

InlineEdit.propTypes = {
  data: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.number,
  path: PropTypes.string,
};

export default InlineEdit;
