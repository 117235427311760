import api from '../../utils/appApi';
import {
  CLEAN_ON_UNMOUNT_FALSE,
  CLEAN_ON_UNMOUNT_TRUE,
  PAD_LIST_MAP_CLEAN,
  USERS_LIST_MAP_GET,
} from '../actionTypes';

export const getUsersListMap = (config) => (dispatch) =>
  api.users
    .getMapList(config)
    .then((res) => {
      dispatch({ type: USERS_LIST_MAP_GET, payload: res.data });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const cleanUsersListMap = () => (dispatch) =>
  dispatch({
    type: PAD_LIST_MAP_CLEAN,
  });

export const setCleanOnUnmountTrue = () => (dispatch) =>
  new Promise((resolve, reject) => {
    try {
      dispatch({
        type: CLEAN_ON_UNMOUNT_TRUE,
      });
      return resolve();
    } catch (e) {
      return reject(Error(e));
    }
  });

export const setCleanOnUnmountFalse = () => (dispatch) =>
  new Promise((resolve, reject) => {
    try {
      dispatch({
        type: CLEAN_ON_UNMOUNT_FALSE,
      });
      return resolve();
    } catch (e) {
      return reject(Error(e));
    }
  });
