import { useCallback, useEffect, useState } from 'react';
import { useMount } from 'react-use';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import jsonToFormData from 'json-form-data';
import Button from '../../uiKitComponents/button';
import Result from '../../uiKitComponents/result';
import DuplicatesResolver from '../../components/dupeResolver';
import api from '../../utils/appApi';
import { selectRegionOptions } from '../../store/selectors/bootstrapSelector';
import history from '../../utils/history';
import axiosInstance from '../../utils/apiConfig';

const ModerationRevisionPage = () => {
  const { revisionId } = useParams();
  const { regions } = useSelector((store) => ({
    regions: selectRegionOptions(store),
  }));
  const [error, setError] = useState();
  const [nextDupeId, setNextDupeId] = useState();
  const [moderationList, setModerationList] = useState();
  const [location, setLocation] = useState({});

  useMount(() => {
    axiosInstance
      .get(`/admin/revisions?filters[type]=3&filters[status:includes][]=1`)
      .then((res) => setModerationList(res.data));
  });

  const setNextIndexes = useCallback(() => {
    const newModerationList = { ...moderationList };

    const index = newModerationList.data.findIndex(
      (itm) => itm.id === Number(revisionId),
    );

    if (index !== -1) {
      if (index + 2 <= newModerationList.data.length) {
        setNextDupeId(newModerationList.data[index + 1].id);
      } else if (
        index + 2 > newModerationList.data.length &&
        newModerationList.data.length > 1
      ) {
        setNextDupeId(newModerationList.data[0].id);
      } else {
        history.push(`/dashboard/moderation-list`);
      }
      newModerationList.data.splice(index, 1);
      setModerationList(newModerationList);
    }
  }, [revisionId, moderationList]);

  useEffect(() => {
    if (nextDupeId) {
      history.push(`/dashboard/moderation-revision/${nextDupeId}`);
    }
  }, [nextDupeId]);

  const getRevision = useCallback(
    () =>
      api.revision
        .get(revisionId)
        .then((res) => {
          setLocation({
            prev: res.data.prev.location,
            next: res.data.next.location,
          });
          if (res.data.type !== 3) {
            setError({
              status: 403,
              message: 'You are not authorized for this action.',
            });
            return Promise.reject();
          }
          return Promise.resolve(res);
        })
        .catch((err) => {
          setError({
            status: err.response.status,
            message: err.response.data.message,
          });
          return Promise.reject(err);
        }),
    [revisionId],
  );

  const onSubmit = useCallback(
    (values) => {
      const data = {
        ...values,
        'location#name': values['location#name'].address,
        'location#country': values['location#name'].country_short,
        'location#county': values['location#name'].county,
        'location#region':
          regions.find((itm) => itm.value === values['location#region'])
            ?.label || values['location#region'],
      };

      if (values['pad_social#trip_advisor_rating'])
        data[
          'pad_social#trip_advisor_rating'
        ] = `${values['pad_social#trip_advisor_rating']}`;
      if (values['pad_social#twitter_handle'])
        data[
          'pad_social#twitter_handle'
        ] = `@${values['pad_social#twitter_handle']}`;

      if (values['location#name'] === location?.prev?.name) {
        data['location#is_address_split'] = location?.prev?.is_address_split;
        data['location#name'] = values['location#name'];
      }

      if (values['location#name'] === location?.next?.name) {
        data['location#is_address_split'] = location?.next?.is_address_split;
        data['location#name'] = values['location#name'];
      }

      const fd = jsonToFormData(data);

      return api.revision.updatePad(revisionId, fd).then(() => {
        setNextIndexes();
      });
    },
    [revisionId, regions, location, setNextIndexes],
  );

  const onReject = useCallback(() => {
    api.pad.cancelRevision(revisionId).then(() => {
      history.push(`/dashboard/moderation-list`);
    });
  }, [revisionId]);

  const goBackHandle = useCallback(() => history.goBack(), []);

  const reloadPageHandle = useCallback(() => window.location.reload(), []);

  if (error)
    return (
      <Result
        style={{ backgroundColor: '#fff' }}
        status={error.status}
        title={error.status}
        subTitle={`Error: ${error.message}`}
        extra={[
          <Button key="go-back-btn" type="primary" onClick={goBackHandle}>
            Go back
          </Button>,
          <Button key="reload-page-btn" onClick={reloadPageHandle}>
            Reload page
          </Button>,
        ]}
      />
    );

  return (
    <DuplicatesResolver
      type="moderation-revision"
      getData={getRevision}
      onSubmit={onSubmit}
      onReject={onReject}
    />
  );
};

export default ModerationRevisionPage;
