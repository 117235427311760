import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, Link } from 'react-router-dom';
import {
  PoweroffOutlined,
  UserOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import Layout from '../../uiKitComponents/layout';
import Menu from '../../uiKitComponents/menu';
import Dropdown from '../../uiKitComponents/dropdown';
import Avatar from '../../uiKitComponents/avatar';
import Modal from '../../uiKitComponents/modal';
import BackTop from '../../uiKitComponents/backTop';
import Router from '../../router/router';
import { logout } from '../../store/actions/userAction';
import helipaddyLogo from '../../assets/svg/basic-company-logo_black.svg';
import LoadingBar from '../../components/loadingBar';
import condStrings from '../../utils/condStrings';

import styles from './mainLayoutPage.module.sass';
import { topBarStatsFilters } from '../../utils/constants';

const { Header, Content } = Layout;

const menuItemsRegister = {
  // Pads
  '/dashboard/pad-list': 'pads',
  '/dashboard/pad-create': 'pads',
  '/dashboard/pad-import': 'pads',
  '/dashboard/pad-view': 'pads',
  // Users
  '/dashboard/user-list': 'users',
  '/dashboard/user-view': 'users',
  '/dashboard/users-export': 'users',
  // Moderation
  '/dashboard/moderation-list': 'moderation',
  '/dashboard/moderation-revision': 'moderation',
  // Comments
  '/dashboard/comments-list': 'comments',
  // Reports
  '/dashboard/reports': 'reports',
  '/dashboard/reports/pad-submissions': 'reports',
  '/dashboard/reports/log-report': 'reports',
  '/dashboard/reports/duplicate-report': 'reports',
  // Announcements
  '/dashboard/announcements': 'announcements',
};

const MainLayoutPage = (props) => {
  const { routes } = props;
  const routeMatch = useRouteMatch();
  const dispatch = useDispatch();
  const [isSticky, setIsSticky] = useState(false);
  const {
    userFirstName,
    userLastName,
    userEmail,
    loadingBarEnabled,
    topLineData,
  } = useSelector((state) => ({
    userFirstName: state.user.payload.first_name,
    userLastName: state.user.payload.last_name,
    userEmail: state.user.payload.email,
    loadingBarEnabled: state.loadingBar.enabled,
    topLineData: state.bootstrap.payload.helipaddyStats,
  }));

  useScrollPosition(
    ({ currPos }) => {
      setIsSticky(currPos.y < -200);
    },
    [isSticky],
  );

  const handleLogout = () => logout()(dispatch);

  function confirmLogout() {
    Modal.confirm({
      title: 'Are you sure you want to quit?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      centered: true,
      onOk: handleLogout,
      zIndex: 1002,
      confirmLoading: true,
      maskClosable: true,
    });
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to="/dashboard/admin-profile">
          <UserOutlined className="mr-2" />
          <span>Profile</span>
        </Link>
      </Menu.Item>
      <Menu.Item onClick={confirmLogout}>
        <PoweroffOutlined className="mr-2" />
        <span>Logout</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout className={styles.root}>
      <BackTop className={styles.backTop} />
      {loadingBarEnabled && <LoadingBar className={styles.loadingBar} />}
      <div className={styles.headerContainer}>
        <div className={styles.statusBar}>
          <div className={styles.statusBarContainer}>
            <table className={styles.statusCounter}>
              <tbody>
                <tr>
                  <td>
                    <a href={topBarStatsFilters['pads.moderated']}>
                      Moderated sites:
                    </a>
                  </td>
                  <td>{topLineData?.pads?.moderated ?? 'n/a'}</td>
                  <td>
                    <a href={topBarStatsFilters['owners.free']}>Free Owners:</a>
                  </td>
                  <td>{topLineData?.owners?.free ?? 'n/a'}</td>
                  <td>
                    <a href={topBarStatsFilters['pilots.free']}>Free Pilots:</a>
                  </td>
                  <td>{topLineData?.pilots?.free ?? 'n/a'}</td>
                  <td>
                    <a href={topBarStatsFilters['pilots.free.30']}>
                      Free Pilots active in last 30 days:
                    </a>
                  </td>
                  <td>{topLineData?.pilots?.free_last_month ?? 'n/a'}</td>
                </tr>
                <tr>
                  <td>
                    <a href={topBarStatsFilters['pads.unmoderated']}>
                      Unmoderated sites:
                    </a>
                  </td>
                  <td>{topLineData?.pads?.unmoderated ?? 'n/a'}</td>
                  <td>
                    <a href={topBarStatsFilters['owners.premium']}>
                      Premium Owners:
                    </a>
                  </td>
                  <td>{topLineData?.owners?.premium ?? 'n/a'}</td>
                  <td>
                    <a href={topBarStatsFilters['pilots.freemium']}>
                      Freemium Pilots:
                    </a>
                  </td>
                  <td>{topLineData?.pilots?.fremium}</td>
                  <td>
                    <a href={topBarStatsFilters['pilots.freemium.30']}>
                      Freemium Pilots active in last 30 days:
                    </a>
                  </td>
                  <td>{topLineData?.pilots?.fpremium_last_month ?? 'n/a'}</td>
                </tr>
                <tr>
                  <td>
                    <a href={topBarStatsFilters['pads.claimed']}>
                      Sites claimed:
                    </a>
                  </td>
                  <td>{topLineData?.pads?.claimed ?? 'n/a'}</td>
                  <td>
                    <a href={topBarStatsFilters.deleted_user_accounts}>
                      Deleted Accounts:
                    </a>
                  </td>
                  <td>{topLineData?.deleted_user_accounts ?? 'n/a'}</td>
                  <td>
                    <a href={topBarStatsFilters['pilots.premium']}>
                      Premium Pilots:
                    </a>
                  </td>
                  <td>{topLineData?.pilots?.premium ?? 'n/a'}</td>
                  <td>
                    <a href={topBarStatsFilters['pilots.premium.30']}>
                      Premium Pilots active in last 30 days:
                    </a>
                  </td>
                  <td>{topLineData?.pilots?.premium_last_month ?? 'n/a'}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Header
          className={condStrings(styles.header, isSticky && styles.isSticky)}
        >
          <div className={styles.menuContainer}>
            <img src={helipaddyLogo} alt="Helipaddy logo" />
            <Menu
              style={{ marginLeft: '-20px' }}
              mode="horizontal"
              selectedKeys={menuItemsRegister[routeMatch.url]}
            >
              <Menu.Item key="pads">
                <Link to="/dashboard/pad-list">Pads</Link>
              </Menu.Item>
              <Menu.Item key="users">
                <Link to="/dashboard/user-list">Users</Link>
              </Menu.Item>
              <Menu.Item key="moderation">
                <Link to="/dashboard/moderation-list">Moderations</Link>
              </Menu.Item>
              <Menu.Item key="comments">
                <Link to="/dashboard/comments-list">Reviews</Link>
              </Menu.Item>
              <Menu.Item key="reports">
                <Link to="/dashboard/reports">Reports</Link>
              </Menu.Item>
              <Menu.Item key="announcements">
                <Link to="/dashboard/announcements">Announcements</Link>
              </Menu.Item>
            </Menu>
            <div style={{ lineHeight: '1.2' }} className="d-flex">
              <div className="mr-3 text-right">
                {(userFirstName || userLastName) && (
                  <div>
                    {userFirstName && <span>{userFirstName}</span>}
                    {userLastName && <span>{userLastName}</span>}
                  </div>
                )}
                <div style={{ fontSize: '12px' }}>{userEmail}</div>
              </div>
              <Dropdown
                overlay={menu}
                trigger={['click']}
                placement="bottomRight"
              >
                <Avatar
                  size="32px"
                  icon={<UserOutlined />}
                  className={condStrings(
                    styles.profileAvatar,
                    routeMatch.url === '/dashboard/admin-profile' && 'active',
                  )}
                />
              </Dropdown>
            </div>
          </div>
        </Header>
      </div>
      <Content className={styles.content}>
        <Router routes={routes} />
      </Content>
    </Layout>
  );
};

MainLayoutPage.defaultProps = {
  routes: [],
};

MainLayoutPage.propTypes = {
  routes: PropTypes.array,
};

export default MainLayoutPage;
