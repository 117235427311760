import React from 'react';
import { Button, notification, Popconfirm } from 'antd';
import { useSelector } from 'react-redux';
import { selectStatusesForDisplay } from '../../../../../store/selectors/bootstrapSelector';
import api from '../../../../../utils/appApi';
import getApiErrorMessages from '../../../../../utils/getApiErrorMessages';
import PadViewContext from '../../../PadView.context';

export function PadStatus() {
  const { statuses } = useSelector((store) => ({
    statuses: selectStatusesForDisplay(store),
  }));
  const { pad, updatePad } = React.useContext(PadViewContext);
  const [loading, setLoading] = React.useState(false);

  const actionDisabled = React.useMemo(
    () => [pad?.is_added_to_purgatory, !!pad?.deleted_at].some(Boolean),
    [pad],
  );

  const addToPurgatoryHandler = React.useCallback(() => {
    setLoading(true);
    api.pad
      .addToPurgatory(pad?.id)
      .then((res) => {
        updatePad({
          is_added_to_purgatory: true,
        });

        notification.success({
          message: 'Success',
          description: 'Done!',
        });
        return Promise.resolve(res);
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(err),
        });
      })
      .finally(() => setLoading(false));
  }, [pad, updatePad]);

  return (
    <span className="d-flex justify-content-between align-items-center">
      <span>{statuses?.[pad?.status_id]}</span>
      {pad?.status_id === 1 && !pad?.is_private && (
        <Popconfirm
          key="add-to-purgatory"
          placement="topRight"
          title="Are you sure you want to add this pad to purgatory?"
          onConfirm={addToPurgatoryHandler}
          okText="Add"
          cancelText="Cancel"
          disabled={actionDisabled}
        >
          <Button
            htmlType="button"
            type="secondary"
            loading={loading}
            disabled={actionDisabled}
          >
            {pad?.is_added_to_purgatory
              ? 'Pad added to purgatory'
              : 'Add to purgatory'}
          </Button>
        </Popconfirm>
      )}
    </span>
  );
}
