import React from 'react';
import { Form, Select } from 'antd';
import { useSelector } from 'react-redux';
import { selectLandingFeeOptions } from '../../../../../store/selectors/bootstrapSelector';
import { formItemVerticalLayout } from '../LandingTabEdit';
import PadViewContext from '../../../PadView.context';

export const LandingTabEditLandingFees = () => {
  const { pad } = React.useContext(PadViewContext);
  const { fees } = useSelector((store) => ({
    fees: selectLandingFeeOptions(store),
  }));

  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue }) => {
        let warningSelected = false;

        if (pad?.category_id === 7) {
          if (getFieldValue('facilities')?.includes(13)) {
            warningSelected = true;
          }
        }

        if (pad?.category_id !== 7) {
          if (getFieldValue('warnings')?.includes(6)) {
            warningSelected = true;
          }
        }
        return (
          warningSelected && (
            <Form.Item
              {...formItemVerticalLayout}
              label="Landing fees category"
              name="landing_fees"
              style={{ width: 200 }}
            >
              <Select placeholder="Choose" options={fees} />
            </Form.Item>
          )
        );
      }}
    </Form.Item>
  );
};
