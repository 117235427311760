import {
  CheckOutlined,
  MailOutlined,
  CloseOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  PauseOutlined,
} from '@ant-design/icons';
import { Button, Input, Modal, notification, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { OwnersListContext } from '.';
import api from '../../../../../../utils/appApi';
import getApiErrorMessages from '../../../../../../utils/getApiErrorMessages';

const actionPropTypes = {
  owner: PropTypes.shape({
    id: PropTypes.number,
    user_id: PropTypes.number,
    contact_type_id: PropTypes.number,
  }),
  toggleLoadingState: PropTypes.func,
};

export const AcceptAction = React.memo((props) => {
  const {
    owner: { id: contactId, user_id },
    toggleLoadingState,
  } = props;

  const { padId, updatePad, getOwnerList } = React.useContext(
    OwnersListContext,
  );

  const acceptOwnerHandler = React.useCallback(() => {
    toggleLoadingState(true);

    api.pad
      .acceptOwner({ pad_id: padId, user_id, contact_id: contactId })
      .then((res) => {
        getOwnerList();
        updatePad({
          ...res.data.data,
          editable_contacts: res.data.data.contacts.filter((c) =>
            [1, 4].includes(c?.contact_type_id),
          ),
        });
      })
      .catch((err) =>
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(err),
        }),
      )
      .finally(() => toggleLoadingState(false));
  }, [getOwnerList, padId, toggleLoadingState, updatePad, contactId, user_id]);

  const confirmAcceptAction = React.useCallback(() => {
    Modal.confirm({
      title: 'Are you sure you want to confirm this owner?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk: acceptOwnerHandler,
    });
  }, [acceptOwnerHandler]);

  return (
    <button
      type="button"
      className="owners-list-item-action"
      onClick={confirmAcceptAction}
    >
      <span>
        <CheckOutlined />
      </span>
      <span>Accept</span>
    </button>
  );
});

export const AcceptConfirmation = React.memo((props) => {
  const {
    owner: { id: contactId },
    toggleLoadingState,
  } = props;

  const { padId, getOwnerList } = React.useContext(OwnersListContext);

  const [pendingReason, setPendingReason] = React.useState('');
  const [pendingModalVisible, setPendingModalVisible] = React.useState(false);

  const confirmOwnerHandler = React.useCallback(() => {
    setPendingModalVisible(false);
    toggleLoadingState(true);

    api.pad
      .pendingOwner(padId, contactId, {is_confirmation : true})
      .then(() => {
        getOwnerList();
        setPendingReason('');
      })
      .finally(() => toggleLoadingState(false));
  }, [contactId, getOwnerList, padId, toggleLoadingState]);

  const confirmConfirmationAction = React.useCallback(() => {
    Modal.confirm({
      title: 'Are you sure you want to send the confirmation email to this owner?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk: confirmOwnerHandler,
    });
  }, [confirmOwnerHandler]);

  return (
    <button
      type="button"
      className="owners-list-item-action"
      onClick={confirmConfirmationAction}
    >
      <span>
        <MailOutlined />
      </span>
      <span>Confirmation</span>
    </button>
  );
});

export const RejectAction = React.memo((props) => {
  const {
    owner: { id: contactId, contact_type_id },
    toggleLoadingState,
    as = 'menuItem',
    isLoading,
  } = props;

  const { padId, updatePad, getOwnerList } = React.useContext(
    OwnersListContext,
  );

  const removeOwnerHandler = React.useCallback(() => {
    toggleLoadingState(true);

    api.pad
      .deleteOwner(padId, contactId, contact_type_id)
      .then((res) => {
        getOwnerList();
        updatePad({
          ...res.data.data,
          editable_contacts: res.data.data.contacts.filter((c) =>
            [1, 4].includes(c?.contact_type_id),
          ),
        });
      })
      .finally(() => toggleLoadingState(false));
  }, [
    contact_type_id,
    getOwnerList,
    padId,
    toggleLoadingState,
    updatePad,
    contactId,
  ]);

  const confirmRemoveAction = React.useCallback(() => {
    Modal.confirm({
      title: 'Are you sure you want to delete this owner?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk: removeOwnerHandler,
    });
  }, [removeOwnerHandler]);

  if (as === 'menuItem') {
    return (
      <button
        type="button"
        className="owners-list-item-action"
        onClick={confirmRemoveAction}
      >
        <span>
          <CloseOutlined />
        </span>
        <span>Reject</span>
      </button>
    );
  }

  return (
    <Button
      htmlType="button"
      type="link"
      danger
      loading={isLoading}
      icon={<DeleteOutlined />}
      onClick={confirmRemoveAction}
    />
  );
});

export const PendingAction = React.memo((props) => {
  const {
    owner: { id: contactId },
    toggleLoadingState,
  } = props;

  const { padId, getOwnerList } = React.useContext(OwnersListContext);

  const [pendingReason, setPendingReason] = React.useState('');
  const [pendingModalVisible, setPendingModalVisible] = React.useState(false);

  const pendingOwnerHandler = React.useCallback(() => {
    setPendingModalVisible(false);
    toggleLoadingState(true);

    api.pad
      .pendingOwner(padId, contactId, {comment : pendingReason})
      .then(() => {
        getOwnerList();
        setPendingReason('');
      })
      .finally(() => toggleLoadingState(false));
  }, [contactId, getOwnerList, padId, pendingReason, toggleLoadingState]);

  return (
    <>
      <button
        type="button"
        className="owners-list-item-action"
        onClick={() => setPendingModalVisible(true)}
      >
        <span>
          <PauseOutlined />
        </span>
        <span>Pending</span>
      </button>
      <Modal
        title="Owner pending confirmation"
        okText="Confirm"
        cancelText="Cancel"
        okButtonProps={{ disabled: !pendingReason }}
        visible={pendingModalVisible}
        onOk={pendingOwnerHandler}
        onCancel={() => setPendingModalVisible(false)}
      >
        <Typography.Title level={5}>Reason</Typography.Title>
        <Input
          value={pendingReason}
          onChange={(e) => setPendingReason(e.target.value)}
        />
      </Modal>
    </>
  );
});

AcceptAction.propTypes = actionPropTypes;
AcceptConfirmation.propTypes = actionPropTypes;
RejectAction.propTypes = {
  ...actionPropTypes,
  as: PropTypes.oneOf(['menuItem', 'button']),
  isLoading: PropTypes.bool,
};
PendingAction.propTypes = actionPropTypes;
