import PropTypes from 'prop-types';
import { Button, Form, Image, Input, notification } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import React from 'react';
import getApiErrorMessages from '../../utils/getApiErrorMessages';
import api from '../../utils/appApi';

const acceptedFiles = '.jpg,.png,.jpeg';

export default function SurveyEditUploadSide({
  sideData,
  surveyId,
  sideName,
  onRemoveSuccess,
  onUploadSuccess,
}) {
  const [removeLoading, setRemoveLoading] = React.useState(false);
  const [uploadLoading, setUploadLoading] = React.useState(false);
  const fileInputRef = React.useRef();

  const initInputFile = () => {
    fileInputRef.current?.click();
  };

  const uploadHandler = async (e) => {
    setUploadLoading(true);

    const file = e.target.files[0];

    if (file) {
      try {
        const formData = new FormData();

        formData.append('upload', file);
        formData.append('direction', sideName);

        const response = await api.upload.store('survey', surveyId, formData);

        onUploadSuccess({
          id: response.data.id,
          url: response.data.file_url,
          dir: sideName,
          dirLabel: sideName.charAt(0).toUpperCase() + sideName.slice(1),
        });
      } catch (error) {
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(error),
        });
      } finally {
        setUploadLoading(false);
      }
    }
  };

  const removeHanlder = async () => {
    setRemoveLoading(true);

    try {
      await api.upload.delete(sideData.id);
      onRemoveSuccess(sideData.id);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: getApiErrorMessages(error),
      });
    } finally {
      setRemoveLoading(false);
    }
  };

  return (
    <div className="survey-upload editing">
      <div>
        <div className="survey-upload-img">
          {sideData.url ? (
            <Image alt={sideData.dirLabel} src={sideData.url} />
          ) : (
            <button type="button" onClick={initInputFile}>
              {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
              <span style={{ marginTop: 8 }}>Upload</span>
            </button>
          )}
        </div>
        {sideData.url && (
          <Button
            type="link"
            className="mt-1 py-0"
            size="small"
            loading={removeLoading}
            onClick={removeHanlder}
          >
            Remove
          </Button>
        )}
      </div>
      <div className="survey-upload-caption">
        <h4>{sideData.dirLabel}</h4>
        <Form.Item name={['view', sideData.dir]} className="mb-0">
          <Input.TextArea
            autoSize={{ minRows: 2 }}
            placeholder="Description of your site"
          />
        </Form.Item>
      </div>
      <input
        ref={fileInputRef}
        type="file"
        accept={acceptedFiles}
        className="d-none"
        onChange={uploadHandler}
      />
    </div>
  );
}

SurveyEditUploadSide.propTypes = {
  sideData: PropTypes.object,
  surveyId: PropTypes.number.isRequired,
  sideName: PropTypes.string.isRequired,
  onRemoveSuccess: PropTypes.func.isRequired,
  onUploadSuccess: PropTypes.func.isRequired,
};

SurveyEditUploadSide.defaultProps = {
  sideData: {},
};
