import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import { useContext } from 'react';
import { DoubleRightOutlined, DoubleLeftOutlined } from '@ant-design/icons';
import condStrings from '../../utils/condStrings';
import DataRowContext from './DataRowContext';

export default function RowCheckbox(props) {
  const { side, onChange, checked, noValues } = props;
  const { readOnly, isConflict } = useContext(DataRowContext);

  return (
    <div
      className={condStrings(
        'data-row-meta',
        side,
        isConflict && 'is-conflict',
        noValues && 'invisible',
      )}
    >
      <Checkbox
        disabled={readOnly || noValues}
        checked={checked}
        onChange={onChange}
      />
      {side === 'prev' ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
    </div>
  );
}

RowCheckbox.propTypes = {
  side: PropTypes.oneOf(['prev', 'next']),
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  noValues: PropTypes.bool,
};

RowCheckbox.defaultProps = {
  side: 'prev',
  noValues: false,
};
