/**
 * This is inspired by https://github.com/fullstackreact/google-maps-react/issues/31#issuecomment-474165100
 * google-maps-react doesn't have their own implementation of MarkerCluster.
 */
import { memo, useEffect, useState } from 'react';
import { usePrevious, useToggle, useUnmount } from 'react-use';
import PropTypes from 'prop-types';
import MarkerClustererPlus from '@googlemaps/markerclustererplus';
import chillout from 'chillout';
import styles from './mapWithPads.module.sass';
import aerialLandmarkIcon from '../../assets/padsCategories/aerial_landmark.png';
import aerialLandmarkIconGrey from '../../assets/padsCategories/aerial_landmark_grey.png';
import eatIcon from '../../assets/padsCategories/eat.png';
import eatIconGrey from '../../assets/padsCategories/eat_grey.png';
import stayIcon from '../../assets/padsCategories/stay.png';
import stayIconGrey from '../../assets/padsCategories/stay_grey.png';
import landingSiteIcon from '../../assets/padsCategories/landing_site.png';
import landingSiteIconGrey from '../../assets/padsCategories/landing_site_grey.png';
import aerodromeIcon from '../../assets/padsCategories/aerodrome.png';
import aerodromeIconGrey from '../../assets/padsCategories/aerodrome_grey.png';
import userIcon from '../../assets/padsCategories/user_icon.png';
import userIconGrey from '../../assets/padsCategories/user_icon_grey.png';

const evtNames = [
  'click',
  'dblclick',
  'dragend',
  'mousedown',
  'mouseout',
  'mouseover',
  'mouseup',
  'recenter',
];

const clusterIconM1 = {
  width: 30,
  height: 30,
  className: styles.clusterIcon,
};

const clusterIconM2 = {
  width: 35,
  height: 35,
  className: styles.clusterIcon,
};

const clusterIconM3 = {
  width: 40,
  height: 40,
  className: styles.clusterIcon,
};

const getIcon = (pad, greyIcon, coloredIcon) => {
  if (pad.status_id === 1 || !pad.is_landing_permitted) {
    return greyIcon;
  }

  return coloredIcon;
};

const MarkerCluster = (props) => {
  const { map, google, pads, markerEventHandlers, urlTarget, ...rest } = props;
  const prevPadsLength = usePrevious(pads.length);
  const [padsChanged, padsChangedToggle] = useToggle(false);
  const prevPadsChanged = usePrevious(padsChanged);
  const [clusterer, setClusterer] = useState();

  useEffect(() => {
    if (pads.length !== prevPadsLength) padsChangedToggle();
  }, [pads.length, prevPadsLength, padsChangedToggle]);

  useEffect(() => {
    if (map && padsChanged !== prevPadsChanged && Array.isArray(pads)) {
      const markerIconDefaultStyles = (iw, ih) => ({
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(iw / 2, ih),
        size: new google.maps.Size(iw, ih),
        scaledSize: new google.maps.Size(iw, ih),
      });
      const markerIconDefault = markerIconDefaultStyles(27, 33);

      const handleEvent = (params) => {
        if (markerEventHandlers?.[params.event])
          markerEventHandlers[params.event](params);
      };

      const mapMarkers = [];
      chillout
        .forEach(pads, (pad) => {
          const markersIcons = {
            1: {
              url: getIcon(pad, aerialLandmarkIconGrey, aerialLandmarkIcon),
              ...markerIconDefaultStyles(15, 15),
            },
            2: {
              url: getIcon(pad, stayIconGrey, stayIcon),
              ...markerIconDefault,
            },
            3: {
              url: getIcon(pad, landingSiteIconGrey, landingSiteIcon),
              ...markerIconDefault,
            },
            4: {
              url: getIcon(pad, stayIconGrey, stayIcon),
              ...markerIconDefault,
            },
            5: {
              url: getIcon(pad, eatIconGrey, eatIcon),
              ...markerIconDefault,
            },
            6: {
              url: getIcon(pad, eatIconGrey, eatIcon),
              ...markerIconDefault,
            },
            7: {
              url: getIcon(pad, aerodromeIconGrey, aerodromeIcon),
              ...markerIconDefault,
            },
            8: {
              url: userIcon,
            },
            9: {
              url: userIconGrey,
            },
          };

          const entry = new google.maps.Marker({
            position: {
              lat: pad.latitude,
              lng: pad.longitude,
            },
            map,
            name: pad.name,
            title: pad.name,
            icon: markersIcons[pad.category_id],
            zIndex: 1000,
          });

          const infoWindow = new google.maps.InfoWindow({
            content: `<a href="${urlTarget}/${pad.id}">${pad.name}</a>`,
          });

          evtNames.forEach((e) => {
            entry.addListener(e, () =>
              handleEvent({
                event: e,
                pad,
                entry,
                infoWindow,
                map,
              }),
            );
          });

          mapMarkers.push(entry);
        })
        .then(() => {
          if (typeof clusterer === 'object') clusterer.clearMarkers();

          const newClusterer = new MarkerClustererPlus(map, mapMarkers, {
            gridSize: 80,
            minimumClusterSize: 4,
            zIndex: 1000,
            styles: [
              MarkerClustererPlus.withDefaultStyle(clusterIconM1),
              MarkerClustererPlus.withDefaultStyle(clusterIconM2),
              MarkerClustererPlus.withDefaultStyle(clusterIconM3),
            ],
            ...rest,
          });

          setClusterer(newClusterer);
        });
    }
  }, [
    map,
    google,
    pads,
    padsChanged,
    prevPadsChanged,
    markerEventHandlers,
    rest,
    pads.length,
    clusterer,
    urlTarget,
  ]);

  useUnmount(() => {
    // Cleanup function. Note, this is only returned if we create the markers
    if (clusterer) clusterer.clearMarkers();
  });

  // Do we need to render anything??
  return null;
};

MarkerCluster.propTypes = {
  map: PropTypes.object,
  google: PropTypes.object,
  pads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      category_id: PropTypes.number,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
  ),
  markerEventHandlers: PropTypes.object,
  urlTarget: PropTypes.string,
};

export default memo(MarkerCluster);
