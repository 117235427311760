import LoginPage from '../containers/LoginPage';
import AdminProfilePage from '../containers/AdminProfilePage';
import MainLayoutPage from '../containers/MainLayoutPage';
import PadListPage from '../containers/PadListPage';
import PadCreatePage from '../containers/PadCreatePage';
import PadViewPage from '../containers/PadViewPage';
import PadImportPage from '../containers/PadImportPage';
import UserListPage from '../containers/UserListPage';
import UserViewPage from '../containers/UserViewPage';
import ModerationListPage from '../containers/ModerationListPage';
import CommentsList from '../containers/CommetsList';
import ModerationRevisionPage from '../containers/ModerationRevisionPage';
import ReportsPageList from '../containers/ReportsPageList';
import DuplicatesCheckerPage from '../containers/DuplicatesCheckerPage';
import {
  PadSubmissions,
  LogReport,
  DuplicateReportList,
  PadStatsFav,
  PadStatsViews,
  UserDropOff,
  PilotsCountryPage,
  ModerationEngagement,
  CampaignsPage,
  SubsReportPage,
  ConcurrentLoginsPage,
  PadsCountryPage,
  SurveysReportPage,
  PilotAircraftsPage,
} from '../containers/ReportsPages';
import AnnouncementsViewPage from '../containers/AnnouncementsCreate';
import AnnouncementsList from '../containers/AnnouncementsList';
import { PadView } from '../modules';

const routes = [
  {
    path: '/login',
    component: LoginPage,
    exact: true,
    private: false,
  },
  {
    path: '/dashboard/:type?',
    component: MainLayoutPage,
    private: true,
    routes: [
      {
        path: '/dashboard/pad-list',
        exact: true,
        private: true,
        containerSize: 'sm',
        component: PadListPage,
      },
      {
        path: '/dashboard/pad-create',
        exact: true,
        private: true,
        containerSize: 'sm',
        component: PadCreatePage,
      },
      {
        path: '/dashboard/pad-import',
        exact: true,
        private: true,
        containerSize: 'sm',
        component: PadImportPage,
      },
      {
        path: '/dashboard/pad-view1/:padId',
        exact: true,
        private: true,
        containerSize: 'sm',
        component: PadViewPage,
      },
      {
        path: '/dashboard/pad-view/:padId',
        exact: true,
        private: true,
        containerSize: 'sm',
        component: PadView,
      },
      {
        path: '/dashboard/user-list',
        exact: true,
        private: true,
        containerSize: 'sm',
        component: UserListPage,
      },
      {
        path: '/dashboard/user-view/:userId',
        exact: true,
        private: true,
        containerSize: 'sm',
        component: UserViewPage,
      },
      {
        path: '/dashboard/comments-list',
        exact: true,
        private: true,
        containerSize: 'sm',
        component: CommentsList,
      },
      {
        path: '/dashboard/moderation-list',
        exact: true,
        private: true,
        containerSize: 'sm',
        component: ModerationListPage,
      },
      {
        path: '/dashboard/moderation-revision/:revisionId',
        exact: true,
        private: true,
        containerSize: 'lg',
        component: ModerationRevisionPage,
      },
      {
        path: '/dashboard/admin-profile',
        exact: true,
        private: true,
        containerSize: 'sm',
        component: AdminProfilePage,
      },
      {
        path: '/dashboard/reports',
        exact: true,
        private: true,
        containerSize: 'sm',
        component: ReportsPageList,
      },
      {
        path: '/dashboard/reports/pad-submissions',
        exact: true,
        private: true,
        containerSize: 'sm',
        component: PadSubmissions,
      },
      {
        path: '/dashboard/reports/log-report',
        exact: true,
        private: true,
        containerSize: 'sm',
        component: LogReport,
      },
      {
        path: '/dashboard/reports/duplicate-report',
        exact: true,
        private: true,
        containerSize: 'sm',
        component: DuplicateReportList,
      },
      {
        path: '/dashboard/reports/pad-stats-fav',
        exact: true,
        private: true,
        containerSize: 'sm',
        component: PadStatsFav,
      },
      {
        path: '/dashboard/reports/pad-stats-views',
        exact: true,
        private: true,
        containerSize: 'sm',
        component: PadStatsViews,
      },
      {
        path: '/dashboard/reports/duplicate-report/:prevPadId/:nextPadId',
        exact: true,
        private: true,
        containerSize: 'sm',
        component: DuplicatesCheckerPage,
      },
      {
        path: '/dashboard/reports/drop-off',
        exact: true,
        private: true,
        containerSize: 'sm',
        component: UserDropOff,
      },
      {
        path: '/dashboard/reports/pilots-country',
        exact: true,
        private: true,
        containerSize: 'sm',
        component: PilotsCountryPage,
      },
      {
        path: '/dashboard/reports/subs-report',
        exact: true,
        private: true,
        containerSize: 'sm',
        component: SubsReportPage,
      },
      {
        path: '/dashboard/reports/moderation-engagement',
        exact: true,
        private: true,
        containerSize: 'sm',
        component: ModerationEngagement,
      },
      {
        path: '/dashboard/reports/campaigns',
        exact: true,
        private: true,
        containerSize: 'sm',
        component: CampaignsPage,
      },
      {
        path: '/dashboard/announcements/:id',
        exact: false,
        private: true,
        containerSize: 'sm',
        component: AnnouncementsViewPage,
      },
      {
        path: '/dashboard/announcements',
        exact: false,
        private: true,
        containerSize: 'sm',
        component: AnnouncementsList,
      },
      {
        path: '/dashboard/reports/concurrent-logins',
        exact: false,
        private: true,
        containerSize: 'sm',
        component: ConcurrentLoginsPage,
      },
      {
        path: '/dashboard/reports/pad-country',
        exact: false,
        private: true,
        containerSize: 'sm',
        component: PadsCountryPage,
      },
      {
        path: '/dashboard/reports/survey-report',
        exact: true,
        private: true,
        containerSize: 'sm',
        component: SurveysReportPage,
      },
      {
        path: '/dashboard/reports/pilot-aircrafts',
        exact: true,
        private: true,
        containerSize: 'sm',
        component: PilotAircraftsPage,
      },
      {
        redirectTo: '/dashboard/pad-list',
      },
    ],
  },
  {
    redirectTo: '/dashboard/pad-list',
  },
];

export default routes;
