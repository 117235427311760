import { Button, Form, Input, notification, Popconfirm, Popover } from 'antd';
import React from 'react';
import api from '../../../../../utils/appApi';
import getApiErrorMessages from '../../../../../utils/getApiErrorMessages';
import PadViewContext from '../../../PadView.context';

export function PadArchiveActions() {
  const { pad, updatePad } = React.useContext(PadViewContext);
  const [loading, setLoading] = React.useState(false);
  const [confirmArchiveVisible, setConfirmArchiveVisible] = React.useState(
    false,
  );
  const [form] = Form.useForm();

  const actionDisabled = React.useMemo(() => [pad?.is_private].some(Boolean), [
    pad,
  ]);

  const archivePadHandler = (data = { note: '' }) => {
    setLoading(true);

    api.pad
      .archive(pad.id, data)
      .then((res) => {
        notification.success({
          message: 'Success',
          description: 'Done!',
        });
        setConfirmArchiveVisible(false);
        updatePad({ deleted_at: res.data.data.deleted_at });
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(err),
        });
      })
      .finally(() => setLoading(false));
  };

  const ArchiveBtnContent = (
    <Form onFinish={archivePadHandler} form={form} layout="vertical">
      <Form.Item
        name="note"
        label="Reason"
        className="mb-2"
        rules={[
          {
            required: true,
            message: 'Please provide reason',
          },
          {
            min: 3,
            message: 'At least 3 characters',
          },
        ]}
      >
        <Input.TextArea autoSize style={{ minHeight: 50 }} />
      </Form.Item>
      <Button.Group size="small" className="d-flex justify-content-end">
        <Button
          htmlType="button"
          onClick={() => setConfirmArchiveVisible(false)}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          htmlType="button"
          onClick={form.submit}
          loading={loading}
        >
          Submit
        </Button>
      </Button.Group>
    </Form>
  );

  if (pad?.deleted_at) {
    return (
      <Popconfirm
        key="unarchive-pad"
        title="Are you sure you want to unarchive this pad?"
        placement="topRight"
        trigger="click"
        onConfirm={() => archivePadHandler()}
      >
        <Button htmlType="button" disabled={actionDisabled} loading={loading}>
          Unarchive
        </Button>
      </Popconfirm>
    );
  }

  return (
    <Popover
      key="archive-pad"
      content={ArchiveBtnContent}
      visible={confirmArchiveVisible}
      trigger="click"
      placement="topRight"
      overlayStyle={{
        minWidth: 240,
      }}
      onVisibleChange={setConfirmArchiveVisible}
    >
      <Button
        htmlType="button"
        disabled={actionDisabled}
        onClick={() => setConfirmArchiveVisible(true)}
      >
        Archive
      </Button>
    </Popover>
  );
}
