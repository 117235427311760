import PropTypes from 'prop-types';
import Input from '../../uiKitComponents/input';
import condStrings from '../../utils/condStrings';

const icaoRegIncomplete = /^[a-zA-Z]{0,4}$/;

const IcaoInput = (props) => {
  const {
    className,
    placeholder,
    value: valueProp,
    onChange,
    onBlur,
    ...rest
  } = props;

  const handleOnChange = (e) => {
    const { value } = e.target;
    if (icaoRegIncomplete.test(value) || value === '')
      onChange({ ...e, target: { ...e.target, value: value.toUpperCase() } });
  };

  const handleOnBlur = (e) => {
    const { value } = e.target;
    const icaoReg = /^[a-zA-Z]{4}$/;
    if (value && !icaoReg.test(value))
      onChange({ ...e, target: { ...e.target, value: '' } });
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <Input
      {...rest}
      placeholder={placeholder}
      className={condStrings('text-uppercase', className)}
      value={icaoRegIncomplete.test(valueProp) ? valueProp : ''}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
    />
  );
};

IcaoInput.defaultProps = {
  className: '',
  placeholder: 'EGLL',
  value: '',
  onChange: () => null,
  onBlur: () => null,
};

IcaoInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default IcaoInput;
