import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import {
  Tabs,
  Form,
  Checkbox,
  Input,
  Select,
  Spin,
  Alert,
  Button,
  Space,
  Descriptions,
  Tag,
  Tooltip,
} from 'antd';
import fieldsErrors from '../../../../utils/fieldsErrors';
import CheckableTagList from '../../../../components/checkableTagList';
import IcaoInput from '../../../../components/icaoInput';
import useApiErrorsWithAntd from '../../../../hooks/useApiErrorsWithAntd';
import {
  loadingBarDisable,
  loadingBarEnable,
} from '../../../../store/actions/loadingBarAction';
import api from '../../../../utils/appApi';
import objectRemoveEmpty from '../../../../utils/objectRemoveEmpty';
import styles from './landingTab.module.sass';
import generateExternalLink from '../../../../utils/generateExternalLink';
import { PadSurveyDetails } from '../../../../modules';

const formItemVerticalLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const twitterUsernameHandle = [
  {
    validator: (itm, value) =>
      (value && !value.includes('@')) || !value
        ? Promise.resolve()
        : Promise.reject(new Error(fieldsErrors.withoutAt)),
  },
];

const visualizeBool = (value) =>
  value !== null &&
  value !== undefined &&
  (value ? (
    <CheckCircleOutlined className={styles.checkCircleOutlined} />
  ) : (
    <CloseCircleOutlined className={styles.closeCircleOutlined} />
  ));

const LandingTab = (props) => {
  const {
    pad,
    warnings,
    facilities,
    tags,
    landingFees,
    padOnChange,
    ...rest
  } = props;

  const [SON, setSON] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [form] = Form.useForm();
  const { setApiErrorsToAntdForm } = useApiErrorsWithAntd(form);
  const globalErrors = form.getFieldError('global');
  const dispatch = useDispatch();
  const { loadingBarEnabled } = useSelector((state) => ({
    loadingBarEnabled: state.loadingBar.enabled,
  }));

  useEffect(() => {
    if (pad?.is_landing_advice_displayed) {
      setSON('Landing Instructions');
    } else {
      setSON('Landing Advice');
    }
  }, [pad?.is_landing_advice_displayed]);

  const enableEditableMode = useCallback(() => {
    form.resetFields();
    setEditMode(true);
  }, [form]);

  const disableEditableMode = useCallback(() => {
    form.resetFields();
    setEditMode(false);
  }, [form]);

  const onSubmit = useCallback(
    (values) => {
      const data = {
        ...values,
        tab: 'landing',
      };

      if (values['pad_social#trip_advisor_rating'])
        data[
          'pad_social#trip_advisor_rating'
        ] = `${values['pad_social#trip_advisor_rating']}`;
      if (values['pad_social#twitter_handle'])
        data[
          'pad_social#twitter_handle'
        ] = `@${values['pad_social#twitter_handle']}`;

      loadingBarEnable()(dispatch);

      if (pad?.id) {
        api.pad
          .update(pad?.id, objectRemoveEmpty(data))
          .then((res) => {
            loadingBarDisable()(dispatch);
            setEditMode(false);
            padOnChange(res.data);
          })
          .catch((err) => {
            loadingBarDisable()(dispatch);
            setApiErrorsToAntdForm(err);
          });
      }
    },
    [pad?.id, dispatch, setApiErrorsToAntdForm, padOnChange],
  );

  return (
    <div {...rest}>
      <Spin spinning={!pad}>
        {!!globalErrors.length && (
          <Alert
            message="Error"
            description={globalErrors}
            type="error"
            showIcon
          />
        )}
        <Tabs
          defaultActiveKey="pad-landing-tab"
          tabPosition="left"
          size="small"
        >
          <Tabs.TabPane tab="Landing Information" key="pad-landing-tab">
            <Form form={form} onFinish={onSubmit} autoComplete="off">
              <div className="text-right mb-2">
                <Space>
                  {editMode ? (
                    <>
                      <Button.Group>
                        <Button htmlType="button" onClick={disableEditableMode}>
                          Cancel
                        </Button>
                        <Button
                          type="primary"
                          htmlType="submit"
                          disabled={loadingBarEnabled}
                        >
                          Save changes
                        </Button>
                      </Button.Group>
                    </>
                  ) : (
                    <Button
                      htmlType="button"
                      disabled={!!pad?.deleted_at}
                      onClick={enableEditableMode}
                    >
                      Edit pad
                    </Button>
                  )}
                </Space>
              </div>
              <Descriptions
                labelStyle={{ width: 150 }}
                size="small"
                bordered
                column={1}
              >
                {pad && (
                  <Descriptions.Item label="Site Description">
                    {editMode ? (
                      <Form.Item
                        name="site_information"
                        initialValue={pad?.site_information}
                      >
                        <Input.TextArea
                          placeholder="Do NOT include technical landing information here. This is only for interesting or offering information."
                          autoSize={{ minRows: 4 }}
                          showCount
                        />
                      </Form.Item>
                    ) : (
                      <Form.Item
                        name="site_information"
                        initialValue={pad?.site_information}
                        className="mb-0"
                      >
                        <Input.TextArea
                          readOnly
                          autoSize
                          bordered={false}
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        />
                      </Form.Item>
                    )}
                  </Descriptions.Item>
                )}
                {pad && (
                  <Descriptions.Item label={SON}>
                    {editMode ? (
                      <Form.Item
                        name="landing_advice"
                        initialValue={pad?.landing_advice}
                      >
                        <Input.TextArea
                          placeholder="ONLY technical landing information here."
                          autoSize={{ minRows: 4 }}
                        />
                      </Form.Item>
                    ) : (
                      <Form.Item
                        className="mb-0"
                        name="landing_advice"
                        initialValue={pad?.landing_advice}
                      >
                        <Input.TextArea
                          readOnly
                          autoSize
                          bordered={false}
                          className="mb-0"
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        />
                      </Form.Item>
                    )}
                  </Descriptions.Item>
                )}
                {pad?.category_id !== 1 &&
                  (pad?.category_id !== 7 ? (
                    <Descriptions.Item
                      label={
                        <>
                          <div>Establishment Warnings</div>
                        </>
                      }
                    >
                      {editMode ? (
                        <Form.Item
                          {...formItemVerticalLayout}
                          label="Click on list item to select"
                          name="warnings"
                          style={{ maxWidth: 400 }}
                          initialValue={pad?.warnings.map((itm) => itm.id)}
                        >
                          <CheckableTagList
                            options={warnings}
                            style={{ margin: '0 -7px', display: 'block' }}
                          />
                        </Form.Item>
                      ) : (
                        <div style={{ maxWidth: 400 }}>
                          {pad?.warnings?.map((itm) => (
                            <Tag key={`warning-${itm.id}`}>
                              {itm.display_name}
                            </Tag>
                          ))}
                        </div>
                      )}
                    </Descriptions.Item>
                  ) : (
                    <Descriptions.Item
                      label={
                        <>
                          <div>Airfield facilities</div>
                        </>
                      }
                    >
                      {editMode ? (
                        <>
                          <Form.Item
                            {...formItemVerticalLayout}
                            label="Click on list item to select"
                            name="facilities"
                            style={{ maxWidth: 400 }}
                            initialValue={pad?.facilities.map((itm) => itm.id)}
                          >
                            <CheckableTagList
                              options={facilities}
                              style={{ margin: '0 -7px', display: 'block' }}
                            />
                          </Form.Item>
                          <Space align="baseline">
                            <Form.Item
                              {...formItemVerticalLayout}
                              label="Landing fees category"
                              name="landing_fees"
                              style={{ width: 200 }}
                              initialValue={
                                pad?.landing_fees?.toString() === '0'
                                  ? null
                                  : pad?.landing_fees?.toString()
                              }
                            >
                              <Select
                                placeholder="Choose"
                                options={landingFees}
                              />
                            </Form.Item>
                            <Form.Item
                              {...formItemVerticalLayout}
                              label="ICAO Code"
                              name="icao"
                              style={{ width: 200 }}
                              initialValue={pad?.icao}
                            >
                              <IcaoInput style={{ width: 65 }} />
                            </Form.Item>
                          </Space>
                        </>
                      ) : (
                        <>
                          <div className="mb-2" style={{ maxWidth: 400 }}>
                            {pad?.facilities?.map((itm) => (
                              <Tag key={`facility-${itm.id}`}>
                                {itm.display_name}
                              </Tag>
                            ))}
                          </div>
                          <div>
                            Landing fees category:{' '}
                            {
                              landingFees.find(
                                (itm) =>
                                  itm.value === pad?.landing_fees?.toString(),
                              )?.label
                            }
                            <br />
                            ICAO Code: {pad?.icao}
                          </div>
                        </>
                      )}
                    </Descriptions.Item>
                  ))}
                <Descriptions.Item label="Social">
                  {editMode ? (
                    <div style={{ maxWidth: 400 }}>
                      <Form.Item
                        name="pad_social#website"
                        label="Website"
                        className="mb-0"
                        initialValue={pad?.pad_social?.website}
                      >
                        <Input size="small" placeholder="https://website.com" />
                      </Form.Item>
                      <Form.Item
                        name="pad_social#twitter_handle"
                        label="Twitter handle"
                        rules={twitterUsernameHandle}
                        className="mb-0"
                        initialValue={pad?.pad_social?.twitter_handle?.replace(
                          '@',
                          '',
                        )}
                      >
                        <Input size="small" prefix="@" placeholder="username" />
                      </Form.Item>
                      <Form.Item
                        label="Tags"
                        name="tags"
                        help={'To create new one just type and press "Enter"'}
                        className="mb-0"
                        initialValue={pad?.tags.map((itm) => itm.name)}
                      >
                        <Select
                          size="small"
                          mode="tags"
                          placeholder="Select existing or create"
                          options={tags}
                        />
                      </Form.Item>
                    </div>
                  ) : (
                    <>
                      Website:{' '}
                      <a
                        href={generateExternalLink(pad?.pad_social?.website)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {pad?.pad_social?.website}
                      </a>
                      <br />
                      Twitter handle: {pad?.pad_social?.twitter_handle} <br />
                      <br />
                      <Space align="start">
                        Tags:
                        <div style={{ maxWidth: 300 }}>
                          {pad?.tags?.map((itm) => (
                            <Tag key={`tag-${itm.id}`}>{itm.name}</Tag>
                          ))}
                        </div>
                      </Space>
                    </>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Access">
                  <table style={{ maxWidth: 320 }}>
                    {editMode ? (
                      <tbody>
                        <tr>
                          <td>API Access:</td>
                          <td>
                            <Form.Item
                              name="is_api_accessible"
                              className="mb-0"
                              valuePropName="checked"
                              initialValue={pad?.is_api_accessible}
                            >
                              <Checkbox />
                            </Form.Item>
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td>API Access:</td>
                          <td>{visualizeBool(pad?.is_api_accessible)}</td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </Descriptions.Item>
              </Descriptions>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <Tooltip
                title={!pad?.is_owned ? 'This Pad must be owned' : null}
                placement="right"
              >
                Survey Information
              </Tooltip>
            }
            disabled={!pad?.id || !pad?.is_owned}
            key="pad-survey-tab"
          >
            {pad?.id && <PadSurveyDetails padId={pad.id} />}
          </Tabs.TabPane>
        </Tabs>
      </Spin>
    </div>
  );
};

LandingTab.defaultProps = {
  pad: undefined,
  warnings: [],
  facilities: [],
  tags: [],
  landingFees: [],
  padOnChange: () => null,
};

LandingTab.propTypes = {
  pad: PropTypes.shape({
    id: PropTypes.number,
    landing_fees: PropTypes.number,
    deleted_at: PropTypes.string,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
    warnings: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        display_name: PropTypes.string,
      }),
    ),
    facilities: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        display_name: PropTypes.string,
      }),
    ),
    is_owned: PropTypes.bool,
    is_api_accessible: PropTypes.bool,
    is_landing_permitted: PropTypes.bool,
    pad_social: PropTypes.shape({
      website: PropTypes.string,
      twitter_handle: PropTypes.string,
      trip_advisor_level: PropTypes.string,
      trip_advisor_rating: PropTypes.string,
      trip_advisor_web_url: PropTypes.string,
    }),
    site_information: PropTypes.string,
    landing_advice: PropTypes.string,
    is_landing_advice_displayed: PropTypes.bool,
    category_id: PropTypes.number,
    landingFees: PropTypes.string,
    icao: PropTypes.string,
  }),
  warnings: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  facilities: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  landingFees: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  padOnChange: PropTypes.func,
};

export default LandingTab;
