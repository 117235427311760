import InitialReportPage from './initialReportPage';

const reportPageProps = {
  sessionStorageName: 'log-reports',
  pageTitle: 'Log Reports',
  stateName: 'logReports',
  actionName: 'getLogReports',
  default_sort_column: 'user_id',
};

const LogReport = () => <InitialReportPage reportPageProps={reportPageProps} />;

export default LogReport;
