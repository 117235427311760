import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Input from '../../../../../uiKitComponents/input';

const EditNote = (props) => {
  const {
    value,
    onChange,
    onBlur,
    // onEditFinish,
    setTempValue,
    ...rest
  } = props;

  const [tempLocalValue, setTempLocalValue] = useState(value);

  const textareaChangeHandle = useCallback(
    (e) => {
      setTempLocalValue(e.target.value);
      onChange(e);
      setTempValue(e.target.value);
    },
    [onChange, setTempValue],
  );

  return (
    <Input.TextArea
      placeholder="Write note..."
      autoSize
      {...rest}
      value={tempLocalValue}
      onChange={textareaChangeHandle}
    />
  );
};

EditNote.defaultProps = {
  value: undefined,
  onChange: () => null,
  onBlur: () => null,
  // onEditFinish: () => null,
  setTempValue: () => null,
};

EditNote.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  // onEditFinish: PropTypes.func,
  setTempValue: PropTypes.func,
};

export default EditNote;
