import { findPhoneNumbersInText } from 'libphonenumber-js';
import linkifyHtml from 'linkifyjs/html';
import parse from 'html-react-parser';
import { regex } from './constants';
import pipe from './pipe';

const linkifyDefaultOptions = {
  attributes: {
    rel: 'noopener noreferrer',
  },
  target: {
    url: '_blank',
  },
};

const replacePhone = (text) => {
  let replacedText = text;

  findPhoneNumbersInText(replacedText).forEach(
    ({ startsAt, endsAt, number: { number } }) => {
      replacedText = replacedText.replace(
        replacedText.slice(startsAt, endsAt),
        `<a href="tel:${number}">${number}</a>`,
      );
    },
  );

  return replacedText;
};

const replaceLineBreaks = (text) =>
  text.replace(regex.breakLine, (match) => `${match} <br />`);

/**
 * Formatting string value.
 *
 * options.replaceBreakLines: false - By example you want to format text with white-space: pre or pre-wrap.
 *
 * @param {string} text
 * @param {object} options
 * @param {boolean} options.replaceBreakLines true for replacing "\n" with "<br />"
 * @param {object} options.linkifyOptions
 * @returns {string} HtmlString
 *
 */
export const formatTextToHtmlString = (text, options = {}) => {
  const opts = {
    replaceBreakLines: true,
    linkifyOptions: {},
    ...options,
  };

  return pipe(
    (x) => (opts.replaceBreakLines ? replaceLineBreaks(x) : x),
    replacePhone,
    (x) =>
      linkifyHtml(x, {
        ...linkifyDefaultOptions,
        ...opts.linkifyOptions,
      }),
  )(text);
};

/**
 * Formatting string value and converting to jsx object.
 *
 * options.replaceBreakLines: false - By example you want to format text with white-space: pre or pre-wrap.
 *
 * @param {string} text
 * @param {object} options
 * @param {boolean} options.replaceBreakLines
 * @param {object} options.linkifyOptions
 * @returns {ReturnType<typeof domToReact>}
 */
export const formatTextToJsx = (text, options) =>
  pipe((x) => formatTextToHtmlString(x, options), parse)(text);
