import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Select from '../../../../uiKitComponents/select';
import Descriptions from '../../../../uiKitComponents/descriptions';
import Spin from '../../../../uiKitComponents/spin';
import Space from '../../../../uiKitComponents/space';
import Button from '../../../../uiKitComponents/button';
import { dateFormats, userType } from '../../../../utils/constants';
import Form from '../../../../uiKitComponents/form';
import Input from '../../../../uiKitComponents/input';
import PhoneInput from '../../../../components/phoneInput';
import api from '../../../../utils/appApi';
import Alert from '../../../../uiKitComponents/alert';
import useApiErrorsWithAntd from '../../../../hooks/useApiErrorsWithAntd';
import DatePicker from '../../../../uiKitComponents/datePicker';

import {
  loadingBarDisable,
  loadingBarEnable,
} from '../../../../store/actions/loadingBarAction';
import objectRemoveEmpty from '../../../../utils/objectRemoveEmpty';
import {
  selectCountriesOptions,
  selectUSStatesOptions,
} from '../../../../store/selectors/bootstrapSelector';
import {
  getCampaignNameById,
  selectCampaignsOptions,
} from '../../../../store/selectors/campaignsSelector';
import phoneWithPlus from '../../../../utils/phoneWithPlus';
import Tag from '../../../../uiKitComponents/tag';
import ArchiveAction from './ArchiveAction';

const InfoTab = (props) => {
  const { info, editMode, setEditMode, infoOnChange, userTags } = props;

  const { loadingBarEnabled, countries, USStates, campaigns } = useSelector(
    (state) => ({
      loadingBarEnabled: state.loadingBar.enabled,
      countries: selectCountriesOptions(state),
      USStates: selectUSStatesOptions(state),
      campaigns: selectCampaignsOptions(state),
    }),
  );
  const [isUSSelected, setIsUSSelected] = useState(false);
  const [form] = Form.useForm();
  const { setApiErrorsToAntdForm } = useApiErrorsWithAntd(form);
  const globalErrors = form.getFieldError('global');
  const dispatch = useDispatch();

  const disableEditableMode = useCallback(() => {
    form.resetFields();
    setEditMode(false);
  }, [form, setEditMode]);

  const enableEditableMode = useCallback(() => {
    form.resetFields();
    setEditMode(true);

    if (info.location?.country === 'US') setIsUSSelected(true);
  }, [form, info?.location, setEditMode]);

  const onSubmit = useCallback(
    (values) => {
      const data = {
        ...values,
        tab: 'info',
        phone: phoneWithPlus(values.phone),
        renewal_date: dayjs(values.renewal_date?.$d).format('YYYY-MM-DD HH:mm'),
      };

      loadingBarEnable()(dispatch);

      if (info?.id) {
        api.users
          .update(info?.id, objectRemoveEmpty(data))
          .then((res) => {
            loadingBarDisable()(dispatch);
            setEditMode(false);
            infoOnChange(res.data);
          })
          .catch((err) => {
            loadingBarDisable()(dispatch);
            setApiErrorsToAntdForm(err);
          });
      }
    },
    [info?.id, dispatch, setApiErrorsToAntdForm, setEditMode, infoOnChange],
  );

  const onCountryChange = (country) => {
    if (country === 'US') setIsUSSelected(true);
    else setIsUSSelected(false);
  };

  const getUserExpiryText = useCallback(() => {
    const expiryText = [];

    if (info?.renewal_date) {
      expiryText.push(dayjs(info?.renewal_date).format(dateFormats[1]));
    }

    if (info?.campaign_id) {
      expiryText.push(
        getCampaignNameById(info?.campaign_id, campaigns, 'value', 'label'),
      );
    }

    return expiryText.join(' | ');
  }, [campaigns, info?.campaign_id, info?.renewal_date]);

  const getUserPremiumStatus = useCallback(() => {
    if (info?.is_premium) {
      if (info?.is_fremium) return 'Freemium';
      return 'Premium';
    }

    return 'Non-premium';
  }, [info?.is_fremium, info?.is_premium]);

  return (
    <div>
      <Spin spinning={!info}>
        {!!globalErrors.length && (
          <Alert
            message="Error"
            description={globalErrors}
            type="error"
            showIcon
            className="mb-3"
          />
        )}
        <Form form={form} onFinish={onSubmit} autoComplete="off">
          <div className="text-right mb-3">
            <Form.Item hidden name="global">
              <Input />
            </Form.Item>
            <Space className="d-flex justify-content-end">
              {editMode && (
                <>
                  <span>Editing</span>
                  <Button.Group>
                    <Button htmlType="button" onClick={disableEditableMode}>
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={loadingBarEnabled}
                    >
                      Save changes
                    </Button>
                  </Button.Group>
                </>
              )}
              {!editMode && !info?.is_deleted && (
                <Button
                  htmlType="button"
                  type="primary"
                  onClick={enableEditableMode}
                >
                  Edit
                </Button>
              )}
            </Space>
          </div>
          <Descriptions
            labelStyle={{ width: 170 }}
            size="small"
            bordered
            column={1}
          >
            <Descriptions.Item label="First Name">
              {editMode ? (
                <Form.Item
                  name="first_name"
                  initialValue={info?.first_name}
                  className="mb-0"
                >
                  <Input
                    style={{ maxWidth: 200 }}
                    size="small"
                    placeholder="First Name"
                  />
                </Form.Item>
              ) : (
                `${info?.first_name}`
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Last Name">
              {editMode ? (
                <Form.Item
                  name="last_name"
                  initialValue={info?.last_name}
                  className="mb-0"
                >
                  <Input
                    style={{ maxWidth: 200 }}
                    size="small"
                    placeholder="Last Name"
                  />
                </Form.Item>
              ) : (
                `${info?.last_name}`
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Username">
              {editMode ? (
                <Form.Item
                  name="username"
                  initialValue={info?.username}
                  className="mb-0"
                >
                  <Input
                    style={{ maxWidth: 200 }}
                    size="small"
                    placeholder="Username"
                  />
                </Form.Item>
              ) : (
                `${info?.username}`
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Country/State">
              {editMode ? (
                <>
                  <Form.Item
                    name={['location', 'country_code']}
                    className="mb-0"
                    initialValue={info.location?.country}
                    rules={[
                      {
                        required: info.location?.country,
                        message: 'Please select country',
                      },
                    ]}
                  >
                    <Select
                      size="small"
                      placeholder="Country"
                      style={{ maxWidth: 200 }}
                      options={countries}
                      optionFilterProp="label"
                      onSelect={onCountryChange}
                      showSearch
                    />
                  </Form.Item>
                  {isUSSelected && (
                    <Form.Item
                      name={['location', 'state_code']}
                      className="mb-0"
                      initialValue={info.location?.county}
                      rules={[
                        {
                          required: true,
                          message: 'Please select state',
                        },
                      ]}
                    >
                      <Select
                        size="small"
                        placeholder="State"
                        style={{ maxWidth: 200 }}
                        options={USStates}
                        optionFilterProp="label"
                        showSearch
                      />
                    </Form.Item>
                  )}
                </>
              ) : (
                `${info?.location?.name || '-'}`
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              {editMode ? (
                <Form.Item
                  name="email"
                  initialValue={info?.email}
                  className="mb-0"
                >
                  <Input
                    style={{ maxWidth: 200 }}
                    size="small"
                    placeholder="Email"
                  />
                </Form.Item>
              ) : (
                `${info?.email}`
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Email Confirmed?">
              {info?.email_verified_at
                ? dayjs(info?.email_verified_at).format(dateFormats[0])
                : 'False'}
            </Descriptions.Item>
            <Descriptions.Item label="Expiry Date">
              {editMode ? (
                <div className="d-flex">
                  <Form.Item
                    name="renewal_date"
                    className="mb-0 mr-2"
                    initialValue={
                      info?.renewal_date &&
                      dayjs(info?.renewal_date, 'YYYY-MM-DD HH:mm:ss')
                    }
                  >
                    <DatePicker size="small" />
                  </Form.Item>
                  <Form.Item
                    name="campaign_id"
                    className="mb-0"
                    initialValue={info?.campaign_id}
                  >
                    <Select
                      size="small"
                      options={campaigns}
                      placeholder="Select a campaign"
                      style={{ minWidth: 180 }}
                    />
                  </Form.Item>
                </div>
              ) : (
                getUserExpiryText()
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Phone">
              {editMode ? (
                <Form.Item
                  label="Phone"
                  name="phone"
                  wrapperCol={{ span: 24 }}
                  className="mb-0"
                  fieldKey={[info?.phone, 'phone']}
                  initialValue={info?.phone}
                >
                  <PhoneInput
                    size="small"
                    value={info?.phone}
                    style={{ maxWidth: 250 }}
                    placeholder="Phone number"
                  />
                </Form.Item>
              ) : (
                info?.phone
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Premium Status">
              {getUserPremiumStatus()}
            </Descriptions.Item>
            <Descriptions.Item label="Archived?">
              {info?.is_active ? 'No' : 'Yes'}
            </Descriptions.Item>
            <Descriptions.Item label="Role">
              {info?.role === 0
                ? 'Undefined'
                : userType.map(
                    (role) => role.value === info?.role && role.label,
                  )}
            </Descriptions.Item>
            <Descriptions.Item label="Tags">
              {editMode ? (
                <div style={{ maxWidth: 400 }}>
                  <Form.Item
                    name="tags"
                    help={'To create new one just type and press "Enter"'}
                    className="mb-0"
                    initialValue={info?.tags.map((itm) => itm.name)}
                  >
                    <Select
                      size="small"
                      mode="tags"
                      placeholder="Select existing or create"
                      options={userTags.userTags}
                    />
                  </Form.Item>
                </div>
              ) : (
                <Space align="start">
                  <div>
                    {info?.tags?.map((itm) => (
                      <Tag key={`tag-${itm.id}`}>{itm.name}</Tag>
                    ))}
                  </div>
                </Space>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Last Active">
              {dayjs(info?.last_action_at).format(dateFormats[0])}
            </Descriptions.Item>
            {!info?.role && (
              <Descriptions.Item
                label={`Last Onboarding Screen (${
                  info?.registration_device || 'n/a'
                })`}
              >
                {info?.registration_step || 'n/a'}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Created at">
              {dayjs(info?.created_at).format(dateFormats[0])}
            </Descriptions.Item>
            <Descriptions.Item label="Updated at">
              {dayjs(info?.updated_at).format(dateFormats[0])}
            </Descriptions.Item>
          </Descriptions>
          {/* {(!(info?.role === 3 && !info?.is_active) && !info?.is_deleted) ? ( */}
          <div className="mt-3 text-right">
            <Space align="center">
              <ArchiveAction info={info} infoOnChange={infoOnChange} />
            </Space>
          </div>
          {/* ) : null} */}
        </Form>
      </Spin>
    </div>
  );
};

InfoTab.defaultProps = {
  info: undefined,
  editMode: false,
  setEditMode: () => null,
  infoOnChange: () => null,
  userTags: [],
};

InfoTab.propTypes = {
  info: PropTypes.shape({
    id: PropTypes.number,
    is_deleted: PropTypes.bool,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    username: PropTypes.string,
    role: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    email_verified_at: PropTypes.string,
    phone: PropTypes.string,
    is_premium: PropTypes.bool,
    is_fremium: PropTypes.bool,
    campaign_id: PropTypes.number,
    renewal_date: PropTypes.string,
    is_active: PropTypes.bool,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    last_action_at: PropTypes.string,
    location: PropTypes.shape({
      name: PropTypes.string,
      country: PropTypes.string,
      county: PropTypes.string,
    }),
    registration_device: PropTypes.string,
    registration_step: PropTypes.string,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
  }),
  editMode: PropTypes.bool,
  setEditMode: PropTypes.func,
  infoOnChange: PropTypes.func,
  userTags: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
};

export default InfoTab;
