import PropTypes from 'prop-types';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Popconfirm } from 'antd';
import { useCallback, useContext, useState } from 'react';
import DataRowContext from '../DataRowContext';
import api from '../../../utils/appApi';

export default function DataRowControllerContact(props) {
  const { name, padId, onContactRemove } = props;
  const { label, checkedSide } = useContext(DataRowContext);
  const [loading, setLoading] = useState(false);

  const removeContact = useCallback(
    (contact, clbk) => () => {
      setLoading(true);

      if (contact.removed) {
        onContactRemove(checkedSide, contact.id);
        setLoading(false);
      } else {
        api.pad
          .deleteOwner(padId[checkedSide], contact.id, contact.contact_type_id)
          .then(() => {
            clbk();
            onContactRemove(checkedSide, contact.id);
            return Promise.resolve();
          })
          .catch(() => Promise.reject())
          .finally(() => setLoading(false));
      }
    },
    [checkedSide, onContactRemove, padId],
  );

  return (
    <Form.Item shouldUpdate>
      {({ getFieldValue }) => {
        const contacts = getFieldValue(name);

        return (
          <Form.List name={name}>
            {(fields, { remove }) => (
              <div className="data-row-contacts">
                <div className="contacts-label">
                  <span>{label}</span>
                  {loading && (
                    <span className="ml-2">
                      <LoadingOutlined />
                    </span>
                  )}
                </div>
                {fields.map((field) => (
                  <div className="row-contacts-item" key={field.key}>
                    <li>
                      <span className="label">Name:</span>
                      <span className="value">
                        {contacts[field.name]?.name}
                      </span>
                    </li>
                    <li>
                      <span className="label">Email:</span>
                      <span className="value">
                        {contacts[field.name]?.email}
                      </span>
                    </li>
                    <li>
                      <span className="label">Phone:</span>
                      <span className="value">
                        {contacts[field.name]?.phone}
                      </span>
                    </li>
                    {contacts[field.name]?.is_primary && (
                      <li>
                        <span className="label">
                          <b>Primary Contact</b>
                        </span>
                      </li>
                    )}
                    <Popconfirm
                      key="reject-owner-btn"
                      placement="topRight"
                      title="Are you sure you want to delete this contact?"
                      onConfirm={removeContact(contacts[field.name], () =>
                        remove(field.name),
                      )}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        htmlType="button"
                        type="secondary"
                        size="small"
                        danger
                        style={{ zIndex: 1 }}
                        className="row-contacts-remove"
                        icon={<DeleteOutlined />}
                      />
                    </Popconfirm>
                  </div>
                ))}
              </div>
            )}
          </Form.List>
        );
      }}
    </Form.Item>
  );
}

DataRowControllerContact.propTypes = {
  name: PropTypes.string,
  contacts: PropTypes.object,
  padId: PropTypes.object.isRequired,
  onContactRemove: PropTypes.func.isRequired,
};

DataRowControllerContact.defaultProps = {
  name: '',
  contacts: {},
};
