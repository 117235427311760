import React from 'react';
import { Form, Space } from 'antd';
import debounce from 'lodash.debounce';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import {
  PadArchiveActions,
  PadExternalLink,
  PadModerateActions,
  PadUserModeration,
} from './parts';
import ContactTabRead from './ContactTabRead';
import ContactTabEdit from './ContactTabEdit';
import PadViewContext from '../../PadView.context';
import { EditActions } from '../EditActions';
import { selectRegionOptions } from '../../../../store/selectors/bootstrapSelector';
import {
  loadingBarDisable,
  loadingBarEnable,
} from '../../../../store/actions/loadingBarAction';
import api from '../../../../utils/appApi';
import phoneWithPlus from '../../../../utils/phoneWithPlus';
import useApiErrorsWithAntd from '../../../../hooks/useApiErrorsWithAntd';
import { alertOnSave } from './ContactTab.utils';
import getApiErrorMessages from '../../../../utils/getApiErrorMessages';

function ContactTab() {
  const {
    pad,
    updatePad,
    setMapDragPinActive,
    mapDragPinActive,
    location,
    setLocation,
    padViewState,
  } = React.useContext(PadViewContext);
  const [isEditingContactTab, setIsEditingContactTab] = React.useState(
    padViewState.edit_active === 'contact',
  );
  const { regions } = useSelector((store) => ({
    regions: selectRegionOptions(store),
  }));
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const { setApiErrorsToAntdForm } = useApiErrorsWithAntd(form);
  const [isChangedOnMap, setIsChangedOnMap] = React.useState(false);
  const [oldLocation, setOldLocation] = React.useState(null);
  const [anotherSelected, setAnotherSelected] = React.useState(false);

  useMount(() => {
    setOldLocation(pad?.location);
    if (pad?.location?.is_address_split) {
      setIsChangedOnMap(true);
    }
  });

  React.useEffect(() => {
    setMapDragPinActive(isEditingContactTab);
  }, [isEditingContactTab, setMapDragPinActive]);

  React.useEffect(() => {
    if (
      mapDragPinActive &&
      (oldLocation?.latitude !== location?.latitude ||
        oldLocation?.longitude !== location?.longitude)
    ) {
      setIsChangedOnMap(true);
    }
  }, [
    mapDragPinActive,
    oldLocation,
    location,
    pad?.location?.is_address_split,
    isChangedOnMap,
  ]);

  React.useEffect(() => {
    if (anotherSelected) {
      setIsChangedOnMap(false);
      setAnotherSelected(false);
    }
  }, [anotherSelected, setIsChangedOnMap]);

  React.useEffect(() => {
    if (isChangedOnMap && anotherSelected) {
      setOldLocation((prev) => ({ ...prev, ...location }));
      setIsChangedOnMap(false);
    }
  }, [location, isChangedOnMap, anotherSelected]);

  const onContactTabSubmit = React.useCallback(
    (values) => {
      const data = { ...form.getFieldsValue(), tab: 'contact' };

      data['location#name'] = data['location#name']?.address;
      data['location#country'] = data['location#name']?.country;
      data['location#county'] = data['location#name']?.county;
      data['location#region'] =
        regions.find((r) => r.value === data['location#region'])?.label ||
        data['location#region'];
      data.contacts = data.editable_contacts.map((c) => ({
        ...c,
        phone: phoneWithPlus(c.phone),
      }));

      data['location#is_address_split'] = isChangedOnMap;

      // Update contacts primary statuses
      for (let i = 0; i < data.contacts.length; i++) {
        const padContact = pad.editable_contacts.find(
          (c) => c.id === data.contacts[i].id,
        );

        if (padContact) {
          data.contacts[i].is_primary = padContact.is_primary;
        }
      }

      delete data.editable_contacts;

      dispatch(loadingBarEnable());

      api.pad
        .update(pad?.id, data)
        .then((res) => {
          const updatedPadValues = {
            ...res.data.data,
            editable_contacts: res.data.data.contacts.filter((c) =>
              [1, 4].includes(c?.contact_type_id),
            ),
          };

          form.setFieldsValue(updatedPadValues);
          setIsEditingContactTab(false);
          updatePad(updatedPadValues);
          alertOnSave(res.data);
          dispatch(loadingBarDisable());
        })
        .catch((err) => {
          const errors = getApiErrorMessages(err);

          if (Array.isArray(errors)) {
            errors.forEach(([name, msgs]) => {
              if (name.includes('contacts')) {
                form.setFields([
                  {
                    name: name.split('.').map((i) => {
                      if (i === 'contacts') return 'editable_contacts';
                      if (i === '0') return 0;

                      return +i || i;
                    }),
                    errors: msgs,
                  },
                ]);
              }
            });
          }
          dispatch(loadingBarDisable());
          setApiErrorsToAntdForm(err);
        });
    },

    [
      regions,
      dispatch,
      pad?.id,
      updatePad,
      setApiErrorsToAntdForm,
      form,
      pad?.editable_contacts,
      isChangedOnMap,
    ],
  );

  const debouncedLocationInput = debounce((key, value, limit) => {
    if (value >= -limit && value <= limit) {
      setLocation((prevState) => ({
        ...prevState,
        [key]: value,
        getGeoCode: true,
      }));
    }
  }, 300);

  const onFormChange = React.useCallback(
    (changedValue) => {
      const {
        // 'location#name': locationName,
        'location#latitude': latitude,
        'location#longitude': longitude,
      } = changedValue;

      // !!! This part is commented out because it is not used in the code but it is the part of logic that can be useful in the future
      // if (locationName) {
      //   setLocation(locationName);
      //   form.setFields([
      //     { name: 'location#latitude', value: locationName.latitude },
      //     { name: 'location#longitude', value: locationName.longitude },
      //   ]);
      // }

      if (latitude) debouncedLocationInput('latitude', latitude, 90);
      if (longitude) debouncedLocationInput('longitude', longitude, 180);
    },
    [debouncedLocationInput],
  );

  const onFormFinish = () => {
    if (isChangedOnMap) {
      onContactTabSubmit();
    } else {
      onContactTabSubmit();
    }
  };

  return (
    <Form
      onFinish={onFormFinish}
      onValuesChange={onFormChange}
      form={form}
      initialValues={pad}
    >
      <div className="d-flex align-items-center justify-content-between mb-2">
        <PadModerateActions />
        <EditActions
          isEditing={isEditingContactTab}
          setIsEditing={setIsEditingContactTab}
        />
      </div>
      {isEditingContactTab ? (
        <ContactTabEdit
          form={form}
          setIsChangedOnMap={setIsChangedOnMap}
          setAnotherSelected={setAnotherSelected}
        />
      ) : (
        <ContactTabRead updateFormValues={form.setFields} />
      )}
      <div className="mt-2 d-flex align-items-center justify-content-between">
        <Space align="center">
          {pad?.category_id !== 1 && <PadUserModeration />}
          <PadExternalLink />
        </Space>
        <Space align="center">
          <PadArchiveActions />
        </Space>
      </div>
    </Form>
  );
}

export { ContactTab };
