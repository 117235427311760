import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Popconfirm, Popover, notification } from 'antd';
import api from '../../../../utils/appApi';
import getApiErrorMessages from '../../../../utils/getApiErrorMessages';

const ArchiveAction = (props) => {
  const { info, infoOnChange } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleArchivePad = (data) => {
    setLoading(true);
    api.users
      .archive(info?.id, data)
      .then((res) => {
        infoOnChange({ ...info, ...res.data });
        setVisible(false);
      })
      .catch((error) =>
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(error),
        }),
      )
      .finally(() => {
        setLoading(false);
        form.resetFields();
      });
  };

  const onFinish = (values) => {
    handleArchivePad(values);
  };

  const cancelArchivePad = () => {
    setVisible(false);
    form.resetFields();
  };

  const ArchiveBtnContent = (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Form.Item
        name="note"
        label="Reason"
        className="mb-2"
        rules={[
          {
            required: true,
            message: 'Please provide reason',
          },
          {
            min: 3,
            message: 'At least 3 characters',
          },
        ]}
      >
        <Input.TextArea autoSize style={{ minHeight: 50 }} />
      </Form.Item>
      <Button.Group size="small" className="d-flex justify-content-end">
        <Button htmlType="button" onClick={cancelArchivePad}>
          Cancel
        </Button>
        <Button type="primary" onClick={() => form.submit()} loading={loading}>
          Submit
        </Button>
      </Button.Group>
    </Form>
  );

  return (
    <>
      {info?.is_active ? (
        <Popover
          key="archive-user"
          content={ArchiveBtnContent}
          visible={visible}
          trigger="click"
          placement="topRight"
        >
          <Button htmlType="button" onClick={() => setVisible(true)}>
            {info?.is_active ? 'Archive' : 'Unarchive'}
          </Button>
        </Popover>
      ) : (
        <Popconfirm
          title={`Are you sure you want to ${
            info?.is_active ? 'archive' : 'unarchive'
          } this user?`}
          onConfirm={() => handleArchivePad({ note: undefined })}
          placement="topRight"
        >
          <Button htmlType="button">
            {info?.is_active ? 'Archive' : 'Unarchive'}
          </Button>
        </Popconfirm>
      )}
    </>
  );
};

export default ArchiveAction;

ArchiveAction.propTypes = {
  info: PropTypes.object.isRequired,
  infoOnChange: PropTypes.func.isRequired,
};
