import { useState, useCallback, useContext, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Map as GoogleMap, Marker } from 'google-maps-react';
import GoogleMapsContext from '../../context/googleMapsContext';
import { UKLocation } from '../../utils/constants';

const MapRoot = memo((props) => {
  const {
    location,
    locationOnDragend,
    map: mapProp,
    readOnly,
    onClick,
    onReady,
    mapTypeId,
    ...rest
  } = props;
  const [localMap, setLocalMap] = useState();
  const map = mapProp || localMap;

  // Set location marker
  useEffect(() => {
    if (location && map) {
      if (!location.withoutZoom) map.setZoom(17);
      if (!location.withoutPan) map.panTo(location);
    }
  }, [location, map]);

  // Set mapTypeId
  useEffect(() => {
    if (mapTypeId && map) map.setMapTypeId(mapTypeId);
  }, [mapTypeId, map]);

  const onClickHandle = useCallback(
    (...args) => (readOnly ? null : onClick(...args)),
    [readOnly, onClick],
  );

  const onReadyHandle = useCallback(
    (mapProps, _map) => (onReady ? onReady(mapProps, _map) : setLocalMap(_map)),
    [onReady],
  );

  return (
    <GoogleMap
      minZoom={5}
      zoom={7}
      clickableIcons
      initialCenter={UKLocation}
      {...rest}
      google={window.google}
      onClick={onClickHandle}
      onReady={onReadyHandle}
    >
      {readOnly ? (
        <Marker
          key="not-draggable-marker"
          position={
            location ||
            (map &&
              !!map.getBounds() && {
                lat: map.getBounds().getCenter().lat(),
                lng: map.getBounds().getCenter().lng(),
              }) ||
            null
          }
        />
      ) : (
        <Marker
          key="draggable-marker"
          onDragend={locationOnDragend}
          position={
            location ||
            (map &&
              !!map.getBounds() && {
                lat: map.getBounds().getCenter().lat(),
                lng: map.getBounds().getCenter().lng(),
              }) ||
            null
          }
          draggable
        />
      )}
    </GoogleMap>
  );
});

MapRoot.defaultProps = {
  location: null,
  locationOnDragend: () => null,
  categories: [],
  map: null,
  readOnly: false,
  onClick: () => null,
  onReady: undefined,
  mapTypeId: undefined,
};

MapRoot.propTypes = {
  location: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
    withoutZoom: PropTypes.bool,
    withoutPan: PropTypes.bool,
  }),
  locationOnDragend: PropTypes.func,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
  ),
  map: PropTypes.object,
  readOnly: PropTypes.bool,
  onClick: PropTypes.func,
  onReady: PropTypes.func,
  mapTypeId: PropTypes.string,
};

const MapWithSinglePad = (props) => {
  const {
    containerClassName,
    mapStyle,
    height,
    errorContainerStyle,
    map,
    ...rest
  } = props;

  const { googleMapsIsLoaded, googleMapsError } = useContext(GoogleMapsContext);

  return (
    <div className={containerClassName}>
      {googleMapsIsLoaded ? (
        <MapRoot
          {...rest}
          containerStyle={{
            position: 'relative',
            width: '100%',
            ...mapStyle,
            height,
          }}
          map={map}
        />
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ ...errorContainerStyle, height }}
        >
          {googleMapsError || null}
        </div>
      )}
    </div>
  );
};

MapWithSinglePad.defaultProps = {
  containerClassName: '',
  mapStyle: {},
  height: 400,
  errorContainerStyle: {},
  location: null,
  map: null,
};

MapWithSinglePad.propTypes = {
  containerClassName: PropTypes.string,
  mapStyle: PropTypes.object,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errorContainerStyle: PropTypes.object,
  location: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
    withoutZoom: PropTypes.bool,
    withoutPan: PropTypes.bool,
  }),
  map: PropTypes.object,
};

export default memo(MapWithSinglePad);
