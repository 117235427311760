import { ReactComponent as aerialLandmarkIcon } from '../assets/padsCategories/aerial_landmark.svg';
import { ReactComponent as eatIcon } from '../assets/padsCategories/eat.svg';
import { ReactComponent as stayIcon } from '../assets/padsCategories/stay.svg';
import { ReactComponent as landingSiteIcon } from '../assets/padsCategories/landing_site.svg';
import { ReactComponent as aerodromeIcon } from '../assets/padsCategories/aerodrome.svg';
import condStrings from './condStrings';

/**
 * @name getPadIcon
 *
 * @param text
 * @param category
 * @param status
 *
 * @returns {Node}
 */

export default (text, category, status) => {
  let Icon = null;
  let className = '';

  switch (category) {
    case 1:
      Icon = aerialLandmarkIcon;
      break;
    case 2:
      Icon = stayIcon;
      break;
    case 3:
      Icon = landingSiteIcon;
      break;
    case 4:
      Icon = stayIcon;
      break;
    case 5:
      Icon = eatIcon;
      break;
    case 6:
      Icon = eatIcon;
      break;
    case 7:
      Icon = aerodromeIcon;
      break;
    default:
      break;
  }

  if (status === 1) className = 'grey';

  return (
    <>
      <span className={condStrings('icon', className)}>
        <Icon />
      </span>
    </>
  );
};
