import { useEffect, useState, useCallback } from 'react';
import { useMount, useSessionStorage, useUnmount } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import moment from 'moment';
import PageHeader from '../../uiKitComponents/pageHeader';
import Table from '../../uiKitComponents/table';
import Form from '../../uiKitComponents/form';
import DatePicker from '../../uiKitComponents/datePicker';
import Alert from '../../uiKitComponents/alert';
import Button from '../../uiKitComponents/button';
import * as reports from '../../store/actions/reportPagesAction';
import buildTableColumns from '../../utils/buildTableColumns';

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';

const initialState = {
  page: 1,
  sort_column: 'id',
  sort_direction: 'descend',
  dates: { from: { string: '', locale: '' }, to: { string: '', locale: '' } },
  roleType: 1,
  reportV: 1,
  filtersTrigger: false,
};

const userRolesFilter = {
  1: { type: 1 },
  2: { type: 0, device: 'ios' },
  3: { type: 0, device: 'android' },
};

const UserDropOff = () => {
  const [state, setState] = useSessionStorage('user-drop-off', {
    ...initialState,
  });
  const { page, filtersTrigger, dates } = state;
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { cleanOnUnmount, undefinedUsers } = useSelector((store) => ({
    cleanOnUnmount: store.reportPages.cleanOnUnmount,
    undefinedUsers: store.reportPages.payload.undefinedUsers,
  }));
  const dispatch = useDispatch();

  useMount(() => {
    reports.setCleanOnUnmountTrue()(dispatch);
  });

  const fetchUndefinedUsers = () => {
    setLoading(true);

    reports
      .getUserDropOff({
        from: dates.from.string,
        to: dates.to.string,
        ...userRolesFilter[state.roleType],
        is_new_version: state.reportV,
      })(dispatch)
      .catch((err) => {
        if (err.response.status === 500) {
          setError({
            status: err.response.status,
            message: err.response.data.message,
          });
          sessionStorage.removeItem('user-drop-off');
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchUndefinedUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersTrigger, dispatch, dates, state.roleType, state.reportV]);

  useUnmount(() => {
    if (cleanOnUnmount) {
      sessionStorage.removeItem('user-drop-off');
      reports.cleanReports()(dispatch);
    }
  });

  const onTableChange = useCallback(
    (pagination, tableFilters, sorter, extra) => {
      const newState = {};
      if (extra.action === 'paginate') newState.page = pagination.current;
      else if (extra.action === 'sort') {
        newState.sort_column = sorter.field;
        newState.sort_direction = sorter.order;
      }
      setState((prevState) => ({ ...prevState, ...newState }));
    },
    [setState],
  );

  const onSelectedDatesHandler = (values) => {
    const { range } = values;

    if (range && range.length) {
      const date_from = dayjs(range[0].$d).format('DD-MM-YYYY');
      const date_to = dayjs(range[1].$d).format('DD-MM-YYYY');

      setState((prevState) => ({
        ...prevState,
        dates: {
          from: { string: date_from, locale: range[0] },
          to: { string: date_to, locale: range[1] },
        },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        dates: {
          from: { string: '', locale: '' },
          to: { string: '', locale: '' },
        },
      }));
    }
  };

  const getMomentDate = (date) => {
    if (date) return moment(date, dateFormat);

    return null;
  };

  if (error)
    return (
      <PageHeader className="page-main-header" title="Engagement - Fav/Rev">
        <Alert
          showIcon
          type="error"
          message={`Error ${error.status}`}
          description={error.message}
        />
      </PageHeader>
    );

  return (
    <div>
      <PageHeader className="page-main-header" title="Onboarding Drop Off" />
      <Form layout="inline" className="mb-3" onFinish={onSelectedDatesHandler}>
        <Form.Item
          name="range"
          label="Select Dates"
          initialValue={[
            getMomentDate(dates.from?.locale),
            getMomentDate(dates.to?.locale),
          ]}
        >
          <RangePicker
            format="DD/MM/YYYY"
            disabledDate={(date) => date.isAfter(moment())}
          />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Select
        </Button>
      </Form>
      <div className="summary-container d-flex flex-wrap align-items-center justify-content-between mb-3 p-2">
        <div>
          <button
            type="button"
            onClick={() => setState({ ...state, roleType: 1 })}
            className={`summary-el ${state.roleType === 1 && 'isSelected'}`}
          >
            <span>Owners</span>
          </button>
          <button
            type="button"
            onClick={() => setState({ ...state, roleType: 2 })}
            className={`summary-el ${state.roleType === 2 && 'isSelected'}`}
          >
            <span>Pilots iOS</span>
          </button>
          <button
            type="button"
            onClick={() => setState({ ...state, roleType: 3 })}
            className={`summary-el ${state.roleType === 3 && 'isSelected'}`}
          >
            <span>Pilots Android</span>
          </button>
        </div>
        {[2, 3].includes(state.roleType) && (
          <div>
            <button
              type="button"
              onClick={() => setState({ ...state, reportV: 0 })}
              className={`summary-el ${state.reportV === 0 && 'isSelected'}`}
            >
              <span>Old version</span>
            </button>
            <button
              type="button"
              onClick={() => setState({ ...state, reportV: 1 })}
              className={`summary-el ${state.reportV === 1 && 'isSelected'}`}
            >
              <span>New version</span>
            </button>
          </div>
        )}
      </div>
      <div className="page-main-content">
        <Table
          columns={buildTableColumns(undefinedUsers?.columns)}
          rowKey={(e) => e.id}
          dataSource={undefinedUsers?.data || []}
          loading={loading}
          onChange={onTableChange}
          pagination={{
            position: ['topRight', 'bottomRight'],
            showSizeChanger: false,
            showQuickJumper: true,
            pageSize: 25,
            total: undefinedUsers?.meta?.total,
            current: page,
            hideOnSinglePage: true,
          }}
        />
      </div>
    </div>
  );
};

UserDropOff.defaultProps = {
  reportPageProps: {},
  row_action: [],
  row_inner_action: [],
};

UserDropOff.propTypes = {
  reportPageProps: PropTypes.shape({
    sessionStorageName: PropTypes.string,
    pageTitle: PropTypes.string,
    stateName: PropTypes.string,
    actionName: PropTypes.string,
    default_sort_column: PropTypes.string,
  }),
  row_action: PropTypes.array,
  row_inner_action: PropTypes.array,
};

export default UserDropOff;
