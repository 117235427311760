import dayjs from 'dayjs';
import Tag from '../uiKitComponents/tag';
import Button from '../uiKitComponents/button';
import { dateFormats } from './constants';
import history from './history';
import InlineEdit from '../components/inlineEditRow';
import axiosInstance from './apiConfig';
import simulateNativeLink from './simulateNativeLink';

const goTo = (path, config) => () => {
  if (config?.promisedClbk)
    config.promisedClbk().then(() => history.push(path));
  else history.push(path);
};

const onRowEdited = async (path, id, value) => {
  try {
    await axiosInstance.put(path, { id, renewal_date: value });
  } catch (err) {
    console.log(err);
  }
};

// Transformation To Components
const transformToTag = (key, text, link = null) => {
  if (link) {
    const path = `${link?.path}/${link?.identifier}${link?.query}`;

    return (
      <Tag
        style={{ cursor: 'pointer' }}
        key={key}
        onClick={simulateNativeLink(path, goTo(path, link?.config))}
      >
        {text}
      </Tag>
    );
  }

  return <Tag key={key}>{text}</Tag>;
};

const transformToBtnLink = (text, link, icon = '') => {
  const path = `${link?.path}/${link?.identifier}${link?.query}`;

  return (
    <Button
      type="link"
      htmlType="button"
      className={icon ? 'custom-btn custom-icon' : 'custom-btn'}
      onClick={simulateNativeLink(path, goTo(path, link?.config))}
      icon={icon}
    >
      {text}
    </Button>
  );
};

const transformToInlineEditableRow = (val, id, path) => (
  <InlineEdit
    data={dayjs(val).format('LL')}
    id={id}
    path={path}
    onChange={onRowEdited}
  />
);

function transformToTableCell(itm, config) {
  const {
    relation,
    relation_type,
    edit_path,
    link,
    link_column,
    link_query,
    column,
    display_name,
    sort_order,
    ...rest
  } = itm;
  const dataIndex = relation ? `${relation}.${column}` : column;

  const transformURIToRowRelation = (row, label) => {
    if (label?.split('.').length > 1) {
      const row_relation = label.split('.');
      return row[row_relation[0]][row_relation[1]];
    }
    return row[label];
  };

  const transformToComponent = (data, rowData) => {
    // Check if exist icons
    let rowIcon = null;
    if (config?.[dataIndex]?.setIcon) {
      rowIcon = config?.[dataIndex]?.setIcon(data, rowData);
    }
    if (relation_type === 'tags') {
      return rowData[relation].map((tagObj) => {
        const tag_text = tagObj[column] || tagObj.name || '';
        return link
          ? transformToTag(tagObj.id, tag_text, {
            path: link,
            identifier: transformURIToRowRelation(tagObj, link_column),
            query: link_query || '',
            config,
          })
          : transformToTag(tagObj.id, data);
      });
    }

    if (relation_type === 'object' && rowData[relation]) {
      return link
        ? transformToBtnLink(rowData[relation][column], {
          path: link,
          identifier: transformURIToRowRelation(
            rowData[relation],
            link_column,
          ),
          query: link_query || '',
          config,
        })
        : rowData[relation][column] || data;
    }

    if (relation_type === 'timestamp' && data) {
      if (edit_path) {
        return transformToInlineEditableRow(data, rowData.id, edit_path);
      }
      return dayjs(data).format(dateFormats[0]);
    }

    if (link) {
      return transformToBtnLink(
        data,
        {
          path: link,
          identifier: transformURIToRowRelation(rowData, link_column),
          query: link_query || '',
          config,
        },
        rowIcon,
      );
    }

    return data;
  };

  if (config?.[dataIndex]?.render || config?.[relation]?.render) {
    const render = config?.[dataIndex]?.render || config?.[relation]?.render;

    return {
      ...rest,
      sortOrder: sort_order || false,
      title: display_name,
      dataIndex,
      render: (data, rowData) => render(data, rowData),
    };
  }

  return {
    ...rest,
    sortOrder: sort_order || false,
    title: display_name,
    dataIndex,
    render: transformToComponent,
  };
}

const buildTableColumns = (columns, config = {}) =>
  columns?.map((itm) => transformToTableCell(itm, config)) || [];

export default buildTableColumns;
