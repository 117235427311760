import PageHeader from '../../uiKitComponents/pageHeader';
import Table from '../../uiKitComponents/table';
import buildTableColumns from '../../utils/buildTableColumns';

const ReportsPageList = () => {
  const columns = [
    {
      column: 'name',
      display_name: 'Name',
      edit_path: null,
      link: '/dashboard/reports',
      link_column: 'id',
      relation: null,
      relation_type: null,
      sort_order: null,
      sorter: false,
    },
    {
      column: 'description',
      display_name: 'Description',
      edit_path: null,
      link: null,
      link_column: null,
      relation: null,
      relation_type: null,
      sort_order: null,
      sorter: false,
    },
  ];
  const dataSource = [
    {
      id: 'pad-submissions',
      key: '1',
      name: 'Pad Submissions by Pilot',
      description: 'Counts how many pads have been added by specific Pilots',
    },
    {
      id: 'log-report',
      key: '2',
      name: 'System Logs',
      description: 'Every log, system-wide, in chronological order',
    },
    {
      id: 'duplicate-report',
      key: '3',
      name: 'Duplicate Checker',
      description:
        'Identifies any duplicates, based on criteria and statuses above',
    },
    {
      id: 'pad-stats-fav',
      key: '4',
      name: 'Pad Engagement - Fav/Rev',
      description: 'Shows which Pads have the most favourites and reviews',
    },
    {
      id: 'pad-stats-views',
      key: '5',
      name: 'Pad Engagement - All',
      description:
        'All time total pad views, PPR count, favourites and reviews',
    },
    {
      id: 'drop-off',
      key: '6',
      name: 'Onboarding Drop Off',
      description: 'Where did Pilots and Owners drop off during onboarding?',
    },
    {
      id: 'pilots-country',
      key: '7',
      name: 'Pilot Country',
      description: 'Where do our Pilots live?',
    },
    {
      id: 'moderation-engagement',
      key: '8',
      name: 'In-app Engagement',
      description: 'Total Pilot engagement/contributions over time',
    },
    {
      id: 'campaigns',
      key: '9',
      name: 'Campaigns',
      description:
        'Which campaigns were the most successful in signing up new Pilots?',
    },
    {
      id: 'subs-report',
      key: '10',
      name: 'Premium Subscriptions',
      description:
        'Splits new and recurring Pilot/Owner subscriptions, against time',
    },
    {
      id: 'concurrent-logins',
      key: '11',
      name: 'Concurrent Pilot Logins',
      description: 'Active tokens by Pilot ID',
    },
    {
      id: 'pad-country',
      key: '12',
      name: 'Pad Country',
      description: 'Where are our pads located?',
    },
    {
      id: 'survey-report',
      key: '13',
      name: '360 Surveys',
      description: 'Metrics on 360 Survey completion',
    },
    {
      id: 'pilot-aircrafts',
      key: '13',
      name: 'Pilot aircrafts',
      description: 'Aircrafts associated with the pilot',
    },
  ];
  return (
    <div>
      <PageHeader className="page-main-header" title="Reports" />
      <div className="page-main-content">
        <Table
          className="cell-fixed-width"
          columns={[...buildTableColumns(columns)]}
          rowKey="id"
          dataSource={dataSource}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default ReportsPageList;
