import { useEffect, useState, useCallback } from 'react';
import {
  useMount,
  useSessionStorage,
  useUnmount,
  useUpdateEffect,
} from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { Menu, Switch } from 'antd';
import simulateNativeLink from '../../utils/simulateNativeLink';
import Button from '../../uiKitComponents/button';
import PageHeader from '../../uiKitComponents/pageHeader';
import Table from '../../uiKitComponents/table';
import buildTableColumns from '../../utils/buildTableColumns';
import FiltersSection from '../../components/filtersSection';
import Dropdown from '../../uiKitComponents/dropdown';
import Alert from '../../uiKitComponents/alert';
import Space from '../../uiKitComponents/space';

import formattingFilters from '../../utils/formattingFilters';
import {
  getUsersList,
  cleanUsersList,
  setCleanOnUnmountTrue,
  setCleanOnUnmountFalse,
} from '../../store/actions/userListAction';
import { getUserFilters } from '../../store/actions/userFiltersAction';
import withURLFilters from '../../utils/withURLFilters';
import api from '../../utils/appApi';
import { updateUserSettings } from '../../store/actions/userAction';
import UserListMap from './userListMap';

const initialState = {
  page: 1,
  sort_column: 'id',
  sort_direction: 'descend',
  filters: [{}],
  keyword: undefined,
  filter_info: undefined,
  filtersTrigger: false,
  listOnMap: false,
};

const UserListPage = () => {
  const [error, setError] = useState();
  const [selectedFilter, setSelectedFilter] = useState('initial');
  const {
    cleanOnUnmount,
    usersList,
    savedFilters,
    adminSettings,
  } = useSelector((store) => ({
    cleanOnUnmount: store.usersList.cleanOnUnmount,
    usersList: store.usersList.payload,
    savedFilters: store.userFilters.payload,
    adminSettings: store.user.payload.settings.admin_settings,
  }));
  const [state, setState] = useSessionStorage('user-list-state', {
    ...initialState,
    listOnMap: adminSettings.is_user_map_on,
  });
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    page,
    sort_column,
    sort_direction,
    filters,
    keyword,
    filter_info,
    filtersTrigger,
    listOnMap,
  } = state;

  useMount(() => {
    setCleanOnUnmountTrue()(dispatch);
    setState(withURLFilters(state, history.location.search));
  });

  useUpdateEffect(() => {
    const advancedFilters = {};
    filters.forEach((filter) => {
      const temp_filter = formattingFilters(filter, filter.keyword, 'STRING');
      Object.keys(temp_filter).forEach((key) => {
        advancedFilters[`filters[${key}]`] =
          filter.column === 'role' && filter.keyword === 'null'
            ? ''
            : temp_filter[key];
      });
    });

    getUsersList({
      params: {
        page,
        sort_column,
        sort_direction: sort_direction || 'ascend',
        keyword,
        ...advancedFilters,
      },
    })(dispatch).catch((err) => {
      if (err?.response?.status === 500) {
        setError({
          status: err.response.status,
          message: err.response.data.message,
        });
        sessionStorage.removeItem('user-list-state');
      }
    });
    // eslint-disable-next-line
  }, [page, sort_column, sort_direction, filtersTrigger, dispatch]);

  useUnmount(() => {
    if (cleanOnUnmount) {
      sessionStorage.removeItem('user-list-state');
      cleanUsersList()(dispatch);
    }
  });

  const onTableChange = useCallback(
    (pagination, tableFilters, sorter, extra) => {
      const newState = {};
      if (extra.action === 'paginate') newState.page = pagination.current;
      else if (extra.action === 'sort') {
        newState.sort_column = sorter.field;
        newState.sort_direction = sorter.order;
      }
      setState((prevState) => ({ ...prevState, ...newState }));
    },
    [setState],
  );

  const onSubmitFilters = useCallback(
    (values) =>
      setState((prevState) => ({
        ...prevState,
        ...values,
        filtersTrigger: !prevState.filtersTrigger,
      })),
    [setState],
  );

  const clearFilters = useCallback(
    () =>
      setState((prevState) => ({
        ...initialState,
        filtersTrigger: !prevState.filtersTrigger,
      })),
    [setState],
  );

  const setSavedFilter = (id = null) => {
    if (id) {
      let filter = {};
      if (savedFilters.length) {
        filter = savedFilters.find((itm) => itm.id === id);
      } else {
        filter = savedFilters;
      }
      const rows = Object.keys(filter.filters).map((row) =>
        formattingFilters(row, filter.filters[row], 'OBJECT'),
      );
      onSubmitFilters({
        filters: rows,
        keyword: undefined,
        filter_info: { id, name: filter.name, is_public: filter.is_public },
      });
    }
  };

  const checkIfEmailVerified = useCallback(
    (record) =>
      !record.email_verified_at || !!record.deleted_at ? 'transparent-el' : '',
    [],
  );

  const checkIfIsArchived = useCallback(
    (record) => (record.deleted_at ? 'archived-row' : ''),
    [],
  );

  const getRowStatus = useCallback(
    (record) => `${checkIfEmailVerified(record)} ${checkIfIsArchived(record)}`,
    [checkIfEmailVerified, checkIfIsArchived],
  );

  useEffect(() => {
    if (selectedFilter) {
      getUserFilters(usersList?.entity)(dispatch)
        .then(() => {
          if (selectedFilter !== 'initial') {
            setSavedFilter(selectedFilter);
            setSelectedFilter(undefined);
          }
        })
        .catch((err) => {
          if (err.response?.status === 500) {
            setError({
              status: err.response.status,
              message: err.response.data.message,
            });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersList?.entity, selectedFilter]);

  const goToUser = (id) => (e) => {
    setCleanOnUnmountFalse()(dispatch).then(() => {
      simulateNativeLink(`/dashboard/user-view/${id}`, () =>
        history.push(`/dashboard/user-view/${id}`),
      )(e);
    });
  };

  const getUserListColumnOptions = useCallback(() => {
    if (usersList && usersList.filterable) {
      delete usersList.filterable.role.options[''];

      return {
        ...usersList.filterable,
        role: {
          ...usersList.filterable.role,
          options: {
            ...usersList.filterable.role.options,
            null: 'Undefined',
          },
        },
      };
    }

    return {};
  }, [usersList]);

  const menu = (
    <Menu>
      {savedFilters.length &&
        savedFilters.map((filter, idx) => (
          <Menu.Item key={idx} onClick={() => setSavedFilter(filter.id)}>
            {filter.name}
          </Menu.Item>
        ))}
    </Menu>
  );

  const toggleListOnMap = async () => {
    const res = await api.users.toggleMap();
    await updateUserSettings(res.data)(dispatch);

    setState((prevState) => ({
      ...prevState,
      listOnMap: !prevState.listOnMap,
    }));
  };

  if (error)
    return (
      <PageHeader className="page-main-header" title="Users">
        <Alert
          showIcon
          type="error"
          message={`Error ${error.status}`}
          description={error.message}
        />
      </PageHeader>
    );

  return (
    <div>
      <PageHeader
        className="page-main-header"
        title="Users"
        extra={[
          <Dropdown
            overlay={menu}
            trigger={['click']}
            placement="bottomRight"
            disabled={!savedFilters.length}
            key="saved_filters"
          >
            <Button loading={!savedFilters}>
              Saved Filters
              <DownOutlined />
            </Button>
          </Dropdown>,
        ]}
      >
        <div className="filters-container">
          <FiltersSection
            clearFilters={clearFilters}
            onSubmit={onSubmitFilters}
            filters={filters}
            keyword={keyword}
            columnOptions={getUserListColumnOptions()}
            loading={!usersList.filterable}
            filter_info={filter_info}
            filter_columns={usersList?.columns}
            entity={usersList?.entity}
            onUpdateFilter={setSelectedFilter}
            exportOptions={{
              action: api.users.export,
              text: 'Export Users (CSV)',
              filename: 'helipaddy_users.csv',
            }}
            hiddenExportForUpload
          />
        </div>
      </PageHeader>

      <div className="toggle-pads-view d-inline-flex align-items-center">
        View on the Map
        <Switch
          size="small"
          checked={listOnMap}
          onClick={toggleListOnMap}
          className="ml-2"
          disabled={!usersList?.data}
        />
      </div>

      {listOnMap && (
        <UserListMap
          filters={filters}
          keyword={keyword}
          onDisableAction={toggleListOnMap}
          cleanOnUnmount={cleanOnUnmount}
        />
      )}

      <div className="page-main-content">
        <Space
          className="d-flex justify-content-end"
          style={{ color: '#d9d9d9' }}
        >
          Showing {usersList?.meta?.from}-{usersList?.meta?.to} of{''}
          {usersList?.meta?.total} listings
        </Space>
        <Table
          className='scroll-custom'
          columns={[
            ...buildTableColumns(usersList?.columns, {
              promisedClbk: () => setCleanOnUnmountFalse()(dispatch),
              is_premium: {
                render: (_, rowData) => {
                  if (rowData.is_premium) {
                    if (rowData.is_fremium) return 'Freemium';
                    return 'Premium';
                  }

                  return 'Non-premium';
                },
              },
              name: {
                render: (data, rowData) => (
                  <Button type="link" onClick={goToUser(rowData?.id)}>
                    {data?.trim() || 'Undefined'}
                  </Button>
                ),
              },
              username: {
                render: (data) =>
                  data && data.length > 15 ? (
                    <span title={data}>{data.substr(0, 15).concat('...')}</span>
                  ) : (
                    data || ''
                  ),
              },
            }),
          ]}
          rowClassName={getRowStatus}
          rowKey="id"
          dataSource={usersList?.data}
          loading={!usersList?.data}
          onChange={onTableChange}
          scroll={{ x: 1000 }}
          pagination={{
            position: ['topRight', 'bottomRight'],
            showSizeChanger: false,
            showQuickJumper: true,
            pageSize: usersList?.meta?.per_page,
            total: usersList?.meta?.total,
            current: page,
          }}
        />
        <Space
          className="d-flex justify-content-end"
          style={{ color: '#d9d9d9' }}
        >
          Showing {usersList?.meta?.from}-{usersList?.meta?.to} of{''}
          {usersList?.meta?.total} listings
        </Space>
      </div>
    </div>
  );
};

export default UserListPage;
