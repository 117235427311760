import PropTypes from 'prop-types';
import { Input, Form } from 'antd';
import { useCallback, useContext, useMemo } from 'react';
import DataRowContext from '../DataRowContext';
import IcaoInput from '../../icaoInput';

export default function RowControllerInput(props) {
  const { placeholder, name, inputType, rules, ...rest } = props;
  const { label, setCheckedSide, readOnly } = useContext(DataRowContext);

  const onInputChange = useCallback(() => setCheckedSide(null), [
    setCheckedSide,
  ]);

  const InputByType = useMemo(() => {
    const inputProps = {
      readOnly,
      placeholder,
      onInput: onInputChange,
      ...rest,
    };
    switch (inputType) {
      case 'icao':
        return <IcaoInput {...inputProps} />;
      case 'textarea':
        return <Input.TextArea {...inputProps} autoSize={{ minRows: 2 }} />;
      default:
        return <Input {...inputProps} />;
    }
  }, [inputType, onInputChange, placeholder, readOnly, rest]);

  return (
    <Form.Item
      className="data-row-input"
      label={label}
      name={name}
      rules={rules}
    >
      {InputByType}
    </Form.Item>
  );
}

RowControllerInput.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  inputType: PropTypes.oneOf(['default', 'icao', 'textarea']),
  rules: PropTypes.array,
};

RowControllerInput.defaultProps = {
  placeholder: '',
  name: '',
  inputType: 'default',
  rules: [],
};
