import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Button, Image, List, Rate, Tag } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import FormattedText from '../../../../../components/formattedText';
import condStrings from '../../../../../utils/condStrings';
import { dateFormats, userRoles } from '../../../../../utils/constants';

import { ReactComponent as ExLink } from '../../../../../assets/icons/ex-link.svg';
import { ReactComponent as HistoryLink } from '../../../../../assets/icons/history.svg';

import styles from '../ReviewTab.module.sass';

export function Comment(props) {
  const { comment, toggleReviewMode } = props;

  const ReviewToggleButton = React.useMemo(() => {
    const { id, is_reported, is_review_approved } = comment;

    if (is_reported) {
      return (
        <Button
          key="hide-show-comment"
          htmlType="button"
          shape="round"
          disabled
        >
          <span className="error">Reported</span>
        </Button>
      );
    }

    return (
      <Button
        key="hide-show-comment"
        htmlType="button"
        shape="round"
        icon={is_review_approved ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        onClick={toggleReviewMode(id)}
      >
        {is_review_approved ? 'Hide' : 'Show'}
      </Button>
    );
  }, [comment, toggleReviewMode]);

  return (
    <List.Item
      className={condStrings(
        styles.comment,
        !comment.is_review_approved && 'inactive',
        !comment.is_reported && 'inavtive',
      )}
      extra={ReviewToggleButton}
    >
      <List.Item.Meta
        avatar={
          <Avatar>
            {comment?.user?.first_name?.substring(0, 1)}
            {comment?.user?.last_name?.substring(0, 1)}
          </Avatar>
        }
        description={
          <>
            {dayjs(comment.created_at).format(dateFormats[0])}
            <Rate
              defaultValue={comment.rating}
              disabled
              allowHalf
              className="d-block"
            />
          </>
        }
        title={
          <>
            <Link
              target="_blank"
              to={`/dashboard/user-view/${comment.user.id}`}
              className="d-flex align-items-center"
            >
              <span className="icon mr-1">
                <ExLink width="12" />
              </span>
              <span className="text">
                {comment.user.first_name} {comment.user.last_name}
              </span>
              <span className={styles.reviewUpdatedAt}>
                <HistoryLink />
                <span>{dayjs(comment.updated_at).format(dateFormats[0])}</span>
              </span>
            </Link>
            {comment.user.is_anonymous && ' (Anonymously)'}
            {comment.user.role === userRoles.ROLE_ADMIN && (
              <Tag className="ml-2">Admin</Tag>
            )}
          </>
        }
      />
      <div style={{ paddingLeft: 48 }}>
        <FormattedText text={comment.comment ? comment.comment : ''} />
        {!!comment.uploads_count && (
          <Image.PreviewGroup>
            <div className="attachments">
              {comment.uploads.map((u) => (
                <Image
                  wrapperClassName="attach-item"
                  key={u.id}
                  src={u.file_url}
                  alt={u.title}
                />
              ))}
            </div>
          </Image.PreviewGroup>
        )}
      </div>
    </List.Item>
  );
}

Comment.propTypes = {
  comment: PropTypes.object,
  toggleReviewMode: PropTypes.func,
};
