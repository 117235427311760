import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Form, Input } from 'antd';

export const SurveyApproachStep = React.memo(({stepValues}) => (
  <Descriptions
    labelStyle={{ width: 150 }}
    size="small"
    bordered
    column={1}
    layout="vertical"
    className="mb-3"
  >
    <Descriptions.Item label={`Procedures: ${stepValues.is_completed ? 'Completed' : 'Incomplete'}`}>
      <Form.Item
        name={['approach', 'approach_procedures_comment']}
        label="Approach"
        rules={[
          {
            min: 3,
            max: 200,
            message: 'Must be between 3 and 200 characters',
          },
        ]}
      >
        <Input.TextArea
          autoSize
          placeholder="Please provide a description of any known approach procedures, areas to not overfly or local noise sensitive areas"
        />
      </Form.Item>
      <Form.Item
        name={['approach', 'approach_departures_comment']}
        label="Departures"
        rules={[
          {
            min: 3,
            max: 200,
            message: 'Must be between 3 and 200 characters',
          },
        ]}
      >
        <Input.TextArea
          autoSize
          placeholder="Please provide a description of any known departures"
        />
      </Form.Item>
      <Form.Item
        name={['approach', 'approach_reference_points_comment']}
        label="Nearby Visual Reference Points"
        className="mb-0"
        rules={[
          {
            min: 3,
            max: 200,
            message: 'Must be between 3 and 200 characters',
          },
        ]}
      >
        <Input.TextArea
          autoSize
          placeholder="Please provide details & rough bearing/distance of any landmarks or obvious nearby visual cues that could help the pilot locate the site. Road junctions, rivers, lakes & tall buildings etc"
        />
      </Form.Item>
    </Descriptions.Item>
  </Descriptions>
));

SurveyApproachStep.propTypes = {
  stepValues: PropTypes.object,
};
