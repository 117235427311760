import React from 'react';
import { useSelector } from 'react-redux';
import PadViewContext from '../PadView.context';
import MapWithPads from '../../../components/mapWithPads';
import { selectCategoryOptions } from '../../../store/selectors/bootstrapSelector';
import api from '../../../utils/appApi';

export function PadViewMap() {
  const { categories } = useSelector((store) => ({
    categories: selectCategoryOptions(store),
  }));
  const [mapPlaces, setMapPlaces] = React.useState([]);
  const { mapDragPinActive, location, setLocation } = React.useContext(
    PadViewContext,
  );
  const mapInstance = React.useRef(null);

  React.useEffect(() => {
    api.pad.getMapPlaces().then((res) => setMapPlaces(res.data.data));
  }, []);

  const mapReadyHanlder = (_, map) => {
    mapInstance.current = map;
  };

  const mapClickHanlder = React.useCallback(
    (_, _map, e) => {
      const latitude = e.latLng.lat();
      const longitude = e.latLng.lng();

      console.log(latitude);

      setLocation((prevState) => ({
        ...prevState,
        latitude,
        longitude,
        getGeoCode: true,
        withoutZoom: true,
      }));
    },
    [setLocation],
  );

  const pinDragendHandler = React.useCallback(
    (_, marker) => {
      const latitude = marker.position.lat();
      const longitude = marker.position.lng();

      setLocation((prevState) => ({
        ...prevState,
        latitude,
        longitude,
        getGeoCode: true,
        withoutZoom: true,
      }));
    },
    [setLocation],
  );

  const mapPinLocation = React.useMemo(
    () =>
      !isNaN(location.latitude) && !isNaN(location.longitude)
        ? {
            lat: parseFloat(location.latitude),
            lng: parseFloat(location.longitude),
            withoutZoom: !!location.withoutZoom,
          }
        : null,
    [location.latitude, location.longitude, location.withoutZoom],
  );

  return (
    <MapWithPads
      height={250}
      clustererConfig={{
        gridSize: 45,
      }}
      onReady={mapReadyHanlder}
      map={mapInstance.current}
      pads={mapPlaces}
      categories={categories}
      locationOnDragend={pinDragendHandler}
      location={mapPinLocation}
      onClick={mapClickHanlder}
      readOnly={!mapDragPinActive}
    />
  );
}
