/* eslint-disable no-return-assign */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */

import isObject from './isObject';

const withURLFilters = (state, query) => {
  const params = new URLSearchParams(query);
  const stateWithUrlFilters = { ...state };

  stateWithUrlFilters.filtersTrigger = !stateWithUrlFilters.filtersTrigger;

  if (!params.has('filters')) return stateWithUrlFilters;

  const urlFilters = { current: [] };

  params.delete('filters');

  const parseKeywordToArray = (key, val) => {
    if (key === 'keyword') {
      const match = val.match(/\[(.*?)\]/);

      if (match) {
        return match[1].replace(/\s/g, '').split(',');
      }
    }
    return val;
  };

  for (const [key, value] of params) {
    const keyValues = value.split(';');

    keyValues.forEach((keyVal, index) => {
      if (isObject(urlFilters.current[index])) {
        urlFilters.current[index][key] = parseKeywordToArray(key, keyVal);
      } else {
        urlFilters.current[index] = {};
        urlFilters.current[index][key] = parseKeywordToArray(key, keyVal);
      }
    });
  }

  stateWithUrlFilters.filters = urlFilters.current;

  return stateWithUrlFilters;
};

export default withURLFilters;
