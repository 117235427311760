export default function formattingFilters(data, keyword, type) {
  if (type === 'OBJECT') {
    const temp_row = data.split(':');

    return {
      column: temp_row[0],
      operator: temp_row[1],
      keyword,
    };
  }

  if (type === 'STRING') {
    if (!data || !Object.keys(data).length) return '';

    const temp_row = `${data.column}:${data.operator}`;
    let word = keyword;
    if (
      !keyword &&
      (data.operator === 'is blank' || data.operator === "isn't blank")
    ) {
      word = 'true';
    }

    if (!keyword && data.column === 'role') {
      word = '';
    }

    return {
      [temp_row]: word,
    };
  }

  return console.log('Something went wrong - formattingFilters');
}
