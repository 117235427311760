import { useEffect, useState, useCallback } from 'react';
import { useMount, useSessionStorage, useUnmount } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Input } from 'antd';
import PageHeader from '../../uiKitComponents/pageHeader';
import Table from '../../uiKitComponents/table';
import Select from '../../uiKitComponents/select';
import Alert from '../../uiKitComponents/alert';
import Button from '../../uiKitComponents/button';
import Form from '../../uiKitComponents/form';
import * as reports from '../../store/actions/reportPagesAction';
import simulateNativeLink from '../../utils/simulateNativeLink';
import {
  selectCountriesOptions,
  selectUSStatesOptions,
} from '../../store/selectors/bootstrapSelector';

const initialState = {
  page: 1,
  sort_column: 'public_pads_moderated',
  sort_direction: 'descend',
  filtersTrigger: false,
  keyword: '',
  country: '',
  us_state: '',
};

const PadSubmissions = () => {
  const [state, setState] = useSessionStorage('pad-submissions', {
    ...initialState,
  });
  const {
    page,
    sort_column,
    sort_direction,
    keyword,
    country,
    us_state,
    filtersTrigger,
  } = state;
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [isUSSelected, setIsUSSelected] = useState(false);
  const { cleanOnUnmount, reportsList, countries, USStates } = useSelector(
    (store) => ({
      cleanOnUnmount: store.reportPages.cleanOnUnmount,
      reportsList: store.reportPages.payload.padSubmissions,
      countries: selectCountriesOptions(store),
      USStates: selectUSStatesOptions(store),
    }),
  );

  const dispatch = useDispatch();
  const history = useHistory();

  useMount(() => {
    reports.setCleanOnUnmountTrue()(dispatch);

    if (country === 'US') setIsUSSelected(true);
  });

  const goTo = (path) =>
    simulateNativeLink(path, () =>
      reports
        .setCleanOnUnmountFalse()(dispatch)
        .then(() => history.push(path)),
    );

  const fetchPadSubmissions = () => {
    setLoading(true);

    reports
      .getPadSubmissions({
        params: {
          page: 1,
          sort_column,
          sort_direction: sort_direction || 'ascend',
          keyword,
          country,
          state: us_state,
        },
      })(dispatch)
      .catch((err) => {
        if (err.response.status === 500) {
          setError({
            status: err.response.status,
            message: err.response.data.message,
          });
          sessionStorage.removeItem('pad-submissions');
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchPadSubmissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersTrigger, dispatch, keyword, country, us_state]);

  useUnmount(() => {
    if (cleanOnUnmount) {
      sessionStorage.removeItem('pad-submissions');
      reports.cleanReports()(dispatch);
    }
  });

  useEffect(() => {
    if (reportsList?.data) {
      setDataSource(() =>
        reportsList.data.map((data) => ({ ...data, name: data.contact.name })),
      );
    }
  }, [reportsList]);

  const onTableChange = useCallback(
    (pagination, tableFilters, sorter, extra) => {
      const newState = {};
      if (extra.action === 'paginate') newState.page = pagination.current;
      else if (extra.action === 'sort') {
        newState.sort_column = sorter.field;
        newState.sort_direction = sorter.order;
      }
      setState((prevState) => ({ ...prevState, ...newState }));
    },
    [setState],
  );

  const columns = [
    {
      dataIndex: 'name',
      title: 'Name',
      render: (data, rowData) => (
        <Button
          type="link"
          onClick={goTo(`/dashboard/user-view/${rowData.contact.user_id}`)}
        >
          {data}
        </Button>
      ),
    },
    {
      dataIndex: 'public_pads_moderated',
      title: 'Moderated public pads',
      sortDirections: ['descend', 'ascend', 'descend'],
      sortOrder: sort_column === 'public_pads_moderated' && sort_direction,
      sorter: (a, b) => a.public_pads_moderated - b.public_pads_moderated,
      render: (data, rowData) => (
        <Button
          size="small"
          onClick={goTo(`/dashboard/user-view/${rowData.contact.user_id}`)}
        >
          {data}
        </Button>
      ),
    },
    {
      dataIndex: 'public_pads_not_moderated',
      title: 'Not moderated public pads',
      sortDirections: ['descend', 'ascend', 'descend'],
      sortOrder: sort_column === 'public_pads_not_moderated' && sort_direction,
      sorter: (a, b) =>
        a.public_pads_not_moderated - b.public_pads_not_moderated,
      render: (data, rowData) => (
        <Button
          size="small"
          onClick={goTo(`/dashboard/user-view/${rowData.contact.user_id}`)}
        >
          {data}
        </Button>
      ),
    },
    {
      dataIndex: 'private_pads',
      title: 'Private pads',
      sortDirections: ['descend', 'ascend', 'descend'],
      sortOrder: sort_column === 'private_pads' && sort_direction,
      sorter: (a, b) => a.private_pads - b.private_pads,
      render: (data, rowData) => (
        <Button
          size="small"
          onClick={goTo(
            `/dashboard/user-view/${rowData.contact.user_id}?filter_id=1`,
          )}
        >
          {data}
        </Button>
      ),
    },
    {
      dataIndex: 'ratings',
      title: 'Ratings',
      sortDirections: ['descend', 'ascend', 'descend'],
      sortOrder: sort_column === 'ratings' && sort_direction,
      sorter: (a, b) => a.ratings - b.ratings,
    },
    {
      dataIndex: 'reviews',
      title: 'Reviews',
      sortDirections: ['descend', 'ascend', 'descend'],
      sortOrder: sort_column === 'reviews' && sort_direction,
      sorter: (a, b) => a.reviews - b.reviews,
    },
  ];

  const onSubmitFilters = useCallback(
    (values) => {
      const mockValues = {
        keyword: null,
        country: null,
        us_state: null,
      };
      const data = { ...mockValues, ...values };

      setState((prevState) => ({ ...prevState, ...data }));
    },
    [setState],
  );

  if (error)
    return (
      <PageHeader className="page-main-header" title={'Pad Submissions'}>
        <Alert
          showIcon
          type="error"
          message={`Error ${error.status}`}
          description={error.message}
        />
      </PageHeader>
    );

  return (
    <div>
      <PageHeader className="page-main-header" title={'Pad Submissions'}>
        <div className="filters-container pad-submissions-filters">
          <Form
            layout="inline"
            onFinish={onSubmitFilters}
            initialValues={{
              keyword,
              country: country || null,
              us_state: us_state || null,
            }}
          >
            <Form.Item label="Name" name="keyword">
              <Input placeholder="John Doe" />
            </Form.Item>
            <Form.Item label="Country" name="country">
              <Select
                placeholder="United Kingdom"
                options={countries}
                optionFilterProp="label"
                onChange={(value) =>
                  value === 'US'
                    ? setIsUSSelected(true)
                    : setIsUSSelected(false)
                }
                showSearch
              />
            </Form.Item>
            {isUSSelected && (
              <Form.Item label="State" name="us_state">
                <Select
                  placeholder="Alaska"
                  options={USStates}
                  optionFilterProp="label"
                  showSearch
                />
              </Form.Item>
            )}
            <Button htmlType="submit" type="primary">
              Search
            </Button>
          </Form>
        </div>
      </PageHeader>
      <div className="page-main-content pad-submissions">
        <Table
          columns={columns}
          rowKey={(e) => e.contact?.id}
          dataSource={dataSource}
          loading={loading}
          onChange={onTableChange}
          pagination={{
            position: ['topRight', 'bottomRight'],
            showSizeChanger: false,
            showQuickJumper: true,
            pageSize: 25,
            total: dataSource.length,
            current: page,
            showTotal: (total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total} listings`,
          }}
        />
      </div>
    </div>
  );
};

export default PadSubmissions;
