import { BOOTSTRAP_PUSH } from '../actionTypes';
import api from '../../utils/appApi';

export const setGlobalPrivateRequisites = () => (dispatch) =>
  Promise.allSettled([
    api.warning.getList(),
    api.facility.getList(),
    api.region.getList(),
    api.tag.getList(),
    api.tag.getListUserTags(),
    api.helipaddyStats.get(),
    api.region.getCountries(),
    api.region.getUSStates(),
  ])
    .then((settledPromises) => {
      const [
        warnings,
        facilities,
        regions,
        tags,
        userTags,
        helipaddyStats,
        countries,
        USStates,
      ] = settledPromises;

      const payload = {
        warnings: warnings.value?.data || [],
        facilities: facilities.value?.data || [],
        regions: regions.value?.data || [],
        tags: tags.value?.data || [],
        userTags: userTags.value?.data || [],
        helipaddyStats: helipaddyStats.value?.data || {},
        countries: countries.value?.data || {},
        USStates: USStates.value?.data || {},
      };

      dispatch({
        type: BOOTSTRAP_PUSH,
        payload,
      });

      return settledPromises;
    })
    .catch((err) => Promise.reject(err));

export const setGlobalPublicRequisites = () => (dispatch) =>
  Promise.allSettled([
    api.status.getList(),
    api.category.getList(),
    api.contactType.getList(),
    api.pad.getLandingFees(),
  ])
    .then((settledPromises) => {
      const [statuses, categories, contactTypes, landingFees] = settledPromises;

      const payload = {
        statuses: statuses.value?.data || [],
        categories: categories.value?.data || [],
        contactTypes: contactTypes.value?.data || [],
        landingFees: landingFees.value?.data || {},
      };

      dispatch({
        type: BOOTSTRAP_PUSH,
        payload,
      });

      return settledPromises;
    })
    .catch((err) => Promise.reject(err));
